import styled from '@emotion/styled';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { TrashIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Trash';
import { COLORS3, SPACING } from '@zola/zola-ui/src/styles/emotion';
import React, { forwardRef } from 'react';

export const DeleteItemButton = styled(LinkV2)<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${SPACING.XXS};
  ${({ disabled }) => disabled && 'opacity: 0.5; cursor: not-allowed;'}
`;

export const DeleteContainer = styled.div<{ isGroup: boolean }>`
  margin-top: ${SPACING.S32};

  ${({ isGroup }) =>
    !isGroup &&
    `
      padding-top: ${SPACING.S32};
      border-top: 1px solid ${COLORS3.BLACK_010};`}
`;

type BudgetDeleteEntityButtonProps = {
  type: 'payment' | 'item' | 'group';
  disabled?: boolean;
  onDelete: () => void;
};

const BudgetDeleteEntityButton = forwardRef(
  ({ type, disabled = false, onDelete }: BudgetDeleteEntityButtonProps, ref) => (
    <DeleteContainer isGroup={type === 'group'}>
      <DeleteItemButton
        role="button"
        onClick={onDelete}
        innerRef={ref}
        color="red"
        inline
        noUnderline
        disabled={disabled}
      >
        <TrashIcon height={20} width={20} /> Delete {type}
      </DeleteItemButton>
    </DeleteContainer>
  )
);

export default BudgetDeleteEntityButton;
