import _groupBy from 'lodash/groupBy';
import { UNCATEGORIZED } from 'photobooks/constants/gallery';

export const groupByCategory = photos => {
  const categoryGroups = _groupBy(
    photos || [],
    p => p.photobook_project_item_media_category_uuid || UNCATEGORIZED
  );

  return { [UNCATEGORIZED]: [], ...categoryGroups };
};

// Returns a completely empty image. Used to get rid of the default drag preview in react-dnd.
let emptyImage;

export const getEmptyImage = () => {
  if (!emptyImage) {
    emptyImage = new Image();
    emptyImage.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
  }

  return emptyImage;
};
