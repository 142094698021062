import React, { FormEvent, KeyboardEvent, useCallback, useRef, useState } from 'react';
import Autosuggest, { GetSuggestionValue, SuggestionSelectedEventData } from 'react-autosuggest';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import cx from 'classnames';
import { Form, useForm, useFormState } from 'react-final-form';

import type { WMarketplaceLocationEntity } from '@zola-helpers/client/dist/es/api/marketplace';
import type { USCityView } from '@zola/svc-marketplace-ts-types';
import type {
  WBudgetItemTypeView,
  WBudgetView,
  WSuggestedTotalBudgetView,
} from '@zola/svc-web-api-ts-client';

import { setCookie, deleteCookie } from '@zola-helpers/client/dist/es/util/storage';
import { isDesktopV2 } from '@zola-helpers/client/dist/es/util/responsive';
import pluralize from '@zola-helpers/client/dist/es/transformers/pluralize';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';

import Modal, { ModalV2Props } from '@zola/zola-ui/src/components/Modal/ModalV2';
import { Title2 } from '@zola/zola-ui/src/typography/Headings';
import { BodySmall } from '@zola/zola-ui/src/typography/Paragraphs';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { InputFieldAddOnType } from '@zola/zola-ui/src/components/Form/InputFieldV2/InputFieldAddOn';
import FinalFormInput from '@zola/zola-ui/src/components/Form/inputV3/FinalFormInput';
import { REQUIRED } from '@zola/zola-ui/src/components/Form/util/validations';
import {
  debouncedFetchSuggestedLocations,
  parseCityState,
} from '@zola/zola-ui/src/components/OnboardV2/common/steps/WeddingLocationStep/helpers';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

import bellCurveGraph from 'assets/images/budget/bell-curve-graph.png';
import { getUserContext as fetchUserContext } from 'actions/UserActions';
import {
  getAccountVendors,
  getMarketplaceLocation,
  MappedWAccountVendorView,
  updateWeddingLocation,
} from 'api/vendorMarketplaceApi';
import { MIN_NUMBER_1000 } from 'components/common/form/commonValidations';
import useSimpleScrollLock from 'lib/hooks/useSimpleScrollLock';
import { useAppDispatch } from 'reducers/useAppDispatch';
import { useAppSelector } from 'reducers/useAppSelector';
import {
  getUserContext,
  getUserWeddingCity,
  getUserWeddingGuestCount,
  getUserWeddingStateProvince,
} from 'selectors/user/userSelectors';
import { handleBudgetRouteChange } from '../routes/handleBudgetRouteChange';
import {
  createBudget,
  getBudgetItemTypes,
  updateBudget,
  getSuggestedBudgetTotal,
} from '../util/api';
import { mapCostToFormattedString } from '../util/mappers';
import { useBudgetContext } from '../context';

import {
  Container,
  HeadingContainer,
  Dek,
  Flex,
  FormFields,
  GapControl,
  AutosuggestContainer,
  FormLabel,
  AssetContainer,
  Animation,
  BellCurveGraph,
  GraphLineText,
  GraphLineContainer,
  GraphText,
  NoWhiteSpace,
  GuestCount,
  Total,
  ButtonContainer,
} from './BudgetDetailsModal.styles';

type BudgetDetailsFormValues = {
  weddingCity?: string | null;
  weddingStateProvince?: string | null;
  weddingGuestCount?: number;
  budgetedCents: string;
};

type BudgetDetailsFormProps = {
  onAnimate: (trigger: boolean) => void;
  onReceiveBudgetSuggestions: (suggestedValues: WSuggestedTotalBudgetView) => void;
};

const BudgetDetailsForm = ({ onAnimate, onReceiveBudgetSuggestions }: BudgetDetailsFormProps) => {
  const {
    actions: { receiveMarketplaceLocation },
    state: {
      marketplaceLocation: { citySlug, source },
    },
  } = useBudgetContext();

  const { batch, change } = useForm();
  const {
    values: { weddingCity, weddingStateProvince, weddingGuestCount },
  } = useFormState<BudgetDetailsFormValues>();

  const autoSuggestElement = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState<string>(
    weddingCity && weddingStateProvince ? `${weddingCity}, ${weddingStateProvince}` : ''
  );
  const [suggestedLocations, setSuggestedLocations] = useState<WMarketplaceLocationEntity[]>([]);

  /** using this state to prevent the suggestions dropdown from showing on first render */
  const [firstRender, setFirstRender] = useState(false);
  useEffectOnce(() => {
    setFirstRender(true);
  });

  const onTotalBudgetBlur: React.FocusEventHandler<HTMLInputElement> = e => {
    if (e.target.value) {
      change(
        'budgetedCents',
        parseFloat(e.target.value.replace(/,/, '')).toLocaleString('en-US', {
          minimumFractionDigits: 2,
        })
      );
    }
  };

  const onTotalBudgetFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '0.00') {
      change('budgetedCents', '');
    }
  };

  const handleFetchSuggestedBudgetTotal = async ({
    slug,
    count,
  }: {
    slug: string | undefined;
    count: string | number | undefined;
  }) => {
    onAnimate(true);
    const suggestedBudgetValues = await getSuggestedBudgetTotal({
      location: slug,
      guest_count: Number(count),
    });
    onReceiveBudgetSuggestions(suggestedBudgetValues);
    onAnimate(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchSuggestedBudgetTotal = useCallback(
    _debounce(handleFetchSuggestedBudgetTotal, 500),
    []
  );

  const handleGuestChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFetchSuggestedBudgetTotal({
      slug: source !== 'DEFAULT' ? (citySlug as string) : undefined,
      count: e.target.value,
    })?.catch(() => undefined);
  };

  const setNoLocation = () => {
    batch(() => {
      change('weddingCity', '');
      change('weddingStateProvince', '');
    });
    handleFetchSuggestedBudgetTotal({ slug: undefined, count: weddingGuestCount }).catch(
      () => undefined
    );
  };

  const handleSuggestionBlur = (e: React.FocusEvent<HTMLElement>) => {
    if (!(e.target as HTMLInputElement).value) {
      setNoLocation();
    }
  };

  // --------------------------------------------------------------------------
  // Begin Auto-Suggest
  // --------------------------------------------------------------------------
  const onSuggestionSelected = (
    event: FormEvent,
    { suggestion: location }: SuggestionSelectedEventData<WMarketplaceLocationEntity>
  ): void => {
    event.preventDefault();
    const { type, name, slug } = location;

    if (type === 'US_CITY' || type === 'NOT_LISTED') {
      deleteCookie('citySlug');
      if (slug) {
        setCookie('citySlug', slug, 1);
      }
      if (type === 'US_CITY') {
        const { city = '', state_province = '' } = parseCityState(name) || {};
        batch(() => {
          change('weddingCity', city);
          change('weddingStateProvince', state_province);
        });
        updateWeddingLocation({ location: { name: city, stateCode: state_province } as USCityView })
          .then(getMarketplaceLocation)
          .then(res => {
            if (res && !_isEmpty(res)) {
              receiveMarketplaceLocation(res);
              if (res.citySlug) {
                handleFetchSuggestedBudgetTotal({
                  slug: res.citySlug,
                  count: weddingGuestCount,
                }).catch(() => undefined);
              }
            }
          })
          .catch(() => undefined);
      }
      if (type === 'NOT_LISTED') {
        setNoLocation();
      }
    } else if (autoSuggestElement.current) {
      autoSuggestElement.current.focus();
    }

    const suggestEl = document?.getElementById('location-autosuggest');
    if (location.type !== 'PLACEHOLDER' && suggestEl) {
      suggestEl.blur();
    }
  };

  // Used as the value for the input
  const getSuggestionValue: GetSuggestionValue<WMarketplaceLocationEntity> = suggestion => {
    if (suggestion?.type === 'US_CITY' || suggestion?.type === 'NOT_LISTED') {
      return suggestion.name;
    }
    return '';
  };

  // Used in render suggestion
  const getSuggestionText = (location: WMarketplaceLocationEntity): string | null => {
    if (location.type === 'PLACEHOLDER') {
      return location.name;
    }
    return getSuggestionValue(location);
  };

  const handleEnterKeyPress = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const suggestion = suggestedLocations && suggestedLocations[0];
      if (suggestion) {
        onSuggestionSelected(event, {
          suggestion,
          suggestionValue: suggestion.name,
          suggestionIndex: 0,
          sectionIndex: 0,
          method: 'enter',
        });
      }
      const suggestEl = document?.getElementById('location-autosuggest');
      if (suggestEl) suggestEl.blur();
    }
  };

  const renderSuggestion = (location: WMarketplaceLocationEntity): JSX.Element => {
    const classes = cx('auto-suggestion-container', {
      'auto-suggestion-container-placeholder': location.type === 'PLACEHOLDER',
    });

    return <div className={classes}>{getSuggestionText(location)}</div>;
  };

  const handleShouldRenderSuggestions = () => firstRender;
  // --------------------------------------------------------------------------
  // End Auto-Suggest
  // --------------------------------------------------------------------------

  useEffectOnce(() => {
    handleFetchSuggestedBudgetTotal({
      slug: source !== 'DEFAULT' ? (citySlug as string) : undefined,
      count: weddingGuestCount,
    }).catch(() => undefined);
  });

  return (
    <FormFields>
      {isDesktopV2() && <FormLabel htmlFor="location-autosuggest">Wedding location</FormLabel>}
      <GapControl>
        <AutosuggestContainer>
          {!isDesktopV2() && <FormLabel htmlFor="location-autosuggest">Wedding location</FormLabel>}
          <Autosuggest<WMarketplaceLocationEntity>
            suggestions={suggestedLocations}
            onSuggestionSelected={onSuggestionSelected}
            onSuggestionsFetchRequested={({ value }): void =>
              debouncedFetchSuggestedLocations(value, setSuggestedLocations)
            }
            onSuggestionsClearRequested={(): void => setSuggestedLocations([])}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            shouldRenderSuggestions={handleShouldRenderSuggestions}
            inputProps={{
              id: 'location-autosuggest',
              value: inputValue || '',
              autoComplete: 'off',
              autoFocus: false,
              placeholder: 'City, State',
              ref: autoSuggestElement,
              required: false,
              onKeyUp: handleEnterKeyPress,
              onChange: (_e, { newValue }): void => {
                setInputValue(newValue);
              },
              onBlur: handleSuggestionBlur,
            }}
          />
        </AutosuggestContainer>
        <GuestCount>
          <FinalFormInput
            name="weddingGuestCount"
            label="Guest count"
            onChange={handleGuestChange}
          />
        </GuestCount>
        <Total>
          <FinalFormInput
            name="budgetedCents"
            label="Total budget"
            tooltip="The absolute max amount you'd want to spend."
            addOn={InputFieldAddOnType.CASH}
            onBlur={onTotalBudgetBlur}
            onFocus={onTotalBudgetFocus}
            ref={inputRef}
            validate={[REQUIRED, MIN_NUMBER_1000]}
            isRequired
          />
        </Total>
        {isDesktopV2() && (
          <BodySmall color="BLACK_075">You can always change these later</BodySmall>
        )}
      </GapControl>
    </FormFields>
  );
};

type BudgetDetailsModalProps = Pick<ModalV2Props, 'triggerRef'> & {
  onClose?: () => void;
};

const BudgetDetailsModal = ({ onClose, triggerRef }: BudgetDetailsModalProps) => {
  const dialogHeadingId = 'budget-onboarding-modal';
  const dispatch = useAppDispatch();
  const { budget } = useAppSelector(getUserContext);
  const hasBudget = Boolean(budget);
  const userCity = useAppSelector(getUserWeddingCity);
  const userStateProvince = useAppSelector(getUserWeddingStateProvince);
  const userGuestCount = useAppSelector(getUserWeddingGuestCount);
  const {
    actions: { receiveBudget, receiveItemTypes, receiveAccountVendors, receiveBusyState },
    state: {
      budget: { budgeted_cents: budgetedCents, uuid: budgetUuid },
      marketplaceLocation: { city: marketplaceCity, stateProvince, source },
    },
  } = useBudgetContext();
  useSimpleScrollLock(true);

  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [suggestedBudgetValues, setSuggestedBudgetValues] = useState<WSuggestedTotalBudgetView>({
    budget_estimate_lower_bound_cents: 0,
    budget_estimate_mid_point_cents: 0,
    budget_estimate_upper_bound_cents: 0,
    location: 'united-states',
    guest_count: userGuestCount || 0,
  });
  const handleTriggerAnimation = (trigger: boolean) => setTriggerAnimation(trigger);
  const handleReceiveBudgetSuggestions = (suggestedValues: WSuggestedTotalBudgetView) =>
    setSuggestedBudgetValues(suggestedValues);

  const handleCreateOrUpdateBudget = (values: BudgetDetailsFormValues) => {
    const requestVals = {
      wedding_details: {
        wedding_city: values?.weddingCity || undefined,
        wedding_state_province: values?.weddingStateProvince || undefined,
        wedding_guest_count: values?.weddingGuestCount,
      },
      budgeted_cents: values?.budgetedCents
        ? parseFloat(values?.budgetedCents.replace(/,/, '')) * 100
        : undefined,
    };

    Promise.all([
      hasBudget
        ? updateBudget({ ...requestVals, budget_uuid: budgetUuid as string })
        : createBudget({ ...requestVals, origin: 'BUDGET_TOOL', template_key: 'default-v2' }),
      getBudgetItemTypes(),
      getAccountVendors(),
    ])
      .then((responses: [WBudgetView, WBudgetItemTypeView[], MappedWAccountVendorView[]]) => {
        const [newBudget, itemTypes, accountVendors] = responses;
        receiveBudget(newBudget);
        receiveItemTypes(itemTypes);
        receiveAccountVendors(accountVendors);
        receiveBusyState(false);
      })
      .then(() => {
        if (hasBudget) {
          dispatch(
            toastsActions.positive({
              headline: 'Updated estimates',
            })
          );
        }
        onClose?.();
        dispatch(fetchUserContext()).catch(() => undefined);
        handleBudgetRouteChange();
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({
            headline:
              'There was an error creating your budget. Please refresh the page or contact customer service.',
          })
        );
      });
  };

  const renderSuggestedGuestCount = useCallback(
    (weddingGuestCount: number) => {
      const count = weddingGuestCount || userGuestCount;
      if (!count) return ' with 100 guests'; // default suggestions are based on 100 guests
      if (count < 50) return ' with <50 guests';
      if (count > 300) return ' with 300+ guests';
      return ` with ${pluralize('guest', 'guests', count)}`;
    },
    [userGuestCount]
  );

  const initialBudgetedCents = ((budgetedCents || 0) / 100).toLocaleString('en-US', {
    minimumFractionDigits: 2,
  });

  const disabledClose = () => undefined;
  const fallBackToMarketplaceLocation = !hasBudget && source !== 'DEFAULT';

  return (
    <Modal
      onClose={hasBudget ? onClose || disabledClose : disabledClose}
      rootId="budget-modal-root"
      triggerRef={triggerRef}
      dialogHeadingId={dialogHeadingId}
      showCloseButton={hasBudget}
      closeOnOverlayClick={hasBudget}
      closeOnEscape={hasBudget}
    >
      <Form<BudgetDetailsFormValues>
        onSubmit={handleCreateOrUpdateBudget}
        initialValues={{
          budgetedCents: initialBudgetedCents || '',
          weddingCity: userCity || (fallBackToMarketplaceLocation ? marketplaceCity : undefined),
          weddingStateProvince:
            userStateProvince || (fallBackToMarketplaceLocation ? stateProvince : undefined),
          weddingGuestCount: userGuestCount || undefined,
        }}
      >
        {({ handleSubmit, values, submitting, hasValidationErrors }) => (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <Container onSubmit={handleSubmit}>
            {isDesktopV2() ? (
              <HeadingContainer>
                <Title2 presentation="h3">Let&apos;s build your total budget</Title2>
                <Dek color="BLACK_075">
                  We&apos;ll create your estimate using data from millions of couples with a similar
                  location and guest count.
                </Dek>
              </HeadingContainer>
            ) : (
              <HeadingContainer>
                <Title2 presentation="h3">{budgetedCents ? 'Update' : 'Setup'} your budget</Title2>
                <Dek color="BLACK_075">You can always change these later.</Dek>
              </HeadingContainer>
            )}
            <Flex>
              <BudgetDetailsForm
                onAnimate={handleTriggerAnimation}
                onReceiveBudgetSuggestions={handleReceiveBudgetSuggestions}
              />
              <AssetContainer>
                {suggestedBudgetValues.location && (
                  <GraphText>
                    Total budget from couples in{' '}
                    <NoWhiteSpace>
                      {values?.weddingCity && values?.weddingStateProvince
                        ? `${values?.weddingCity ? `${values.weddingCity}, ` : ''}${
                            values?.weddingStateProvince || ''
                          }`
                        : 'the US'}
                    </NoWhiteSpace>
                    {renderSuggestedGuestCount(values.weddingGuestCount || 0)}:
                  </GraphText>
                )}
                <Animation>
                  <BellCurveGraph src={bellCurveGraph} alt="" triggerAnimation={triggerAnimation} />
                  <GraphLineContainer triggerAnimation={triggerAnimation}>
                    <GraphLineText>
                      {mapCostToFormattedString(
                        suggestedBudgetValues.budget_estimate_lower_bound_cents,
                        true
                      )}
                    </GraphLineText>
                    <GraphLineText>
                      {mapCostToFormattedString(
                        suggestedBudgetValues.budget_estimate_mid_point_cents,
                        true
                      )}
                    </GraphLineText>
                    <GraphLineText>
                      {mapCostToFormattedString(
                        suggestedBudgetValues.budget_estimate_upper_bound_cents,
                        true
                      )}
                    </GraphLineText>
                  </GraphLineContainer>
                </Animation>
              </AssetContainer>
            </Flex>
            <ButtonContainer>
              <ButtonV3
                type="submit"
                fullWidth={isDesktopV2()}
                size="large"
                disabled={submitting || hasValidationErrors}
              >
                {hasBudget ? 'Update' : 'Next'}
              </ButtonV3>
            </ButtonContainer>
          </Container>
        )}
      </Form>
    </Modal>
  );
};

export default BudgetDetailsModal;
