import fetch from 'isomorphic-fetch';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';

import handleZoomErrorNotifications from 'actions/helpers/handleZoomErrorNotifications';
import type {
  CreateCmsEventRequest,
  UpdateCmsEventRequest,
  WCmsEventPageView,
  WCmsEventView,
  WSuggestedAttireOptionView,
  WSuggestedRsvpQuestionView,
  WZoomErrorView,
} from '@zola/svc-web-api-ts-client';
import type { ToastAction } from '@zola-helpers/client/dist/types/redux/toasts/toastActionTypes';
import * as ModalActions from 'actions/ModalActions';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { AppThunk, AppDispatch } from 'reducers/useAppDispatch';
import * as ActionTypes from './types/EventActionTypes';
import type { EventActionTypes } from './types/EventActionTypes';
import featureFlags from '../util/featureFlags';

import { handleErrors } from './util';
import type { TResponse } from './types';

// Getters for a WCmsEventView
const getEventError = (eventView: WCmsEventView): WZoomErrorView.TypeEnum | null =>
  eventView.virtual_meeting?.error?.type || null;
const getEventId = (eventView: WCmsEventView): number | undefined => eventView.id;

// Note: Some lint issues here. Read more: https://stackoverflow.com/questions/62079477/line-0-parsing-error-cannot-read-property-map-of-undefined
const reduceVirtualEventErrors = (
  acc: unknown[], // Array<[eventId: number | undefined, errorType: string]>,
  eventView: WCmsEventView
): unknown[] => {
  const errorType = getEventError(eventView);
  const eventId = getEventId(eventView);
  if (errorType) {
    switch (errorType.toString()) {
      case 'UNAUTHORIZED':
      case 'OUT_OF_SYNC':
        acc.push([eventId, errorType]);
        return acc;
      case 'RATE_LIMIT':
      default:
        return acc;
    }
  }
  return acc;
};

const getVirtualEventsError = (eventViewArray: WCmsEventView[]): unknown => {
  // Array<[eventId: number | undefined, errorType: string]> => {
  return eventViewArray.reduce(reduceVirtualEventErrors, []);
};

export function requestEvent(): EventActionTypes {
  return {
    type: ActionTypes.REQUEST_EVENT,
  };
}

export function receiveZoomEvent(json: WCmsEventView): EventActionTypes {
  return {
    type: ActionTypes.RECEIVE_ZOOM_EVENT,
    payload: json,
  };
}

export function resetZoomEvent(): EventActionTypes {
  return {
    type: ActionTypes.RESET_ZOOM_EVENT,
  };
}

export function requestEvents(): EventActionTypes {
  return {
    type: ActionTypes.REQUEST_EVENTS,
  };
}

export function requestEventsRsvpPage(): EventActionTypes {
  return {
    type: ActionTypes.REQUEST_EVENTS_RSVP_PAGE,
  };
}

export function receiveEvents(json: WCmsEventView[]): EventActionTypes {
  return {
    type: ActionTypes.RECEIVE_EVENTS,
    payload: {
      events: json,
    },
  };
}

export function receiveEventsByDate(json: WCmsEventPageView): EventActionTypes {
  return {
    type: ActionTypes.RECEIVE_EVENTS_BY_DATE,
    payload: {
      groupedEventsByDate: json,
    },
  };
}

export function requestSuggestedAttireOptions(): EventActionTypes {
  return {
    type: ActionTypes.SUGGESTED_ATTIRE_OPTIONS_REQUESTED,
  };
}

export function receiveSuggestedAttireOptions(
  data: WSuggestedAttireOptionView[]
): EventActionTypes {
  return {
    type: ActionTypes.SUGGESTED_ATTIRE_OPTIONS_RECEIVED,
    payload: data,
  };
}

export function receiveEventsRsvpPage(json: WCmsEventPageView): EventActionTypes {
  return {
    type: ActionTypes.RECEIVE_EVENTS_RSVP_PAGE,
    payload: {
      events: json.events,
      hidden: json.hidden,
      id: json.id,
    },
  };
}

export function requestSuggestions(): EventActionTypes {
  return {
    type: ActionTypes.SUGGESTIONS_REQUESTED,
  };
}

export function receiveSuggestions(data: WSuggestedRsvpQuestionView[]): EventActionTypes {
  return {
    type: ActionTypes.SUGGESTIONS_RECEIVED,
    payload: data,
  };
}

export function selectEvent(selectedId?: number): EventActionTypes {
  return {
    type: ActionTypes.SELECT_EVENT,
    payload: {
      selectedId,
    },
  };
}

export function initializeSelectedEvent(): EventActionTypes {
  return {
    type: ActionTypes.INITIALIZE_SELECTED_EVENT,
  };
}

export function fetchEvents(): AppThunk<Promise<WCmsEventView[]>> {
  return (dispatch, getState): Promise<WCmsEventView[]> => {
    const state = getState();
    const currentToasts = state.toasts;
    dispatch(requestEvents());
    return fetch('/web-api/v2/cms/page/event', {
      credentials: 'same-origin',
    })
      .then(handleErrors)
      .then(response => response.json())
      .then(response => handleZoomErrorNotifications({ dispatch, response, currentToasts }))
      .then(json => {
        dispatch(receiveEvents(json.events));
        return json.events;
      });
  };
}

export type MapWCmsEventViewType = {
  [key: string]: Array<WCmsEventView>;
};

export function fetchEventsByDate(): AppThunk<Promise<void>> {
  return (dispatch, getState): Promise<void> => {
    const state = getState();
    const currentToasts = state.toasts;
    dispatch(requestEvents());
    return fetch('/web-api/v2/cms/events-by-date', {
      credentials: 'same-origin',
    })
      .then(handleErrors)
      .then(response => response.json())
      .then((json: MapWCmsEventViewType) => {
        const eventsByDay = Object.values(json);
        const events = eventsByDay.reduce((acc, dailyEvents) => {
          dailyEvents.forEach(e => acc.push(e));
          return acc;
        }, []);
        const zoomErrors = getVirtualEventsError(events);
        const spoofedResponseObj = { zoomErrors };
        handleZoomErrorNotifications({
          dispatch,
          response: spoofedResponseObj,
          currentToasts,
        });
        return json;
      })
      .then(json => {
        dispatch(receiveEventsByDate(json));
      });
  };
}

function isWCmsEventViewResponse<T>(response: T | TResponse<T>): response is TResponse<T> {
  return Boolean((response as TResponse<T>).status);
}

const handleEventErrors = <T>(response: T | TResponse<T>, dispatch: AppDispatch): T => {
  if (!isWCmsEventViewResponse(response)) {
    return response;
  }
  const { domain } = response.category || {};
  const defaultHeadline = 'There was an issue with your request. Please try again.';
  let headline;
  switch (domain) {
    case 'marketplace-vendor':
      headline = response.message || defaultHeadline;
      break;
    case 'meal-option':
      headline = 'Cannot Delete Meal Option: already selected by guests.';
      break;
    case 'rsvp-question':
      headline = 'Cannot Delete RSVP Question: already answered by guests.';
      break;
    case 'seating-chart':
      headline = 'Cannot Delete Event: please remove seating chart first.';
      break;
    default:
      headline = defaultHeadline;
  }
  dispatch(toastsActions.negative({ headline }));
  throw new Error('error');
};

export const handleDeleteEventErrors = <T>(
  response: T | TResponse<T>,
  eventUuid: string,
  dispatch: AppDispatch,
  callback?: () => void
): T => {
  if (!isWCmsEventViewResponse(response)) {
    return response;
  }

  const apiErrorCategory = response.category;
  const apiErrors = response.errors;
  const modalType = featureFlags.get('deleteEventWithSeatingChart')
    ? 'DELETE_EVENT_EXCEPTION'
    : 'DELETE_RSVP_EVENT';

  if (apiErrorCategory) {
    dispatch(
      ModalActions.showModal(
        modalType,
        { eventUuid, apiErrorCategory, apiErrors, callback },
        { size: 'md' }
      )
    );
  }

  throw new Error('error');
};

export type CreateCmsEventRequestClientType = Omit<
  CreateCmsEventRequest,
  'wedding_account_id' | 'created_by_user_object_id' | 'account_id'
>;

export function createZoomEvent(
  eventRequest: CreateCmsEventRequestClientType
): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    return ApiService.post<WCmsEventView>('/web-api/v2/cms/events', eventRequest)
      .then(e => handleEventErrors(e, dispatch))
      .then(event => {
        dispatch(receiveZoomEvent(event));
        dispatch(toastsActions.positive({ headline: 'Zoom Meeting Created' }));
      });
  };
}

export type UpdateCmsEventRequestClientType = Omit<
  UpdateCmsEventRequest,
  'wedding_account_id' | 'created_by_user_object_id' | 'account_id'
>;
export function updateZoomEvent(
  eventRequest: UpdateCmsEventRequestClientType,
  customMsg?: string
): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    return ApiService.post<WCmsEventView>('/web-api/v2/cms/events/update', eventRequest)
      .then(e => handleEventErrors(e, dispatch))
      .then(event => {
        dispatch(receiveZoomEvent(event));
        dispatch(toastsActions.positive({ headline: customMsg || 'Zoom Meeting Updated' }));
      });
  };
}

export function createEvent(
  eventRequest: CreateCmsEventRequestClientType
): AppThunk<Promise<ToastAction>> {
  return (dispatch): Promise<ToastAction> => {
    return ApiService.post('/web-api/v2/cms/events', eventRequest)
      .then(e => handleEventErrors(e, dispatch))
      .then(() => dispatch(fetchEventsByDate()))
      .then(() => dispatch(toastsActions.positive({ headline: 'Event Added' })));
  };
}

export function createEventAndUpdateSelectedId(
  eventRequest: CreateCmsEventRequestClientType
): AppThunk<Promise<ToastAction>> {
  return (dispatch): Promise<ToastAction> => {
    return ApiService.post<WCmsEventView>('/web-api/v2/cms/events', eventRequest)
      .then(e => handleEventErrors(e, dispatch))
      .then(response => dispatch(selectEvent(response.id)))
      .then(() => dispatch(fetchEvents()))
      .then(() => dispatch(toastsActions.positive({ headline: 'Event Added' })));
  };
}

export function updateEvent(
  eventRequest: UpdateCmsEventRequestClientType
): AppThunk<Promise<ToastAction>> {
  return (dispatch): Promise<ToastAction> => {
    return ApiService.post('/web-api/v2/cms/events/update', eventRequest)
      .then(e => handleEventErrors(e, dispatch))
      .then(() => dispatch(fetchEventsByDate()))
      .then(() => dispatch(toastsActions.positive({ headline: 'Event Updated' })));
  };
}

export function updateCMSEvent(
  eventRequest: UpdateCmsEventRequestClientType
): AppThunk<Promise<ToastAction>> {
  return (dispatch): Promise<ToastAction> => {
    return ApiService.post('/web-api/v2/cms/events/update', eventRequest)
      .then(e => handleEventErrors(e, dispatch))
      .then(() => dispatch(fetchEvents()))
      .then(() => dispatch(toastsActions.positive({ headline: 'Event Updated' })));
  };
}

export const forceDeleteEventByUuid = (
  eventUuid: string,
  fetchByDate = true,
  callback?: () => void
): AppThunk<Promise<ToastAction | null>> => {
  return async dispatch => {
    return ApiService.post(`/web-api/v2/cms/events/delete/uuid/${eventUuid}`)
      .then(() => {
        if (fetchByDate) dispatch(fetchEventsByDate()).catch(() => undefined);
        else dispatch(fetchEvents()).catch(() => undefined);
        if (callback) {
          callback();
        }
      })
      .then(() => dispatch(toastsActions.positive({ headline: 'Event Deleted' })));
  };
};

export function deleteEventByUuid(
  eventUuid: string,
  callback?: () => void
): AppThunk<Promise<ToastAction | null>> {
  return async (dispatch): Promise<ToastAction | null> => {
    // Check if safe to delete event - throws error if not
    await ApiService.post(`/web-api/v2/cms/events/delete/uuid/${eventUuid}/verify`).then(e => {
      handleDeleteEventErrors(e, eventUuid, dispatch, callback);
    });

    // Since safe (no errors thrown), delete event
    return dispatch(forceDeleteEventByUuid(eventUuid, false, callback));
  };
}

export function deleteEvent(id: number, fetchByDate = true): AppThunk<Promise<ToastAction | null>> {
  return async (dispatch, getState): Promise<ToastAction | null> => {
    const eventData = getState().events.events;
    const eventUuid = eventData.byId[id].uuid;

    if (featureFlags.get('deleteEventWithSeatingChart')) {
      // Check if safe to delete event - throws error if not
      await ApiService.post(`/web-api/v2/cms/events/delete/uuid/${eventUuid}/verify`).then(e => {
        handleDeleteEventErrors(e, eventUuid, dispatch);
      });

      // Since safe (no errors thrown), delete event
      return dispatch(forceDeleteEventByUuid(eventUuid, fetchByDate));
    }

    // Old API endpoint
    return ApiService.post('/web-api/v2/cms/events/delete', { id })
      .then(e => {
        handleDeleteEventErrors(e, eventUuid, dispatch);
      })
      .then(() => {
        if (fetchByDate) dispatch(fetchEventsByDate()).catch(() => undefined);
        else dispatch(fetchEvents()).catch(() => undefined);
      })
      .then(() => dispatch(toastsActions.positive({ headline: 'Event Deleted' })));
  };
}

export function fetchSuggestedAttireOptions(): AppThunk<Promise<EventActionTypes>> {
  return (dispatch): Promise<EventActionTypes> => {
    dispatch(requestSuggestedAttireOptions());
    return fetch('/web-api/v2/cms/page/event/attire/suggestions', {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => dispatch(receiveSuggestedAttireOptions(json)));
  };
}

// used on guest list to fetch events and rsvp status in one request
export function fetchEventsRsvpPage(): AppThunk<Promise<EventActionTypes>> {
  return (dispatch, getState): Promise<EventActionTypes> => {
    const state = getState();
    const currentToasts = state.toasts;
    dispatch(requestEventsRsvpPage());
    return fetch('/web-api/v2/cms/page/rsvp', {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(response => handleZoomErrorNotifications({ dispatch, response, currentToasts }))
      .then(json => dispatch(receiveEventsRsvpPage(json)));
  };
}

// might want a new endpoint for this not linked to the rsvp page - discuss with platform
export function fetchSuggestedRsvpQuestions(): AppThunk<Promise<EventActionTypes>> {
  return (dispatch): Promise<EventActionTypes> => {
    dispatch(requestSuggestions());
    return fetch('/web-api/v1/cms/page/rsvp/suggestions', {
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(json => dispatch(receiveSuggestions(json)));
  };
}
