import React from 'react';
import type { RouteComponentProps } from 'react-router';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

import { inputFormat } from '@zola/zola-ui/src/components/Form/util/dateUtils';
import { DrawerTitle, useDrawerControls } from 'components/common/zolaUI/Drawer';
import { getBudgetItemByUuid, getBudgetPaymentByUuid, useBudgetContext } from '../context';

import {
  BudgetPaymentStatus,
  getBudgetPaymentStatus,
  mapCostToFormattedString,
  mapDateToFormattedString,
  mapTaxonomyNodesToAllPayments,
} from '../util/mappers';

import { useBudgetDrawerLayer } from '../util/useBudgetDrawerLayer';

import {
  Container,
  Text,
  DetailContainer,
  Detail,
  Notes,
  NotesHeader,
} from './BudgetItemPaymentQuickView.styles';
import { useLeaveBudgetRoute } from '../routes/useLeaveBudgetRoute';
import { handleBudgetRouteChange } from '../routes/handleBudgetRouteChange';

type RouteParams = {
  itemUuid: string;
  paymentUuid: string;
};

export type BudgetItemPaymentQuickViewProps = RouteComponentProps<RouteParams, RouteParams>;

export const BudgetItemPaymentQuickView = ({
  params: { itemUuid, paymentUuid },
  location: { pathname, state: { previousPathname } = {} },
  route,
  router: { setRouteLeaveHook },
}: BudgetItemPaymentQuickViewProps): JSX.Element | null => {
  useLeaveBudgetRoute(route, setRouteLeaveHook);
  const paymentEditRoute = `/item/${itemUuid}/payment/${paymentUuid}`;
  const { removeDrawerLayer, currentLayer } = useDrawerControls();

  // Context
  const {
    state: {
      budget: { taxonomy_nodes = [] },
    },
  } = useBudgetContext();
  const allPayments = mapTaxonomyNodesToAllPayments(taxonomy_nodes);
  const payment = getBudgetPaymentByUuid(paymentUuid, allPayments);
  const { amount_cents, due_at, note } = payment || {};
  const amount = mapCostToFormattedString(amount_cents);
  const paymentStatus = payment ? getBudgetPaymentStatus(payment) : undefined;
  const isPaid = paymentStatus === BudgetPaymentStatus.PAID;
  const isOverdue = paymentStatus === BudgetPaymentStatus.OVERDUE;
  const budgetItem = getBudgetItemByUuid(itemUuid, taxonomy_nodes);

  // Routes to BudgetPaymentAddOrEdit as unpaid item
  const handleTertiaryAction = () => {
    removeDrawerLayer(currentLayer?.key as string);
    handleBudgetRouteChange(paymentEditRoute, {
      preventIfUnsaved: false,
      previousPathname: pathname,
    });
  };

  // Routes to BudgetPaymentAddOrEdit as locally paid item
  const handleSave = () => {
    handleBudgetRouteChange(paymentEditRoute, {
      preventIfUnsaved: false,
      previousPathname: pathname,
      markPaid: true,
    });
  };

  const handleOnBack = () => {
    handleBudgetRouteChange(previousPathname || '/upcoming-payments');
  };

  useBudgetDrawerLayer(
    {
      key: pathname,
      ...(!isPaid && {
        useClose: false,
        saveButtonText: 'Mark as paid',
        tertiaryElement: (
          <ButtonV3 variant="secondary" onClick={handleTertiaryAction}>
            Edit
          </ButtonV3>
        ),
        onSave: handleSave,
        onBack: handleOnBack,
      }),
    },
    false,
    [currentLayer?.key]
  );

  // Close drawer if budget item doesn't exist for user
  if (!budgetItem?.uuid) {
    handleBudgetRouteChange();
    return null;
  }
  // Reroute to BudgetPaymentAddOrEdit if it's already been paid
  if (isPaid) {
    handleBudgetRouteChange(paymentEditRoute);
    return null;
  }

  return (
    <Container>
      <DrawerTitle>
        {isOverdue ? 'Payment overdue' : 'Upcoming payment'} for {budgetItem?.title}
      </DrawerTitle>
      <DetailContainer>
        <Detail>
          <Text>Due on</Text>
          <Text>{mapDateToFormattedString(due_at, inputFormat)}</Text>
        </Detail>
        <Detail>
          <Text>Amount</Text>
          <Text>{amount}</Text>
        </Detail>
      </DetailContainer>
      {note ? (
        <Notes>
          <NotesHeader>Notes</NotesHeader>
          <Text>{note}</Text>
        </Notes>
      ) : null}
    </Container>
  );
};
