import {
  AlbumOptionsMenuState,
  AlbumOptionsMenuActionType,
  SET_ALBUM_OPTIONS_MENU_SELECTION,
  SET_SHOW_ALBUM_OPTIONS_MENU_LOADING_OVERLAY,
} from './types';

const init: AlbumOptionsMenuState = {
  selection: null,
  showLoadingOverlay: false,
};

const reducer = (state = init, action: AlbumOptionsMenuActionType): AlbumOptionsMenuState => {
  switch (action.type) {
    case SET_ALBUM_OPTIONS_MENU_SELECTION: {
      return {
        ...state,
        selection: action.payload,
      };
    }
    case SET_SHOW_ALBUM_OPTIONS_MENU_LOADING_OVERLAY: {
      return {
        ...state,
        showLoadingOverlay: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
