export const init = {
  customizations: {
    albumSize: null,
    coverColor: null,
    initialized: false,
    synced: true,
    isBlank: true,
    font: null,
    fontSize: null,
    foil: null,
    coverText: '',
    hasDisplayBox: false,
    displayBoxText: '',
  },
  coverDesignTemplates: [],
};

const reducer = (state = init, action) => {
  switch (action.type) {
    case 'SET_COVER_CUSTOMIZATIONS': {
      return {
        ...state,
        customizations: { ...state.customizations, ...action.payload },
      };
    }
    case 'CLEAR_COVER_CUSTOMIZATIONS': {
      return {
        ...state,
        customizations: init.customizations,
      };
    }
    case 'SET_COVER_DESIGN_TEMPLATES': {
      return {
        ...state,
        coverDesignTemplates: action.payload.coverDesignTemplates,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
