import React, { RefObject } from 'react';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { BodyBase } from '@zola/zola-ui/src/typography/Paragraphs';
import { Title2 } from '@zola/zola-ui/src/typography/Headings';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';

import useSimpleScrollLock from 'lib/hooks/useSimpleScrollLock';
import { useAppDispatch } from 'reducers/useAppDispatch';
import { deleteBudgetItem, deletePayment, getBudgetByAccountId } from '../util/api';
import { useBudgetContext } from '../context';

import { StyledModal, Content, Ctas } from './BudgetConfirmDeleteModal.styles';

type BudgetConfirmDeleteModalProps = {
  uuid: string;
  type: 'payment' | 'item';
  hed?: string;
  onDelete?: () => void;
  onCancel: () => void;
  triggerRef: RefObject<HTMLButtonElement> | null;
};

const BudgetConfirmDeleteModal = ({
  uuid,
  type,
  hed: _hed,
  triggerRef,
  onDelete,
  onCancel,
}: BudgetConfirmDeleteModalProps) => {
  const dispatch = useAppDispatch();
  const {
    actions: { receiveDeletedBudgetItem, receiveBudget, receiveDeletedBudgetPayment },
  } = useBudgetContext();

  useSimpleScrollLock(true);

  const dialogHeadingId = 'delete-budget-item';
  const hed = _hed || `Are you sure you want to delete this ${type}?`;
  const dek =
    type === 'payment'
      ? 'This will alter your budget and remove reminders.'
      : "Deleting this item will alter your budget.  You'll lose payment and reminders attached.";

  const handleDelete = () => {
    const deleteFn = type === 'payment' ? deletePayment : deleteBudgetItem;
    const receiveDeletedEntityFn =
      type === 'payment' ? receiveDeletedBudgetPayment : receiveDeletedBudgetItem;
    deleteFn(uuid)
      .then(() => {
        receiveDeletedEntityFn(uuid);
        dispatch(
          toastsActions.positive({
            headline: 'Deleted',
          })
        );
        onDelete?.();
      })
      // update the budget summary because deleted item may have had payments attached
      .then(getBudgetByAccountId)
      .then(res => {
        receiveBudget(res);
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({
            headline: 'There was an error deleting your budget item.',
          })
        );
      });
  };

  return (
    <StyledModal
      onClose={onCancel}
      rootId="budget-modal-root"
      triggerRef={triggerRef}
      dialogHeadingId={dialogHeadingId}
    >
      <Content data-testid="BudgetConfirmDeleteModal">
        <Title2 presentation="h3">{hed}</Title2>
        <BodyBase>{dek}</BodyBase>
        <Ctas>
          <ButtonV3 variant="secondary" onClick={onCancel}>
            Cancel
          </ButtonV3>
          <ButtonV3 onClick={handleDelete}>Delete</ButtonV3>
        </Ctas>
      </Content>
    </StyledModal>
  );
};

export default BudgetConfirmDeleteModal;
