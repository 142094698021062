const init = {
  allPhotos: [],
  allCategories: [],
  galleryDragState: null,
  photoMenuDragState: null,
  allPhotosSet: false,
};

const reducer = (state = init, action) => {
  switch (action.type) {
    case 'SET_PHOTOS': {
      return {
        ...state,
        allPhotos: action.payload,
        allPhotosSet: true,
      };
    }
    case 'SET_CATEGORIES': {
      return {
        ...state,
        allCategories: action.payload,
      };
    }
    case 'RESET_GALLERY_STATE': {
      return {
        ...state,
        allPhotos: [],
        allCategories: [],
      };
    }
    case 'SET_GALLERY_DRAG_STATE': {
      return {
        ...state,
        galleryDragState: action.payload,
      };
    }
    case 'SET_PHOTOMENU_DRAG_STATE': {
      return {
        ...state,
        photoMenuDragState: action.payload,
      };
    }
    case 'CLEAR_GALLERY': {
      return {
        ...state,
        allPhotos: [],
        allCategories: [],
        galleryDragState: null,
        photoMenuDragState: null,
        allPhotosSet: false,
      };
    }
    default:
      return state;
  }
};

export default reducer;
