import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { ListBoxContainer } from '@zola/zola-ui/src/components/Form/common/DropdownListBox/DropdownListBox.styles';

import { COLORS3, SPACING, ZINDEX, MEDIA_QUERY, FONT } from '@zola/zola-ui/src/styles/emotion';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

export const DRAWER_WIDTH = '520px';

export const lockBgscrolling = css`
  ${ListBoxContainer} {
    ${MEDIA_QUERY.MOBILE} {
      z-index: ${ZINDEX.top + 1}!important;
    }
  }
`;

export const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${COLORS3.WHITE_100};
  width: ${DRAWER_WIDTH};
  z-index: ${ZINDEX.super};
  transform: translateX(${DRAWER_WIDTH});
  transition: transform 0.3s ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    `
      transform: translateX(0px);
      ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
        width: 100vw;
      }
    `}
`;

export const HeaderBar = styled.div`
  padding: ${SPACING.S24};
  background: linear-gradient(rgba(255, 255, 255, 0.9) 75%, transparent);
  display: flex;
  position: sticky;
  top: 0;
  z-index: ${ZINDEX.middle};
`;

export const BackButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background: none;
  height: 20px;
  color: ${COLORS3.BLACK_100};
`;

export const CloseButton = styled(BackButton)`
  margin-left: auto;
`;

export const Overlay = styled.div<{ isOpen: boolean }>`
  transition: background 0.3s ease-in-out;
  ${({ isOpen }) =>
    isOpen &&
    `
    position: fixed;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: ${ZINDEX.super};
    display: flex;
    flex-direction: column;
    align-items: center;
    ${MEDIA_QUERY.MOBILE} {
      align-items: flex-end;
    }
  `}
`;

export const ScreenReaderText = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const DrawerTitle = styled.h2`
  word-break: break-word;
  margin: 0 0 ${SPACING.LG};
  ${FONT.textSizeCompact(FONT.SIZE.LARGER)};
  ${MEDIA_QUERY.MOBILE_XS} {
    ${FONT.textSizeCompact(FONT.SIZE.LARGE)};
  }
`;

export const DrawerFooter = styled.div`
  border-top: 1px solid ${COLORS3.BLACK_030};
  padding: ${SPACING.S16} ${SPACING.S24};
  background: ${COLORS3.WHITE_100};
  position: fixed;
  bottom: 0;
  left: 0;
  width: ${DRAWER_WIDTH};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    width: 100vw;
  }
`;

export const DrawerContent = styled.div`
  padding: 0 ${SPACING.LG} 213px; // height of footer + height of header bar * 2;
  ${MEDIA_QUERY.MOBILE_XS} {
    padding: 0 ${SPACING.S24} 213px;
  }
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  height: 100vh;
`;

export const UnsavedChangesPrompt = styled.div`
  background: ${COLORS3.WHITE_100};
  padding: ${SPACING.S24} ${SPACING.S24} ${SPACING.S32};
  border: 1px solid ${COLORS3.BLACK_100};
  border-radius: 16px;
  max-width: 310px;
`;

export const PromptOverlay = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PromptTitle = styled.h2`
  ${FONT.textSizeRegular(FONT.SIZE.REGULAR)}
  font-weight: ${FONT.WEIGHT.BOLD};
  margin: 0 0 ${SPACING.SM};
`;

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${SPACING.XS};
  margin-top: ${SPACING.S24};
`;

export const Discard = styled(LinkV2)`
  margin: ${SPACING.S16} auto 0;
  display: flex;
`;

export const StyledCancelButton = styled(ButtonV3)`
  margin: 0 ${SPACING.S16} 0 auto;
`;
