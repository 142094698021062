import * as ActionType from '../types/website/ManageWebsiteActionTypes';
import type { ManageWebsiteActionTypes } from '../types/website/ManageWebsiteActionTypes';

export function manageWebsiteSetReady(): ManageWebsiteActionTypes {
  return {
    type: ActionType.DATA_READY,
  };
}

export function manageWebsiteSetNotReady(): ManageWebsiteActionTypes {
  return {
    type: ActionType.DATA_NOT_READY,
  };
}
