import React, { useRef, useState } from 'react';

import { isDesktopV2 } from '@zola-helpers/client/dist/es/util/responsive';
import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import { InputFieldAddOnType } from '@zola/zola-ui/src/components/Form/InputFieldV2/InputFieldAddOn';
import { useCurrencyInput } from 'components/manage/budget/util/useCurrencyInput';

import { useBudgetContext } from '../context';

import {
  Container,
  Output,
  RangeInput,
  Label,
  Dek,
  LabelContainer,
  InputContainer,
  RangeContainer,
} from './BudgetSliderInput.styles';

type BudgetSliderInputProps = {
  value: number;
  name: string;
  label?: string;
  dek?: string;
  min?: number;
  max?: number;
  step?: number;
  onChange?: (centsValue: number, percentage?: number) => void;
};

const BudgetSliderInput = ({
  value: initialValue,
  name,
  label,
  dek,
  min = 0,
  max = 100,
  step = 1,
  onChange,
}: BudgetSliderInputProps) => {
  const {
    state: {
      budget: { budgeted_cents = 0 },
    },
  } = useBudgetContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [percentage, setPercentage] = useState((initialValue / budgeted_cents) * 100);

  const { currencyValue, setCurrencyValue, toggleIsEditingCurrency } = useCurrencyInput(
    (initialValue / 100).toFixed(2),
    inputRef,
    []
  );

  const getBackgroundSize = () => {
    return { backgroundSize: `${(percentage * 100) / max}% 100%` };
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const percentageVal = Number(e.target.value);
    const centsVal = (percentageVal / 100) * budgeted_cents;
    setPercentage(percentageVal);
    setCurrencyValue((centsVal / 100).toString());
    onChange?.(centsVal, percentageVal / 100);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const centsVal = Number(e.target.value);
    const percentageVal = ((centsVal * 100) / budgeted_cents) * 100;
    setPercentage(percentageVal);
    setCurrencyValue(centsVal.toString());
    onChange?.(centsVal * 100, percentageVal);
  };

  return (
    <Container ref={containerRef}>
      <LabelContainer>
        <Label>{label}</Label>
        {isDesktopV2() && <Dek>{dek}</Dek>}
      </LabelContainer>
      <InputContainer>
        <InputFieldV3
          name={name}
          onChange={handleInputChange}
          onFocus={toggleIsEditingCurrency}
          onBlur={toggleIsEditingCurrency}
          value={currencyValue}
          isControlled
          ref={inputRef}
          addOn={InputFieldAddOnType.CASH}
        />
      </InputContainer>
      <RangeContainer>
        <Output value={percentage}>
          {percentage.toLocaleString('en', {
            maximumFractionDigits: 2,
          })}
          %
        </Output>
        <RangeInput
          name={name}
          type="range"
          min={min}
          max={max}
          value={percentage}
          step={step}
          onChange={handleSliderChange}
          style={getBackgroundSize()}
        />
      </RangeContainer>
      {!isDesktopV2() && <Dek>{dek}</Dek>}
    </Container>
  );
};

export default BudgetSliderInput;
