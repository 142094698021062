import LogRocket from 'logrocket';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { continueExistingLogRocketSession } from '@zola-helpers/client/dist/es/util/logrocket/logRocketUtils';
import { continueExistingSessionReplay } from '@zola-helpers/client/dist/es/util/mixpanel/sessionReplay';
import { ResponsiveProvider } from 'components/common/ResponsiveContext';
import { ZolaThemeProvider } from './ZolaThemeProvider';
import getRoutes from './routes';
import persistedStore from './store';
import scrollViewTo from './util/scrollViewTo';
import routeSniffer from './util/routeSniffer';

if (typeof window !== 'undefined') {
  continueExistingSessionReplay();
  window.LogRocket = LogRocket;
  continueExistingLogRocketSession();
}

const { store, persistor } = persistedStore;
const history = syncHistoryWithStore(browserHistory, store);

const root = document.getElementById('root');

window.zola.nodeEnv = process.env.NODE_ENV;

function handleRouteUpdate() {
  const isChecklist = routeSniffer(history.getCurrentLocation().pathname, 'checklist');
  const isPaperPLP = routeSniffer(history.getCurrentLocation().pathname, 'paperPLP');
  const isBudgetTool = routeSniffer(history.getCurrentLocation().pathname, 'budget');

  if (!isChecklist && !isPaperPLP && !isBudgetTool) {
    window.scrollTo(0, 0);
  }

  const { hash } = window.location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        scrollViewTo(element);
      }
    }, 0);
  }
}

render(
  <ResponsiveProvider>
    <ZolaThemeProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router onUpdate={handleRouteUpdate} history={history}>
            {getRoutes(store)}
          </Router>
        </PersistGate>
      </Provider>
    </ZolaThemeProvider>
  </ResponsiveProvider>,
  root
);
