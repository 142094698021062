import { createSelector } from 'reselect';
import { ENVELOPE_PRINTING } from 'cards/constants/Envelopes';
import { getMaxOptionPrice } from 'cards/util/optionPrice';
import { HAS_MOTIFS_BY_CARD_TYPE } from 'cards/constants/hasMotifsByCardType';

export const getIsAdminView = state => state.cards.cardCatalog.isAdminView;
export const getCards = state => state.cards.cardCatalog.cards;
export const getCardUUIDsByVariationUUID = state =>
  state.cards.cardCatalog.cardUUIDsByVariationUUID;
export const getRecentlyViewedSuites = state => state.cards.cardCatalog.recentlyViewed;

export const getBackOfEnvelopePrintingPrice = createSelector(getCards, cards => {
  const envelope = Object.values(cards).find(card => {
    return card.type === 'ENVELOPE' && !/rsvp/i.test(card.name);
  });
  const envelopesWithBackPrintingOptionOnly = envelope?.variations.filter(
    ({ option_values }) =>
      option_values.color === 'ffffff' &&
      option_values['envelope-lining'] === 'none' &&
      option_values['envelope-printing'] === ENVELOPE_PRINTING.both
  );
  return getMaxOptionPrice(envelopesWithBackPrintingOptionOnly);
});

// SELECTORS FOR PLP
export const getCardCatalogBusy = state => state.cards.cardCatalog.busy;
export const getOrderedCardSuiteUUIDs = state => state.cards.cardCatalog.suites.orderedUUIDs;
export const getCardSuitesByUUID = state => state.cards.cardCatalog.suites.byUUID;
export const getDisplayableSearchTotal = state => state.cards.cardCatalog.suites.displayableTotal;
export const getSearchFilters = state => state.cards.cardCatalog.filters;
export const getCollaborators = state => state.cards.cardCatalog.collaborators;
export const getLoadedStartIndex = state => state.cards.cardCatalog.suites.loadedStartIndex;
export const getLoadedEndIndex = state => state.cards.cardCatalog.suites.loadedEndIndex;
export const getCardPromotionData = state => state.cards.cardCatalog.promotionData;

const getCardSuitesAsArray = createSelector(
  getCardSuitesByUUID,
  getOrderedCardSuiteUUIDs,
  (cardSuitesByUUID, orderedCardSuiteUUIDs) =>
    orderedCardSuiteUUIDs.map(uuid => cardSuitesByUUID[uuid])
);

export const getSuiteLeadCards = createSelector(getCardSuitesAsArray, cardSuites =>
  cardSuites.map(cardSuite => ({
    ...cardSuite.lead_card,
    newSuite: cardSuite.new_suite,
    suiteUUID: cardSuite.uuid,
    suiteName: cardSuite.name,
    collaborator: cardSuite.collaborator,
    thirdParty: cardSuite.third_party,
    singleSampleAvailable: cardSuite.single_sample_available,
    singleSampleSku: cardSuite.single_sample_sku,
    description: cardSuite.description,
    orientation: cardSuite.orientation,
    unitMultiplier: cardSuite.paper_info?.qty_unit_multiplier || 1,
  }))
);

const getThemeMatchedCardsByUUID = state => state.cards.cardCatalog.suites.themeMatchedCards.byUUID;
const getOrderedThemeMatchedCardUUIDs = state =>
  state.cards.cardCatalog.suites.themeMatchedCards.orderedUUIDs;

const getThemeMatchedCardsAsArray = createSelector(
  getThemeMatchedCardsByUUID,
  getOrderedThemeMatchedCardUUIDs,
  (themeMatchedCardsByUuid, orderedThemeMatchedCardUuids) =>
    orderedThemeMatchedCardUuids.map(uuid => themeMatchedCardsByUuid[uuid])
);

export const getThemeMatchedCards = createSelector(getThemeMatchedCardsAsArray, cardSuites =>
  cardSuites.map(cardSuite => ({
    ...cardSuite.lead_card,
    suiteUUID: cardSuite.uuid,
    suiteName: cardSuite.name,
    collaborator: cardSuite.collaborator,
    thirdParty: cardSuite.third_party,
    singleSampleAvailable: cardSuite.single_sample_available,
    singleSampleSku: cardSuite.single_sample_sku,
    description: cardSuite.description,
    isThemeMatchedCard: cardSuite.isThemeMatchedCard,
  }))
);

export const getAllMotifs = state => state.cards.cardCatalog.motifs;

export const getIsFilteredCardSearch = createSelector(
  getSearchFilters,
  getAllMotifs,
  (searchFilters, allMotifs) => {
    const {
      lead_card_type: cardType,
      color: activeColor,
      multiColor,
      seo_colors: seoColors,
      is_portrait: isPortrait,
      is_landscape: isLandscape,
      has_foil: hasFoil,
      has_magnet: hasMagnet,
      has_custom_photo: hasCustomPhoto,
      has_no_custom_photo: hasNoCustomPhoto,
      is_vow_renewal: isVowRenewal,
      featured_artist: featuredArtist,
      motifs: selectedMotifs,
      silhouettes: selectedSilhouettes,
      is_letterpress: isLetterpress,
    } = searchFilters;
    const hasMotifs = HAS_MOTIFS_BY_CARD_TYPE[cardType];
    const motifsLoaded = allMotifs.length > 0;

    return Boolean(
      hasCustomPhoto ||
        hasNoCustomPhoto ||
        activeColor !== null ||
        (!!multiColor && Array.isArray(multiColor) && multiColor.length > 0) ||
        (!!selectedSilhouettes &&
          Array.isArray(selectedSilhouettes) &&
          selectedSilhouettes.length > 0) ||
        (!!seoColors && Array.isArray(seoColors) && seoColors.length > 0) ||
        (hasMotifs && motifsLoaded && selectedMotifs.length > 0) ||
        hasMagnet ||
        hasFoil ||
        featuredArtist ||
        isPortrait ||
        isLandscape ||
        isLetterpress ||
        isVowRenewal
    );
  }
);

// SELECTORS FOR PDP
export const getAdditionalSuiteVersions = state => state.cards.cardCatalog.additionalSuiteVersions;
const getRelatedCardsFromLeadSuite = state => state.cards.cardCatalog.relatedCardsFromSuite;
const getRelatedCardsByDesigner = state => state.cards.cardCatalog.relatedCardsByDesigner;
export const getPaperAddOns = state => state.cards.cardCatalog.paperAddOns;

export const getRelatedCardsFromCurrentSuite = createSelector(
  getRelatedCardsFromLeadSuite,
  relatedCardsFromSuite => {
    if (relatedCardsFromSuite) {
      return relatedCardsFromSuite.map(relatedCardFromSuite => ({
        ...relatedCardFromSuite.lead_card,
        suiteUUID: relatedCardFromSuite.uuid,
        suiteName: relatedCardFromSuite.name,
        defaultQuantity: relatedCardFromSuite.qty_default || 100,
        unitMultiplier: relatedCardFromSuite.paper_info?.qty_unit_multiplier || 1,
      }));
    }
    return null;
  }
);

export const getRelatedCardsByCurrentDesigner = createSelector(
  getRelatedCardsByDesigner,
  relatedCardsByDesigner => {
    if (relatedCardsByDesigner) {
      return relatedCardsByDesigner.map(relatedCardByDesigner => ({
        ...relatedCardByDesigner.lead_card,
        suiteUUID: relatedCardByDesigner.uuid,
        suiteName: relatedCardByDesigner.name,
      }));
    }
    return null;
  }
);

export const getCompatiblePaperAddOns = createSelector(getPaperAddOns, paperAddOns => {
  if (paperAddOns) {
    return paperAddOns.map(paperAddOn => ({
      ...paperAddOn.lead_card,
      suiteUUID: paperAddOn.uuid,
      suiteName: paperAddOn.name,
      unitMultiplier: paperAddOn.paper_info?.qty_unit_multiplier || 1,
    }));
  }
  return null;
});
