import { Breadcrumb } from 'components/common/types';
import { isCustomizationStep } from '@zola/zola-ui/src/paper/cards/constants/customizationSteps';
import { CARD_TYPE_MAP } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import { getStepKey } from './getStepKey';
import { getLeadCardBySlug } from './getLeadCardBySlug';

export const getStepFromPathname = (pathname: string, orderedSteps: Breadcrumb[]) => {
  const paths = pathname.split('/');
  const slug = paths[paths.length - 2];
  const stepId = paths[paths.length - 1];
  let cardType = getLeadCardBySlug(slug);

  // In the customization flow, we use the FE-specific ENCLOSURE type instead of the "real" DETAILS type from the BE.
  // TODO: It would be great to clean up the customization flow to always use DETAILS. Check if it would affect analytics.
  if (cardType === CARD_TYPE_MAP.details) {
    cardType = CARD_TYPE_MAP.enclosure;
  }

  let currentStep;
  if (cardType) {
    // Find the step with matching type and id
    const stepKey = isCustomizationStep(stepId) && getStepKey(stepId, cardType);
    currentStep = orderedSteps.find(step => step.key === stepKey);
  } else {
    // Otherwise, find the first step with matching id
    currentStep = orderedSteps.find(step => step.id === stepId);
  }

  return currentStep;
};
