import { fetchPhotobookProject as fetchPhotobookProjectAPI } from 'photobooks/utils/api';
import ApiService from '../../../util/api';

const requestPhotobookProjectCreation = () => ({
  type: 'REQUEST_PHOTOBOOK_PROJECT_CREATION',
});

const receivePhotobookProjectCreation = payload => ({
  type: 'RECEIVE_PHOTOBOOK_PROJECT_CREATION',
  payload,
});

const rejectedPhotobookProjectCreation = payload => ({
  type: 'REJECTED_PHOTOBOOK_PROJECT_CREATION',
  payload,
});

export function createNewProject(data) {
  return dispatch => {
    dispatch(requestPhotobookProjectCreation());

    return ApiService.post(`/web-api/v1/photobook/project`, data)
      .then(res => {
        dispatch(receivePhotobookProjectCreation(res));
        return res;
      })
      .catch(err => {
        dispatch(rejectedPhotobookProjectCreation(err));
      });
  };
}

const requestAddProjectToCart = () => ({
  type: 'REQUEST_ADD_PROJECT_TO_CART',
});

const receiveAddProjectToCart = payload => ({
  type: 'RECEIVE_ADD_PROJECT_TO_CART',
  payload,
});

const rejectedAddProjectToCart = payload => ({
  type: 'REJECTED_ADD_PROJECT_TO_CART',
  payload,
});

export function addProjectToCart(data, redirectToCart = true) {
  return dispatch => {
    dispatch(requestAddProjectToCart());
    return ApiService.post(`/web-api/v1/photobook/project/cart`, data)
      .then(() => {
        dispatch(receiveAddProjectToCart());
      })
      .then(() => {
        if (redirectToCart) {
          window.location = '/registry/cart/checkout';
        } else {
          window.dispatchEvent(new CustomEvent('OPEN_MODAL_EVENT', { detail: 'CART' }));
        }
      })
      .catch(err => {
        dispatch(rejectedAddProjectToCart(err));
      });
  };
}

const protobookProjectIsFetching = () => ({
  type: 'FETCH_PHOTOBOOK_PROJECT',
});

const photobookProjectFulfilled = payload => ({
  type: 'FETCH_PHOTOBOOK_PROJECT_FULFILLED',
  payload,
});

const photobookProjectRejected = payload => ({
  type: 'FETCH_PHOTOBOOK_PROJECT_REJECTED',
  payload,
});

export function fetchPhotobookProject(projectUuid) {
  return dispatch => {
    dispatch(protobookProjectIsFetching());

    return fetchPhotobookProjectAPI({ projectUuid })
      .then(res => {
        dispatch(photobookProjectFulfilled(res));
        return res;
      })
      .catch(err => {
        dispatch(photobookProjectRejected(err));
      });
  };
}

export const clearPhotobookProject = () => ({
  type: 'CLEAR_PHOTOBOOK_PROJECT',
});
