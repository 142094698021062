import React, { useCallback, useEffect, useMemo, useState } from 'react';
import type { WGooglePlaceDetailsView } from '@zola/svc-web-api-ts-client';
import Autosuggest, { ChangeEvent, SuggestionSelectedEventData } from 'react-autosuggest';
import _debounce from 'lodash/debounce';
import cx from 'classnames';

// Types
import { FieldInputProps, FieldMetaState } from 'react-final-form';
import type { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';

// Utils
import { useAppDispatch } from 'reducers/useAppDispatch';
import usePrevious from '@zola/zola-ui/src/hooks/usePrevious';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import {
  MappedVendorSearchResult,
  vendorMarketplaceSearch,
  VendorTaxonomyNodeId,
} from 'api/vendorMarketplaceApi';
import { useGooglePlaces } from 'components/hooks/useGooglePlaces';

// Styles
import sharedStyles from 'components/onboard/questions/WeddingLocationQuestion/WeddingLocationQuestion.module.less';
import {
  autosuggestVenueFieldContainer,
  suggestionContainer,
  SuggestionTextMain,
  SuggestionTextSecondary,
  ShowMoreContainer,
  StyledArrowIcon,
  StyledLinkV2,
  NoResultsContainer,
} from './AutosuggestVenueField.styles';

export type MappedGoogleResult = WGooglePlaceDetailsView & { type: 'google' };
export type MappedVendorResult = MappedVendorSearchResult & { type: 'vendor' };

export type VendorOrGoogleSearchResult = MappedGoogleResult | MappedVendorResult;

type VendorSuggestion = MappedVendorSearchResult & { type: 'vendor' };
type GoogleSuggestion = google.maps.places.AutocompletePrediction & {
  type: 'google';
};
const showMoreLabel = { type: 'more' as const, label: 'Show more' as const };
const noResultsLabel = { label: 'No results found' as const, type: 'noResults' as const };

type ShowMoreLabel = typeof showMoreLabel;
type NoResultsLabel = typeof noResultsLabel;
type VendorOrGoogleSuggestion =
  | VendorSuggestion
  | GoogleSuggestion
  | ShowMoreLabel
  | NoResultsLabel;

export type AutosuggestVenueFieldV2Props = {
  /** Allows show more CTA to render, defaults to true */
  allowShowMore?: boolean;
  /** Option to provide Google Place suggestions */
  allowGoogleSuggestions?: boolean;
  /** Vendor taxonomy id for vendorMarketplaceSearch API; defaults to id for venue if not provided */
  categoryId?: VendorTaxonomyNodeId;
  /** className */
  className?: string;
  /** Input field identifier */
  id: string;
  /** Input field props from either react-final-form | redux-form */
  input: FieldInputProps<string> | WrappedFieldInputProps;
  /** Input field label */
  label?: string | React.ReactNode;
  /** Max character count allowed */
  maxChars?: number;
  /** Meta data holding error message from either react-final-form | redux-form */
  meta: FieldMetaState<string> | WrappedFieldMetaProps;
  /** Callback for onblur event */
  onInputBlur?: (e: React.FormEvent<HTMLElement>, newValue?: string) => void;
  /** Callback for when reset button is clicked */
  onResetButtonClick?: () => void;
  /** Callback for when a suggestion is selected */
  onSuggestSelect: (searchResult: VendorOrGoogleSearchResult) => void;
  /** Placeholder text for input */
  placeholder?: string;
  /** Option to display or hide reset button which defaults to true */
  showResetButton?: boolean;
  /** Reference vendor uuids that should be filtered from list of suggestions */
  suggestionsToFilter?: string[];
};

// Formats secondary location text for a marketplace suggestion
const formatLocation = (suggestion: VendorSuggestion): string => {
  const { address } = suggestion;
  const location = [address.city, address.stateProvince];
  return location.filter(item => item).join(', ');
};

// Renders each suggestion or show more button in the autosuggest dropdown
const renderSuggestion = (
  suggestion: VendorOrGoogleSuggestion,
  allowShowMore: boolean
): JSX.Element => {
  const { type } = suggestion;
  if (type === 'more' && allowShowMore) {
    return (
      <ShowMoreContainer>
        Show more <StyledArrowIcon width={10} height={8} />
      </ShowMoreContainer>
    );
  }
  if (type === 'noResults') {
    return <NoResultsContainer>{suggestion.label}</NoResultsContainer>;
  }
  const vendorSuggestionLabel = type === 'vendor' && suggestion.name;
  const googleSuggestionLabel = type === 'google' && suggestion.description;

  return (
    <div css={suggestionContainer}>
      <SuggestionTextMain>{googleSuggestionLabel || vendorSuggestionLabel}</SuggestionTextMain>
      {vendorSuggestionLabel && (
        <SuggestionTextSecondary>{formatLocation(suggestion)}</SuggestionTextSecondary>
      )}
    </div>
  );
};

/**
 * Field that renders both vendor marketplace and google autosuggestions.
 * Acts as an adapter component that works for either react-final-form or redux-form.
 * Also allows for manual entry of venue name if autosuggestion is not selected.
 *
 * To minimize calls to the Google Places api, google results will only be shown if:
 * 1. The search term returns no results from the vendor search: OR
 * 2. The user clicks the "Show more" button at the end of the Marketplace results
 */
const AutosuggestVenueFieldV2 = ({
  categoryId,
  className,
  id,
  input,
  label,
  maxChars,
  meta: { error, touched },
  onInputBlur,
  onSuggestSelect,
  onResetButtonClick,
  placeholder,
  showResetButton = true,
  suggestionsToFilter = [],
  allowGoogleSuggestions = true,
  allowShowMore = true,
}: AutosuggestVenueFieldV2Props): JSX.Element => {
  const dispatch = useAppDispatch();
  // Need this to keep dropdown open when show more button is selected with keypress event
  // since shouldKeepSuggestionsOnSelect only seems to work for click event
  // should be false by default, but setting to true if is Jest test to help with dropdown rendering
  const [alwaysRenderSuggestions, setAlwaysRenderSuggestions] = useState(
    process.env.JEST_WORKER_ID !== undefined
  );
  // Need this to set as input value when keying down to show more button
  const [originallyTypedVenueName, setOriginallyTypedVenueName] = useState<string>(input.value);
  // Main value that stays up to date on user input
  const [venueName, setVenueName] = useState<string>(input.value);
  // Venue search results that populates the autosuggestion dropdown
  const [marketplaceSuggestions, setMarketplaceSuggestions] = useState<VendorOrGoogleSuggestion[]>(
    []
  );
  // Hides google suggestions until user hits show more button within dropdown
  const [showGoogleSuggestions, setShowGoogleSuggestions] = useState(false);
  const [noGoogleResults, setNoGoogleResults] = useState(false);
  // For error handling
  const errorMessage = touched && error;

  const {
    suggestions: googleSuggestions,
    setSuggestions: setGoogleSuggestions,
    getPlaceDetails,
    busy: isFetchingGoogleSuggestions,
  } = useGooglePlaces((allowGoogleSuggestions && showGoogleSuggestions && input.value) || '', {
    disabled: !allowGoogleSuggestions,
    minTermLength: 1,
  });
  const mappedGoogleSuggestions: VendorOrGoogleSuggestion[] = googleSuggestions.map(s => ({
    ...s,
    type: 'google',
  }));
  if (noGoogleResults) {
    mappedGoogleSuggestions.push({ label: 'No results found', type: 'noResults' as const });
  }
  const filterShowMore = (s: VendorOrGoogleSuggestion) => {
    const googleResultsRecieved = Boolean(googleSuggestions.length || noGoogleResults);
    const shouldRemove = googleResultsRecieved && s.type === 'more';
    return !shouldRemove;
  };
  const venueNameSuggestions = [...marketplaceSuggestions, ...mappedGoogleSuggestions].filter(
    filterShowMore
  );

  const prevIsFetchingGoogleSuggestions = usePrevious(isFetchingGoogleSuggestions);
  const newGoogleResults =
    prevIsFetchingGoogleSuggestions === true && isFetchingGoogleSuggestions === false;
  /**
   * Listen for google results to go from busy => not busy, to check if no results were found.
   * Prevents No Results from flashing while fetching.
   * */
  useEffect(() => {
    if (newGoogleResults && googleSuggestions.length === 0) {
      setNoGoogleResults(true);
    }
    if (googleSuggestions.length) {
      setNoGoogleResults(false);
    }
  }, [googleSuggestions.length, newGoogleResults]);

  useEffect(() => {
    if (input.value !== venueName) {
      setVenueName(input.value);
      setOriginallyTypedVenueName(input.value);
    }
  }, [input.value, venueName]);

  // Value that populates input as you tab through suggestions or once you click one
  const getSuggestionValue = (suggestion: VendorOrGoogleSuggestion): string => {
    if (suggestion.type === 'more' || suggestion.type === 'noResults') {
      return originallyTypedVenueName;
    }
    if (suggestion.type === 'google') {
      return suggestion.description;
    }
    return suggestion.name;
  };

  // Clears all suggestions in the autosuggest dropdown
  const clearSuggestions = useCallback((): void => {
    setMarketplaceSuggestions([]);
    setGoogleSuggestions([]);
    setShowGoogleSuggestions(false);
    setNoGoogleResults(false);
  }, [setGoogleSuggestions]);

  // Invoked by the form control when the value changes
  const onChange = (e: React.FormEvent<HTMLElement>, params: ChangeEvent): void => {
    const { newValue } = params;
    setVenueName(newValue);
    input.onChange(newValue);
  };

  // Handler for showing google items when show more is clicked
  const onShowGoogleSuggestions = useCallback((): void => {
    if (allowGoogleSuggestions) {
      setAlwaysRenderSuggestions(false);
      setShowGoogleSuggestions(true);
    }
  }, [allowGoogleSuggestions]);

  // Fetches vendor marketplace suggestions
  const fetchMarketplaceSuggestions = useMemo(
    () => async (inputVal: string): Promise<VendorSuggestion[]> => {
      return vendorMarketplaceSearch(inputVal, categoryId || 1)
        .then(searchResults => {
          return searchResults
            .filter(suggestion => !suggestionsToFilter.includes(suggestion.uuid))
            .map(s => {
              return { ...s, type: 'vendor' as const };
            });
        })
        .catch(() => {
          throw new Error('Vendor marketplace suggestions not fetched');
        });
    },
    [categoryId, suggestionsToFilter]
  );

  // Handler for when input value has changed and we need to fetch new suggestions
  const onSuggestionsFetchRequested = useMemo(
    () =>
      _debounce(async ({ value }) => {
        const shouldNotFetchNew = value === originallyTypedVenueName;
        const isInputFieldEmpty = value.trim().length === 0;
        if (shouldNotFetchNew || isInputFieldEmpty) {
          if (isInputFieldEmpty) clearSuggestions();
          return;
        }
        setOriginallyTypedVenueName(value);
        const suggestions: VendorOrGoogleSuggestion[] = await fetchMarketplaceSuggestions(value);
        clearSuggestions();

        if (suggestions.length === 0) {
          if (allowGoogleSuggestions) {
            // No vendor results... fetch google results if enabled:
            onShowGoogleSuggestions();
          } else {
            suggestions.push(noResultsLabel);
          }
        } else {
          // Append the show more label if there are vendor results
          suggestions.push(showMoreLabel);
        }

        setMarketplaceSuggestions(suggestions);
      }, 500),
    [
      originallyTypedVenueName,
      fetchMarketplaceSuggestions,
      clearSuggestions,
      allowGoogleSuggestions,
      onShowGoogleSuggestions,
    ]
  );

  // Handles suggestion selection for either marketplace or google item
  const onSuggestionSelected = (
    _event: React.FormEvent<HTMLElement>,
    params: SuggestionSelectedEventData<VendorOrGoogleSuggestion>
  ) => {
    const { suggestion } = params;
    switch (suggestion.type) {
      case 'more':
        onShowGoogleSuggestions();
        return;
      case 'noResults':
        clearSuggestions();
        return;
      case 'google': {
        const { place_id, description } = suggestion;
        getPlaceDetails({ place_id, description })
          .then(placeDetails => {
            onSuggestSelect({ ...placeDetails, type: 'google' });
          })
          .catch(() => {
            dispatch(
              toastsActions.negative({
                headline: 'Error fetching venue details, please try again.',
              })
            );
          });
        return;
      }
      case 'vendor':
      default:
        onSuggestSelect(suggestion);
    }
  };

  // Handles reset button click
  const onClickResetButton = () => {
    if (onResetButtonClick) {
      onResetButtonClick();
    }
  };

  return (
    <div
      className={cx(
        sharedStyles.autoSuggest,
        'zola-ui render-field',
        {
          'has-error': errorMessage,
        },
        {
          'with-google-suggestions': showGoogleSuggestions && googleSuggestions.length,
        },
        className
      )}
      css={autosuggestVenueFieldContainer}
    >
      {label ? <label htmlFor={id}>{label}</label> : <></>}
      {showResetButton ? (
        <StyledLinkV2 arrow inline onClick={onClickResetButton} role="button">
          Reset address
        </StyledLinkV2>
      ) : (
        <></>
      )}
      <Autosuggest<VendorOrGoogleSuggestion>
        alwaysRenderSuggestions={alwaysRenderSuggestions}
        inputProps={{
          ...input,
          className: 'form-control',
          id,
          onBlur: onInputBlur
            ? (e: React.FormEvent<HTMLElement>) => onInputBlur(e, venueName)
            : undefined,
          onChange,
          placeholder,
          value: venueName,
        }}
        getSuggestionValue={getSuggestionValue}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionSelected={onSuggestionSelected}
        renderSuggestion={suggestion => renderSuggestion(suggestion, allowShowMore)}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        shouldKeepSuggestionsOnSelect={s => s.type === 'more'}
        suggestions={venueNameSuggestions}
      />
      {errorMessage ? (
        <div className="secondary-help-block max-characters-block">
          <span className="text-danger">{errorMessage}</span>
          <span className="text-danger">
            {venueName.length}/{maxChars}
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AutosuggestVenueFieldV2;
