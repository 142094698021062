import React from 'react';
import { IndexRedirect, Route } from 'react-router';

import { asyncComponent } from 'util/asyncComponent';
import { BudgetSettings } from '../BudgetSettings/BudgetSettings';

import { BudgetItemAddOrEdit } from '../BudgetItemAddOrEdit/BudgetItemAddOrEdit';
import { BudgetItemView } from '../BudgetItemView/BudgetItemView';
import { BudgetPaymentAddOrEdit } from '../BudgetPaymentAddOrEdit/BudgetPaymentAddOrEdit';
import { BudgetItemAddVendor } from '../BudgetItemAddVendor/BudgetItemAddVendor';
import { BudgetItemPaymentQuickView } from '../BudgetItemPaymentQuickView/BudgetItemPaymentQuickView';
import { BudgetGroupAddOrEdit } from '../BudgetGroupAddOrEdit/BudgetGroupAddOrEdit';
import BudgetEstimates from '../BudgetEstimates/BudgetEstimates';
import { BudgetUpcomingPayments } from '../BudgetUpcomingPayments/BudgetUpcomingPayments';

const Budget = asyncComponent(() => import(/* webpackChunkName: "Budget" */ '../Budget'));

export const getBudgetRoutes = (): JSX.Element | null => {
  return (
    <Route path="budget" component={Budget}>
      <Route path="settings" component={BudgetSettings} />
      <Route path="estimates" component={BudgetEstimates} />
      <Route path="upcoming-payments" component={BudgetUpcomingPayments} />
      <Route path="group">
        <IndexRedirect to="new" />
        <Route path="new" component={BudgetGroupAddOrEdit} />
        <Route path=":taxonomyNodeUuid" component={BudgetGroupAddOrEdit} />
      </Route>
      <Route path="item">
        <IndexRedirect to="new" />
        <Route path="new" component={BudgetItemAddOrEdit} />
        <Route path=":itemUuid" component={BudgetItemView} />
        <Route path=":itemUuid/edit" component={BudgetItemAddOrEdit} />
        <Route path=":itemUuid/add-vendor" component={BudgetItemAddVendor} />
        <Route path=":itemUuid/payment">
          <IndexRedirect to="new" />
          <Route path="new" component={BudgetPaymentAddOrEdit} />
          <Route path=":paymentUuid" component={BudgetPaymentAddOrEdit} />
        </Route>
        <Route
          path=":itemUuid/payment/:paymentUuid/quick-view"
          component={BudgetItemPaymentQuickView}
        />
      </Route>
    </Route>
  );
};
