import type {
  UpdateBudgetItemRequest,
  WBudgetItemPaymentView,
  WBudgetItemTypeView,
  WBudgetItemView,
  WBudgetTaxonomyNodeView,
} from '@zola/svc-web-api-ts-client';
import type { BookVendorFormValues, MappedWAccountVendorView } from 'api/vendorMarketplaceApi';
import type { BudgetProgressSummary } from 'components/manage/budget/util/types';
import type { BudgetContextStateType, BudgetSortBy } from 'components/manage/budget/context';

import convertCurrencyFormat from '@zola-helpers/client/dist/es/util/convertCurrencyFormat';
import {
  formatDateUtcLocal,
  parseDate,
  sortByDate,
  isBeforeDate,
  isSameDate,
} from '@zola-helpers/client/dist/es/util/dateUtils';
import { notEmpty } from 'util/typeUtil';

export const mapItemTypesToDropdownV3Values = (item_types: WBudgetItemTypeView[]) =>
  item_types.map(type => ({
    label: type.display_name as string,
    value: (type.budget_item_type as unknown) as string,
  }));

export const mapTaxonomyNodesToDropdownV3Values = (taxonomy_nodes: WBudgetTaxonomyNodeView[]) =>
  taxonomy_nodes.map(node => ({
    label: node.title as string,
    value: node.uuid as string,
  }));

export const mapTaxonomyNodesToAllBudgetItems = (
  taxonomyNodes: WBudgetTaxonomyNodeView[]
): WBudgetItemView[] => {
  const allBudgetItems: WBudgetItemView[] = [];
  taxonomyNodes.forEach(({ items }) => {
    if (items) {
      allBudgetItems.push(...items);
    }
  });
  return allBudgetItems;
};

export const mapBudgetItemsToAllPayments = (
  budgetItems: WBudgetItemView[]
): WBudgetItemPaymentView[] => {
  const allPayments: WBudgetItemPaymentView[] = [];
  budgetItems.forEach(({ payments }) => {
    if (payments) {
      allPayments.push(...payments);
    }
  });
  return allPayments;
};

export const mapTaxonomyNodesToAllPayments = (
  taxonomyNodes: WBudgetTaxonomyNodeView[]
): WBudgetItemPaymentView[] =>
  mapBudgetItemsToAllPayments(mapTaxonomyNodesToAllBudgetItems(taxonomyNodes));

export const mapTaxonomyNodesToSortedPendingPayments = (
  taxonomyNodes: WBudgetTaxonomyNodeView[]
): WBudgetItemPaymentView[] => {
  const pendingPayments: WBudgetItemPaymentView[] = [];
  mapTaxonomyNodesToAllPayments(taxonomyNodes).forEach(payment => {
    if (
      payment.payment_type === (('PENDING' as unknown) as WBudgetItemPaymentView.PaymentTypeEnum) &&
      payment.due_at
    ) {
      pendingPayments.push(payment);
    }
  });
  return pendingPayments.sort((paymentOne, paymentTwo) =>
    sortByDate(paymentOne.due_at as Date, paymentTwo.due_at as Date)
  );
};

// 2000000 => '$20,000.00'
// undefined => '$0.00'
export const mapCostToFormattedString = (cost?: number, trim?: boolean): string =>
  convertCurrencyFormat((cost || 0) / 100, 'USD', trim ? 0 : 2);

// 2023-01-01T05:00:00.000Z => 'January 1, 2023'
// undefined => undefined
export const mapDateToFormattedString = (
  date?: Date,
  format = 'MMMM D, YYYY'
): string | undefined => (date ? formatDateUtcLocal(date, format) : undefined);

export const mapBudgetToProgressSummary = (
  budget: BudgetContextStateType['budget'],
  isBudgetRevamp?: boolean
): BudgetProgressSummary => {
  const {
    budgeted_cents: budgetedCents,
    cost_cents: costCents,
    cost_cents_without_estimates: costCentsWithoutEstimates,
    balance_due_cents: balanceDueCents,
    paid_cents: paidCents,
    actual_cost_cents: actualCostCents,
  } = budget;

  const progressSummary: BudgetProgressSummary = {
    maxSpend: {
      type: 'maxSpend',
      cents: budgetedCents || 0,
      formattedString: mapCostToFormattedString(budgetedCents, isBudgetRevamp),
      width: 0,
    },
    totalCost: {
      type: 'totalCost',
      cents: (isBudgetRevamp ? actualCostCents : costCents) || 0,
      formattedString: mapCostToFormattedString(
        isBudgetRevamp ? actualCostCents : costCents,
        isBudgetRevamp
      ),
      width: 0,
    },
    totalCostWithoutEstimates: {
      type: 'totalCostWithoutEstimates',
      cents: costCentsWithoutEstimates || 0,
      formattedString: mapCostToFormattedString(costCentsWithoutEstimates, isBudgetRevamp),
      width: 0,
    },
    totalDue: {
      type: 'totalDue',
      cents: balanceDueCents || 0,
      formattedString: mapCostToFormattedString(balanceDueCents, isBudgetRevamp),
      width: 0,
    },
    totalPaid: {
      type: 'totalPaid',
      cents: paidCents || 0,
      formattedString: mapCostToFormattedString(paidCents, isBudgetRevamp),
      width: 0,
    },
  };

  // Max between all four values should take up the full progress bar container
  const progressValuesArray = Object.values(progressSummary);
  const maxCentsValue = Math.max(...progressValuesArray.map(progress => progress.cents || 0));
  progressValuesArray.forEach(progress => {
    const progressWidth = (progress.cents / maxCentsValue || 0) * 100;
    if (progressWidth <= 2) {
      progressSummary[progress.type].width = 0;
      return;
    }
    progressSummary[progress.type].width = progressWidth;
  });

  return progressSummary;
};

export const mapItemTypeToItemTypeDetails = (
  item_types: WBudgetItemTypeView[],
  item_type: string | undefined
) => item_types?.find(t => t.budget_item_type === item_type) || {};

export const mapAccountVendorsToReferenceVendorUuids = (
  accountVendors: MappedWAccountVendorView[]
): string[] => {
  return accountVendors
    .filter(v => v.reference_vendor_uuid)
    .map(v => v.reference_vendor_uuid)
    .filter(notEmpty);
};

// uuids of all vendors that have already been associated with a budget item
export const mapTaxonomyNodesToUnselectableAccountVendorUuids = (
  taxonomyNodes: WBudgetTaxonomyNodeView[]
): (string | undefined)[] =>
  mapTaxonomyNodesToAllBudgetItems(taxonomyNodes)
    .filter(item => item.account_vendor?.uuid)
    .map(item => item.account_vendor?.uuid);

export const mapAccountVendorsToSelectableAccountVendors = (
  accountVendors: MappedWAccountVendorView[],
  taxonomyNodes: WBudgetTaxonomyNodeView[],
  vendorType?: string
): MappedWAccountVendorView[] => {
  const unselectableVendorUuids = mapTaxonomyNodesToUnselectableAccountVendorUuids(taxonomyNodes);
  return accountVendors.filter(
    v =>
      v.vendor_type === vendorType &&
      v.vendor_name &&
      !unselectableVendorUuids.includes(v.account_vendor_uuid)
  );
};

export const mapItemToBookedVendor = (
  accountVendors: MappedWAccountVendorView[],
  accountVendorUuid: string | undefined
) => accountVendors.find(v => v.account_vendor_uuid === accountVendorUuid);

/** Preserves any existing fields we don't want to overwrite on each request */
export const mapItemToUpdateItemRequest = (
  item: WBudgetItemView,
  isBudgetRevamp: boolean
): UpdateBudgetItemRequest => ({
  item_uuid: item.uuid as string,
  title: item.title,
  taxonomy_node_uuid: item.taxonomy_node_uuid,
  item_type: item.item_type,
  note: item.note,
  account_vendor_uuid: item.account_vendor?.uuid,
  ...(isBudgetRevamp
    ? {
        actual_cost_cents: item.actual_cost_cents,
        estimated_cost_cents: item.estimated_cost_cents || 0,
      }
    : {
        estimate: item.estimate,
        cost_cents: item.cost_cents,
      }),
});

export const mapBookedVendorFormValues = (values: BookVendorFormValues) => ({
  syncWithBudgetToolEnabled: false, // this is buggy, so must be done after vendor created
  booked: true,
  eventDate: values.eventDate ? parseDate(values.eventDate) : undefined,
  vendorType: values.vendorType,
  referenceVendorRequest: {
    referenceVendorId: values.referenceVendorId,
    name: values.vendorName,
    email: values.email || null,
    ...(values.city || values.stateProvince
      ? {
          address: {
            city: values.city,
            stateProvince: values.stateProvince,
          },
        }
      : { address: null }),
  },
});

export const getTaxonomyNodeUuidFromPrevPathname = (previousPathname: string | undefined) =>
  previousPathname?.replace('/wedding/manage/budget/group/', '');

export enum BudgetPaymentStatus {
  OVERDUE = 'Overdue',
  PAID = 'Paid',
  UPCOMING = 'Upcoming',
}

export const getBudgetPaymentStatus = (payment: WBudgetItemPaymentView) => {
  const { due_at, paid_at, payment_type } = payment;
  const isPaid = payment_type === (('PAID' as unknown) as WBudgetItemPaymentView.PaymentTypeEnum);
  const today = new Date();

  let type = BudgetPaymentStatus.UPCOMING;
  if (isPaid && paid_at) {
    type = BudgetPaymentStatus.PAID;
  } else if (!isPaid && due_at && isBeforeDate(due_at, today) && !isSameDate(due_at, today)) {
    type = BudgetPaymentStatus.OVERDUE;
  }
  return type;
};

export const getIsPaperType = (item_type: string | undefined) =>
  [
    'INVITATIONS',
    'SAVE_THE_DATES',
    'PROGRAMS', // deprecated, but here to support v1
    'PLACE_CARDS', // deprecated, but here to support v1
    'THANK_YOU_CARDS', // deprecated, but here to support v1
    'DAY_OF_PAPER', // budget-v2
  ].includes(item_type || '');

export const getPaperTypeUrl = (item_type: string | undefined) => {
  switch (item_type) {
    case 'INVITATIONS':
      return '/wedding-planning/invitations/shop';
    case 'SAVE_THE_DATES':
      return '/wedding-planning/save-the-date/shop';
    case 'PROGRAMS':
      return '/wedding-planning/programs/shop';
    case 'PLACE_CARDS':
      return '/wedding-planning/place-cards/shop';
    case 'THANK_YOU_CARDS':
      return '/wedding-planning/thank-you-cards/shop';
    case 'DAY_OF_PAPER':
      return '/wedding-planning/day-of';
    default:
      return '/wedding-planning/universal';
  }
};

export const mapSortByToProp = (item: WBudgetItemView, sortBy: BudgetSortBy) => {
  switch (sortBy) {
    case 'vendor':
      return item?.account_vendor?.vendor_name || '';
    case 'estimate':
      return item?.estimated_cost_cents || 0;
    case 'cost':
      return item?.actual_cost_cents || 0;
    case 'paid':
      return item?.paid_cents || 0;
    default:
      return 0;
  }
};
