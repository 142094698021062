import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

import type { WBudgetItemPaymentView } from '@zola/svc-web-api-ts-client';
import { BellIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Bell';
import { NotesIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Notes';
import { BudgetPaymentTag } from '../BudgetPaymentTag/BudgetPaymentTag';

import { getBudgetItemByUuid, useBudgetContext } from '../context';

import { handleBudgetRouteChange } from '../routes/handleBudgetRouteChange';
import {
  BudgetPaymentStatus,
  getBudgetPaymentStatus,
  mapCostToFormattedString,
  mapDateToFormattedString,
} from '../util/mappers';

import {
  BudgetUpcomingPaymentButton,
  Section,
  Text,
  Amount,
  Subtext,
  VendorCatTitle,
} from './BudgetUpcomingPayment.styles';

export type BudgetUpcomingPaymentProps = WithRouterProps & {
  payment: WBudgetItemPaymentView;
  onClick?: () => void;
};

const BudgetUpcomingPaymentSansRoute = ({
  payment,
  location: { pathname },
  onClick,
}: BudgetUpcomingPaymentProps): JSX.Element | null => {
  const {
    state: {
      budget: { taxonomy_nodes = [] },
    },
  } = useBudgetContext();

  const budgetItem = payment.budget_item_uuid
    ? getBudgetItemByUuid(payment.budget_item_uuid, taxonomy_nodes)
    : null;

  const budgetItemTitle = budgetItem?.title;
  const vendorTitle = budgetItem?.account_vendor?.vendor_name;
  const isPaid =
    payment.payment_type === (('PAID' as unknown) as WBudgetItemPaymentView.PaymentTypeEnum);
  const paymentPaidAt = mapDateToFormattedString(payment.paid_at);
  const paymentDueAt = mapDateToFormattedString(payment.due_at);
  const paymentAmountCents = mapCostToFormattedString(payment.amount_cents);
  const paymentNote = payment.note;
  const paymentRemindAtDate = mapDateToFormattedString(payment.remind_at, 'M/D/YYYY');
  const paymentRemindAtTime = mapDateToFormattedString(payment.remind_at, 'h:mm A');
  const isOverdue = getBudgetPaymentStatus(payment) === BudgetPaymentStatus.OVERDUE;

  const renderPaidAt = () =>
    paymentPaidAt ? (
      <Text data-testid="BudgetUpcomingPayment-Paid">Paid {paymentPaidAt}</Text>
    ) : null;
  const renderDueAt = () =>
    paymentDueAt ? (
      <Text isOverdue={isOverdue} data-testid="BudgetUpcomingPayment-Upcoming">
        Due {paymentDueAt}
      </Text>
    ) : null;

  const handleClick = () => {
    if (onClick) onClick();
    const paymentRoute = `/item/${payment.budget_item_uuid}/payment/${payment.uuid}`;
    handleBudgetRouteChange(`${paymentRoute}/quick-view`, {
      preventIfUnsaved: false,
      previousPathname: pathname,
    });
  };

  if (!budgetItem) return null;
  return (
    <BudgetUpcomingPaymentButton onClick={handleClick} variant="transparent">
      <BudgetPaymentTag payment={payment} />
      <Section>
        {isPaid ? renderPaidAt() : renderDueAt()}
        <Amount data-testid="BudgetUpcomingPayment-Amount">{paymentAmountCents}</Amount>
      </Section>
      <VendorCatTitle>{vendorTitle || budgetItemTitle}</VendorCatTitle>
      {paymentRemindAtDate && (
        <Subtext>
          <BellIcon height={20} width={20} /> Reminder on {paymentRemindAtDate} at{' '}
          {paymentRemindAtTime}
        </Subtext>
      )}
      {paymentNote && (
        <Subtext>
          <NotesIcon height={20} width={20} /> {paymentNote}
        </Subtext>
      )}
    </BudgetUpcomingPaymentButton>
  );
};

export const BudgetUpcomingPayment = withRouter(BudgetUpcomingPaymentSansRoute);
