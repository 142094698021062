export const FOIL_COLOR_LABELS = {
  none: 'No Foil',
  gold: 'Gold',
  copper: 'Rose Gold',
  silver: 'Silver',
  'bright-silver': 'Silver',
  'rose-gold': 'Rose Gold',
  blue: 'Blue',
  'glittery-gold': 'Gold Shimmer',
  'glittery-silver': 'Silver Shimmer',
  bronze: 'Bronze',
  black: 'Black',
  'matte-black': 'Matte Black',
  'ice-blue': 'Frost Blue',
  white: 'White',
} as const;
