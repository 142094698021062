import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { getBackendTypeFromType } from 'cards/util/cardTypes';
import {
  CARD_TYPE_MAP,
  PAPER_ADD_ONS_LEAD_CARD_TYPES,
} from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import ApiService from '../../util/api';
import {
  getSearchFilters,
  getDisplayableSearchTotal,
  getCardUUIDsByVariationUUID,
  getCards,
} from '../selectors/cardCatalogSelector';
import ActionTypes from './cardCatalogActionTypes';

// From old type to new type
// Use before sending to faceted api
export function facetedFilterMapperOldToNew(oldFiltersObj) {
  const newFilters = {
    ...oldFiltersObj,
    colors:
      [...(oldFiltersObj.multiColor || []), ...(oldFiltersObj.seo_colors || [])] ||
      (oldFiltersObj.color ? [oldFiltersObj.color] : []),
  };

  // remove multiColor, color, and seo_colors -> not part of new API
  return _omit(newFilters, ['multiColor', 'color', 'seo_colors']);
}

export function requestSearchCardSuites() {
  return {
    type: ActionTypes.REQUEST_SEARCH_CARD_SUITES,
  };
}

function receiveSearchCardSuites(json) {
  return {
    type: ActionTypes.RECEIVE_SEARCH_CARD_SUITES,
    payload: {
      cardSuites: json,
    },
  };
}

const receiveSearchCardSuitesByUUID = json => {
  return {
    type: ActionTypes.RECEIVE_SEARCH_CARD_SUITES_BY_UUID,
    payload: {
      cardSuites: json,
    },
  };
};

function receiveThemeMatchedCardSuites(json) {
  return {
    type: ActionTypes.RECEIVE_THEME_MATCHED_CARD_SUITES,
    payload: {
      cardSuites: json,
    },
  };
}

export function resetThemeMatchedCards() {
  return {
    type: ActionTypes.RESET_THEME_MATCHED_CARDS,
  };
}

function receiveCard(card, variationUUID) {
  return {
    type: ActionTypes.RECEIVE_CARD,
    payload: {
      card,
      variationUUID,
    },
  };
}

function updateCardCustomizationRef(cardUUID, customizationUUID) {
  return {
    type: ActionTypes.UPDATE_CARD_CUSTOMIZATION_UUID,
    payload: {
      cardUUID,
      customizationUUID,
    },
  };
}

export function receiveAdditionalSuiteVersions(suiteVersions) {
  return {
    type: ActionTypes.RECEIVE_ADDITIONAL_SUITE_VERSIONS,
    payload: suiteVersions,
  };
}

function receiveRelatedCardsFromSuite(relatedCards) {
  return {
    type: ActionTypes.RECEIVE_RELATED_CARDS_FROM_SUITE,
    payload: { relatedCards },
  };
}

const receiveRelatedCardsByDesigner = json => ({
  type: ActionTypes.RECEIVE_RELATED_CARDS_BY_DESIGNER,
  payload: {
    relatedCards: json,
  },
});

const receivePaperAddOnCards = paperAddOns => ({
  type: ActionTypes.RECEIVE_PAPER_ADD_ONS,
  payload: { paperAddOns },
});

function receiveCollaborators(collaborators) {
  return {
    type: ActionTypes.RECEIVE_COLLABORATORS,
    payload: {
      collaborators,
    },
  };
}

export function unMountRelatedCards() {
  return {
    type: ActionTypes.RESET_RELATED_CARDS_FROM_SUITE,
  };
}

export const resetRelatedCardsByDesigner = () => ({
  type: ActionTypes.RESET_RELATED_CARDS_BY_DESIGNER,
});

export const resetPaperAddOnCards = () => ({
  type: ActionTypes.RESET_PAPER_ADD_ONS,
});

export function updateSearchFilters(key, value) {
  return {
    type: ActionTypes.UPDATE_SEARCH_FILTERS,
    payload: { key, value },
  };
}

export function updateMultipleSearchFilters(newValues) {
  return {
    type: ActionTypes.UPDATE_MULTIPLE_SEARCH_FILTERS,
    payload: newValues,
  };
}

export function overwriteSearchFilters(newValues) {
  return {
    type: ActionTypes.OVERWRITE_SEARCH_FILTERS,
    payload: newValues,
  };
}

export function resetSearchFilters() {
  return {
    type: ActionTypes.RESET_SEARCH_FILTERS,
  };
}

export function updateCatalogAdminView(isAdminView) {
  return {
    type: ActionTypes.UPDATE_CATALOG_ADMIN_VIEW,
    payload: { isAdminView },
  };
}

export function hydrateMotifs(motif) {
  return {
    type: ActionTypes.HYDRATE_MOTIFS,
    payload: motif,
  };
}

export function getMotifs(categories) {
  return dispatch =>
    ApiService.get(`/web-api/v1/card-catalog/motifs/${categories}`).then(json => {
      dispatch(hydrateMotifs(json));
    });
}

const getOrientation = filters => {
  let orientation = null;
  if (filters.is_landscape) orientation = 'landscape';
  if (filters.is_portrait) orientation = 'portrait';
  if (filters.is_landscape && filters.is_portrait) orientation = null;
  return orientation;
};

export function searchCardSuites() {
  return (dispatch, getState) => {
    const { filters, initial } = getState().cards.cardCatalog;
    const { limit, offset } = filters;

    const orientation = getOrientation(filters);

    const isPaperAddOns = filters.lead_card_type === CARD_TYPE_MAP.paperAddOns;

    const requestBody = {
      offset: initial ? 0 : offset + limit,
      limit,
      active_only: filters.active_only,
      // When the lead card type is PAPER_ADD_ONS on the FE it means the the request payload sent to the BE includes:
      // lead_card_types: ["BELLY_BAND", "VELLUM_JACKET", "WAX_SEAL"]
      // Since the BE doesn't recognize PAPER_ADD_ONS as a card type, it must be removed from the request payload so the request isn't rejected
      lead_card_type: isPaperAddOns ? null : filters.lead_card_type,
      lead_card_types: filters.lead_card_types,
      color: filters.color,
      seo_colors: filters.seo_colors,
      has_foil: filters.has_foil,
      has_custom_photo: filters.has_custom_photo,
      has_magnet: filters.has_magnet,
      is_letterpress: filters.is_letterpress,
      collaborator: filters.collaborator,
      motifs: filters.motifs,
      silhouettes: filters.silhouettes,
      has_digital: filters.has_digital,
      orientation,
      third_party: filters.featured_artist,
      single_sample_available: filters.single_sample_available,
      digital_suite: filters.digital_suite,
      sorts: isPaperAddOns ? ['LAUNCH_DATE'] : null,
    };
    dispatch(requestSearchCardSuites());
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(requestBody)
    ).then(json => dispatch(receiveSearchCardSuites(json)));
  };
}

export const searchCardSuitesByUUID = UUIDs => {
  return (dispatch, getState) => {
    const state = getState();
    const requestBody = {
      card_suite_uuids: UUIDs,
      offset: 0,
      limit: UUIDs.length,
      active_only: true,
      digital_suite: _get(state, 'cards.cardCatalog.filters.digital_suite'),
    };

    dispatch(requestSearchCardSuites());
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(requestBody)
    ).then(({ suites }) => {
      const orderedSuites = UUIDs.map(uuid => suites.find(suite => suite.uuid === uuid));
      return dispatch(receiveSearchCardSuitesByUUID(orderedSuites));
    });
  };
};

const body = (wwTheme = '', state) => {
  const leadCardType = _get(state, 'cards.cardCatalog.filters.lead_card_type');
  const isPaperAddOns = leadCardType === CARD_TYPE_MAP.paperAddOns;

  return {
    active_only: true,
    // When the lead card type is PAPER_ADD_ONS on the FE it means the the request payload sent to the BE includes:
    // lead_card_types: ["BELLY_BAND", "VELLUM_JACKET", "WAX_SEAL"]
    // Since the BE doesn't recognize PAPER_ADD_ONS as a card type, it must be removed from the request payload so the request isn't rejected
    lead_card_type: isPaperAddOns ? null : leadCardType,
    lead_card_types: _get(state, 'cards.cardCatalog.filters.lead_card_types'),
    family: wwTheme,
    offset: 0,
    limit: 1, // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1723
    color: null,
    seo_colors: [],
    has_foil: false,
    has_custom_photo: false,
    has_magnet: false,
    motifs: [],
    silhouettes: [],
    has_digital: false,
    orientation: null,
    third_party: null,
    single_sample_available: _get(state, 'cards.cardCatalog.filters.single_sample_available'),
    digital_suite: _get(state, 'cards.cardCatalog.filters.digital_suite'),
    sorts: isPaperAddOns ? ['LAUNCH_DATE'] : null,
  };
};

export function initialLoadMatchingThemeCardsForUser() {
  return (dispatch, getState) => {
    const state = getState();

    const isCollaboratorFilterActive = _get(state, 'cards.cardCatalog.filters.collaborator');
    const userHasIdentified = _get(state, 'user.hasIdentified');
    const userHasWeddingAccount = _get(state, 'user.userContext.has_wedding_account');

    // Only fetch matching theme cards if user is logged-in, has a wedding account, and isn't searching for cards from collaborators
    if (isCollaboratorFilterActive || !userHasIdentified || !userHasWeddingAccount)
      return Promise.resolve();

    dispatch(requestSearchCardSuites()); // Set busy state to true

    return ApiService.get('/web-api/v2/weddingTheme/getThemeGroup')
      .then(({ key: wwThemeKey }) => {
        ApiService.post(
          '/web-api/v1/card-catalog/search/faceted',
          facetedFilterMapperOldToNew(body(wwThemeKey, getState()))
        ).then(json => dispatch(receiveThemeMatchedCardSuites(json)));
      })
      .catch(() => undefined);
  };
}

export function getCollaborators() {
  return dispatch =>
    ApiService.get('/web-api/v1/card-catalog/collaborators').then(json => {
      dispatch(receiveCollaborators(json));
    });
}

export function getCardByVariationUUID(variationUUID, customizationUUID, showUnlisted = false) {
  return (dispatch, getState) => {
    // Since the data won't change, check if the variation's card is available before trying to fetch it.
    const cardUUIDsByVariationUUID = getCardUUIDsByVariationUUID(getState());
    const cardUUID = cardUUIDsByVariationUUID[variationUUID];
    if (cardUUID) {
      /*
       * When navigating back and forth between the PDP and the cust. flow
       * we need to update the attached customization UUID to the already
       * existing card variation data when a new project
       * is created to properly load the related options in the flow.
       * e.g. theme colors/paper types/etc.
       */
      dispatch(updateCardCustomizationRef(cardUUID, customizationUUID));

      const allCardData = getCards(getState());
      return Promise.resolve(allCardData[cardUUID]);
    }

    const requestBody = {
      show_unlisted: showUnlisted,
    };
    return ApiService.post(
      `/web-api/v1/card-catalog/variations/${variationUUID}/card`,
      requestBody
    ).then(json => {
      // TODO: think about possible ways to improve this without needing to inject customizationUUID
      // into response object, perhaps set cardUUID on customization objects in cardProject.customizations instead
      const card = { ...json, customizationUUID };
      dispatch(receiveCard(card, variationUUID));
      return card;
    });
  };
}

export function getRelatedCardsFromSuite(familyName, color, seo_colors, cardType, limit = 12) {
  return dispatch => {
    let requestBody = {
      offset: 0,
      limit,
      active_only: true,
      color,
      seo_colors,
      has_foil: null,
      has_custom_photo: null,
      has_magnet: null,
      family: familyName,
      orientation: null,
      third_party: null,
    };

    if (Array.isArray(cardType)) {
      requestBody = {
        ...requestBody,
        ...{
          lead_card_types: cardType,
        },
      };
    } else {
      requestBody = {
        ...requestBody,
        ...{
          lead_card_type: familyName ? null : cardType,
        },
      };
    }

    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(requestBody)
    ).then(json => dispatch(receiveRelatedCardsFromSuite(json)));
  };
}

export const getPaperAddOnCards = () => {
  return dispatch => {
    const requestBody = {
      offset: 0,
      limit: 12,
      active_only: true,
      lead_card_types: PAPER_ADD_ONS_LEAD_CARD_TYPES,
      color: null,
      seo_colors: [],
      has_foil: null,
      has_custom_photo: null,
      family: null,
      orientation: null,
      third_party: null,
      sorts: ['LAUNCH_DATE'],
    };

    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(requestBody)
    ).then(json => dispatch(receivePaperAddOnCards(json)));
  };
};

export const getRelatedCardsByDesigner = (cardType, collaborator, limit = 12) => {
  return dispatch => {
    const requestBody = {
      offset: 0,
      limit,
      active_only: true,
      lead_card_type: getBackendTypeFromType(cardType),
      color: null,
      seo_colors: [],
      has_foil: null,
      has_custom_photo: null,
      family: null,
      orientation: null,
      third_party: null,
      collaborator,
    };

    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(requestBody)
    ).then(json => dispatch(receiveRelatedCardsByDesigner(json)));
  };
};

export function receiveAdditionalCardSuites(cardSuites) {
  return {
    type: ActionTypes.RECEIVE_ADDITIONAL_CARD_SUITES,
    payload: { cardSuites },
  };
}

export function requestAdditionalCardSuites(offset, limit) {
  return (dispatch, getState) => {
    const state = getState();
    const displayableSearchTotal = getDisplayableSearchTotal(state);
    const currentFilters = getSearchFilters(state);

    const noMore = offset + 1 >= displayableSearchTotal;
    if (noMore) return Promise.resolve({ total: displayableSearchTotal, count: 0, offset });

    const orientation = getOrientation(currentFilters);

    const overTotal = offset + limit >= displayableSearchTotal;
    const searchRequest = {
      ...currentFilters,
      limit: overTotal ? displayableSearchTotal - offset : limit,
      offset,
      orientation,
    };

    dispatch(requestSearchCardSuites());
    // TODO: If search returns 0 items - search again at offset 0.
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(searchRequest)
    ).then(json => dispatch(receiveAdditionalCardSuites(json)));
  };
}

export const receiveInitialCardSuites = cardSuites => ({
  type: ActionTypes.RECEIVE_INITIAL_CARD_SUITES,
  payload: {
    cardSuites,
  },
});

export function requestInitialCardSuites(offset, limit) {
  return (dispatch, getState) => {
    const { filters } = getState().cards.cardCatalog;
    const orientation = getOrientation(filters);
    const searchRequest = {
      ...filters,
      limit,
      offset,
      orientation,
      third_party: filters.featured_artist,
      // When the lead card type is PAPER_ADD_ONS on the FE it means the the request payload sent to the BE includes:
      // lead_card_types: ["BELLY_BAND", "VELLUM_JACKET", "WAX_SEAL"]
      // Since the BE doesn't recognize PAPER_ADD_ONS as a card type, it must be removed from the request payload so the request isn't rejected
      lead_card_type:
        filters.lead_card_type === CARD_TYPE_MAP.paperAddOns ? null : filters.lead_card_type,
      sorts: filters.lead_card_type === CARD_TYPE_MAP.paperAddOns ? ['LAUNCH_DATE'] : filters.sorts,
    };

    dispatch(requestSearchCardSuites());
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(searchRequest)
    ).then(json => {
      dispatch(receiveInitialCardSuites(json));
      return Promise.resolve();
    });
  };
}

export function requestSSRCardSuites(offset, limit, filterUpdates) {
  return (dispatch, getState) => {
    const { filters } = getState().cards.cardCatalog;
    const allFilters = { ...filters, ...filterUpdates };
    const orientation = getOrientation(filters);
    const isPaperAddOns = allFilters.lead_card_type === CARD_TYPE_MAP.paperAddOns;
    const searchRequest = {
      ...allFilters,
      limit,
      offset,
      orientation,
      third_party: allFilters.featured_artist,
      // When the lead card type is PAPER_ADD_ONS on the FE it means the the request payload sent to the BE includes:
      // lead_card_types: ["BELLY_BAND", "VELLUM_JACKET", "WAX_SEAL"]
      // Since the BE doesn't recognize PAPER_ADD_ONS as a card type, it must be removed from the request payload so the request isn't rejected
      lead_card_type: isPaperAddOns ? null : allFilters.lead_card_type,
    };

    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(searchRequest)
    ).catch(e => {
      throw new Error(e);
    });
  };
}

function receiveSearchPreviousCardSuites(cardSuites) {
  return {
    type: ActionTypes.RECEIVE_PREVIOUS_SEARCH_RESULTS,
    payload: {
      cardSuites,
    },
  };
}

export function requestPreviousCardSuites(offset, limit) {
  return (dispatch, getState) => {
    const state = getState();
    const currentFilters = getSearchFilters(state);
    const orientation = getOrientation(currentFilters);
    const searchRequest = { ...currentFilters, limit, offset, orientation };
    dispatch(requestSearchCardSuites());
    return ApiService.post(
      '/web-api/v1/card-catalog/search/faceted',
      facetedFilterMapperOldToNew(searchRequest)
    ).then(json => dispatch(receiveSearchPreviousCardSuites(json)));
  };
}
