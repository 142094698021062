import React from 'react';

import type { WBudgetItemView } from '@zola/svc-web-api-ts-client';

import { mapCostToFormattedString } from '../../util/mappers';
import { handleBudgetRouteChange } from '../../routes/handleBudgetRouteChange';
import { useBudgetContext } from '../../context';

import { BudgetUpcomingPayment } from '../../BudgetUpcomingPayment/BudgetUpcomingPayment';

import {
  PaidRemaining,
  PaidRemainingCurrency,
  PaymentsHeader,
  StyledP,
  AddPaymentButton,
} from '../BudgetItemView.styles';

type BudgetItemViewPaymentsProps = {
  paidCents: WBudgetItemView['paid_cents'];
  costCents: WBudgetItemView['actual_cost_cents'] | WBudgetItemView['cost_cents']; // TODO: remove 'cost_cents' when all items are backfilled
  payments: WBudgetItemView['payments'];
  itemUuid: string;
  handleStashChanges: () => void;
};

export const BudgetItemViewPayments = ({
  paidCents = 0,
  costCents = 0,
  payments,
  itemUuid,
  handleStashChanges,
}: BudgetItemViewPaymentsProps) => {
  const { isBudgetRevamp } = useBudgetContext();
  const handleStashChangesAndChangeRoute = () => {
    handleStashChanges();
    handleBudgetRouteChange(`/item/${itemUuid}/payment/new`, { preventIfUnsaved: false });
  };

  return (
    <>
      {!isBudgetRevamp && (
        <PaidRemaining>
          <span>Paid</span>
          <PaidRemainingCurrency>{mapCostToFormattedString(paidCents)}</PaidRemainingCurrency>
          <span>Remaining due</span>
          <PaidRemainingCurrency>
            {mapCostToFormattedString(costCents - paidCents)}
          </PaidRemainingCurrency>
        </PaidRemaining>
      )}
      <PaymentsHeader>Payments and reminders</PaymentsHeader>
      {payments?.length ? (
        payments.map(payment => (
          <BudgetUpcomingPayment
            key={payment.uuid}
            payment={payment}
            onClick={handleStashChanges}
          />
        ))
      ) : (
        <StyledP>Track your payments and get alerts on deadlines.</StyledP>
      )}
      <AddPaymentButton variant="secondary" onClick={handleStashChangesAndChangeRoute}>
        Add a payment or reminder
      </AddPaymentButton>
    </>
  );
};
