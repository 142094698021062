import { browserHistory } from 'react-router';
import { BUDGET_BASE_ROUTE } from './constants';

type BudgetRouteChangeOptions = Record<string, string | number | boolean> & {
  preventIfUnsaved?: boolean;
};

export const handleBudgetRouteChange = (pathname?: string, options?: BudgetRouteChangeOptions) => {
  const { preventIfUnsaved = true } = options || {};
  const cleanPath = pathname?.replace(BUDGET_BASE_ROUTE, '');
  browserHistory.replace({
    pathname: cleanPath ? `${BUDGET_BASE_ROUTE}${cleanPath}` : BUDGET_BASE_ROUTE,
    state: { preventIfUnsaved, ...options },
  });
};
