import _get from 'lodash/get';
import { createSelector } from 'reselect';

export const getCustomizationState = state => _get(state, ['photobooks', 'project']);

export const getProjectCustomizationId = createSelector(
  getCustomizationState,
  customizationState => {
    const currentProjectDetails = _get(customizationState, ['photobookProject', 'details']);
    const projectCustomizationDetails = currentProjectDetails?.find(
      detail => detail.key === 'album-project-customization-id'
    );
    return projectCustomizationDetails?.value;
  }
);

export const getProjectUuid = createSelector(getCustomizationState, customizationState =>
  _get(customizationState, ['photobookProject', 'uuid'])
);

export const getIsAddingToCart = createSelector(getCustomizationState, customizationState =>
  _get(customizationState, ['addingToCart'])
);

export const getProjectInCart = createSelector(getCustomizationState, customizationState =>
  _get(customizationState, ['photobookProject', 'in_cart'])
);

export const getCurrentAlbum = createSelector(getCustomizationState, customizationState => {
  const currentProject = _get(customizationState, ['photobookProject', 'project_items']);
  if (!currentProject) return null;
  const currentAlbum = currentProject.find(each => each.type === 'ALBUM');
  return currentAlbum;
});

export const getProjectPrice = createSelector(getCurrentAlbum, currentAlbum => {
  return _get(currentAlbum, ['price_cents']);
});

export const getProjectAlbumStyle = createSelector(getCurrentAlbum, currentAlbum => {
  return _get(currentAlbum, ['details', 'album-style']);
});

export const getMaxSpreads = createSelector(getCurrentAlbum, currentAlbum => {
  const maxSpreads = _get(currentAlbum, ['details', 'maximum-spreads']);
  return typeof maxSpreads === 'string' ? parseInt(maxSpreads, 10) : 20;
});
