import styled from '@emotion/styled';

import { COLORS3, FONT, SPACING } from '@zola/zola-ui/src/styles/emotion';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

import DropdownV3 from '@zola/zola-ui/src/components/Form/dropdownV3';
import { Input } from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3/InputFieldV3.styles';
import { ListBoxContainer } from '@zola/zola-ui/src/components/Form/common/DropdownListBox/DropdownListBox.styles';
import ToggleV2 from '@zola/zola-ui/src/components/ToggleV2';
import { BodyBase, BodySmall } from '@zola/zola-ui/src/typography/Paragraphs';

export const Grid = styled.div<{ gap?: keyof typeof SPACING }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ gap = 'XS' }) => SPACING[gap]};
  margin-top: ${SPACING.LG};
`;

export const StyledBodySmall = styled(BodySmall)`
  margin-top: ${SPACING.XXS};
  margin-bottom: ${SPACING.XS};
  color: ${COLORS3.BLACK_050};

  ${MEDIA_QUERY_V2.DESKTOP} {
    padding-left: 56px;
  }
`;

export const StyledBodyBase = styled(BodyBase)`
  margin-top: ${SPACING.S16};
`;

export const NotesLabel = styled.label`
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-family: ${FONT.FAMILY.SANS_SERIF};
  text-transform: none;
  letter-spacing: normal;
  margin-top: ${SPACING.S16};
`;

export const Optional = styled.span`
  color: ${COLORS3.BLACK_050};
`;

export const AmountField = styled.div`
  ${Input} {
    text-align: right;
  }
`;

export const SetUpAReminder = styled.div`
  padding-top: ${SPACING.S16};
  margin-bottom: ${SPACING.S24};
`;

export const ReminderFields = styled.div`
  margin-bottom: ${SPACING.S16};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${SPACING.S16};
  ${MEDIA_QUERY_V2.DESKTOP} {
    padding-left: 56px;
  }
`;

export const StyledDropdown = styled(DropdownV3)`
  ${ListBoxContainer} {
    ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
      max-height: 175px;
    }
  }
`;

export const StyledToggle = styled(ToggleV2)`
  label {
    text-transform: none;
    margin-bottom: 0;
    font-weight: ${FONT.WEIGHT.REGULAR};
    font-size: ${FONT.SIZE.SMALL};
    line-height: ${FONT.SIZE.SMALL};
    letter-spacing: normal;
    color: ${COLORS3.BLACK_100};
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
