const init = {
  busy: true,
  error: null,
  photobookProject: null,
  addingToCart: false,
};

const reducer = (state = init, action) => {
  switch (action.type) {
    case 'REQUEST_PHOTOBOOK_PROJECT_CREATION':
      return {
        ...state,
        busy: true,
        error: null,
      };
    case 'RECEIVE_PHOTOBOOK_PROJECT_CREATION':
      return {
        ...state,
        photobookProject: action.payload,
        busy: false,
        error: null,
      };
    case 'REJECTED_PHOTOBOOK_PROJECT_CREATION':
      return {
        ...state,
        busy: false,
        error: action.payload,
      };
    case 'REQUEST_ADD_PROJECT_TO_CART':
      return {
        ...state,
        addingToCart: true,
        error: null,
      };
    case 'RECEIVE_ADD_PROJECT_TO_CART':
      return {
        ...state,
        addingToCart: false,
      };
    case 'REJECTED_ADD_PROJECT_TO_CART':
      return {
        ...state,
        addingToCart: false,
        error: action.payload,
      };
    case 'FETCH_PHOTOBOOK_PROJECT':
      return {
        ...state,
        error: null,
      };
    case 'FETCH_PHOTOBOOK_PROJECT_FULFILLED':
      return {
        ...state,
        photobookProject: action.payload,
      };
    case 'FETCH_PHOTOBOOK_PROJECT_REJECTED':
      return {
        ...state,
        error: action.payload,
      };
    case 'CLEAR_PHOTOBOOK_PROJECT':
      return {
        ...state,
        busy: true,
        error: null,
        photobookProject: null,
        addingToCart: false,
      };
    default:
      return state;
  }
};

export default reducer;
