const init = {
  leftMenuWasToggled: false,
  activeMenu: {},
  photoMenuHideUsed: false,
};

const reducer = (state = init, action) => {
  switch (action.type) {
    case 'LEFT_MENU_OPEN': {
      return {
        ...state,
        leftMenuWasToggled: true,
        activeMenu: action.payload,
      };
    }
    case 'LEFT_MENU_CLOSE': {
      return {
        ...state,
        activeMenu: {},
      };
    }
    case 'PHOTO_MENU_TOGGLE_HIDE_USED': {
      const previousToggleState = state.photoMenuHideUsed;
      return {
        ...state,
        photoMenuHideUsed: !previousToggleState,
      };
    }
    default:
      return state;
  }
};

export default reducer;
