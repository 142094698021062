import { useEffect } from 'react';
import type { PlainRoute, InjectedRouter } from 'react-router';

import { useDrawerControls } from 'components/common/zolaUI/Drawer';

export const useLeaveBudgetRoute = (
  route: PlainRoute,
  setRouteLeaveHook: InjectedRouter['setRouteLeaveHook']
) => {
  const { currentLayer } = useDrawerControls();
  const { onUnsavedChanges } = currentLayer || {};
  useEffect(() => {
    setRouteLeaveHook(route, nextRoute => {
      if (onUnsavedChanges && nextRoute?.state?.preventIfUnsaved) {
        onUnsavedChanges();
        return false;
      }
      return true;
    });
  }, [onUnsavedChanges, route, setRouteLeaveHook]);
};
