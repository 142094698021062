import React from 'react';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

import {
  PromptOverlay,
  PromptTitle,
  ButtonContainer,
} from 'components/common/zolaUI/Drawer/Drawer.styles';
import { PromptDek, Container } from './BudgetConfirmDeleteMiniModal.styles';

export type BudgetConfirmDeleteMiniModalProps = {
  className?: string;
  hed?: string;
  dek?: string;
  confirmText?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

export const BudgetConfirmDeleteMiniModal = ({
  className,
  hed = 'Are you sure you want to delete this?',
  dek,
  confirmText = 'Yes, delete',
  onConfirm,
  onCancel,
}: BudgetConfirmDeleteMiniModalProps): JSX.Element => {
  return (
    <PromptOverlay>
      <Container data-testid="BudgetConfirmDeleteMiniModal" className={className}>
        <PromptTitle>{hed}</PromptTitle>
        <PromptDek>{dek}</PromptDek>
        <ButtonContainer>
          <ButtonV3 onClick={onConfirm} variant="secondary" size="small">
            {confirmText}
          </ButtonV3>
          <ButtonV3 onClick={onCancel} size="small">
            No
          </ButtonV3>
        </ButtonContainer>
      </Container>
    </PromptOverlay>
  );
};
