import React from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import type { WBudgetItemView, WBudgetTaxonomyNodeView } from '@zola/svc-web-api-ts-client';
import { BudgetSortableItem } from '../BudgetSortableItem/BudgetSortableItem';

import { SortableListContainer, NoItemsText } from './BudgetSortableList.styles';

type BudgetSortableListProps = {
  items: WBudgetItemView[] | WBudgetTaxonomyNodeView[];
  itemUuids: string[];
  onClickEdit: (uuid: string) => void;
  onReorder: (reorderedItems: WBudgetItemView[] | WBudgetTaxonomyNodeView[]) => void;
};

export const BudgetSortableList = ({
  items,
  itemUuids,
  onClickEdit,
  onReorder,
}: BudgetSortableListProps): JSX.Element => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      const oldIndex = items.map(item => item.uuid).indexOf(active.id);
      const newIndex = items.map(item => item.uuid).indexOf(over.id);
      onReorder(arrayMove(items, oldIndex, newIndex));
    }
  };

  return (
    <SortableListContainer data-testid="BudgetSortableList">
      <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
        <SortableContext items={itemUuids} strategy={verticalListSortingStrategy}>
          {items.length > 0 ? (
            items.map(({ title = '', uuid = '' }, i) => (
              <BudgetSortableItem
                key={`budget-sortable-item-${i}`}
                onClickEdit={() => onClickEdit(uuid)}
                sortableItemUuid={uuid}
                title={title}
              />
            ))
          ) : (
            <NoItemsText>No items to show</NoItemsText>
          )}
        </SortableContext>
      </DndContext>
    </SortableListContainer>
  );
};
