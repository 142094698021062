import React, { useState, useContext, useCallback } from 'react';

type VoidFn = () => void;

export type DrawerLayer = {
  key: string;
  /** Should show close OR back icon (as of yet, no layer should ever show both at same time) */
  useClose?: boolean;
  onBack?: VoidFn;
  onSave?: VoidFn;
  onUnsavedChanges?: VoidFn;
  tertiaryElement?: React.ReactNode;
  cancelButtonText?: string;
  cancelButtonDisabled?: boolean;
  saveButtonText?: string;
  saveButtonDisabled?: boolean;
};

type DrawerContextStateType = {
  onClose?: VoidFn;
  /** Drawer is visible state */
  isOpen: boolean;
  /** Show the unsaved changes prompt */
  showSavePrompt: boolean;
  drawerLayers: DrawerLayer[];
  currentLayer: DrawerLayer | undefined;
};

export type DrawerContextProps = {
  isOpen?: boolean;
  showSavePrompt?: boolean;
  useClose?: boolean;
  onClose?: VoidFn;
};

const defaultState: DrawerContextStateType & { drawerLayers: DrawerLayer[] } = {
  isOpen: false,
  showSavePrompt: false,
  drawerLayers: [],
  currentLayer: undefined,
};

export const DrawerContext = React.createContext<
  DrawerContextStateType & {
    /* Callback setter for onUnsavedChanges */
    setOnClose: (onClose?: VoidFn) => void;
    /* Callback setter for onUnsavedChanges */
    setOnUnsavedChanges: (onUnsavedChanges?: VoidFn) => void;
    /* State setter for isOpen */
    setIsOpen: (isOpen: boolean) => void;
    /* State setter for showSavePrompt */
    setShowSavePrompt: (showSavePrompt: boolean) => void;
    setDrawerLayer: (drawerLayer: DrawerLayer) => void;
    removeDrawerLayer: (keyToRemove: string) => void;
  }
>({
  ...defaultState,
  setOnUnsavedChanges: () => undefined,
  setOnClose: () => undefined,
  setIsOpen: () => undefined,
  setShowSavePrompt: () => undefined,
  setDrawerLayer: () => undefined,
  removeDrawerLayer: () => undefined,
});

const { Provider } = DrawerContext;

export const DrawerProvider: React.FC<DrawerContextProps> = ({ children, ...props }) => {
  const [drawerState, setDrawerState] = useState({ ...defaultState, ...props });

  const setIsOpen = useCallback((isOpen: boolean) => {
    setDrawerState(prevState => ({ ...prevState, isOpen }));
  }, []);

  const setShowSavePrompt = useCallback((showSavePrompt: boolean) => {
    setDrawerState(prevState => ({ ...prevState, showSavePrompt }));
  }, []);

  const setOnClose = useCallback((onClose?: VoidFn) => {
    setDrawerState(prevState => ({ ...prevState, onClose }));
  }, []);

  const setDrawerLayer = useCallback((layer: DrawerLayer) => {
    setDrawerState(prevState => {
      const getDrawerLayersState = () => {
        const existingIdx = prevState.drawerLayers.findIndex(({ key }) => key === layer.key);
        // update existing record
        if (existingIdx >= 0) {
          return prevState.drawerLayers?.map((l, i) => (i === existingIdx ? layer : l));
        }
        // add new record
        return [layer, ...prevState.drawerLayers];
      };
      const drawerLayers = getDrawerLayersState();
      const currentLayer = drawerLayers[0];
      return {
        ...prevState,
        drawerLayers,
        currentLayer,
      };
    });
  }, []);

  const removeDrawerLayer = useCallback((keyToRemove: string) => {
    setDrawerState(prevState => {
      const drawerLayers = prevState.drawerLayers.filter(({ key }) => keyToRemove !== key);
      const currentLayer = drawerLayers[0];
      return {
        ...prevState,
        drawerLayers,
        currentLayer,
      };
    });
  }, []);

  const setOnUnsavedChanges = useCallback((onUnsavedChanges?: VoidFn) => {
    setDrawerState(prevState => {
      return {
        ...prevState,
        currentLayer: {
          ...prevState.currentLayer,
          key: (prevState.currentLayer as DrawerLayer).key,
          onUnsavedChanges,
        },
      };
    });
  }, []);

  return (
    <Provider
      value={{
        ...drawerState,
        setOnClose,
        setOnUnsavedChanges,
        setIsOpen,
        setShowSavePrompt,
        setDrawerLayer,
        removeDrawerLayer,
      }}
    >
      {children}
    </Provider>
  );
};

export const useDrawerControls = () => useContext(DrawerContext);
