import _get from 'lodash/get';
import { createSelector } from 'reselect';

export const getAlbumItemState = state => _get(state, ['photobooks', 'albumItem']);

export const getAlbumItemUUID = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['albumItemUuid'])
);

export const getAlbumWidth = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['albumDetails', 'width'])
);

export const getAlbumHeight = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['albumDetails', 'height'])
);

export const getSpreads = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['albumDetails', 'spreads'])
);

export const getActiveSpread = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['activeSpread'])
);

export const getActiveSpreadIndex = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['activeSpread', 'spread_index'])
);

export const getAlbumIsBusy = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['busy'])
);

export const getUpdatingSpreadIndex = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['updatingSpreadIndexes'])
);

export const getAlbumVariationUUID = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['albumVariation', 'uuid'])
);

export const getAlbumVariationOptions = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['albumVariation', 'options'])
);

export const getAlbumVariation = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['albumVariation'])
);

export const getAlbumTier = createSelector(getAlbumItemState, albumItemState => {
  const albumProperties = _get(albumItemState, ['albumProperties']);

  return albumProperties && albumProperties.find(property => property.key === 'album-tier').value;
});

export const getCoverMaterial = createSelector(getAlbumVariationOptions, options =>
  _get(options, ['cover-type'])
);

export const getAlbumVariationSize = createSelector(getAlbumVariationOptions, variationOptions =>
  _get(variationOptions, ['book-size'])
);

// Should only be used to diff current viewed spreads from previously synced viewed spreads
export const getSyncedViewedSpreads = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['syncedViewedSpreads'])
);

export const getViewedSpreads = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['viewedSpreads'])
);

export const getTotalNumberOfSpreads = createSelector(getSpreads, allSpreads => {
  if (Array.isArray(allSpreads)) {
    return allSpreads.length;
  }
  return null;
});

export const getAllSlots = createSelector(getSpreads, allSpreads => {
  if (!allSpreads) return null;

  const allSlots = [];
  allSpreads.forEach(spread => spread.layouts.forEach(layout => allSlots.push(...layout.slots)));
  return allSlots;
});

export const getAllImageUUIDs = createSelector(getAllSlots, allSlots =>
  allSlots
    ? allSlots.reduce((acc, slot) => (slot.image_uuid ? [...acc, slot.image_uuid] : acc), [])
    : null
);

export const getImageOriginalRefs = createSelector(getAlbumItemState, albumItemState =>
  _get(albumItemState, ['albumDetails', 'image_original_refs'])
);

// Returns the number of times each image's original is used in the album
export const getImageOriginalUseCount = createSelector(
  getImageOriginalRefs,
  getAllImageUUIDs,
  (imageOriginalRefs, allImageUUIDs) => {
    const imageUUIDs = allImageUUIDs || [];
    const originalRefs = imageOriginalRefs || {};
    const counters = {};

    for (let i = 0; i < imageUUIDs.length; i += 1) {
      const currentUUID = imageUUIDs[i];
      const originalUUID = originalRefs[currentUUID] || currentUUID;

      counters[originalUUID] = (counters[originalUUID] || 0) + 1;
    }

    return counters;
  }
);
