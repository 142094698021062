import type {
  WBudgetView,
  WBudgetItemTypeView,
  WBudgetItemView,
  WBudgetItemPaymentView,
  WBudgetTaxonomyNodeView,
  WSuggestedBudgetByCategoryView,
} from '@zola/svc-web-api-ts-client';
import type { AccountWeddingLocationView } from '@zola/svc-marketplace-ts-types';
import type { MappedWAccountVendorView } from 'api/vendorMarketplaceApi';
import { ActionTypes } from './actionTypes';
import type { BudgetContextActionsType, BudgetContextType } from './context';
import type { BudgetSortBy } from './types';

export const getBudgetActions = (
  dispatch: BudgetContextType['dispatch']
): BudgetContextActionsType => {
  const receiveBudget = (budget: WBudgetView) => {
    return dispatch({
      type: ActionTypes.RECEIVE_BUDGET,
      payload: {
        budget,
      },
    });
  };

  const receiveBusyState = (busy: boolean) => {
    return dispatch({
      type: ActionTypes.RECEIVE_BUSY,
      payload: {
        busy,
      },
    });
  };

  const receiveBudgetItem = (item: WBudgetItemView) => {
    return dispatch({
      type: ActionTypes.RECEIVE_ITEM,
      payload: {
        item,
      },
    });
  };

  const receiveBudgetItems = (items: WBudgetItemView[]) => {
    return dispatch({
      type: ActionTypes.RECEIVE_ITEMS,
      payload: {
        items,
      },
    });
  };

  const receiveDeletedBudgetItem = (itemUuid: string) => {
    return dispatch({
      type: ActionTypes.RECEIVE_DELETED_ITEM,
      payload: {
        itemUuid,
      },
    });
  };

  const receiveItemTypes = (itemTypes: WBudgetItemTypeView[]) => {
    return dispatch({
      type: ActionTypes.RECEIVE_ITEM_TYPES,
      payload: {
        itemTypes,
      },
    });
  };

  const receiveTaxonomyNode = (taxonomyNode: WBudgetTaxonomyNodeView) => {
    return dispatch({
      type: ActionTypes.RECEIVE_TAXONOMY_NODE,
      payload: {
        taxonomyNode,
      },
    });
  };

  const receiveTaxonomyNodes = (taxonomyNodes: WBudgetTaxonomyNodeView[]) => {
    return dispatch({
      type: ActionTypes.RECEIVE_TAXONOMY_NODES,
      payload: {
        taxonomyNodes,
      },
    });
  };

  const receiveDeletedTaxonomyNode = (taxonomyNodeUuid: string) => {
    return dispatch({
      type: ActionTypes.RECEIVE_DELETED_TAXONOMY_NODE,
      payload: {
        taxonomyNodeUuid,
      },
    });
  };

  const receiveUnsavedMaxSpend = (maxSpend: number) => {
    return dispatch({
      type: ActionTypes.RECEIVE_UNSAVED_MAX_SPEND,
      payload: {
        maxSpend,
      },
    });
  };

  const receiveUnsavedItem = (itemUuid: string, fields: WBudgetItemView) => {
    return dispatch({
      type: ActionTypes.RECEIVE_UNSAVED_ITEM,
      payload: {
        itemUuid,
        fields,
      },
    });
  };

  const receiveUnsavedTaxonomyNode = (node: WBudgetTaxonomyNodeView) => {
    return dispatch({
      type: ActionTypes.RECEIVE_UNSAVED_TAXONOMY_NODE,
      payload: {
        node,
      },
    });
  };

  const receiveUnsavedOrderedTaxonomyNodes = (nodes: WBudgetTaxonomyNodeView[]) => {
    return dispatch({
      type: ActionTypes.RECEIVE_UNSAVED_ORDERED_TAXONOMY_NODES,
      payload: {
        nodes,
      },
    });
  };

  const clearUnsavedMaxSpend = () => {
    return dispatch({
      type: ActionTypes.CLEAR_MAX_SPEND_FROM_UNSAVED,
    });
  };

  const clearUnsavedItem = (itemUuid: string) => {
    return dispatch({
      type: ActionTypes.CLEAR_ITEM_FROM_UNSAVED,
      payload: {
        itemUuid,
      },
    });
  };

  const clearUnsavedTaxonomyNode = () => {
    return dispatch({
      type: ActionTypes.CLEAR_TAXONOMY_NODE_FROM_UNSAVED,
    });
  };

  const clearUnsavedOrderedTaxonomyNodes = () => {
    return dispatch({
      type: ActionTypes.CLEAR_ORDERED_TAXONOMY_NODES_FROM_UNSAVED,
    });
  };

  const receiveBudgetPayment = (payment: WBudgetItemPaymentView) => {
    return dispatch({
      type: ActionTypes.RECEIVE_PAYMENT,
      payload: {
        payment,
      },
    });
  };

  const receiveDeletedBudgetPayment = (paymentUuid: string) => {
    return dispatch({
      type: ActionTypes.RECEIVE_DELETED_PAYMENT,
      payload: {
        paymentUuid,
      },
    });
  };

  const receiveAccountVendors = (accountVendors: MappedWAccountVendorView[]) => {
    return dispatch({
      type: ActionTypes.RECEIVE_ACCOUNT_VENDORS,
      payload: {
        accountVendors,
      },
    });
  };

  const receiveMarketplaceLocation = (marketplaceLocation: AccountWeddingLocationView) => {
    return dispatch({
      type: ActionTypes.RECEIVE_MARKETPLACE_LOCATION,
      payload: {
        marketplaceLocation,
      },
    });
  };

  const receiveNotificationsEnabled = (enabled: boolean) => {
    return dispatch({
      type: ActionTypes.RECEIVE_EMAIL_PREFERENCES,
      payload: {
        enabled,
      },
    });
  };

  const receiveSortBy = (sortBy: BudgetSortBy) => {
    return dispatch({
      type: ActionTypes.RECEIVE_SORT_BY,
      payload: {
        sortBy,
      },
    });
  };

  const receiveSuggestedAllocations = (suggestedAllocations: WSuggestedBudgetByCategoryView) => {
    return dispatch({
      type: ActionTypes.RECEIVE_SUGGESTED_ALLOCATIONS,
      payload: {
        suggestedAllocations,
      },
    });
  };

  const clearSuggestedAllocations = () => {
    return dispatch({
      type: ActionTypes.CLEAR_SUGGESTED_ALLOCATIONS,
    });
  };

  return {
    receiveBudget,
    receiveBusyState,
    receiveBudgetItem,
    receiveBudgetItems,
    receiveDeletedBudgetItem,
    receiveItemTypes,
    receiveTaxonomyNode,
    receiveTaxonomyNodes,
    receiveDeletedTaxonomyNode,
    receiveUnsavedMaxSpend,
    receiveUnsavedItem,
    receiveUnsavedTaxonomyNode,
    receiveUnsavedOrderedTaxonomyNodes,
    clearUnsavedMaxSpend,
    clearUnsavedItem,
    clearUnsavedTaxonomyNode,
    clearUnsavedOrderedTaxonomyNodes,
    receiveBudgetPayment,
    receiveDeletedBudgetPayment,
    receiveAccountVendors,
    receiveMarketplaceLocation,
    receiveNotificationsEnabled,
    receiveSortBy,
    receiveSuggestedAllocations,
    clearSuggestedAllocations,
  };
};
