import React, { useEffect, useState } from 'react';

import { DrawerTitle } from 'components/common/zolaUI/Drawer';

import type { WBudgetItemPaymentView } from '@zola/svc-web-api-ts-client';
import { useBudgetContext } from '../context';

import { mapTaxonomyNodesToSortedPendingPayments } from '../util/mappers';
import { BudgetUpcomingPayment } from '../BudgetUpcomingPayment/BudgetUpcomingPayment';

import { Container, Dek } from './BudgetUpcomingPayments.styles';

export const BudgetUpcomingPayments = (): JSX.Element | null => {
  const [payments, setPayments] = useState<WBudgetItemPaymentView[] | undefined>();
  const {
    state: { budget },
  } = useBudgetContext();

  useEffect(() => {
    if (budget.taxonomy_nodes) {
      setPayments(mapTaxonomyNodesToSortedPendingPayments(budget.taxonomy_nodes));
    }
  }, [budget]);

  if (payments === undefined) return null;

  return (
    <Container>
      <DrawerTitle>Upcoming payments</DrawerTitle>
      {payments?.map((payment, i) => (
        <BudgetUpcomingPayment key={`budget-payment-${i}`} payment={payment} />
      ))}
      <Dek>
        Don&apos;t miss a beat (or a due date) with payment reminders. Click into any budget item to
        add payments and set up reminders.
      </Dek>
    </Container>
  );
};
