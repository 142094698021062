import ActionTypes from './cardCustomizationActionTypes';

export function customizationCleanUp() {
  return {
    type: ActionTypes.CUSTOMIZATION_CLEANUP,
  };
}

export function setCustomizationErrorList(errorsByElementUUID) {
  return {
    type: ActionTypes.SET_CUSTOMIZATION_ERROR_LIST,
    payload: errorsByElementUUID,
  };
}

export function clearCustomizationErrorList() {
  return {
    type: ActionTypes.CLEAR_CUSTOMIZATION_ERROR_LIST,
  };
}
