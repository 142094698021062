import type { WGuestCollectionDetailView } from '@zola/svc-web-api-ts-client';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import type { ToastAction } from '@zola-helpers/client/dist/types/redux/toasts/toastActionTypes';
import { browserHistory } from 'react-router';

import { getGuestCollectionCreationState } from 'selectors/guestCollection';
import type { AppThunk } from 'reducers/useAppDispatch';

import * as ActionType from './types/GuestCollectionActionTypes';
import type { GuestCollectionActionTypes } from './types/GuestCollectionActionTypes';

function requestGuestCollection(): GuestCollectionActionTypes {
  return {
    type: ActionType.REQUEST_GUEST_COLLECTION,
  };
}

function requestGuestCollectionCreationOrUpdate(): GuestCollectionActionTypes {
  return {
    type: ActionType.REQUEST_GUEST_COLLECTION_CREATE_UPDATE,
  };
}

function receiveGuestCollection(json: WGuestCollectionDetailView): GuestCollectionActionTypes {
  return {
    type: ActionType.RECEIVE_GUEST_COLLECTION,
    payload: json,
  };
}

function receiveNoGuestCollection(): GuestCollectionActionTypes {
  return {
    type: ActionType.RECEIVE_NO_GUEST_COLLECTION,
  };
}

export const fetchGuestCollection = (
  redirectIfNotFound = false
): AppThunk<Promise<GuestCollectionActionTypes>> => (
  dispatch
): Promise<GuestCollectionActionTypes> => {
  dispatch(requestGuestCollection());

  return ApiService.get<WGuestCollectionDetailView>('/web-api/v1/guest-collection/account').then(
    (json: WGuestCollectionDetailView) => {
      if (!json) {
        if (redirectIfNotFound) {
          browserHistory.push('/wedding/manage/guests/collection-link/configure');
        }
        return dispatch(receiveNoGuestCollection());
      }
      return dispatch(receiveGuestCollection(json));
    }
  );
};

export const createOrUpdateGuestCollection = (
  values: WGuestCollectionDetailView
): AppThunk<Promise<GuestCollectionActionTypes | ToastAction>> => (
  dispatch,
  getState
): Promise<GuestCollectionActionTypes | ToastAction> => {
  dispatch(requestGuestCollectionCreationOrUpdate());
  const isCreation = getGuestCollectionCreationState(getState());
  if (isCreation) {
    return ApiService.post<WGuestCollectionDetailView>(
      '/web-api/v1/guest-collection/account',
      values
    )
      .then((json: WGuestCollectionDetailView) => dispatch(receiveGuestCollection(json)))
      .catch(() =>
        dispatch(
          toastsActions.negative({
            headline:
              'There was an error creating your Super Link. Please try again or reach out to customer service.',
          })
        )
      );
  }
  return ApiService.put<WGuestCollectionDetailView>('/web-api/v1/guest-collection/account', values)
    .then((json: WGuestCollectionDetailView) => dispatch(receiveGuestCollection(json)))
    .catch(() =>
      dispatch(
        toastsActions.negative({
          headline:
            'There was an error updating your Super Link. Please try again or reach out to customer service.',
        })
      )
    );
};

export const clearGuestCollectionTempImg = (): GuestCollectionActionTypes => ({
  type: ActionType.CLEAR_GUEST_COLLECTION_TEMP_IMG,
});

export const clearGuestCollectionSavedImg = (): GuestCollectionActionTypes => ({
  type: ActionType.CLEAR_GUEST_COLLECTION_SAVED_IMG,
});
