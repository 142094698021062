import _maxBy from 'lodash/maxBy';
import _minBy from 'lodash/minBy';

export const getMaxOptionPrice = variations =>
  (variations && variations.length && _maxBy(variations, 'price_cents').price_cents) || 0;

export const getMinOptionPrice = variations =>
  (variations && variations.length && _minBy(variations, 'price_cents').price_cents) || 0;

export const getOptionPrice = (availableOptions, optionType, optionValue) => {
  const matchingOption = availableOptions?.[optionType]?.options.find(o => o.value === optionValue);
  return matchingOption?.price_delta_cents || 0;
};
