import styled from '@emotion/styled';

import { utils } from '@zola/zola-ui/src/styles/emotion';

export const Container = styled('div', {
  shouldForwardProp: prop => !['color', 'bgColor', 'size', 'border'].includes(prop),
})<{ color: string; bgColor: string; size: number; border?: string }>`
  color: ${({ color }) => color};
  background-color: ${({ bgColor }) => bgColor};
  ${({ border }) => border && `border: ${border};`}
  ${utils.circle};
  ${({ size }) => `
    height: ${size}px;
    width: ${size}px;
  `}
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const VendorImg = styled.img`
  ${utils.circle};
`;
