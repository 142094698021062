import ActionTypes from '../projectActionTypes';

// Selectors

// Called ONLY by onEnterCustomizationStep (in routes.jsx) to make the pathname available to card project selectors.
// Used to avoid wrapping every customization component in withRouter.
export function setCurrentPathname(newPathname) {
  return {
    type: ActionTypes.SET_CURRENT_PATHNAME,
    payload: newPathname,
  };
}
