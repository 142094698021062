import { usePrevious } from '@zola/zola-ui/src/hooks/usePrevious';
import { fetchPlaceDetailsSDK } from 'api/locationApi';
import { useDebounce } from 'paper/hooks/useDebounce';
import { useCallback, useEffect, useState } from 'react';
import { mapPlacesResultToPlaceDetailsView } from 'util/googlePlaces';

type UseGooglePlacesOptions = {
  /** does not load the autocomplete service if disabled,
   * Calling fetchPlaceSuggestions or getPlaceDetails will result in an error if set to true */
  disabled?: boolean;
  /** The minimum number characters before a search is executed, defaults to 3 */
  minTermLength?: number;
};

/**
 * Uses the Google places service to autocomplete place searches and fetch place details.
 * See the [Google Places Docs](https://developers.google.com/maps/documentation/places/web-service/session-tokens) for full detials.
 *
 * The session begins when the user starts typing a query, and concludes when they select a place and a call to Place Details is made.
 * Each session can have multiple autocomplete queries, followed by one place selection.
 * If the sessiontoken parameter is omitted, or if you reuse a session token, the session is charged as if no session token was provideded.
 */
export const useGooglePlaces = (searchTerm: string, options?: UseGooglePlacesOptions) => {
  const { disabled = false, minTermLength = 3 } = options || {};
  const [
    googleAutocompleteService,
    setGoogleAutocompleteService,
  ] = useState<google.maps.places.AutocompleteService | null>(null);
  const [googleSessionToken, setGoogleSessionToken] = useState<
    google.maps.places.AutocompleteSessionToken
  >();
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && !disabled) {
      const googleMaps = window.google && window.google.maps;
      setGoogleAutocompleteService(new googleMaps.places.AutocompleteService());
      setGoogleSessionToken(new googleMaps.places.AutocompleteSessionToken());
    }
  }, [disabled]);

  // Fetches google suggestions
  const fetchPlaceSuggestions = useCallback(() => {
    const preditionOptions: google.maps.places.AutocompletionRequest = {
      input: searchTerm,
      sessionToken: googleSessionToken,
    };
    if (googleAutocompleteService && !busy) {
      setBusy(true);
      googleAutocompleteService
        .getPlacePredictions(preditionOptions)
        .then(places => {
          setSuggestions(places.predictions);
          setBusy(false);
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          setBusy(false);
          throw new Error('Google suggestions not fetched', e);
        });
    }
  }, [busy, googleAutocompleteService, googleSessionToken, searchTerm]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const prevSearchTerm = usePrevious(debouncedSearchTerm);
  const termChanged = prevSearchTerm !== debouncedSearchTerm;
  useEffect(() => {
    if (debouncedSearchTerm.length >= minTermLength && termChanged) {
      fetchPlaceSuggestions();
    }
  }, [debouncedSearchTerm, fetchPlaceSuggestions, minTermLength, termChanged]);

  const getPlaceDetails = async ({ place_id }: { place_id: string; description: string }) => {
    const request = {
      placeId: place_id,
      sessionToken: googleSessionToken,
      fields: ['address_components', 'geometry', 'name', 'url', 'place_id'],
    };
    return fetchPlaceDetailsSDK(request).then(details => {
      // Reset the token after place details are fetched or we will be charged individually for subsequent place predeiction requests.
      const googleMaps = window.google && window.google.maps;
      setGoogleSessionToken(new googleMaps.places.AutocompleteSessionToken());
      setSuggestions([]);
      return mapPlacesResultToPlaceDetailsView(details);
    });
  };

  return {
    suggestions,
    setSuggestions,
    fetchPlaceSuggestions,
    getPlaceDetails,
    busy,
  };
};
