const REGULAR_OPTIONS = {
  STANDARD: {
    title: 'Standard',
    price: false,
    freeMention: null,
    tooltip: 'Lightweight and timeless, with a square&nbsp;flap.',
  },
  LETTERPRESS: {
    title: 'Luxe Cotton',
    price: false,
    freeMention: null,
    tooltip: 'Made of 100% cotton paper, extra thick with a square&nbsp;flap.',
  },
  LINER: {
    title: 'None',
    price: false,
    freeMention: null,
    tooltip: 'Inside of envelope will be the same color you select above.',
  },
};

const DELUXE_OPTIONS = {
  STANDARD: {
    title: 'Deluxe',
    price: true,
    freeMention: 'Free',
    tooltip: 'Thicker than our standard envelopes, with an elegant Euro&nbsp;flap.',
  },
  LETTERPRESS: {
    title: 'Deluxe',
    price: true,
    freeMention: 'Free with letterpress',
    tooltip: 'Made of thick non-cotton paper, with an elegant Euro&nbsp;flap.',
  },
  LINER: {
    title: 'Deluxe',
    price: true,
    freeMention: 'Free',
    tooltip: 'Inside of envelope will include liner in a unique color.',
  },
};

// envelope liner colors setup
export const ENVELOPE_LINER_COLORS = ['none', 'design', 'watercolor', 'solid', 'metallic'];

export const ENVELOPE_LINER_COLOR_VALUES = {
  'design-octavia-white': 'Romantic Blooms',
  'design-cricket-sage': 'Watercolor Florals',
  'design-napa-cream': 'Realistic Blooms',
  'design-eastwick-green': 'Watercolor Leaves',
  'design-galata-green': 'Eucalyptus',
  'design-essenza-white': 'Delicate Florals',
  'design-clearwater-light-sky': 'Ocean Sky',
  'design-clearwater-periwinkle-grey': 'Ocean Sunset',
  'design-kelton-white': 'Watercolor Pines',
  'design-alires-grey': 'Wildflowers',
  'design-windsong-off-white': 'Whimsical Florals White',
  'design-windsong-mist': 'Whimsical Florals Blue',
  'watercolor-mint': 'Green Watercolor',
  'watercolor-blue': 'Blue Watercolor',
  'watercolor-pink': 'Pink Watercolor',
  'watercolor-lilac': 'Purple Watercolor',
  'solid-cream': 'Ivory',
  'solid-dark-sage': 'Hunter',
  'solid-classic-navy': 'Navy',
  'solid-black': 'Black',
  'metallic-antique-gold': 'Dark Gold',
  'metallic-shimmer-gold': 'Gold',
  'metallic-shimmer-silver': 'Silver',
};

export const REGULAR_LISTS_OPTIONS = [REGULAR_OPTIONS.STANDARD, DELUXE_OPTIONS.STANDARD];
export const LETTERPRESS_LISTS_OPTIONS = [REGULAR_OPTIONS.LETTERPRESS, DELUXE_OPTIONS.LETTERPRESS];
export const LINER_LISTS_OPTIONS = [REGULAR_OPTIONS.LINER, DELUXE_OPTIONS.LINER];
