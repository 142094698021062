import React from 'react';
import { useSelector } from 'react-redux';
import { Global, css } from '@emotion/react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { enhancedContext } from '@zola/tracking-contracts/src/plugins/enhancedContext';
import { enhancedTraits } from '@zola/tracking-contracts/src/plugins/enhancedTraits';
import { mixpanelContext } from '@zola/tracking-contracts/src/plugins/mixpanelContext';
import { COLORS3, MEDIA_QUERY } from '@zola/zola-ui/src/styles/emotion';
import { getUserContext } from 'selectors/user/userSelectors';
import { isPostAuthNav3MobileOrDesktop } from 'util/isPostAuthNav3Active';
import ModalRoot from './components/common/ModalRoot';
import ToastsContainer from './components/common/ToastsContainer';
import './stylesheets/core.less';

import(/* webpackChunkName: "datadog" */ './util/datadog');

// Add Segment Plugins
if (window.analytics) {
  window.analytics.register(enhancedContext, enhancedTraits(), mixpanelContext());
}

function App({ children }) {
  const userContext = useSelector(getUserContext);
  const isPostAuthNav3 = isPostAuthNav3MobileOrDesktop(userContext);
  const isEditSeatingChart = window.location.pathname.indexOf('/seating-chart') > -1;

  const globalStyles = css`
    .root-page-background {
      ${MEDIA_QUERY.DESKTOP_MASSIVE} {
        background-color: ${COLORS3.BLACK_005};
      }
    }
    .root-page-max-width {
      ${MEDIA_QUERY.DESKTOP_MASSIVE} {
        max-width: 1920px;
        min-height: 100vh;
        background-color: ${COLORS3.WHITE_100};
        border-right: 1px solid ${COLORS3.BLACK_030};
      }
      ${MEDIA_QUERY.DESKTOP} {
        .zola-ui.common-footer {
          position: static;
          width: ${isEditSeatingChart ? 'auto' : 'calc(100% - 74px)'};
          margin-left: ${isEditSeatingChart ? '0' : '74px'};
        }
      }
    }
  `;

  return (
    <>
      {isPostAuthNav3 ? <Global styles={globalStyles} /> : null}
      <div className="root-page-background">
        <div id="zola-wedding-container" className="root-page-max-width">
          <Helmet
            meta={[
              {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1, maximum-scale=5',
              },
            ]}
          />
          {children}
          <ModalRoot />
          <ToastsContainer />
          <div id="tooltipRoot" />
        </div>
      </div>
    </>
  );
}

App.propTypes = {
  children: PropTypes.element.isRequired,
};

export default App;
