import type { WGooglePlaceDetailsView } from '@zola/svc-web-api-ts-client';

enum AddressComponentType {
  /** A precise street address. */
  STREET_ADDRESS = 'street_address',

  /** A named route (such as "US 101"). */
  ROUTE = 'route',

  /** A national political entity, typically the highest order type returned by the Geocoder. */
  COUNTRY = 'country',

  /**
   * A first-order civil entity below the country level. Within the United States, these
   * administrative levels are states. Not all nations exhibit these administrative levels.
   */
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1',

  /** An incorporated city or town political entity. */
  LOCALITY = 'locality',

  /**
   * A first-order civil entity below a locality. For some locations may receive one of the
   * additional types: sublocality_level_1 to sublocality_level_5. Each sublocality level is a civil
   * entity. Larger numbers indicate a smaller geographic area.
   */
  SUBLOCALITY = 'sublocality',

  /** A named neighborhood. */
  NEIGHBORHOOD = 'neighborhood',

  /** A postal code as used to address postal mail within the country. */
  POSTAL_CODE = 'postal_code',

  /** The floor of a building address. */
  FLOOR = 'floor',

  /** The precise street number of an address. */
  STREET_NUMBER = 'street_number',
}

function getAddress(addressComponents: google.maps.places.PlaceResult['address_components']) {
  let streetNumber: string | undefined;
  let streetName: string | undefined;
  let floor: string | undefined;
  let city: string | undefined;
  let state_province: string | undefined;
  let postal_code: string | undefined;
  let country_code: string | undefined;

  addressComponents?.forEach(component => {
    const { types = [], short_name, long_name } = component;

    if (types.includes(AddressComponentType.STREET_NUMBER)) {
      streetNumber = long_name;
    } else if (types.includes(AddressComponentType.ROUTE)) {
      streetName = long_name;
    } else if (types.includes(AddressComponentType.FLOOR)) {
      floor = long_name ? `${long_name} FL` : undefined;
    } else if (types.includes(AddressComponentType.LOCALITY)) {
      // LOCALITY takes preference over NEIGHBORHOOD and SUBLOCALITY
      city = long_name;
    } else if (
      types.includes(AddressComponentType.NEIGHBORHOOD) ||
      types.includes(AddressComponentType.SUBLOCALITY)
    ) {
      if (!city) {
        city = long_name;
      }
    } else if (types.includes(AddressComponentType.ADMINISTRATIVE_AREA_LEVEL_1)) {
      state_province = short_name;
    } else if (types.includes(AddressComponentType.POSTAL_CODE)) {
      postal_code = long_name;
    } else if (types.includes(AddressComponentType.COUNTRY)) {
      country_code = short_name;
    }
  });
  const streetNumberFinal = streetNumber ? streetNumber.trim() : '';
  const streetNameFinal = streetName ? streetName.trim() : '';
  const address_1 = `${streetNumberFinal} ${streetNameFinal}`.trim();
  return {
    address_1,
    address_2: floor,
    city,
    state_province,
    postal_code,
    country_code,
  };
}

// Trim latitude to max 15 characters, but round properly.
export function trimLatLong(value: string | undefined) {
  if (!value) {
    return undefined;
  }
  if (value.length <= 15) {
    return value;
  }
  const [whole, decimal] = value.split('.');
  const decimalLength = 14 - whole.length;
  if (decimal) {
    return Number(value).toFixed(decimalLength).toString();
  }
  return whole;
}

/**
 * @param placeResult
 * @returns a WGooglePlaceDetailsView
 */
export function mapPlacesResultToPlaceDetailsView(
  placeResult: google.maps.places.PlaceResult
): WGooglePlaceDetailsView {
  const { address_components, geometry, name, place_id, url } = placeResult;
  const address = getAddress(address_components);
  const { lat, lng } = geometry?.location || {};
  const latitude = typeof lat?.() === 'number' ? trimLatLong(lat().toString()) : undefined;
  const longitude = typeof lng?.() === 'number' ? trimLatLong(lng().toString()) : undefined;

  return {
    ...address,
    google_place_id: place_id,
    name,
    contact_phone: undefined,
    latitude,
    longitude,
    url: undefined,
    google_url: url,
  };
}
