import ApiService from '@zola-helpers/client/dist/es/http/api';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import type { WCmsBannerView } from '@zola/svc-web-api-ts-client';
import { updateWeddingAccount } from 'actions/WeddingActions';
import { getWeddingAccount } from 'selectors/website/websiteSelectors';
import type { AppThunk } from 'reducers/useAppDispatch';
import * as ActionType from '../types/website/BannerActionTypes';
import type { BannerActionTypes } from '../types/website/BannerActionTypes';

// Sets busy key
function requestBanner(): BannerActionTypes {
  return {
    type: ActionType.REQUEST_BANNER,
  };
}

function receiveBannerMessage(bannerMessage?: string): BannerActionTypes {
  return {
    type: ActionType.RECEIVE_BANNER_MESSAGE,
    payload: { bannerMessage },
  };
}

export function fetchBannerMessage(): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    dispatch(requestBanner());
    return ApiService.get<WCmsBannerView>('/web-api/v1/banner/activeBanner')
      .then((data: WCmsBannerView) => {
        dispatch(receiveBannerMessage(data.banner_message));
      })
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Unable to fetch banner message');
      });
  };
}

export function updateBannerMessage(bannerMessage: string): AppThunk<Promise<void>> {
  return (dispatch, getState): Promise<void> => {
    return ApiService.put<WCmsBannerView>(`/web-api/v1/banner/update`, { bannerMessage })
      .then(data => {
        const { banner_message } = data;
        dispatch(receiveBannerMessage(banner_message));
        dispatch(toastsActions.positive({ headline: 'Custom Message Saved' }));
        // if banner message cleared out, hide banner if currently shown
        if (!banner_message) {
          const account = getWeddingAccount(getState());
          const { enable_cms_banner } = account;
          if (enable_cms_banner) {
            const req = Object.assign({}, account, { enable_cms_banner: false });
            dispatch(updateWeddingAccount(req))
              .then(() => {
                dispatch(toastsActions.positive({ headline: 'Banner hidden' }));
              })
              .catch(() => {
                throw new Error('Empty custom banner still visible');
              });
          }
        }
      })
      .catch(() => {
        dispatch(toastsActions.negative({ headline: 'Error Updating Banner' }));
      });
  };
}
