import _toPairs from 'lodash/toPairs';
import { browserHistory } from 'react-router';

export const PRE_PROJECT_QUERY_KEYS = {
  selectedVariationUuid: 'selectedVariationUuid',
  defaultVariationPrice: 'defaultVariationPrice',
  coverColorUuid: 'coverColorUuid',
  selectedAlbumSize: 'selectedAlbumSize',
  selectedQuizLayout: 'selectedQuizLayout',
  preProjectAlbumTier: 'preProjectAlbumTier',
  workingAlbum: { projectUuid: 'projectUuid', albumUuid: 'albumUuid' },
};

// -------------------------------------------------
// ---------- PRE-PROJECT-CREATION STEPS ----------

// Formats a pre-project query object for use in the app
export const deserializePreProjectQuery = (query, supportedKeys = PRE_PROJECT_QUERY_KEYS) => {
  return _toPairs(supportedKeys).reduce((params, [keyName, keyValue]) => {
    // If the key is a string, add the corresponding value to params
    if (typeof keyValue === 'string') {
      return { ...params, [keyName]: query[keyValue] };
    }

    // If the key contains children keys, put them in a new object under the current key
    return { ...params, [keyName]: deserializePreProjectQuery(query, keyValue) };
  }, {});
};

// Formats a pre-project parameter object for use in a query string
const serializePreProjectQuery = (params, supportedKeys = PRE_PROJECT_QUERY_KEYS) => {
  return _toPairs(supportedKeys).reduce((query, [keyName, keyValue]) => {
    const paramValue = params[keyName];

    // Discard any falsy parameters
    if (!paramValue) return query;

    // If the key is a string, add the corresponding value to the query
    if (typeof keyValue === 'string') {
      return { ...query, [keyValue]: paramValue };
    }

    // If the key contains children keys, flatten them into the query
    return { ...query, ...serializePreProjectQuery(paramValue, keyValue) };
  }, {});
};

// Generates a query string from a serialized object
const stringifyQuery = serializedParams => {
  return _toPairs(serializedParams).reduce(
    (qstring, [k, v], idx) => `${qstring}${idx === 0 ? '?' : '&'}${k}=${v}`,
    ''
  );
};

// Patches the existing query params with the specified ones and updates the browser's location.
export const patchPreProjectUrlParams = paramsToPatch => {
  const { pathname, query } = browserHistory.getCurrentLocation();
  const currentParams = deserializePreProjectQuery(query);
  const newParams = { ...currentParams, ...paramsToPatch };
  const serializedParams = serializePreProjectQuery(newParams);

  browserHistory.replace(`${pathname}${stringifyQuery(serializedParams)}`);
};

// Generates a pre-project URL using either the specified params OR the current query string
export const getPreProjectStepUrl = (stepKey, fullParams) => {
  const serializedParams = fullParams
    ? serializePreProjectQuery(fullParams)
    : browserHistory.getCurrentLocation().query;

  return `/wedding-albums/create/${stepKey}${stringifyQuery(serializedParams)}`;
};

export const redirectToPreProjectStep = (stepKey, fullParams) => {
  browserHistory.push(getPreProjectStepUrl(stepKey, fullParams));
};

// -------------------------------------------------
// ---------- POST-PROJECT-CREATION STEPS ----------

export const getProjectUrlParams = () => {
  const { pathname } = browserHistory.getCurrentLocation();
  const projectUuid = pathname.split('/')[3];
  const albumUuid = pathname.split('/')[4];

  return { projectUuid, albumUuid };
};

// Generates a post-project-creation URL using either the specified project and album ids OR the ones present in the current URL
export const getProjectStepUrl = (stepKey, projectUuid, albumUuid) => {
  const urlParams = getProjectUrlParams();
  const validProjectUuid = projectUuid || urlParams.projectUuid;
  const validAlbumUuid = albumUuid || urlParams.albumUuid;

  return `/wedding-albums/create/${validProjectUuid}/${validAlbumUuid}/${stepKey}`;
};

export const redirectToProjectStep = (stepKey, projectUuid, albumUuid) => {
  browserHistory.push(getProjectStepUrl(stepKey, projectUuid, albumUuid));
};
