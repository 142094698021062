import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';

import type { BulkCreateInquiryRequestItem } from '@zola/svc-marketplace-ts-types';
import {
  getVendorTaxonomyKeyFromVendorType,
  VendorTypeEnum,
} from '@zola-helpers/client/dist/es/marketplace';
import type { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import pluralize from '@zola-helpers/client/dist/es/transformers/pluralize';
import { formatDateUtc, getDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';
import {
  DATE_AFTER_NOW,
  MAX_LENGTH_500,
  MIN_LENGTH_50,
  PHONE,
  REQUIRED,
} from '@zola/zola-ui/src/components/Form/util/validations';
import { composeValidators } from '@zola/zola-ui/src/components/Form/util';
import FormInput from '@zola/zola-ui/src/components/Form/inputV3/FinalFormInput';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { trackFlowCompleted } from '@zola/tracking-contracts/src/tracking';
import { getBusinessCategory } from '@zola-helpers/client/dist/es/tracking/trackingHelper';

import { useAppSelector } from 'reducers/useAppSelector';
import { useAppDispatch } from 'reducers/useAppDispatch';
import {
  getAccountId,
  getOwnerFirstName,
  getOwnerLastName,
  getPartnerFirstName,
  getPartnerLastName,
  getUserEmail,
  getUserId,
  getUserWeddingCity,
  getUserWeddingDate,
  getUserWeddingGuestCount,
  getUserWeddingStateProvince,
} from 'selectors/user/userSelectors';
import { bulkCreateInquiry } from 'api/vendorMarketplaceApi';
import { useBudgetContext } from '../../context';

import {
  StyledBodyBase,
  BackBtn,
  FormFields,
  StyledFormTitle,
  DateContainer,
  StyledFormTextarea,
  StyledFormCheckbox,
  StyledFormDatePicker,
} from '../BudgetExploreMatchesModal.styles';

type BudgetMatchesFormProps = {
  firstSelectedVendorName?: string;
  selectedVendors: string[];
  trackingFlowRunId: string;
  vendorType: string | undefined;
  onBack: () => void;
  onClose: () => void;
};

type BudgetMatchesFormValues = {
  notes: string;
  wedding_date: string;
  phone?: string;
  weddingEndPriceCents: number;
};

const BudgetMatchesForm = ({
  firstSelectedVendorName,
  selectedVendors,
  trackingFlowRunId,
  vendorType,
  onClose,
  onBack,
}: BudgetMatchesFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const userEmail = useAppSelector(getUserEmail);
  const ownerFirstName = useAppSelector(getOwnerFirstName);
  const ownerLastName = useAppSelector(getOwnerLastName);
  const partnerFirstName = useAppSelector(getPartnerFirstName);
  const partnerLastName = useAppSelector(getPartnerLastName);
  const guestCount = useAppSelector(getUserWeddingGuestCount);
  const accountId = useAppSelector(getAccountId);
  const userObjectId = useAppSelector(getUserId);
  const weddingDate = useAppSelector(getUserWeddingDate);
  const userCity = useAppSelector(getUserWeddingCity);
  const userStateProvince = useAppSelector(getUserWeddingStateProvince);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    state: {
      budget: { budgeted_cents },
      marketplaceLocation: { city, stateProvince },
    },
  } = useBudgetContext();

  const onFormSave = (values: BudgetMatchesFormValues) => {
    setIsSubmitting(true);
    const { wedding_date, ...rest } = values;
    const dateUtc = getDateUtc(wedding_date);
    const inquiries: BulkCreateInquiryRequestItem[] = selectedVendors.map(uuid => ({
      cta_placement: 'MASS_INQUIRE_BUDGET_TOOL',
      flow_type: 'MASS_INQUIRY',
      storefront_uuid: uuid,
      primary_first_name: ownerFirstName,
      primary_last_name: ownerLastName,
      partner_first_name: partnerFirstName,
      partner_last_name: partnerLastName,
      email_address: userEmail,
      account_id: accountId,
      user_object_id: userObjectId,
      ...((city || stateProvince) && {
        address: {
          // prioritize user's account-level city and state, fallback to marketplace location
          city: userCity || city,
          state_province: userStateProvince || stateProvince,
        },
      }),
      ...(guestCount && { guestCount }),
      wedding_date: wedding_date ? dateUtc : null,
      price_explorer_stage: 'AWARE_OF_COST_AND_LEARN_MORE',
      ...rest,
    }));

    bulkCreateInquiry({ items: inquiries })
      .then(() => {
        selectedVendors.forEach(storefront_uuid => {
          trackFlowCompleted({
            flow_entry: 'BUDGET_TOOL',
            flow_run_id: trackingFlowRunId,
            business_category: vendorType
              ? getBusinessCategory(
                  getVendorTaxonomyKeyFromVendorType(
                    vendorType as VendorTypeEnum
                  ) as SearchableVendorTaxonomyKey
                )
              : 'UNATTRIBUTED',
            business_unit: 'MARKETPLACE',
            storefront_uuid,
            flow_type: 'INQUIRY_MASS',
          });
        });
        dispatch(toastsActions.positive({ headline: 'Inquiries sent' }));
        onClose();
      })
      .catch(error => {
        dispatch(toastsActions.negative({ headline: error.message, autoDismissInSeconds: 10 }));
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Form<BudgetMatchesFormValues>
      initialValues={{
        wedding_date: weddingDate ? formatDateUtc(weddingDate, 'MM/DD/YYYY') : undefined,
        weddingEndPriceCents: budgeted_cents,
      }}
      onSubmit={onFormSave}
    >
      {({ handleSubmit, valid, submitting }) => (
        <form
          onSubmit={e => {
            handleSubmit(e)?.catch(() => undefined);
          }}
        >
          <BackBtn arrow arrowPosition="prefix" role="button" onClick={onBack}>
            Back
          </BackBtn>
          <StyledFormTitle presentation="h3">
            Send a message to {firstSelectedVendorName}{' '}
            {selectedVendors.length > 1 &&
              `and ${pluralize('other', 'others', selectedVendors.length - 1)}`}
          </StyledFormTitle>
          <StyledBodyBase>
            Introduce yourself and share a bit about what you&apos;re looking for!
          </StyledBodyBase>
          <FormFields isSubmitting={submitting}>
            <Field
              component={StyledFormTextarea}
              name="notes"
              id="mass-inquiry-notes"
              label="Your message"
              maxChars={500}
              validate={composeValidators(REQUIRED, MAX_LENGTH_500, MIN_LENGTH_50)}
              helperText="Minimum 50 characters"
            />
            <DateContainer>
              <StyledFormDatePicker
                name="wedding_date"
                label="Wedding date"
                id="mass-inquiry-date"
                validate={DATE_AFTER_NOW}
              />
              <StyledFormCheckbox name="flexible_on_date" inline label="Our date is flexible" />
            </DateContainer>
            <FormInput
              name="phone"
              label="Phone (optional)"
              type="tel"
              id="mass-inquiry-phone"
              autoComplete="tel"
              validate={PHONE}
              helperText="This will only be used by the vendor to contact you"
            />
          </FormFields>
          <ButtonV3
            fullWidth
            type="submit"
            size="large"
            disabled={!valid || submitting || isSubmitting}
          >
            Send
          </ButtonV3>
        </form>
      )}
    </Form>
  );
};

export default BudgetMatchesForm;
