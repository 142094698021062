import ActionTypes from '../projectActionTypes';

export function receiveProject(project, isTestProject) {
  return {
    type: ActionTypes.RECEIVE_PROJECT,
    payload: {
      project,
      isTestProject,
    },
  };
}
