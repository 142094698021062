import styled from '@emotion/styled';
import { COLORS3, FONT, SPACING } from '@zola/zola-ui/src/styles/emotion';
import { COLORS_SEMANTIC } from '@zola/zola-ui/src/styles/emotion/colors3';

import { Input } from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3/InputFieldV3.styles';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

const SLIDER_THUMB_SIZE = 16;

export const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 160px;
  grid-gap: ${SPACING.S10} 0;
  padding: ${SPACING.XS} 0 ${SPACING.XS};

  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    border-bottom: 1px solid ${COLORS3.BLACK_010};
  }

  ${MEDIA_QUERY_V2.DESKTOP} {
    grid-gap: ${SPACING.S16} 0;
    padding: ${SPACING.XS} 0 ${SPACING.LG};
  }
`;

export const RangeInput = styled.input`
  -webkit-appearance: none;
  height: ${SPACING.XXS};
  background: ${COLORS3.BLACK_010};
  border-radius: 200px;
  background-image: linear-gradient(
    ${COLORS_SEMANTIC.POSITIVE_100},
    ${COLORS_SEMANTIC.POSITIVE_100}
  );
  background-repeat: no-repeat;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: ${SLIDER_THUMB_SIZE}px;
    width: ${SLIDER_THUMB_SIZE}px;
    border-radius: 50%;
    background: ${COLORS_SEMANTIC.POSITIVE_100};
    cursor: pointer;
  }

  &::-webkit-slider-thumb::after {
    content: 'test';
    height: 10px;
    width: 10px;
    background-color: black;
    color: white;
  }
`;

export const Output = styled.div<{ value: number }>`
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
  ${MEDIA_QUERY_V2.DESKTOP} {
    position: absolute;
    left: ${({ value }) =>
      `calc(${value}% - ${
        Math.round((SLIDER_THUMB_SIZE * value) / 100) - SLIDER_THUMB_SIZE / 2
      }px - ${SLIDER_THUMB_SIZE / 2}px)`};
    bottom: ${SPACING.S10};
  }
`;

export const LabelContainer = styled.div`
  grid-area: 1 / 1 / 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    display: flex;
    align-items: flex-start;
  }
`;

export const InputContainer = styled.div`
  grid-area: 1 / 2 / 1 / 2;

  ${Input} {
    text-align: right;
  }
`;

export const Label = styled.label`
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)};
  letter-spacing: normal;
  text-transform: none;
  font-weight: ${FONT.WEIGHT.REGULAR};
  margin-bottom: 0;
`;

export const Dek = styled.span`
  display: block;
  ${FONT.textSizeCompact(FONT.SIZE.EXTRA_SMALL)};
  color: ${COLORS3.BLACK_050};

  ${MEDIA_QUERY_V2.DESKTOP} {
    color: ${COLORS3.BLACK_075};
  }
`;

export const RangeContainer = styled.div`
  grid-area: 2 / 1 / 2 / 3;

  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    display: flex;
    align-items: center;
    gap: ${SPACING.SM};
  }
`;
