import React, { useMemo, useRef, useState } from 'react';

import type { RouteComponentProps } from 'react-router';
import type { WBudgetItemPaymentView } from '@zola/svc-web-api-ts-client';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import Chip from '@zola/zola-ui/src/components/Chip';
import DatePickerInputV2 from '@zola/zola-ui/src/components/Form/DatePickerInputV2/DatePickerInputV2';
import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import { LabelSideVariant } from '@zola/zola-ui/src/components/ToggleV2/ToggleV2';
import TagV2, { TagV2Size, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';
import { InputFieldAddOnType } from '@zola/zola-ui/src/components/Form/InputFieldV2/InputFieldAddOn';
import { TextareaFieldV2 } from '@zola/zola-ui/src/components/Form/TextareaFieldV2';
import {
  formatDateIso,
  getDateUtc,
  isBeforeDate,
  parseDate,
} from '@zola-helpers/client/dist/es/util/dateUtils';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { notDesktopV2 } from '@zola-helpers/client/dist/es/util/responsive';
import { inputFormat } from '@zola/zola-ui/src/components/Form/util/dateUtils';
import { useAppDispatch } from 'reducers/useAppDispatch';

import generateTimeOptions from 'components/manage/EditWebsite/EditWebsiteEntityModal/modals/EventForm/eventTimeUtils';
import { DrawerTitle, useDrawerControls } from 'components/common/zolaUI/Drawer';
import { BudgetConfirmDeleteMiniModal } from '../BudgetConfirmDeleteMiniModal/BudgetConfirmDeleteMiniModal';
import BudgetDeleteEntityButton from '../BudgetDeleteEntityButton/BudgetDeleteEntityButton';

import {
  useBudgetContext,
  getBudgetItemByUuid,
  getBudgetPaymentByUuid,
  FE_BUDGET_PAYMENT_TYPE,
} from '../context';

import { handleBudgetRouteChange } from '../routes/handleBudgetRouteChange';
import { useLeaveBudgetRoute } from '../routes/useLeaveBudgetRoute';
import { useBudgetDrawerLayer } from '../util/useBudgetDrawerLayer';
import { useCurrencyInput } from '../util/useCurrencyInput';
import { getNext30MinuteInterval } from '../util/getNext30MinuteInterval';
import { addPayment, deletePayment, getBudgetByAccountId, updatePayment } from '../util/api';
import {
  BudgetPaymentStatus,
  getBudgetPaymentStatus,
  mapDateToFormattedString,
} from '../util/mappers';

import {
  Grid,
  StyledBodySmall,
  StyledBodyBase,
  NotesLabel,
  Optional,
  AmountField,
  SetUpAReminder,
  ReminderFields,
  StyledDropdown,
  StyledToggle,
  ToggleContainer,
} from './BudgetPaymentAddOrEdit.styles';

type RouteParams = {
  itemUuid: string;
  paymentUuid?: string;
};

export type BudgetPaymentAddOrEditProps = RouteComponentProps<RouteParams, RouteParams> & {
  className?: string;
};

export const BudgetPaymentAddOrEdit = ({
  className,
  location: { pathname, state = {} },
  params: { itemUuid, paymentUuid },
  route,
  router: { setRouteLeaveHook },
}: BudgetPaymentAddOrEditProps): JSX.Element | null => {
  const { previousPathname, markPaid } = state;
  useLeaveBudgetRoute(route, setRouteLeaveHook);
  const dispatch = useAppDispatch();
  const isEdit = !!paymentUuid;
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(!!markPaid);

  // context values needed
  const {
    actions: { receiveBudgetPayment, receiveDeletedBudgetPayment, receiveBudget },
    state: {
      budget: { taxonomy_nodes = [] },
      notificationsEnabled,
    },
  } = useBudgetContext();
  const { removeDrawerLayer, currentLayer } = useDrawerControls();

  const getNow = () => mapDateToFormattedString(getDateUtc(), inputFormat);

  // Form values data initializer
  const item = getBudgetItemByUuid(itemUuid, taxonomy_nodes);
  const payment = isEdit ? getBudgetPaymentByUuid(paymentUuid, item?.payments) : undefined;
  const [localPayment, setLocalPayment] = useState({
    note: payment?.note,
    due_at: mapDateToFormattedString(payment?.due_at, inputFormat),
    paid_at: markPaid ? getNow() : mapDateToFormattedString(payment?.paid_at, inputFormat),
    reminderDate: mapDateToFormattedString(payment?.remind_at as Date, inputFormat),
    reminderTime: mapDateToFormattedString(payment?.remind_at, 'HH:mm:ss'),
    payment_type: markPaid
      ? FE_BUDGET_PAYMENT_TYPE.PAID
      : ((payment?.payment_type as unknown) as FE_BUDGET_PAYMENT_TYPE) ||
        FE_BUDGET_PAYMENT_TYPE.PENDING,
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    currencyValue,
    rawCurrencyValue,
    setCurrencyValue,
    toggleIsEditingCurrency,
  } = useCurrencyInput(((payment?.amount_cents || 0) / 100).toString(), inputRef, [
    payment?.amount_cents,
  ]);
  const { note, payment_type, reminderDate, reminderTime, due_at, paid_at } = localPayment;
  const isPaid = payment_type === FE_BUDGET_PAYMENT_TYPE.PAID;
  const date = isPaid ? paid_at : due_at;
  const today = new Date();
  const isOverdue =
    getBudgetPaymentStatus((localPayment as unknown) as WBudgetItemPaymentView) ===
    BudgetPaymentStatus.OVERDUE;
  const noteInvalid = note && note.length > 200;
  const dateInvalid = Boolean(isPaid && date && isBeforeDate(today, date));
  const [sendReminder, setSendReminder] = useState(!!payment?.remind_at);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const times = useMemo(() => generateTimeOptions(30), []);
  const saveDisabled = Boolean(isSubmitting || !date || noteInvalid || dateInvalid);

  /** START form field handlers */
  const handlePaymentTypeSelection = (selection: FE_BUDGET_PAYMENT_TYPE) => {
    setHasUnsavedChanges(true);
    setLocalPayment(prevValue => ({ ...prevValue, payment_type: selection }));
  };

  const handleCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasUnsavedChanges(true);
    setCurrencyValue(e.target.value);
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setHasUnsavedChanges(true);
    setLocalPayment(prevValue => ({ ...prevValue, note: value }));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const dateFieldProp = isPaid ? 'paid_at' : 'due_at';
    setHasUnsavedChanges(true);
    setLocalPayment(prevValue => ({ ...prevValue, [dateFieldProp]: value }));
  };

  const handleReminderDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setHasUnsavedChanges(true);
    setLocalPayment(prevValue => ({ ...prevValue, reminderDate: value }));
  };

  const handleReminderTimeChange = (value: string | number | null) => {
    setHasUnsavedChanges(true);
    setLocalPayment(prevValue => ({ ...prevValue, reminderTime: value as string }));
  };

  const handleToggleSendReminder = () => {
    setSendReminder(prevVal => !prevVal);
    if (!sendReminder) {
      setLocalPayment(prevValue => ({
        ...prevValue,
        reminderDate: prevValue.reminderDate || due_at || getNow(),
        reminderTime: prevValue.reminderTime || getNext30MinuteInterval(),
      }));
    }
  };

  // Mark as paid/Revert to unpaid
  const handleTertiaryAction = () => {
    setHasUnsavedChanges(true);
    handlePaymentTypeSelection(
      isPaid ? FE_BUDGET_PAYMENT_TYPE.PENDING : FE_BUDGET_PAYMENT_TYPE.PAID
    );
    if (!isPaid) {
      setLocalPayment(prevValue => ({
        ...prevValue,
        paid_at: getNow(), // default to now
      }));
    }
  };
  /** END form field handlers */

  const getNextPathname = (exitDrawer?: boolean) => {
    // Exit out of drawer if previously from quick view & wanting to save or delete
    if (exitDrawer && previousPathname) return '';
    // Go to previous pathname if specified
    if (previousPathname) return previousPathname;
    // Otherwise go to budget item view
    return `/item/${itemUuid}`;
  };

  /** START form event handlers */
  const handleCancel = () => {
    // Prevent going to previous layer if there are unsaved changes
    handleBudgetRouteChange(getNextPathname(), { preventIfUnsaved: hasUnsavedChanges });
  };

  const handleDelete = () => {
    setIsSubmitting(true);
    deletePayment(paymentUuid as string)
      .then(() => {
        receiveDeletedBudgetPayment(paymentUuid as string);
        setHasUnsavedChanges(false);
        dispatch(
          toastsActions.positive({
            headline: 'Deleted',
          })
        );
        setShowDeletePrompt(false);
        removeDrawerLayer(currentLayer?.key as string);
        handleBudgetRouteChange(getNextPathname(true), { preventIfUnsaved: false });
      })
      // update the budget summary
      .then(getBudgetByAccountId)
      .then(res => {
        receiveBudget(res);
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(
          toastsActions.negative({
            headline: 'There was an error deleting your budget payment.',
          })
        );
      });
  };

  const handleSave = () => {
    setIsSubmitting(true);
    const shouldIncludeReminder = !isPaid && sendReminder && reminderDate && reminderTime;
    const localTime = parseDate(`${reminderDate} ${reminderTime}`, `${inputFormat} HH:mm:ss`);

    const request = {
      item_uuid: itemUuid,
      payment_type,
      amount_cents: parseFloat(rawCurrencyValue) * 100,
      ...(due_at && { due_at: getDateUtc(due_at) }), // it's ok to always include due_at (if user reverts, we'll use this as default)
      ...(isPaid ? { paid_at: getDateUtc(paid_at) } : {}), // only include paid_at if payment_type === PAID
      ...(note && { note }),
      ...(shouldIncludeReminder && {
        remind_at: getDateUtc(formatDateIso(localTime)),
      }),
    };

    (isEdit ? updatePayment({ ...request, payment_uuid: paymentUuid }) : addPayment(request))
      .then((res: WBudgetItemPaymentView) => {
        receiveBudgetPayment(res);
        setLocalPayment({
          note: res?.note,
          due_at: mapDateToFormattedString(res?.due_at, inputFormat),
          paid_at: mapDateToFormattedString(res?.paid_at, inputFormat),
          reminderDate: mapDateToFormattedString(res?.remind_at, inputFormat),
          reminderTime: mapDateToFormattedString(res?.remind_at, 'HH:mm:ss'),
          payment_type: (res.payment_type as unknown) as FE_BUDGET_PAYMENT_TYPE,
        });
        dispatch(
          toastsActions.positive({
            headline: isEdit ? 'Updated' : 'Added',
          })
        );
        setHasUnsavedChanges(false);
        handleBudgetRouteChange(getNextPathname(true), { preventIfUnsaved: false });
      })
      // update the budget summary
      .then(getBudgetByAccountId)
      .then(res => {
        receiveBudget(res);
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(
          toastsActions.negative({
            headline: `There was an error ${isEdit ? 'updating' : 'creating'} your payment.`,
          })
        );
      });
  };
  /** END form event handlers */

  useBudgetDrawerLayer(
    {
      key: pathname,
      useClose: false,
      saveButtonText: 'Save',
      saveButtonDisabled: saveDisabled,
      cancelButtonDisabled: isSubmitting,
      tertiaryElement:
        isEdit && !markPaid ? (
          <ButtonV3 variant="secondary" onClick={handleTertiaryAction}>
            {isPaid ? 'Revert to unpaid' : 'Mark as paid'}
          </ButtonV3>
        ) : undefined,
      onSave: handleSave,
      onBack: handleCancel,
      onUnsavedChanges: hasUnsavedChanges
        ? () => {
            handleBudgetRouteChange(`/item/${itemUuid}/payment/${isEdit ? paymentUuid : 'new'}`);
          }
        : undefined,
    },
    isSubmitting,
    [localPayment, rawCurrencyValue, sendReminder]
  );

  // Close drawer if budget item doesn't exist for user
  if (!item?.uuid) {
    handleBudgetRouteChange();
    return null;
  }

  const renderTag = () => {
    if (!isEdit) return null;
    if (isPaid) return <TagV2 variant={TagV2Variant.GREEN}>Paid</TagV2>;
    if (isOverdue) return <TagV2 variant={TagV2Variant.RED}>Overdue</TagV2>;
    return <TagV2 variant={TagV2Variant.GRAY}>Upcoming</TagV2>;
  };

  return (
    <div data-testid="BudgetPaymentAddOrEdit" className={className}>
      {renderTag()}
      <DrawerTitle>
        {isEdit
          ? `${isPaid || isOverdue ? 'P' : 'Upcoming p'}ayment details`
          : `New payment for ${item?.title}`}
      </DrawerTitle>
      {!isEdit && (
        <>
          <Grid>
            <Chip
              selected={!isPaid}
              showCheckmark
              onClick={() => handlePaymentTypeSelection(FE_BUDGET_PAYMENT_TYPE.PENDING)}
            >
              Upcoming
            </Chip>
            <Chip
              selected={isPaid}
              showCheckmark
              onClick={() => handlePaymentTypeSelection(FE_BUDGET_PAYMENT_TYPE.PAID)}
            >
              Paid
            </Chip>
          </Grid>
          <StyledBodyBase>
            {isPaid
              ? "When you add a payment, we'll automatically adjust your Remaining Due amount. Cool, huh?"
              : "We'll send you an email when it's time to pay."}
          </StyledBodyBase>
        </>
      )}
      <Grid gap="S16">
        {isPaid ? (
          <DatePickerInputV2
            key="paid_at"
            value={paid_at}
            inputFieldProps={{
              id: 'paid_at',
              name: 'paid_at',
              label: 'Date',
              errorMessage: dateInvalid ? 'Hmm, this looks like a future date...' : null,
              placeholder: inputFormat,
            }}
            inputOnChange={handleDateChange}
          />
        ) : (
          <DatePickerInputV2
            key="due_at"
            value={due_at}
            inputFieldProps={{
              id: 'due_at',
              name: 'due_at',
              label: 'Date',
              placeholder: inputFormat,
            }}
            inputOnChange={handleDateChange}
          />
        )}
        <AmountField>
          <InputFieldV3
            label="Amount"
            name="amount_cents"
            id="amount_cents"
            onChange={handleCostChange}
            onFocus={toggleIsEditingCurrency}
            onBlur={toggleIsEditingCurrency}
            addOn={InputFieldAddOnType.CASH}
            value={currencyValue}
            isControlled
            maxLength={17}
            ref={inputRef}
          />
        </AmountField>
      </Grid>
      <NotesLabel htmlFor="note">
        Notes <Optional>(optional)</Optional>
      </NotesLabel>
      <TextareaFieldV2
        name="note"
        id="note"
        onChange={handleNoteChange}
        value={note}
        placeholder="Use this space to note deposits, related contact info, cancellation deadlines, etc."
        maxChars={200}
      />
      {!isPaid && (
        <SetUpAReminder>
          <ToggleContainer>
            <StyledToggle
              checked={sendReminder}
              id="send_reminder"
              label="Set a payment reminder"
              labelSide={LabelSideVariant.RIGHT}
              onChange={handleToggleSendReminder}
            />
            <TagV2 variant={TagV2Variant.BAY} size={TagV2Size.SMALL}>
              Recommended
            </TagV2>
          </ToggleContainer>
          <StyledBodySmall>
            {notificationsEnabled ? (
              "Never miss a beat.  We'll email you when it's time to pay."
            ) : (
              <>
                You&apos;re unsubscribed from email reminders (resubscribe{' '}
                <LinkV2 inline href="/account/settings/notifications">
                  here
                </LinkV2>
                ).
              </>
            )}
          </StyledBodySmall>
          {sendReminder && (
            <ReminderFields>
              <DatePickerInputV2
                value={reminderDate}
                inputFieldProps={{
                  id: 'reminderDate',
                  name: 'reminderDate',
                  label: 'Send date',
                }}
                inputOnChange={handleReminderDateChange}
              />
              <StyledDropdown
                name="reminder_time"
                id="reminder_time"
                label="Send time"
                options={times}
                value={reminderTime}
                onSelect={handleReminderTimeChange}
                isNativeDropdown={notDesktopV2()}
              />
            </ReminderFields>
          )}
        </SetUpAReminder>
      )}
      {isEdit && (
        <BudgetDeleteEntityButton onDelete={() => setShowDeletePrompt(true)} type="payment" />
      )}
      {showDeletePrompt && (
        <BudgetConfirmDeleteMiniModal
          onConfirm={handleDelete}
          onCancel={() => setShowDeletePrompt(false)}
        />
      )}
    </div>
  );
};
