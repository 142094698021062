import React, { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { V4StorefrontSearchRequest, StorefrontCardView } from '@zola/svc-marketplace-ts-types';
import type { ModalV2Props } from '@zola/zola-ui/src/components/Modal/ModalV2';
import {
  getVendorTaxonomyKey,
  getVendorTaxonomyKeyFromVendorType,
  getVendorWordPlural,
  getVendorWords,
  VendorTaxonomyKey,
  VendorTypeEnum,
} from '@zola-helpers/client/dist/es/marketplace';
import type { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace';
import { getBusinessCategory } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { trackFlowStarted } from '@zola/tracking-contracts/src/tracking';
import { isDesktopV2 } from '@zola-helpers/client/dist/es/util/responsive';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { Title2 } from '@zola/zola-ui/src/typography/Headings';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

import { fetchStorefrontSearchResults, VendorSearchRequestType } from 'api/vendorMarketplaceApi';
import useSimpleScrollLock from 'lib/hooks/useSimpleScrollLock';
import { useAppSelector } from 'reducers/useAppSelector';
import { getUserWeddingGuestCount } from 'selectors/user/userSelectors';

import { useBudgetContext, getBudgetItemByUuid } from '../context';

import BudgetMatchesSearchResults from './components/BudgetMatchesSearchResults';
import BudgetMatchesForm from './components/BudgetMatchesForm';

import {
  Content,
  StyledModalV2,
  Ctas,
  StyledSkeletonLoader,
  VendorsList,
  NoResults,
} from './BudgetExploreMatchesModal.styles';
import { mapCostToFormattedString } from '../util/mappers';

type BudgetExploreMatchesModalProps = Pick<ModalV2Props, 'triggerRef' | 'onClose'> & {
  uuid: string;
};

const INQUIRABLE_VENDOR_TYPES = ['VENUE', 'PHOTOGRAPHER', 'VIDEOGRAPHER'];

const BudgetExploreMatchesModal = ({
  uuid,
  triggerRef,
  onClose,
}: BudgetExploreMatchesModalProps) => {
  const {
    state: {
      budget: { taxonomy_nodes = [] },
    },
  } = useBudgetContext();
  const [isBusy, setIsBusy] = useState(true);
  const [vendors, setVendors] = useState<StorefrontCardView[]>([]);
  const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
  const [view, setView] = useState<'list' | 'form'>('list');
  const LOCATION_FALLBACK = 'new-york-ny';
  const trackingFlowRunId = useMemo(() => uuidv4(), []);
  const guestCount = useAppSelector(getUserWeddingGuestCount);

  const {
    state: {
      marketplaceLocation: { citySlug, city, stateProvince },
    },
  } = useBudgetContext();
  const location = citySlug !== null ? citySlug : LOCATION_FALLBACK;

  useSimpleScrollLock(true);

  const { vendor_type, estimated_cost_cents } = getBudgetItemByUuid(uuid, taxonomy_nodes) || {};

  const allowInquiries = INQUIRABLE_VENDOR_TYPES.includes(vendor_type || '');
  const priceMaxCents = Math.round(estimated_cost_cents || 0);
  const exploreMoreLink = `/wedding-vendors/search/${location}--${getVendorTaxonomyKeyFromVendorType(
    vendor_type as VendorTypeEnum
  )}?price-max=${Math.round(priceMaxCents / 100)}&price-min=0`;

  const dialogHeadingId = 'budget-explore-matches-modal-heading';
  const searchRequest: VendorSearchRequestType = {
    offset: 0,
    limit: 25,
    vendorType: vendor_type as V4StorefrontSearchRequest.VendorTypeEnum,
    locationMethod: vendor_type === 'VENUE' ? 'ADJACENT_MARKET' : 'MARKET',
    pointRadius: null,
    metroTypes: null,
    price: priceMaxCents
      ? {
          priceType: 'A_LA_CARTE',
          priceCents: {
            max: priceMaxCents,
            min: 0,
          },
        }
      : null,
    ...(vendor_type === 'VENUE'
      ? { capacity: { min: guestCount, max: null } }
      : { capacity: null }),
    facetGroups: null,
    targetDates: null,
    sortBy: null,
    sortOrder: 'DESC',
    allowUnclaimedListings: false,
    searchName: 'DEFAULT',
    boostFeaturedStorefronts: null,
    awardGroups: null,
    city,
    stateProvince,
    vendorMarketId: null,
    priceTier: null,
    seoOptimized: false,
    excludeInquiredStorefronts: true,
    excludeBookedStorefronts: true,
  };

  useEffectOnce(() => {
    fetchStorefrontSearchResults(searchRequest)
      .then(res => {
        setVendors(res.entities);
        res.entities.forEach(({ storefrontUuid }) => {
          trackFlowStarted({
            flow_entry: 'BUDGET_TOOL',
            flow_run_id: trackingFlowRunId,
            business_category: vendor_type
              ? getBusinessCategory(
                  getVendorTaxonomyKeyFromVendorType(
                    vendor_type as VendorTypeEnum
                  ) as SearchableVendorTaxonomyKey
                )
              : 'UNATTRIBUTED',
            business_unit: 'MARKETPLACE',
            storefront_uuid: storefrontUuid,
            flow_type: 'INQUIRY_MASS',
          });
        });
        if (allowInquiries) {
          setSelectedVendors(
            res.entities.reduce<string[]>(
              (accumulator, { storefrontUuid }) => [...accumulator, storefrontUuid],
              []
            )
          );
        }
      })
      .catch(() => undefined)
      .finally(() => {
        setIsBusy(false);
      });
  });

  const handleCardClick = (storeUuid: string) => {
    if (
      selectedVendors.some(
        selectedVendorStorefrontUuid => selectedVendorStorefrontUuid === storeUuid
      )
    ) {
      setSelectedVendors(
        selectedVendors.filter(
          selectedVendorStorefrontUuid => selectedVendorStorefrontUuid !== storeUuid
        )
      );
    } else {
      setSelectedVendors([...selectedVendors, storeUuid]);
    }
  };

  const firstSelectedVendorName = vendors.find(
    ({ storefrontUuid }) => storefrontUuid === selectedVendors[0]
  )?.name;

  const noResults = !isBusy && !vendors.length;

  return (
    <StyledModalV2
      onClose={onClose}
      rootId="budget-modal-root"
      triggerRef={triggerRef}
      dialogHeadingId={dialogHeadingId}
    >
      <Content extraPaddingBottom={allowInquiries && view === 'list'}>
        {view === 'list' ? (
          <>
            <VendorsList>
              {noResults ? (
                <NoResults>
                  <Title2 presentation="h3">
                    We couldn&apos;t find any results that match your preferences
                  </Title2>
                  <ButtonV3
                    component="a"
                    href={exploreMoreLink}
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    Browse vendors
                  </ButtonV3>
                </NoResults>
              ) : (
                <Title2 presentation="h3">
                  Top{' '}
                  {getVendorWords(getVendorTaxonomyKey(vendor_type as VendorTaxonomyKey)).plural}{' '}
                  under {mapCostToFormattedString(priceMaxCents, true)}
                </Title2>
              )}
              {isBusy ? (
                [...Array(3)].map((_, i) => <StyledSkeletonLoader key={`skeleton-loader-${i}`} />)
              ) : (
                <BudgetMatchesSearchResults
                  vendors={vendors}
                  selectedVendors={selectedVendors}
                  allowInquiries={allowInquiries}
                  onCardClick={handleCardClick}
                />
              )}
            </VendorsList>
            {allowInquiries && !noResults && (
              <Ctas>
                <ButtonV3
                  fullWidth
                  onClick={() => setView('form')}
                  size="large"
                  disabled={!selectedVendors.length}
                >
                  Reach out for availability
                </ButtonV3>
                {isDesktopV2() && (
                  <LinkV2
                    href={exploreMoreLink}
                    target="_blank"
                    rel="nofollow noopener"
                    arrow
                    sizes="small"
                    inline
                    style={{ textAlign: 'center' }}
                  >
                    Explore more {getVendorWordPlural(vendor_type)}
                  </LinkV2>
                )}
              </Ctas>
            )}
          </>
        ) : (
          <BudgetMatchesForm
            firstSelectedVendorName={firstSelectedVendorName}
            selectedVendors={selectedVendors}
            onClose={onClose}
            onBack={() => setView('list')}
            trackingFlowRunId={trackingFlowRunId}
            vendorType={vendor_type}
          />
        )}
      </Content>
    </StyledModalV2>
  );
};

export default BudgetExploreMatchesModal;
