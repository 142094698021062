import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import type {
  WBudgetView,
  WBudgetItemView,
  WBudgetItemTypeView,
  WBudgetTaxonomyNodeView,
  WBudgetItemPaymentView,
  WSuggestedBudgetByCategoryView,
} from '@zola/svc-web-api-ts-client';
import type { MappedWAccountVendorView } from 'api/vendorMarketplaceApi';
import type { AccountWeddingLocationView } from '@zola/svc-marketplace-ts-types';
import featureFlags from 'util/featureFlags';

import type { BudgetSortBy } from './types';
import { getBudgetActions } from './actions';
import { BudgetActionTypes } from './actionTypes';
import { budgetAppReducer } from './reducers';
import useSortBy from '../util/useSortBy';

export type BudgetContextStateType = {
  busy: boolean;
  budget: {
    account_id?: number;
    balance_due_cents?: number;
    budgeted_cents?: number;
    cost_cents?: number;
    cost_cents_without_estimates?: number;
    paid_cents?: number;
    actual_cost_cents?: number;
    estimated_cost_cents?: number;
    taxonomy_nodes?: WBudgetTaxonomyNodeView[];
    uuid?: string;
  };
  item_types?: WBudgetItemTypeView[];
  unsavedChanges: {
    unsavedMaxSpend?: number;
    unsavedItems: {
      [k: string]: WBudgetItemView;
    };
    unsavedTaxonomyNode?: WBudgetTaxonomyNodeView;
    unsavedOrderedTaxonomyNodes?: WBudgetTaxonomyNodeView[];
  };
  accountVendors?: MappedWAccountVendorView[];
  suggestedAllocations: WSuggestedBudgetByCategoryView;
  marketplaceLocation:
    | AccountWeddingLocationView
    | Pick<AccountWeddingLocationView, 'citySlug' | 'stateProvince' | 'city' | 'source'>;
  notificationsEnabled: boolean;
  sorting: { sortBy?: BudgetSortBy; clickCount: number };
};

export type BudgetContextActionsType = {
  receiveBudget: (budget: WBudgetView) => void;
  receiveBusyState: (busy: boolean) => void;
  receiveBudgetItem: (item: WBudgetItemView) => void;
  receiveBudgetItems: (items: WBudgetItemView[]) => void;
  receiveDeletedBudgetItem: (itemUuid: string) => void;
  receiveItemTypes: (itemTypes: WBudgetItemTypeView[]) => void;
  receiveTaxonomyNode: (taxonomyNode: WBudgetTaxonomyNodeView) => void;
  receiveTaxonomyNodes: (taxonomyNodes: WBudgetTaxonomyNodeView[]) => void;
  receiveDeletedTaxonomyNode: (taxonomyNodeUuid: string) => void;
  receiveUnsavedMaxSpend: (maxSpend: number) => void;
  receiveUnsavedItem: (itemUuid: string, fields: WBudgetItemView) => void;
  receiveUnsavedTaxonomyNode: (node: WBudgetTaxonomyNodeView) => void;
  receiveUnsavedOrderedTaxonomyNodes: (nodes: WBudgetTaxonomyNodeView[]) => void;
  clearUnsavedMaxSpend: () => void;
  clearUnsavedItem: (itemUuid: string) => void;
  clearUnsavedTaxonomyNode: () => void;
  clearUnsavedOrderedTaxonomyNodes: () => void;
  receiveBudgetPayment: (payment: WBudgetItemPaymentView) => void;
  receiveDeletedBudgetPayment: (paymentUuid: string) => void;
  receiveAccountVendors: (accountVendors: MappedWAccountVendorView[]) => void;
  receiveMarketplaceLocation: (marketplaceLocation: AccountWeddingLocationView) => void;
  receiveNotificationsEnabled: (enabled: boolean) => void;
  receiveSortBy: (sortBy: BudgetSortBy) => void;
  receiveSuggestedAllocations: (suggestedAllocations: WSuggestedBudgetByCategoryView) => void;
  clearSuggestedAllocations: () => void;
};

export type BudgetContextType = {
  gridTemplateColumns: string;
  isBudgetRevamp: boolean;
  sorting: {
    sortByFn: <T extends WBudgetItemView>(a: T, b: T) => number;
    sortDirection: 'asc' | 'desc' | undefined;
  };
  state: BudgetContextStateType;
  dispatch: Dispatch<BudgetActionTypes>;
};

export const budgetInitialState: BudgetContextStateType = {
  busy: true,
  budget: {
    account_id: undefined,
    balance_due_cents: undefined,
    budgeted_cents: undefined,
    cost_cents: undefined,
    cost_cents_without_estimates: undefined,
    actual_cost_cents: undefined,
    estimated_cost_cents: undefined,
    paid_cents: undefined,
    taxonomy_nodes: [],
    uuid: undefined,
  },
  item_types: [],
  unsavedChanges: {
    unsavedItems: {},
  },
  accountVendors: [],
  suggestedAllocations: {} as WSuggestedBudgetByCategoryView,
  marketplaceLocation: {
    citySlug: 'new-york-ny',
    stateProvince: 'NY',
    city: 'New York',
    source: 'DEFAULT',
  },
  notificationsEnabled: true,
  sorting: {
    sortBy: undefined,
    clickCount: 0,
  },
};

export const BudgetContext = createContext<BudgetContextType>({
  isBudgetRevamp: false,
  gridTemplateColumns: '',
  sorting: { sortByFn: () => 0, sortDirection: undefined },
  state: budgetInitialState,
  dispatch: () => null,
});

export const defaultGridTemplateColumns = `minmax(201px, 16.36fr) minmax(414px, 38.35fr) minmax(140px, 9.16fr) minmax(140px, 9.16fr) minmax(250px, 18.32fr) minmax(112px, 8.64fr)`;

export const BudgetContextProvider: React.FC<{
  initialState?: BudgetContextStateType;
  gridTemplateColumns?: string;
}> = ({ children, initialState, gridTemplateColumns = defaultGridTemplateColumns }) => {
  const [state, dispatch] = useReducer(budgetAppReducer, initialState || budgetInitialState);
  const { sortByFn, sortDirection } = useSortBy(state.sorting.sortBy, state.sorting.clickCount);
  const isBudgetRevamp =
    process.env.JEST_WORKER_ID !== undefined ? false : featureFlags.get('budgetRevamp');

  return (
    <BudgetContext.Provider
      value={{
        state,
        dispatch,
        isBudgetRevamp,
        gridTemplateColumns,
        sorting: { sortByFn, sortDirection },
      }}
    >
      {children}
    </BudgetContext.Provider>
  );
};

export const useBudgetContext = (): {
  state: BudgetContextStateType;
  actions: BudgetContextActionsType;
  isBudgetRevamp: boolean;
  gridTemplateColumns: string;
  sorting: {
    sortByFn: <T extends WBudgetItemView>(a: T, b: T) => number;
    sortDirection: 'asc' | 'desc' | undefined;
  };
} => {
  const context = useContext(BudgetContext);
  if (context === undefined) {
    throw new Error('useBudgetContext must be used within a BudgetContextProvider');
  }
  const { state, dispatch, isBudgetRevamp, gridTemplateColumns, sorting } = context;
  const actions = getBudgetActions(dispatch);

  return { state, actions, isBudgetRevamp, gridTemplateColumns, sorting };
};
