const init = {
  busy: true,
  error: null,
  activeView: 'drafts',
  draftItems: null,
  orderItems: null,
  counts: null,
};

const extractSplitOrders = orders => {
  // Order objects returned from the BE can now contain multiple split orders (e.g. if a couple orders another copy of the same photobook)
  // In the FE, we want to display them as separate items, so we extract each split order into its own object.
  return orders.reduce((result, currentOrder) => {
    currentOrder.split_orders.forEach(splitOrder => {
      const extractedOrder = { ...currentOrder, ...splitOrder };
      delete extractedOrder.split_orders;
      result.push(extractedOrder);
    });
    return result;
  }, []);
};

const reducer = (state = init, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_PROJECTS_LISTING':
      return {
        ...state,
        activeView: action.payload,
      };
    case 'FETCH_ORDER_LIST':
    case 'FETCH_DRAFT_LIST':
      return {
        ...state,
        busy: true,
        error: null,
      };
    case 'FETCH_ORDER_LIST_REJECTED':
    case 'FETCH_DRAFT_LIST_REJECTED':
    case 'FETCH_PROJECTS_COUNTS_REJECTED':
    case 'CANCEL_PROJECT_REJECTED':
    case 'DELETE_PROJECT_REJECTED':
      return {
        ...state,
        error: action.payload,
      };
    case 'FETCH_ORDER_LIST_FULLFILLED':
      return {
        ...state,
        orderItems: extractSplitOrders(action.payload),
        busy: false,
        error: null,
      };
    case 'FETCH_DRAFT_LIST_FULLFILLED':
      return {
        ...state,
        draftItems: action.payload,
        busy: false,
        error: null,
      };
    case 'FETCH_PROJECTS_COUNTS_FULLFILLED':
      return {
        ...state,
        counts: action.payload,
      };
    case 'EMPTY_PROJECTS_LISTING':
      return {
        ...state,
        draftItems: null,
        orderItems: null,
        counts: null,
      };
    case 'CANCEL_PROJECT_SUCCEEDED': {
      const { projectUuid, status } = action.payload;
      const currentOrders = Object.assign(state.orderItems);
      const canceledOrder = currentOrders.find(order => order.uuid === projectUuid);
      const updatedOrders = Object.values(
        [...currentOrders, ...[{ ...canceledOrder, ...{ order_status: status } }]].reduce(
          (_orders, { uuid, ...rest }) => {
            const orders = Object.assign({}, _orders);
            orders[uuid] = {
              ...(orders[uuid] || {}),
              uuid,
              ...rest,
            };
            return orders;
          },
          {}
        )
      );
      return {
        ...state,
        orderItems: updatedOrders,
      };
    }
    case 'DELETE_PROJECT_SUCCEEDED':
      return {
        ...state,
        draftItems: state.draftItems.filter(draft => draft.uuid !== action.payload),
        counts: {
          ...state.counts,
          drafts: state.counts.drafts - 1,
        },
      };

    default:
      return state;
  }
};

export default reducer;
