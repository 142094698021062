import { reducer as form } from 'redux-form';
import { userAwarenessReducer as userAwareness } from '@zola-helpers/client/dist/es/redux/userAwareness';
import { toastsReducer as toasts } from '@zola-helpers/client/dist/es/redux/toasts';
import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'redu...
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import storage from 'redux-persist/lib/storage';
import websitePlpReducer from 'pages/WebsiteDesignsPLPSSR/reducer/websitePlpReducer';
import websitePdpReducer from 'pages/WebsiteDesignPdp/reducer/websitePdpReducer';
import favorites from 'favorites/redux/reducer';
import photobooks from '../photobooks/reducers';
import onboard from './onboard';
import website from './website';
import wedding from './wedding';
import weddingTheme from './weddingTheme';
import modal from './modal';
import auth from './auth';
import guestList from './guestList';
import user from './user';
import checklist from './checklist';
import publicWebsite from './public';
import moduleGroup from './moduleGroup';
import registry from './registry';
import registryCollections from './registryCollections';
import cart from './cart';
import external from './external';
import events from './events';
import guestCollection from './guestCollection';
import cards from '../cards/reducers';
import seatingChart from './seatingChart';
import guestRail from './guestRail';
import registryBulkImport from './registryBulkImport';
import uploadcare from './uploadcare';
import zoom from './zoom';

// Configs for persisting some values of cards reducer
const cardsPersistConfig = {
  key: 'cards',
  storage,
  whitelist: ['trackedEvents'],
  stateReconciler: autoMergeLevel2,
};

const rootState = combineReducers({
  auth,
  cards: persistReducer(cardsPersistConfig, cards),
  cart,
  checklist,
  events,
  external,
  favorites,
  form,
  guestCollection,
  guestList,
  guestRail,
  modal,
  moduleGroup,
  Onboard: onboard,
  publicWebsite,
  registry,
  registryBulkImport,
  registryCollections,
  seatingChart,
  toasts,
  uploadcare,
  user,
  userAwareness,
  website,
  wedding,
  weddingTheme,
  zoom,
  photobooks,
  routing: routerReducer,
  websitePlp: websitePlpReducer,
  websitePdp: websitePdpReducer,
});

export default rootState;

export type RootState = ReturnType<typeof rootState>;
