import React, { useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'reducers/useAppDispatch';
import type { RouteComponentProps } from 'react-router';
import _isEqual from 'lodash/isEqual';

import type { WBudgetTaxonomyNodeView, WBudgetView } from '@zola/svc-web-api-ts-client';

import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { BodyBase } from '@zola/zola-ui/src/typography/Paragraphs';
import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import { InputFieldAddOnType } from '@zola/zola-ui/src/components/Form/InputFieldV2/InputFieldAddOn';
import { SPACING } from '@zola/zola-ui/src/styles/emotion';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

import { DrawerTitle } from 'components/common/zolaUI/Drawer';
import { BudgetSortableList } from '../BudgetSortableList/BudgetSortableList';
import BudgetDetailsModal from '../BudgetDetailsModal/BudgetDetailsModal';

import { useBudgetContext } from '../context';
import { handleBudgetRouteChange } from '../routes/handleBudgetRouteChange';
import { useLeaveBudgetRoute } from '../routes/useLeaveBudgetRoute';
import { reorderTaxonomyNodes, updateBudget } from '../util/api';
import { useBudgetDrawerLayer } from '../util/useBudgetDrawerLayer';
import { useCurrencyInput } from '../util/useCurrencyInput';

import {
  MaxSpendContainer,
  AddContainer,
  AddHeader,
  AddButton,
  Flex,
} from './BudgetSettings.styles';

type RouteParams = Record<string, never>; // no params in this route

export type BudgetSettingsProps = RouteComponentProps<RouteParams, RouteParams>;

export const BudgetSettings = ({
  route,
  router: { setRouteLeaveHook },
  location: { pathname },
}: BudgetSettingsProps): JSX.Element => {
  // Toast
  const dispatch = useAppDispatch();
  const showErrorToast = () =>
    dispatch(
      toastsActions.negative({
        headline: 'There was an error. Please try again.',
      })
    );

  // Budget context
  const {
    actions: {
      receiveBudget,
      receiveTaxonomyNodes,
      receiveUnsavedMaxSpend,
      receiveUnsavedOrderedTaxonomyNodes,
      clearUnsavedMaxSpend,
      clearUnsavedOrderedTaxonomyNodes,
    },
    state: {
      budget: {
        budgeted_cents: maxSpendCents,
        taxonomy_nodes: taxonomyNodes = [],
        uuid: budgetUuid = '',
      },
      unsavedChanges: { unsavedMaxSpend, unsavedOrderedTaxonomyNodes },
    },
    isBudgetRevamp,
  } = useBudgetContext();

  // Drawer routing and unsaved changes setup
  useLeaveBudgetRoute(route, setRouteLeaveHook);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Form data initialization
  // If there are unsaved changes on coming back, use those; otherwise use context/API values
  const inputRef = useRef<HTMLInputElement | null>(null);
  const initialCurrencyValue = ((unsavedMaxSpend || maxSpendCents || 0) / 100).toString();
  const {
    currencyValue,
    rawCurrencyValue,
    setCurrencyValue,
    toggleIsEditingCurrency,
  } = useCurrencyInput(initialCurrencyValue, inputRef, [maxSpendCents]);
  const [localTaxonomyNodes, setLocalTaxonomyNodes] = useState(
    unsavedOrderedTaxonomyNodes || taxonomyNodes
  );
  const localTaxonomyNodeUuids = useMemo(() => localTaxonomyNodes.map(node => node.uuid || ''), [
    localTaxonomyNodes,
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasMaxSpendChange = useMemo(() => parseFloat(rawCurrencyValue) * 100 !== maxSpendCents, [
    maxSpendCents,
    rawCurrencyValue,
  ]);
  const hasOrderChange = useMemo(
    () =>
      !_isEqual(
        localTaxonomyNodeUuids,
        taxonomyNodes.map(node => node.uuid || '')
      ),
    [localTaxonomyNodeUuids, taxonomyNodes]
  );
  const triggerRef = useRef<HTMLAnchorElement>(null);
  const [showBudgetDetails, setShowBudgetDetails] = useState(false);

  const handleMaxSpendChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasUnsavedChanges(true);
    setCurrencyValue(e.target.value);
  };

  const handleReorderTaxonomyNodes = (reorderedTaxonomyNodes: WBudgetTaxonomyNodeView[]) => {
    setHasUnsavedChanges(true);
    setLocalTaxonomyNodes(reorderedTaxonomyNodes);
  };

  const handleCancel = () => {
    handleBudgetRouteChange();
  };

  const handleSave = () => {
    const budgetRequest = {
      budget_uuid: budgetUuid,
      budgeted_cents: parseFloat(rawCurrencyValue) * 100,
    };
    const taxonomyNodesRequest = {
      budget_uuid: budgetUuid,
      taxonomy_node_uuids: localTaxonomyNodeUuids,
    };

    setIsSubmitting(true);
    setHasUnsavedChanges(false);

    const requests = [];

    if (hasMaxSpendChange) {
      requests.push(updateBudget(budgetRequest));
    }
    if (hasOrderChange) {
      requests.push(reorderTaxonomyNodes(taxonomyNodesRequest));
    }

    Promise.all(requests)
      .then(res => {
        if (hasMaxSpendChange) {
          receiveBudget(res[0] as WBudgetView);
        }
        if (hasOrderChange) {
          receiveTaxonomyNodes(res[hasMaxSpendChange ? 1 : 0] as WBudgetTaxonomyNodeView[]);
        }
        dispatch(
          toastsActions.positive({
            headline: 'Updated',
          })
        );
        handleBudgetRouteChange();
      })
      .catch(() => {
        setHasUnsavedChanges(true);
        setIsSubmitting(false);
        showErrorToast();
      });
  };

  const handleStashChangesAndChangeRoute = (path: string) => {
    if (hasUnsavedChanges) {
      // Stash changes for when user comes back to settings
      receiveUnsavedMaxSpend(parseFloat(rawCurrencyValue) * 100);
      receiveUnsavedOrderedTaxonomyNodes(localTaxonomyNodes);
    }
    handleBudgetRouteChange(path, { preventIfUnsaved: false, previousPathname: pathname });
  };

  // Handles coming back to BudgetSettings if there were unsaved changes here
  useEffectOnce(() => {
    if (unsavedMaxSpend || unsavedOrderedTaxonomyNodes) {
      setHasUnsavedChanges(true);
      // Component was initialized with any unsaved changes, so clear them on mount
      clearUnsavedMaxSpend();
      clearUnsavedOrderedTaxonomyNodes();
    }
  });

  // Handles save for drawer unsaved changes prompt
  // Wrapping inside useEffect ensures handleSave has most up-to-date values
  useBudgetDrawerLayer(
    {
      key: pathname,
      cancelButtonDisabled: isSubmitting,
      saveButtonDisabled: isSubmitting,
      onSave: handleSave,
      onBack: handleCancel,
      onUnsavedChanges: hasUnsavedChanges
        ? () => {
            handleBudgetRouteChange('/settings');
          }
        : undefined,
    },
    isSubmitting,
    [hasUnsavedChanges, rawCurrencyValue, localTaxonomyNodes]
  );

  return (
    <div data-testid="BudgetSettings">
      <DrawerTitle style={{ marginBottom: SPACING.XS }}>
        {isBudgetRevamp ? 'Manage groups' : 'Settings'}
      </DrawerTitle>
      {isBudgetRevamp ? (
        <Flex>
          <BodyBase style={{ maxWidth: '315px' }}>
            Create groups within your wedding budget to help you stay organized, track spending, and
            oversee costs effectively.
          </BodyBase>
          <AddButton
            onClick={() => handleStashChangesAndChangeRoute('/group/new')}
            variant="secondary"
          >
            Add
          </AddButton>
        </Flex>
      ) : (
        <>
          <MaxSpendContainer>
            <InputFieldV3
              id="budgeted_cents"
              name="budgeted_cents"
              label="Max spend"
              alignment="horizontal"
              addOn={InputFieldAddOnType.CASH}
              isControlled
              onChange={handleMaxSpendChange}
              onFocus={toggleIsEditingCurrency}
              onBlur={toggleIsEditingCurrency}
              value={currencyValue}
              maxLength={20}
              ref={inputRef}
            />
          </MaxSpendContainer>
          <AddContainer>
            <AddHeader>Groups</AddHeader>
            <AddButton
              onClick={() => handleStashChangesAndChangeRoute('/group/new')}
              variant="secondary"
            >
              Add
            </AddButton>
          </AddContainer>
        </>
      )}
      <BudgetSortableList
        items={localTaxonomyNodes}
        itemUuids={localTaxonomyNodeUuids}
        onClickEdit={taxonomyNodeUuid =>
          handleStashChangesAndChangeRoute(`/group/${taxonomyNodeUuid}`)
        }
        onReorder={handleReorderTaxonomyNodes}
      />
      {showBudgetDetails && (
        <BudgetDetailsModal
          triggerRef={triggerRef}
          onClose={() => {
            setShowBudgetDetails(false);
          }}
        />
      )}
    </div>
  );
};
