import { browserHistory } from 'react-router';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';

import { syncZoomMeeting } from 'actions/ZoomActions';

export default function handleZoomErrorNotifications({ dispatch, response, currentToasts }) {
  if (!response && response.zoomErrors) return response;
  const { zoomErrors } = response;
  let showUnauthorized = false;
  if (zoomErrors && zoomErrors.length) {
    zoomErrors.forEach(err => {
      const [eventId, type, eventName] = err;
      if (type === 'OUT_OF_SYNC') {
        dispatch(
          toastsActions.negative({
            autoDismissInSeconds: 0, // requires action
            id: { eventId },
            headline: `Sync Failed: ${eventName}`,
            dismissText: 'Update',
            onDismiss: () => dispatch(syncZoomMeeting(eventId)),
            dek: `Update your Zoom meeting details to match Zola's.`,
          })
        );
      }
      if (type === 'UNAUTHORIZED') {
        showUnauthorized = true;
      }
    });
  }

  const unauthorizedMessageAlreadyPresent =
    Array.isArray(currentToasts) &&
    currentToasts.reduce((acc, toastObj) => {
      if (toastObj.dek === 'Your Zoom account was disconnected.') {
        return true;
      }
      return acc;
    }, false);
  if (showUnauthorized && !unauthorizedMessageAlreadyPresent) {
    dispatch(
      toastsActions.negative({
        autoDismissInSeconds: 0, // requires action
        headline: 'Sync Failed',
        dismissText: `Reconnect`,
        onDismiss: () => browserHistory.push('/wedding/manage/virtual/connect'),
        dek: 'Your Zoom account was disconnected.',
      })
    );
  }

  return response;
}
