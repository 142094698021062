import { ALBUM_NAVIGATION_DIRECTIONS } from 'photobooks/constants/albumNavDirections';

export const getNewSpreadIndex = (
  oldSlotIndex: number,
  navDirection: string,
  totalSpreadLastIndex: number
) => {
  let newSpreadIndex = oldSlotIndex;
  if (navDirection === ALBUM_NAVIGATION_DIRECTIONS.next) {
    newSpreadIndex =
      oldSlotIndex + 1 <= totalSpreadLastIndex
        ? Math.round(oldSlotIndex + 1)
        : totalSpreadLastIndex;
  } else if (navDirection === ALBUM_NAVIGATION_DIRECTIONS.previous) {
    newSpreadIndex = oldSlotIndex - 1 >= 0 ? Math.round(oldSlotIndex - 1) : 0;
  }
  return newSpreadIndex;
};
