import styled from '@emotion/styled';

import { SPACING } from '@zola/zola-ui/src/styles/emotion';
import { ModalV2 } from '@zola/zola-ui/src/components/Modal';
import { ModalDialog } from '@zola/zola-ui/src/components/Modal/ModalV2.styles';

export const Ctas = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${SPACING.XS};
  margin-top: ${SPACING.MD};
`;

export const Content = styled.div`
  max-width: 536px;
  padding: 72px ${SPACING.S48} ${SPACING.S48};
  display: flex;
  flex-direction: column;
  gap: ${SPACING.SM};
`;

export const StyledModal = styled(ModalV2)`
  ${ModalDialog} {
    margin: auto;
  }
`;
