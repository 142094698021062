import type { TemplatePreviewRequest, WCardTemplateView } from '@zola/svc-web-api-ts-client';
import type { WeddingDetailsSubstitutions } from 'cards/types/WeddingDetailsSubstitutions';
import ApiService from '../../util/api';

function fetchTemplate(
  variations?: string[],
  substitutions?: WeddingDetailsSubstitutions
): Promise<Record<string, WCardTemplateView> | null> {
  if (!variations || variations.length === 0) return Promise.resolve(null);

  const req: TemplatePreviewRequest = {
    variation_uuids: variations,
    substitutions,
  };

  return ApiService.post('/web-api/v2/card-template/preview/by-variations', req).then(
    (data): Record<string, WCardTemplateView> => data
  );
}

export function fetchDefaultTemplate(
  variations?: string[],
  accountId?: number
): Promise<Record<string, WCardTemplateView> | null> {
  if (!variations || variations.length === 0) return Promise.resolve(null);

  const req: TemplatePreviewRequest = {
    variation_uuids: variations,
  };

  if (accountId) {
    req.account_id = accountId;
  }

  return ApiService.post('/web-api/v2/card-template/preview/by-variations-default', req).then(
    (data): Record<string, WCardTemplateView> => data
  );
}

export default fetchTemplate;
