export const ENVELOPE_PRINTING = {
  none: 'none',
  front: 'front',
  both: 'both',
};

export const RETURN_ADDRESSING = {
  none: 'NONE',
  front: 'FRONT',
  back: 'BACK',
};

export const getEnvelopePrintingValue = (returnAddressingValue: unknown) => {
  switch (returnAddressingValue) {
    case RETURN_ADDRESSING.back:
      return ENVELOPE_PRINTING.both;
    case RETURN_ADDRESSING.front:
      return ENVELOPE_PRINTING.front;
    default:
      return ENVELOPE_PRINTING.none;
  }
};
