import { createSelector } from 'reselect';

import { isCardTypeWithSamples } from '@zola/zola-ui/src/paper/cards/constants/cardTypeFeatures/cardTypesWithSamples';
import { mapCardTypeToBusinessUnitComponent } from '@zola/zola-ui/src/paper/cards/util/trackingHelpers';
import type { RootState } from 'reducers/index';
import type { PageData } from 'cards/pages/ProductDetail/ProductDetailTypes';

const getPdpState = (state: RootState) => state.cards.pdp;

// Card suite. Will only change when suite is fetched.
export const getActiveSuiteUUID = createSelector(getPdpState, pdpState => pdpState.activeSuiteUUID);

// True during suite detail fetch
export const getIsBusy = createSelector(getPdpState, pdpState => pdpState.busy);

// Data from the getSuiteDetails call
export const getPageData = createSelector(
  getPdpState,
  pdpState => (pdpState.data || {}) as Partial<PageData>
);

export const getIsActiveSuiteDataLoaded = createSelector(
  getActiveSuiteUUID,
  getPageData,
  (activeSuiteUUID, pageData) => !!activeSuiteUUID && activeSuiteUUID === pageData.loadedSuiteUUID
);

export const getCardName = createSelector(getPageData, pageData => pageData.name);
export const getCardType = createSelector(getPageData, pageData => pageData.cardType);
export const getCardFamily = createSelector(getPageData, pageData => pageData.family);
export const getCardCollaborator = createSelector(getPageData, pageData => pageData.collaborator);
export const getCardOrientation = createSelector(getPageData, pageData => pageData.orientation);
export const getWebsiteThemeRoute = createSelector(getPageData, pageData =>
  pageData.websiteTheme?.key ? `/design/${pageData.websiteTheme?.key}` : ''
);
export const getPaperInfo = createSelector(getPageData, pageData => pageData.paperInfo);
export const getIsStampFoilCard = createSelector(getPageData, pageData => pageData.isStampFoilCard);
export const getIsCustomFoilAvailable = createSelector(
  getPageData,
  pageData => pageData.isCustomFoilAvailable
);
export const getSingleSampleSku = createSelector(getPageData, pageData => pageData.singleSampleSku);
export const getSingleSampleAvailable = createSelector(
  getPageData,
  pageData => pageData.singleSampleAvailable
);

export const getImages = createSelector(getPdpState, pdpState => pdpState.images);
export const getShotKeyImagesByColor = createSelector(
  getPdpState,
  pdpState => pdpState.shotKeyImagesByColor
);
export const getThumbnailsByColor = createSelector(
  getPdpState,
  pdpState => pdpState.thumbnailsByColor
);
export const getBusinessUnitComponent = createSelector(getCardType, cardType =>
  mapCardTypeToBusinessUnitComponent(cardType || '')
);

export const getActiveMediumKey = createSelector(getPdpState, pdpState => pdpState.activeMediumKey);
export const getActiveMediumUUID = createSelector(
  getPdpState,
  pdpState => pdpState.activeMediumUUID
);

export const getIsMagnet = createSelector(
  getActiveMediumKey,
  activeMediumKey => activeMediumKey === 'magnet'
);

// The current variation's options. Will change whenever selected options change.
export const getActiveSelection = createSelector(getPdpState, pdpState => pdpState.activeSelection);

export const getActiveSelectionColor = createSelector(
  getActiveSelection,
  activeSelection => activeSelection?.color || ''
);
export const getActiveSelectionSize = createSelector(
  getActiveSelection,
  activeSelection => activeSelection?.size || null
);

export const getPDPCardIsLetterpress = createSelector(
  getActiveSelection,
  selection => selection['printing-type'] === 'letterpress'
);

export const getEarliestArrivalDate = createSelector(
  getPdpState,
  pdpState => pdpState.earliestArrivalDate
);

export const getActiveVariationUUID = createSelector(
  getPdpState,
  pdpState => pdpState.activeVariationUUID
);

export const getAvailableOptions = createSelector(
  getPdpState,
  pdpState => pdpState.availableOptions
);

export const getQuantity = createSelector(getPdpState, pdpState => pdpState.quantity);
export const getCostData = createSelector(getPdpState, pdpState => pdpState.cost);

export const getVariationTemplates = createSelector(
  getPdpState,
  pdpState => pdpState.variationTemplates
);

export const getShowOnboardModal = createSelector(
  getPdpState,
  pdpState => pdpState.showOnboardModal
);

export const getIsOutOfStock = createSelector(getPaperInfo, paperInfo => !!paperInfo?.out_of_stock);

// All cards of supported card types will show a sample button, but only "regular"
// paper samples are customizable. Letterpress & stamped foil samples are pre-printed.
export const getSamplesAreSupported = createSelector(
  getCardType,
  getSingleSampleAvailable,
  getSingleSampleSku,
  getIsStampFoilCard,
  getIsMagnet,
  getPDPCardIsLetterpress,
  (cardType, isSampleAvailable, sampleSku, isStampFoilCard, isMagnet, isLetterpress) => {
    // If the card type doesn't support samples, stop here
    if (!isCardTypeWithSamples(cardType)) return false;

    const isRegularPaper = !isStampFoilCard && !isMagnet && !isLetterpress;
    const isRegularPaperWithSample = isRegularPaper && isSampleAvailable;
    const isSpecialPaperWithSample = !isRegularPaper && !!sampleSku;

    return isRegularPaperWithSample || isSpecialPaperWithSample;
  }
);

export const getProofsAreSupported = createSelector(
  getPaperInfo,
  paperInfo => !!paperInfo?.qty_single_allowed
);

export const getPdpUnitMultiplier = createSelector(
  getPaperInfo,
  paperInfo => paperInfo?.qty_unit_multiplier || 1
);
