import ApiService from '@zola-helpers/client/dist/es/http/api';
import type { WGooglePlaceDetailsView, WLocationDetailsRequest } from '@zola/svc-web-api-ts-client';

type LocationDetailsRequest = Omit<WLocationDetailsRequest, 'session_token'> & {
  session_token: google.maps.places.AutocompleteSessionToken;
};

export const getGooglePlaceDetails = ({
  google_place_id,
  address,
  session_token,
}: LocationDetailsRequest): Promise<WGooglePlaceDetailsView> => {
  return ApiService.post('/web-api/v1/location/details', {
    google_place_id,
    address,
    sessionToken: session_token,
  });
};

export const fetchPlaceDetailsSDK = (
  request: google.maps.places.PlaceDetailsRequest
): Promise<google.maps.places.PlaceResult> => {
  if (typeof window !== 'undefined') {
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    return new Promise((resolve, reject) => {
      service.getDetails(
        request,
        (
          placeDetailsResult: google.maps.places.PlaceResult | null,
          placesServiceStatus: google.maps.places.PlacesServiceStatus
        ): void => {
          if (placesServiceStatus !== google.maps.places.PlacesServiceStatus.OK) {
            reject(Error(`Google place details status error: ${placesServiceStatus}`));
          }
          if (!placeDetailsResult) {
            reject(Error(`Google places returned null value for placeId: ${request.placeId}`));
          }
          resolve(placeDetailsResult as google.maps.places.PlaceResult);
        }
      );
    });
  }
  return Promise.reject(Error('Google maps is not loaded'));
};
