import { fetchAvailableSamplesCount } from 'cards/components/common/Samples/util/api/fetchAvailableSamplesCount';

import ActionTypes from '../projectActionTypes';

export function checkFreeSampleAvailability(cardType) {
  return dispatch => {
    return fetchAvailableSamplesCount(cardType).then(data => {
      const freeSamplesAvailable = data.order_placed < data.maximum_orders_allowed;
      dispatch({
        type: ActionTypes.CHECK_FREE_SAMPLE_AVAILABILITY,
        payload: {
          freeSamplesAvailable,
        },
      });
      return data;
    });
  };
}
