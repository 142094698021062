import React from 'react';

import type { MappedWAccountVendorView } from 'api/vendorMarketplaceApi';

import { CustomCircleIcon } from '@zola/zola-ui/src/components/CircleIconRebrand/CircleIconRebrand';

import getZolaImageFromUuid from '@zola-helpers/client/dist/es/util/getZolaImageFromUuid';
import getVendorIcon from '@zola/zola-ui/src/util/getVendorIcon';
import {
  getVendorTaxonomyKeyFromVendorType,
  VendorTypeEnum,
} from '@zola-helpers/client/dist/es/marketplace';

import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';
import {
  AccountVendor,
  SelectedVendor,
  EditLink,
  StyledAddVendorLink,
} from '../BudgetItemView.styles';
import {
  VendorImg,
  BUDGET_VENDOR_THUMBNAIL_SIZE,
} from '../../BudgetItemAddVendor/BudgetItemAddVendor.styles';

type BudgetItemViewVendorCtasProps = {
  bookedVendor: MappedWAccountVendorView | undefined;
  typeDisplayName?: string;
  vendorType: string | undefined;
  itemUuid: string;
  handleDisconnectAccountVendor: () => void;
  handleStashChangesAndChangeRoute: (path: string) => void;
};

export const BudgetItemViewVendorCtas = ({
  bookedVendor,
  typeDisplayName,
  vendorType,
  itemUuid,
  handleDisconnectAccountVendor,
  handleStashChangesAndChangeRoute,
}: BudgetItemViewVendorCtasProps) => {
  const vendorIcon = getVendorIcon(
    getVendorTaxonomyKeyFromVendorType(vendorType as VendorTypeEnum)
  );
  return (
    <AccountVendor>
      {bookedVendor ? (
        <>
          <SelectedVendor>
            {bookedVendor.storefront_photo_uuid ? (
              <VendorImg
                src={`${getZolaImageFromUuid(
                  bookedVendor.storefront_photo_uuid
                )}?w=${BUDGET_VENDOR_THUMBNAIL_SIZE}`}
                alt={bookedVendor.vendor_name}
              />
            ) : (
              <CustomCircleIcon
                image={vendorIcon}
                bgColor={COLORS3.BLACK_015}
                size={BUDGET_VENDOR_THUMBNAIL_SIZE}
              />
            )}
            {bookedVendor.vendor_name}
          </SelectedVendor>
          <EditLink role="button" sizes="smaller" onClick={handleDisconnectAccountVendor}>
            Remove
          </EditLink>
        </>
      ) : (
        <StyledAddVendorLink
          role="button"
          onClick={() => handleStashChangesAndChangeRoute(`/item/${itemUuid}/add-vendor`)}
          arrow
          inline
        >
          <CustomCircleIcon image={vendorIcon} bgColor={COLORS3.BLACK_015} size={60} />
          Add your {typeDisplayName?.toLowerCase()}
        </StyledAddVendorLink>
      )}
    </AccountVendor>
  );
};
