import Segment, { trackEvent } from '@redux-beacon/segment';
import { createMiddleware } from 'redux-beacon';
import { getPageContext } from '@zola-helpers/client/dist/es/tracking/trackingHelper';

// Actions
import ActionTypes from '../cards/actions/trackingActionTypes';

/**
 * Leaving this here as examples in case action needs to be extracted or state manipulated
 * also another way to map the events down below
 * */

// const trackCardEvent = trackEvent((action, prevState, nextState) => {
//   const { payload } = action;
// if (prevState.cards.project.uuid !== nextState.cards.project.uuid) {
//   console.log('prevState', prevState.cards.project.uuid);
//   console.log('nextState', nextState.cards.project.uuid);
// }
// payload consists of { name, properties, options }, last two are optional
//   return { ...payload };
// });

// const cardsEventsMap = {
//   [ActionTypes.TRACK_CARD_EVENT]: trackEvent(action => ({ ...action.payload })),
//   [ActionTypes.TRACK_CARD_LIST_VIEWED]: trackEvent(action => ({ ...action.payload })),
// };

const eventsMapper = action => {
  switch (action.type) {
    case ActionTypes.TRACK_CARD_CUSTOMIZATION_EVENT:
    case ActionTypes.TRACK_CARD_LIST_VIEWED:
    case ActionTypes.TRACK_CARD_PRODUCT_CLICKED:
    case ActionTypes.TRACK_CARD_PRODUCT_ADDED_TO_CART:
    case ActionTypes.TRACK_TYNG_RECOMMENDATION_VIEWED:
    case ActionTypes.TRACK_TYNG_RECOMMENDATION_SELECTED: {
      // Explicitly pass page options to overwrite the page context added to event payloads by segment (fixes the referrer).
      const optionsFinal = action.options || {};
      optionsFinal.page = getPageContext();
      return trackEvent(() => ({ ...action.payload, options: { ...optionsFinal } }));
    }
    default:
      return [];
  }
};

const segmentMiddleware = createMiddleware(eventsMapper, Segment());

export default segmentMiddleware;
