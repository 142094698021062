import React, { useRef, useState } from 'react';

import type { MappedWAccountVendorView } from 'api/vendorMarketplaceApi';

import { PlusIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Plus';
import { Strong } from '@zola/zola-ui/src/typography/Text';
import { BodyBase, BodyLarge } from '@zola/zola-ui/src/typography/Paragraphs';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import vendorCircles from 'assets/images/budget/vendor-circles.png';
import exploreDesigns from 'assets/images/budget/explore-designs.jpg';

import { getUserWeddingCity, getUserWeddingStateProvince } from 'selectors/user/userSelectors';
import { useAppSelector } from 'reducers/useAppSelector';

import BudgetExploreMatchesModal from '../../BudgetExploreMatchesModal/BudgetExploreMatchesModal';
import BudgetVendorImgOrIcon from '../../BudgetVendorImgOrIcon/BudgetVendorImgOrIcon';
import { getIsPaperType, getPaperTypeUrl } from '../../util/mappers';

import {
  AccountVendor,
  SelectedVendor,
  AddOrSeeVendors,
  AddVendorBtn,
  IconCircle,
  SeeTopMatchesBtn,
} from '../BudgetItemView.styles';

type BudgetItemViewVendorCtasV2Props = {
  esimtatedCostCents: number | undefined;
  bookedVendor: MappedWAccountVendorView | undefined;
  isBookableVendorType?: boolean;
  vendorType: string | undefined;
  itemType: string | undefined;
  itemUuid: string;
  handleDisconnectAccountVendor: () => void;
  handleStashChangesAndChangeRoute: (path: string) => void;
};

export const BudgetItemViewVendorCtasV2 = ({
  bookedVendor,
  isBookableVendorType,
  itemType,
  vendorType,
  itemUuid,
  esimtatedCostCents,
  handleDisconnectAccountVendor,
  handleStashChangesAndChangeRoute,
}: BudgetItemViewVendorCtasV2Props) => {
  const userCity = useAppSelector(getUserWeddingCity);
  const userStateProvince = useAppSelector(getUserWeddingStateProvince);
  const isPaperType = getIsPaperType(itemType);
  const exploreTriggerRef = useRef(null);
  const [showExploreMatchesModal, setShowExploreMatchesModal] = useState(false);

  const allowExploreMatches = Boolean(
    isBookableVendorType && userCity && userStateProvince && esimtatedCostCents
  );

  const handleVendorCtaClick = () =>
    handleStashChangesAndChangeRoute(`/item/${itemUuid}/add-vendor`);

  const handleVendorOrPaperClick = () =>
    isPaperType
      ? window.open(getPaperTypeUrl(itemType), '_blank')
      : setShowExploreMatchesModal(true);

  return (
    <>
      <AccountVendor isBudgetRevamp>
        {bookedVendor ? (
          <>
            <SelectedVendor isBudgetRevamp>
              <BudgetVendorImgOrIcon
                bookedVendor={bookedVendor}
                size={60}
                vendorType={vendorType}
              />
              <div>
                <BodyLarge>{bookedVendor.vendor_name}</BodyLarge>
                <LinkV2
                  role="button"
                  sizes="smaller"
                  onClick={handleDisconnectAccountVendor}
                  subtle
                >
                  Remove
                </LinkV2>
              </div>
            </SelectedVendor>
          </>
        ) : (
          <AddOrSeeVendors>
            <AddVendorBtn onClick={handleVendorCtaClick}>
              <IconCircle>
                <PlusIcon width={20} height={20} />
              </IconCircle>
              <Strong>Add your vendor</Strong>
            </AddVendorBtn>
            {(allowExploreMatches || isPaperType) && (
              <>
                <BodyBase>or</BodyBase>
                <SeeTopMatchesBtn onClick={handleVendorOrPaperClick} ref={exploreTriggerRef}>
                  <img src={isPaperType ? exploreDesigns : vendorCircles} alt="" />
                  {isPaperType ? 'Explore designs' : 'See top matches'}
                </SeeTopMatchesBtn>
              </>
            )}
          </AddOrSeeVendors>
        )}
      </AccountVendor>
      {showExploreMatchesModal && (
        <BudgetExploreMatchesModal
          uuid={itemUuid}
          triggerRef={exploreTriggerRef}
          onClose={() => setShowExploreMatchesModal(false)}
        />
      )}
    </>
  );
};
