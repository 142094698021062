import type { RootState } from 'reducers/index';
import type { GuestCollectionReducerStateType } from 'reducers/guestCollection';

export const getGuestCollection = (state: RootState): GuestCollectionReducerStateType['data'] =>
  state.guestCollection.data;

export const getGuestCollectionTempImg = (
  state: RootState
): GuestCollectionReducerStateType['tempImg'] => state.guestCollection.tempImg;

export const getGuestCollectionSavedImg = (state: RootState): string | undefined =>
  state.guestCollection.data?.image_link;

export const getGuestCollectionCreationState = (state: RootState): boolean =>
  state.guestCollection.isCreation;
