import styled from '@emotion/styled';
import { COLORS3 as COLORS, FONT } from '@zola/zola-ui/src/styles/emotion';

export const SortableListContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

export const NoItemsText = styled.p`
  color: ${COLORS.BLACK_050};
  ${FONT.textSizeRegular(FONT.SIZE.REGULAR)};
`;
