import type {
  CreateBudgetRequest,
  WBudgetItemView,
  AddBudgetItemRequest,
  UpdateBudgetItemRequest,
  AddPaymentRequest,
  UpdatePaymentRequest,
} from '@zola/svc-web-api-ts-client';

export type CreateBudgetRequestWithStringOrigin = Omit<
  CreateBudgetRequest,
  'account_id' | 'origin'
> & {
  origin?: string;
};

export type BudgetItemWithUuid = WBudgetItemView & { uuid: string };

export type AddOrUpdateBudgetItemRequest = (
  | Omit<AddBudgetItemRequest, 'item_type'>
  | Omit<UpdateBudgetItemRequest, 'item_type'>
) & {
  item_type?: string;
};

export enum FE_BUDGET_PAYMENT_TYPE {
  PENDING = 'PENDING',
  PAID = 'PAID',
}

export type AddOrUpdateBudgetPaymentRequest = (
  | Omit<AddPaymentRequest, 'payment_type'>
  | Omit<UpdatePaymentRequest, 'payment_type'>
) & {
  payment_type: FE_BUDGET_PAYMENT_TYPE;
};

export type BudgetSortBy = 'vendor' | 'estimate' | 'cost' | 'paid' | undefined;

export type BudgetSortDirection = 'asc' | 'desc' | undefined;
