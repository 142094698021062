import { browserHistory } from 'react-router';
import { fetchDefaultTemplate } from 'cards/util/fetchTemplate';

import { getCustomizationStepUrl } from '@zola/zola-ui/src/paper/cards/util/getCustomizationStepUrl';
import ApiService from '../../../util/api';
import { getCardByVariationUUID } from '../../actions/cardCatalogActions';
import { receiveProject } from '../../actions/project/receiveProject';
import { fetchProjectMeta } from '../../actions/project/meta';
import { getActiveMediumUUID, getActiveVariationUUID, getAvailableOptions } from './selectors';

const cardSuiteIsFetching = () => ({
  type: 'CARD_SUITE_FETCH',
});

export const cardSuiteFulfilled = payload => ({
  type: 'CARD_SUITE_FETCH_FULFILLED',
  payload,
});

const cardSuiteRejected = payload => ({
  type: 'CARD_SUITE_FETCH_REJECTED',
  payload,
});

export const setActiveSuite = uuid => ({
  type: 'SET_ACTIVE_SUITE',
  payload: uuid,
});

export const nextVariationReceived = (nextVariation, changedOptionKey, changedOption) => ({
  type: 'NEXT_VARIATION_RECEIVED',
  payload: { nextVariation, changedOptionKey, changedOption },
});

export const setShowOnboardModal = payload => ({
  type: 'SET_SHOW_ONBOARD_MODAL',
  payload,
});

export const setQuantity = qty => dispatch => dispatch({ type: 'SET_QUANTITY', payload: qty });

export const fetchCardSuite = (uuid, isAdminView = false) => dispatch => {
  if (!uuid) {
    return Promise.resolve();
  }

  dispatch(cardSuiteIsFetching());

  const query = isAdminView ? '?show_unlisted=true' : '';
  return ApiService.get(`/web-api/v2/card-catalog/suite/details/${uuid}${query}`)
    .then(data => dispatch(cardSuiteFulfilled(data)))
    .catch(err => dispatch(cardSuiteRejected(err)));
};

export const fetchCardSuiteWithOptions = (
  uuid,
  isAdminView = false,
  selectedOptions = {}
) => dispatch => {
  if (!uuid) {
    return Promise.resolve();
  }

  dispatch(cardSuiteIsFetching());

  const body = {
    show_unlisted: isAdminView,
    selected_options: selectedOptions,
    selected_variation_uuid: selectedOptions.variation,
  };

  return ApiService.post(`/web-api/v2/card-catalog/suite/details/${uuid}`, body)
    .then(data => dispatch(cardSuiteFulfilled(data)))
    .catch(err => dispatch(cardSuiteRejected(err)));
};

export const fetchCardSuiteByProjectId = projectUUID => dispatch => {
  if (!projectUUID) {
    return Promise.resolve();
  }

  dispatch(cardSuiteIsFetching());

  return ApiService.get(`/web-api/v4/card-project/${projectUUID}/suite-details`)
    .then(data => {
      dispatch(cardSuiteFulfilled({ ...data, ...{ projectUUID } }));
      return (data && data.uuid) || null;
    })
    .catch(err => dispatch(cardSuiteRejected(err)));
};

// Used to lock variation updates. This is to avoid having users select multiple options in quick succession, but only keeping the last change.
// In prod, it shouldn't really be visible to users - the API call is blazing fast.
let variationUpdateInProgress = false;

export const setNextVariationFromOption = (changedOption, isAdminView = false) => (
  dispatch,
  getState
) => {
  // Immediately stop if there's already a variation update in progress.
  if (variationUpdateInProgress) return Promise.resolve();

  const { type, value } = changedOption;
  const availableOptions = getAvailableOptions(getState());
  const activeVariationUUID = getActiveVariationUUID(getState());
  const foundOption = availableOptions[type]?.options?.find(option => option.value === value);
  const variationUUID = foundOption?.variation_uuid;
  const cardUUID = foundOption?.card_uuid;

  // Don't update if we can't find a valid variation, or if the new variation is already active
  if (!cardUUID || !variationUUID || variationUUID === activeVariationUUID) {
    return Promise.resolve();
  }

  // Lock updates until the current one completes
  variationUpdateInProgress = true;

  const query = isAdminView ? '?show_unlisted=true' : '';
  return ApiService.get(
    `/web-api/v2/card-catalog/next-variation/${cardUUID}/${variationUUID}${query}`
  )
    .then(data => dispatch(nextVariationReceived(data, type, foundOption)))
    .catch(err => dispatch(cardSuiteRejected(err)))
    .finally(() => {
      // Unlock updates
      variationUpdateInProgress = false;
    });
};

export const setNextVariationFromOptions = (variationOptions, isAdminView = false) => (
  dispatch,
  getState
) => {
  // Immediately stop if there's already a variation update in progress.
  if (variationUpdateInProgress) return Promise.resolve();

  const cardUUID = getActiveMediumUUID(getState());

  // Don't update if we can't find a valid variation, or if the new variation is already active
  if (!cardUUID || !variationOptions) return Promise.resolve();

  // Lock updates until the current one completes
  variationUpdateInProgress = true;

  return ApiService.post('/web-api/v2/card-catalog/next-variation', {
    body: {
      card_uuid: cardUUID,
      variation_options: variationOptions,
    },
    ...(isAdminView ? { showUnlisted: true } : {}),
  })
    .then(data => dispatch(nextVariationReceived(data)))
    .catch(err => dispatch(cardSuiteRejected(err)))
    .finally(() => {
      // Unlock updates
      variationUpdateInProgress = false;
    });
};

export const customizeCard = (
  activeSuiteUUID,
  activeVariationUUID,
  quantity,
  isAdminView = false,
  skipRedirect = false,
  pointOfOrigin,
  extraCustomizable,
  photoUuid = null,
  updateActiveProject = true
) => dispatch => {
  const withCustomPhoto = typeof photoUuid === 'string' ? { photo_uuid: photoUuid } : {};

  return ApiService.post('/web-api/v3/card-project', {
    suite_uuid: activeSuiteUUID,
    lead_variation_uuid: activeVariationUUID,
    quantity,
    from_admin_route: isAdminView,
    point_of_origin: pointOfOrigin,
    extra_customizable: extraCustomizable,
    ...withCustomPhoto,
  }).then(json => {
    const { customizations, uuid } = json;

    dispatch(fetchProjectMeta(uuid));

    // Fetch every customization variation
    customizations.forEach(customization => {
      const variationUUID = customization?.variation?.uuid;
      if (variationUUID) {
        dispatch(getCardByVariationUUID(variationUUID, customization.uuid, isAdminView));
      }
    });

    // Save the newly created project in the Redux store
    if (json && updateActiveProject) {
      dispatch(receiveProject(json, isAdminView));
    }

    if (!skipRedirect) {
      browserHistory.push(getCustomizationStepUrl(uuid));
    }

    return json;
  });
};

const fetchVariationTemplatePromises = {};

export const fetchVariationTemplate = variationUUID => dispatch => {
  // Only make 1 template fetch call for a given variation UUID.
  // This avoids multiple simultaneous fetches from different components.
  if (!fetchVariationTemplatePromises[variationUUID]) {
    fetchVariationTemplatePromises[variationUUID] = fetchDefaultTemplate([variationUUID]);
  }

  return fetchVariationTemplatePromises[variationUUID]
    .then(data => {
      dispatch({
        type: 'FETCH_VARIATION_TEMPLATE_SUCCESS',
        payload: {
          variationUUID,
          template: data[variationUUID],
        },
      });
    })
    .catch(() => {
      // If the call fails, remove the promise from the saved ones.
      // Otherwise, it wouldn't be possible to try again later.
      delete fetchVariationTemplatePromises[variationUUID];
    });
};

// Duplicated for SSR - The ONLY place we want to manually pass accountId
export const fetchVariationTemplateSSR = (variationUUID, accountId) => dispatch => {
  return fetchDefaultTemplate([variationUUID], accountId).then(data => {
    dispatch({
      type: 'FETCH_VARIATION_TEMPLATE_SUCCESS',
      payload: {
        variationUUID,
        template: data[variationUUID],
      },
    });
  });
};
