import styled from '@emotion/styled';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { FormLabel } from '@zola/zola-ui/src/components/Form/form.styles';
import {
  Input,
  InputFieldContainer,
} from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3/InputFieldV3.styles';
import { COLORS3, FONT, MEDIA_QUERY, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const MaxSpendContainer = styled.div`
  ${FormLabel} {
    ${FONT.textSizeUltraCompact(FONT.SIZE.REGULAR)};
    ${MEDIA_QUERY.MOBILE_XS} {
      ${FONT.textSizeCompact(FONT.SIZE.SMALL)};
    }
  }
  ${InputFieldContainer} {
    align-items: center;
    justify-content: space-between;
  }
  ${Input} {
    text-align: right;
  }
`;

export const AddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${SPACING.S16};
`;

export const AddHeader = styled.h3`
  margin: 0;
  ${FONT.textSizeCompact(FONT.SIZE.LARGE)};
  ${MEDIA_QUERY.MOBILE_XS} {
    ${FONT.textSizeCompact(FONT.SIZE.REGULAR)};
  }
`;

export const AddButton = styled(ButtonV3)`
  padding: 0 ${SPACING.S24};
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACING.LG};
  padding-bottom: ${SPACING.LG};
  border-bottom: 1px solid ${COLORS3.BLACK_030};
  gap: ${SPACING.S16};
`;
