import styled from '@emotion/styled';

import RadioFieldV2 from '@zola/zola-ui/src/components/Form/RadioFieldV2/RadioField/RadioFieldV2';
import AutosuggestVenueFieldV2 from 'components/common/form/AutosuggestVenueField/AutosuggestVenueFieldV2';

import { inputBaseStyles } from '@zola/zola-ui/src/components/Form/form.styles';
import { DrawerTitle } from 'components/common/zolaUI/Drawer';
import { COLORS3, FONT, SPACING, utils } from '@zola/zola-ui/src/styles/emotion';

export const BUDGET_VENDOR_THUMBNAIL_SIZE = 60;

export const DrawerSubTitle = styled.p`
  margin-bottom: ${SPACING.LG};
  color: ${COLORS3.BLACK_100};
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
`;

export const NotFound = styled.div`
  margin-top: ${SPACING.LG};
  margin-bottom: ${SPACING.S16};
  ${FONT.textSizeUltraCompact(FONT.SIZE.REGULAR)}
`;

export const StyledDrawerTitle = styled(DrawerTitle)`
  margin-bottom: ${SPACING.XS};
`;

export const VendorOption = styled.label`
  display: flex;
  align-items: center;
  padding: ${SPACING.S16} 0;
  border-bottom: 1px solid ${COLORS3.BLACK_015};

  &:hover {
    cursor: pointer;
  }
`;

export const VendorImg = styled.img`
  height: ${BUDGET_VENDOR_THUMBNAIL_SIZE}px;
  width: ${BUDGET_VENDOR_THUMBNAIL_SIZE}px;
  ${utils.circle}
`;

export const VendorName = styled.span`
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
  font-family: ${FONT.FAMILY.SANS_SERIF};
  margin-left: ${SPACING.S16};
  text-transform: none;
  letter-spacing: normal;
`;

export const StyledRadio = styled(RadioFieldV2)`
  margin-left: auto;
`;

export const StyledAutosuggestVenueFieldV2 = styled(AutosuggestVenueFieldV2)`
  label {
    font-size: ${FONT.SIZE.SMALL}!important;
    line-height: ${FONT.SIZE.LARGE};
    font-weight: ${FONT.WEIGHT.SEMI_BOLD};
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: ${SPACING.XS};
    color: ${COLORS3.BLACK_100};
  }
  input[type='text'] {
    ${inputBaseStyles()}
    height: 44px;
  }
`;

export const Grid = styled.div<{ columns?: string; gap?: string }>`
  display: grid;
  ${({ columns = '1fr', gap = SPACING.S16 }) => `
    grid-template-columns: ${columns};
    grid-gap: ${gap};
  `}
`;

export const RequiredAst = styled.span`
  color: ${COLORS3.ROSE_100};
`;
