import type {
  WBudgetItemPaymentView,
  WBudgetItemView,
  WBudgetTaxonomyNodeView,
  WSuggestedBudgetByCategoryView,
} from '@zola/svc-web-api-ts-client';

import { ActionTypes, BudgetActionTypes } from './actionTypes';
import type { BudgetContextStateType } from './context';

export const budgetPaymentReducer = (
  state: WBudgetItemPaymentView[],
  action: BudgetActionTypes,
  itemUuid: string | undefined
): WBudgetItemPaymentView[] => {
  switch (action.type) {
    case ActionTypes.RECEIVE_PAYMENT: {
      const existingPaymentIndex = state.findIndex(
        payment => payment.uuid === action.payload.payment.uuid
      );
      if (existingPaymentIndex >= 0) {
        return state.map((payment, i) =>
          i === existingPaymentIndex
            ? { ...state[existingPaymentIndex], ...action.payload.payment }
            : payment
        );
      }
      if (itemUuid === action.payload.payment.budget_item_uuid) {
        return [...state, action.payload.payment];
      }
      return state;
    }
    case ActionTypes.RECEIVE_DELETED_PAYMENT: {
      return state.filter(payment => payment.uuid !== action.payload.paymentUuid);
    }
    default:
      return state;
  }
};

export const budgetItemReducer = (
  state: WBudgetItemView[],
  action: BudgetActionTypes,
  taxonomyUuid: string | undefined
): WBudgetItemView[] => {
  switch (action.type) {
    case ActionTypes.RECEIVE_ITEM: {
      const existingItemIndex = state.findIndex(item => item.uuid === action.payload.item.uuid);
      if (existingItemIndex >= 0) {
        return (
          state
            .map((item, i) =>
              i === existingItemIndex
                ? { ...state[existingItemIndex], ...action.payload.item }
                : item
            )
            // if taxonomy group changed, remove from original
            .filter(item => item.taxonomy_node_uuid === taxonomyUuid)
        );
      }
      if (taxonomyUuid === action.payload.item.taxonomy_node_uuid) {
        return [...state, action.payload.item];
      }
      return state;
    }
    case ActionTypes.RECEIVE_ITEMS: {
      if (taxonomyUuid === action.payload.items[0]?.taxonomy_node_uuid) {
        return action.payload.items.map(item => ({
          ...item,
          payments: budgetPaymentReducer(item.payments || [], action, item.uuid),
        }));
      }
      return state;
    }
    case ActionTypes.RECEIVE_DELETED_ITEM: {
      return state.filter(item => item.uuid !== action.payload.itemUuid);
    }
    default:
      return state.map(item => ({
        ...item,
        payments: budgetPaymentReducer(item.payments || [], action, item.uuid),
      }));
  }
};

export const taxonomyNodeReducer = (
  state: WBudgetTaxonomyNodeView[],
  action: BudgetActionTypes
): BudgetContextStateType['budget']['taxonomy_nodes'] => {
  switch (action.type) {
    case ActionTypes.RECEIVE_TAXONOMY_NODE: {
      const existingNodeIndex = state.findIndex(
        node => node.uuid === action.payload.taxonomyNode.uuid
      );
      if (existingNodeIndex >= 0) {
        return state.map((node, i) =>
          i === existingNodeIndex ? action.payload.taxonomyNode : node
        );
      }
      return [...state, action.payload.taxonomyNode];
    }
    case ActionTypes.RECEIVE_TAXONOMY_NODES: {
      return action.payload.taxonomyNodes.map(node => ({
        ...node,
        items: budgetItemReducer(node.items || [], action, node.uuid),
      }));
    }
    case ActionTypes.RECEIVE_DELETED_TAXONOMY_NODE: {
      return state.filter(node => node.uuid !== action.payload.taxonomyNodeUuid);
    }
    case ActionTypes.RECEIVE_BUDGET: {
      return action.payload.budget.taxonomy_nodes?.map(node => ({
        ...node,
        items: budgetItemReducer(node.items || [], action, node.uuid),
      }));
    }
    default:
      return state.map(node => ({
        ...node,
        items: budgetItemReducer(node.items || [], action, node.uuid),
      }));
  }
};

export const budgetReducer = (
  state: BudgetContextStateType['budget'],
  action: BudgetActionTypes
): BudgetContextStateType['budget'] => {
  switch (action.type) {
    case ActionTypes.RECEIVE_BUDGET:
      return {
        ...state,
        ...action.payload.budget,
        taxonomy_nodes: taxonomyNodeReducer(state.taxonomy_nodes || [], action),
      };
    default:
      return { ...state, taxonomy_nodes: taxonomyNodeReducer(state.taxonomy_nodes || [], action) };
  }
};

export const unsavedChangesReducer = (
  state: BudgetContextStateType['unsavedChanges'],
  action: BudgetActionTypes
): BudgetContextStateType['unsavedChanges'] => {
  switch (action.type) {
    case ActionTypes.RECEIVE_UNSAVED_MAX_SPEND:
      return {
        ...state,
        unsavedMaxSpend: action.payload.maxSpend,
      };
    case ActionTypes.RECEIVE_UNSAVED_ITEM:
      return {
        ...state,
        unsavedItems: {
          ...state.unsavedItems,
          [action.payload.itemUuid]: action.payload.fields,
        },
      };
    case ActionTypes.RECEIVE_UNSAVED_TAXONOMY_NODE:
      return {
        ...state,
        unsavedTaxonomyNode: action.payload.node,
      };
    case ActionTypes.RECEIVE_UNSAVED_ORDERED_TAXONOMY_NODES:
      return {
        ...state,
        unsavedOrderedTaxonomyNodes: action.payload.nodes,
      };
    case ActionTypes.RECEIVE_TAXONOMY_NODE: {
      if (!state.unsavedOrderedTaxonomyNodes) return state;
      const existingNodeIndex = state.unsavedOrderedTaxonomyNodes.findIndex(
        node => node.uuid === action.payload.taxonomyNode.uuid
      );
      const updatedNodes =
        existingNodeIndex >= 0
          ? state.unsavedOrderedTaxonomyNodes.map((node, i) =>
              i === existingNodeIndex ? action.payload.taxonomyNode : node
            )
          : [...state.unsavedOrderedTaxonomyNodes, action.payload.taxonomyNode];
      return {
        ...state,
        unsavedOrderedTaxonomyNodes: updatedNodes,
      };
    }
    case ActionTypes.RECEIVE_ITEM: {
      if (!state.unsavedTaxonomyNode) return state;
      const { items = [] } = state.unsavedTaxonomyNode;
      const { item: updatedItem } = action.payload;
      const existingItemIndex = items.findIndex(item => item.uuid === updatedItem.uuid);
      const updatedItems =
        existingItemIndex >= 0
          ? items
              .map((item, i) => (i === existingItemIndex ? { ...item, ...updatedItem } : item))
              // if taxonomy group changed, remove from original
              .filter(item => item.taxonomy_node_uuid === items[0].taxonomy_node_uuid)
          : [...items, updatedItem];
      return {
        ...state,
        unsavedTaxonomyNode: {
          ...state.unsavedTaxonomyNode,
          items: updatedItems,
        },
      };
    }
    case ActionTypes.RECEIVE_DELETED_TAXONOMY_NODE:
      if (!state.unsavedOrderedTaxonomyNodes) return state;
      return {
        ...state,
        unsavedOrderedTaxonomyNodes: state.unsavedOrderedTaxonomyNodes?.filter(
          node => node.uuid !== action.payload.taxonomyNodeUuid
        ),
      };
    case ActionTypes.RECEIVE_DELETED_ITEM:
      if (!state.unsavedTaxonomyNode) return state;
      return {
        ...state,
        unsavedTaxonomyNode: {
          ...state.unsavedTaxonomyNode,
          items: state.unsavedTaxonomyNode?.items?.filter(
            item => item.uuid !== action.payload.itemUuid
          ),
        },
      };
    case ActionTypes.CLEAR_MAX_SPEND_FROM_UNSAVED: {
      const updatedState = { ...state };
      delete updatedState.unsavedMaxSpend;
      return updatedState;
    }
    case ActionTypes.CLEAR_ITEM_FROM_UNSAVED: {
      const { [action.payload.itemUuid]: _, ...rest } = state.unsavedItems;
      return {
        ...state,
        unsavedItems: {
          ...rest,
        },
      };
    }
    case ActionTypes.CLEAR_TAXONOMY_NODE_FROM_UNSAVED: {
      const updatedState = { ...state };
      delete updatedState.unsavedTaxonomyNode;
      return updatedState;
    }
    case ActionTypes.CLEAR_ORDERED_TAXONOMY_NODES_FROM_UNSAVED: {
      const updatedState = { ...state };
      delete updatedState.unsavedOrderedTaxonomyNodes;
      return updatedState;
    }
    default:
      return state;
  }
};

export const budgetAppReducer = (
  state: BudgetContextStateType,
  action: BudgetActionTypes
): BudgetContextStateType => {
  switch (action.type) {
    case ActionTypes.RECEIVE_BUDGET:
      return {
        ...state,
        budget: budgetReducer(state.budget, action),
      };
    case ActionTypes.RECEIVE_BUSY:
      return {
        ...state,
        busy: action.payload.busy,
      };
    case ActionTypes.RECEIVE_ITEM_TYPES:
      return {
        ...state,
        item_types: action.payload.itemTypes,
      };
    case ActionTypes.RECEIVE_ACCOUNT_VENDORS:
      return {
        ...state,
        accountVendors: action.payload.accountVendors,
      };
    case ActionTypes.CLEAR_SUGGESTED_ALLOCATIONS: {
      return {
        ...state,
        suggestedAllocations: {} as WSuggestedBudgetByCategoryView,
      };
    }
    case ActionTypes.RECEIVE_SUGGESTED_ALLOCATIONS:
      return {
        ...state,
        suggestedAllocations: action.payload.suggestedAllocations,
      };
    case ActionTypes.RECEIVE_MARKETPLACE_LOCATION:
      return {
        ...state,
        marketplaceLocation: {
          ...action.payload.marketplaceLocation,
          citySlug:
            action.payload.marketplaceLocation.citySlug ?? state.marketplaceLocation.citySlug,
        },
      };
    case ActionTypes.RECEIVE_EMAIL_PREFERENCES:
      return {
        ...state,
        notificationsEnabled: action.payload.enabled,
      };
    case ActionTypes.RECEIVE_SORT_BY:
      return {
        ...state,
        sorting: { sortBy: action.payload.sortBy, clickCount: state.sorting.clickCount + 1 },
      };
    default:
      return {
        ...state,
        budget: budgetReducer(state.budget, action),
        unsavedChanges: unsavedChangesReducer(state.unsavedChanges, action),
      };
  }
};
