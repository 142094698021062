import _get from 'lodash/get';
import { createSelector } from 'reselect';

const getGalleryState = state => _get(state, ['photobooks', 'gallery']);

export const getGalleryPhotos = createSelector(
  getGalleryState,
  galleryState => galleryState.allPhotos
);

export const getGalleryCategories = createSelector(
  getGalleryState,
  galleryState => galleryState.allCategories
);

export const getGalleryDragState = createSelector(
  getGalleryState,
  galleryState => galleryState.galleryDragState
);

export const getPhotoMenuDragState = createSelector(
  getGalleryState,
  galleryState => galleryState.photoMenuDragState
);

export const isDraggingPhoto = createSelector(
  getGalleryState,
  galleryState => !!galleryState.photoMenuDragState
);

export const isDraggingCategory = createSelector(
  getGalleryState,
  galleryState => !!(galleryState.dragState && galleryState.dragState.category)
);

export const getAllPhotosSet = createSelector(
  getGalleryState,
  galleryState => galleryState.allPhotosSet
);
