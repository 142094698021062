import styled from '@emotion/styled';
import { COLORS3 as COLORS, MEDIA_QUERY, SPACING } from '@zola/zola-ui/src/styles/emotion';
import { BodyBase } from '@zola/zola-ui/src/typography/Paragraphs';

export const Container = styled.div`
  padding: 0 0 ${SPACING.S32};

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    padding: 0 0 ${SPACING.S24};
  }
`;

export const Dek = styled(BodyBase)`
  color: ${COLORS.BLACK_050};
  text-align: center;
  margin: ${SPACING.LG} auto 0;
  max-width: 320px;
`;
