import styled from '@emotion/styled';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { ReorderIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Reorder';
import { COLORS3 as COLORS, FONT, SPACING, ZINDEX } from '@zola/zola-ui/src/styles/emotion';

export const SortableItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.S24} ${SPACING.MD} ${SPACING.S24} ${SPACING.XXS};
  border-bottom: 1px solid ${COLORS.BLACK_010};
  position: relative;
  touch-action: none;
  background-color: ${COLORS.WHITE_100};
  @media (hover: hover) {
    &:hover {
      background-color: ${COLORS.BLACK_005};
      z-index: ${ZINDEX.middle};
    }
  }
`;

export const SortableItemLeft = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  white-space: nowrap;
  padding-right: ${SPACING.S24};
`;

export const ReorderButton = styled(ButtonV3)`
  height: fit-content;
  padding: 0;
  border: none;
`;

export const StyledReorderIcon = styled(ReorderIcon)`
  color: ${COLORS.BLACK_100};
`;

export const Text = styled.p`
  ${FONT.textSizeUltraCompact(FONT.SIZE.REGULAR)};
  margin: 0 0 0 ${SPACING.S24};
  color: ${COLORS.BLACK_100};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EditButton = styled(LinkV2)`
  ${FONT.textSizeUltraCompact(FONT.SIZE.SMALL)}
`;
