import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import {
  isMobile as getIsMobile,
  isDesktop as getIsDesktop,
  isTablet as getIsTablet,
} from '@zola-helpers/client/dist/es/util/responsive';
import { ResponsiveContext } from './ResponsiveContext';

interface ResponsiveProviderProps {
  isInitialMobile?: boolean;
  isInitialDesktop?: boolean;
  isInitialTablet?: boolean;
}

/**
 *  The responsive provider is used to provide the current responsive state to the app.
 *  Which avoids the need to isMobile()/isDesktop() in zola-helpers which rely on windows that are not available in SSR.
 *  ONLY use this if css media queries are not sufficient.
 */

export const ResponsiveProvider: React.FC<ResponsiveProviderProps> = ({
  isInitialMobile = false,
  isInitialDesktop = false,
  isInitialTablet = false,
  children,
}) => {
  const [isMobile, setIsMobile] = useState(isInitialMobile);
  const [isDesktop, setIsDesktop] = useState(isInitialDesktop);
  const [isTablet, setIsTablet] = useState(isInitialTablet);

  useEffect(() => {
    setIsMobile(getIsMobile());
    setIsDesktop(getIsDesktop());
    setIsTablet(getIsTablet());
    const handleResize = _debounce(() => {
      setIsMobile(getIsMobile());
      setIsDesktop(getIsDesktop());
      setIsTablet(getIsTablet());
    }, 1000);

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ResponsiveContext.Provider value={{ isMobile, isDesktop, isTablet }}>
      {children}
    </ResponsiveContext.Provider>
  );
};
