import type { WThemeDetailView, WWeddingThemeV2View } from '@zola/svc-web-api-ts-client';

export const RECEIVE_WEDDING_THEME = 'zola/weddingtheme/receive';
export const RECEIVE_WEDDING_THEME_DETAIL = 'zola/weddingthemedetail/receive';

type ReceiveWeddingThemeActionType = {
  type: typeof RECEIVE_WEDDING_THEME;
  payload: { theme: WWeddingThemeV2View };
};

type ReceiveWeddingThemeDetailActionType = {
  type: typeof RECEIVE_WEDDING_THEME_DETAIL;
  payload: { themeDetail: WThemeDetailView };
};

export type WeddingThemeActionTypes =
  | ReceiveWeddingThemeActionType
  | ReceiveWeddingThemeDetailActionType;
