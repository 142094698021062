import { createSelector } from 'reselect';
import _find from 'lodash/find';
import _has from 'lodash/has';
import _every from 'lodash/every';
import _keys from 'lodash/keys';
import _some from 'lodash/some';
import _uniq from 'lodash/uniq';

import { getLabelByCardType } from '@zola/zola-ui/src/paper/cards/constants/labelByCardType';
import { ENVELOPE_PRINTING, RETURN_ADDRESSING } from 'cards/constants/Envelopes';
import { getOptionPrice, getMaxOptionPrice, getMinOptionPrice } from 'cards/util/optionPrice';
import { CARD_TYPE_MAP, CardType } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import { isCardTypeWithProofOrders } from '@zola/zola-ui/src/paper/cards/constants/cardTypeFeatures/cardTypesWithProofOrders';
import { getTypeFromCustomization } from '@zola/zola-ui/src/paper/cards/util/customization';
import {
  CUSTOMIZATION_STEP_MAP,
  isCustomizationAddressingStep,
  isCustomizationStep,
  isCustomizationStepWithFoil,
} from '@zola/zola-ui/src/paper/cards/constants/customizationSteps';
import { RootState } from 'reducers/index';
import { Breadcrumb } from 'components/common/types';
import type {
  WCardProjectThankYouMetaView,
  WCardVariationView,
  WProjectCustomizationElementView,
} from '@zola/svc-web-api-ts-client';
import { CustomizationEntity } from 'cards/reducers/projectReducer';
import { getSelectedGuestIds } from './Customization';
import { getCards } from './cardCatalogSelector';
import { NON_STAMP_FOIL_VALUES } from '../constants/Cards';
import { STEP_DESCRIPTIONS, STEP_LABELS } from '../constants/customizationStepCopy';
import { getFoilColorLabel } from '../util/getFoilColorLabel';
import { isCustomizationActive } from '../util/isCustomizationActive';
import { getStepFromPathname } from '../util/getStepFromPathname';
import {
  getCustomizationDefaultQuantity,
  getCustomizationQuantityOptions,
} from '../util/cardQuantityHelper';

export const getProjectState = (state: RootState) => state.cards.project;
export const getProjectError = (state: RootState) => state.cards.project.error;
export const getActiveField = (state: RootState) => state.cards.project.ui.activeField;
export const getBreadcrumbs = (state: RootState) => state.cards.project.ui.breadcrumbs;
export const getCustomizations = (state: RootState) => state.cards.project.entities.customizations;
export const getCustomizationTypeUUIDs = (state: RootState) =>
  state.cards.project.customizationTypeToUUID;
export const getValidationErrorsByCustomization = (state: RootState) =>
  state.cards.project.validationErrorsByCustomization;
export const getValidationErrorsByElement = (state: RootState) =>
  state.cards.project.validationErrorsByElement;
export const getElementEntities = (state: RootState) => state.cards.project.entities.elements;
export const getFontsByCustomizationUUID = (state: RootState) =>
  state.cards.project.fontsByCustomizationUUID;
export const getIsAddingToCart = (state: RootState) => state.cards.project.isAddingToCart;
export const getPageEntities = (state: RootState) => state.cards.project.entities.pages;
export const getProjectBusy = (state: RootState) => state.cards.project.isFetching;
export const getProjectType = (state: RootState) => state.cards.project.type;
export const getProjectUUID = (state: RootState) => state.cards.project.uuid;
export const getIsProofProject = (state: RootState) => state.cards.project.isProofProject;
export const getProjectMainColor = (state: RootState) => state.cards.project.optionValues.color;
export const getProjectShape = (state: RootState) => state.cards.project.optionValues.silhouette;
export const getProjectEnvelopeLinerKey = (state: RootState) =>
  state.cards.project.optionValues['envelope-lining'];
export const getProjectIsThemColorPickerEnabled = (state: RootState) =>
  state.cards.project.hasThemeColorPicker;
export const getProjectCollaborator = (state: RootState) => state.cards.project.suiteCollaborator;
export const getProjectReversePrinting = (state: RootState) =>
  state.cards.project.optionValues['reverse-printing'];
export const getSize = (state: RootState) => state.cards.project.size;
export const getSplitOrderId = (state: RootState) => state.cards.project.splitOrderId;
export const getSteps = (state: RootState) => state.cards.project.ui.steps;
export const getOrderedSteps = (state: RootState) => state.cards.project.ui.orderedSteps;
export const getProjectMedium = (state: RootState) => state.cards.project.medium;
export const getAllProjectMeta = (state: RootState) => state.cards.project.metaByProjectUUID;
export const getProjectFoilStatus = (state: RootState) => state.cards.project.hasFoil;
export const getProjectProofModalStatus = (state: RootState) =>
  state.cards.project.hasSeenProofModal;
export const getProjectIsTestProject = (state: RootState) => state.cards.project.isTestProject;
export const getSeededCardSuite = (state: RootState) => state.cards.project.seededCardSuiteUUID;
export const getProjectInitializedCustomFoilStatus = (state: RootState) =>
  state.cards.project.initializedCustomFoil;
export const getFoilHistory = (state: RootState) => state.cards.project.foilHistory;
export const getDeleteFoilHistory = (state: RootState) => state.cards.project.deletedFoilHistory;
export const getRSVPFoilHistory = (state: RootState) => state.cards.project.rsvpFoilHistory;
export const getRSVPDeleteFoilHistory = (state: RootState) =>
  state.cards.project.rsvpDeletedFoilHistory;
export const getInitializedFoilColor = (state: RootState) =>
  state.cards.project.initializedFoilColor;
export const getEarliestArrivalDate = (state: RootState) => state.cards.project.earliestArrivalDate;
export const getHolidaysShippingDetails = (state: RootState) =>
  state.cards.project.holidaysShippingDetails;

export const getStepGroups = (state: RootState) => state.cards.project.ui.stepGroups;
export const getProjectAccountId = (state: RootState) => state.cards.project.accountId;
export const getProjectFamily = (state: RootState) => state.cards.project.family;
export const getProjectPdfRenderDetails = (state: RootState) =>
  state.cards.project.pdfRenderDetails;
export const getProjectPdfForceReviewFlag = (state: RootState) =>
  state.cards.project.forceReviewPdf;

export const getIsDisneyProject = createSelector(
  getProjectCollaborator,
  collaborator => !!(collaborator?.toLowerCase() === 'disney')
);

export const getValidationErrorsExist = createSelector(
  getValidationErrorsByCustomization,
  validationErrorsByCustomization =>
    Object.values(validationErrorsByCustomization).filter(e => !!e).length > 0
);

export const getStepKeysWithValidationErrors = createSelector(
  getValidationErrorsByCustomization,
  getValidationErrorsByElement,
  getCustomizations,
  getPageEntities,
  getOrderedSteps,
  (
    validationErrorsByCustomization,
    validationErrorsByElement,
    customizations,
    pageEntities,
    orderedSteps
  ) => {
    const stepsKeysWithValidationErrors: string[] = [];

    Object.keys(validationErrorsByCustomization).forEach(customizationUUID => {
      // Ignore the customization if the error has already been fixed
      if (!validationErrorsByCustomization[customizationUUID]) return;

      const customization = customizations[customizationUUID];
      const cardType = getTypeFromCustomization(customization);

      // Check each page for validation errors, and if any are found, add the corresponding step to the list
      (customization.pages || []).forEach(pageUUID => {
        const page = pageEntities[pageUUID];
        const pageContainsError = page.elements.some(
          elementUUID => !!validationErrorsByElement[elementUUID]
        );
        if (!pageContainsError) return;

        const correspondingStep = orderedSteps.find(
          step =>
            step.showInBreadcrumbs &&
            step.cardType === cardType &&
            step.pageNumber === page.page_number - 1
        );
        if (correspondingStep) {
          stepsKeysWithValidationErrors.push(correspondingStep.key);
        }
      });
    });

    return _uniq(stepsKeysWithValidationErrors);
  }
);

// Store meta by projectUUID to avoid issues when switching between 2 projects
export const getProjectMeta = createSelector(
  getProjectUUID,
  getAllProjectMeta,
  (projectUUID, allMeta) => (projectUUID ? allMeta?.[projectUUID] : undefined)
);

// This relies on ownProps.location to be available, so any component that calls it must be
// hooked up with react-router (i.e. withRouter HOC). Attempts to access the path through other
// router props failed as they are not updated at the same time as the actual URL (causing bugs).
// Directly using window.location.pathname also causes bugs because it changes BEFORE route components update/unmount.
// const getPathname = (_, props) => (props && props.location && props.location.pathname) || '';
// ----- UPDATE -----
// We no longer need every component to pass "location" to these selectors as explained above.
// The onEnterCustomizationStep function (in routes.jsx) now dispatches location.pathname to the card project store before rendering
export const getCurrentPathname = createSelector(
  getProjectState,
  projectState => projectState.currentPathname
);

export const getCurrentStepObject = createSelector(
  getCurrentPathname,
  getOrderedSteps,
  (pathname, orderedSteps) => getStepFromPathname(pathname, orderedSteps)
);

export const getPreviousStepObject = createSelector(
  getBreadcrumbs,
  getCurrentStepObject,
  (breadcrumbs, currentStep) => {
    let previousStep;

    if (isCustomizationAddressingStep(currentStep?.id)) {
      // For addressing steps, return the step where the addressing will apply. It shares the same card type & page number.
      previousStep = breadcrumbs.find(step => {
        return (
          step.cardType === currentStep!.cardType && step.pageNumber === currentStep!.pageNumber
        );
      });
    } else {
      // For non-addressing steps, simply return the step that preceeds it in the breadcrumbs.
      const currentIndex = breadcrumbs.findIndex(breadcrumb => breadcrumb.key === currentStep?.key);

      if (currentIndex > 0) {
        previousStep = breadcrumbs[currentIndex - 1];
      }
    }

    return previousStep || null;
  }
);

export const getPreviousStepUrl = createSelector(
  getPreviousStepObject,
  previousStep => (previousStep && previousStep.url) || ''
);

export const getNextStepObject = createSelector(
  getOrderedSteps,
  getBreadcrumbs,
  getCurrentStepObject,
  getSelectedGuestIds,
  (orderedSteps, breadcrumbs, currentStep, selectedGuestIds) => {
    let nextStep;

    if (
      isCustomizationStep(currentStep?.id) &&
      currentStep?.id === 'recipients' &&
      selectedGuestIds.length
    ) {
      // If guests are selected on the 'recipients' step, next will be the 'addressing' step that shares the same card type & page number.
      nextStep = orderedSteps.find(({ id, cardType, pageNumber }) => {
        return (
          id === CUSTOMIZATION_STEP_MAP.addressing &&
          cardType === currentStep.cardType &&
          pageNumber === currentStep.pageNumber
        );
      });
    } else if (isCustomizationAddressingStep(currentStep?.id)) {
      // For addressing steps, return the step where the addressing will apply. It shares the same card type & page number. Same as previous step.
      nextStep = breadcrumbs.find(step => {
        return (
          step.cardType === currentStep!.cardType && step.pageNumber === currentStep!.pageNumber
        );
      });
    } else {
      // For all other steps, return the next step in breadcrumbs
      const currentIndex = breadcrumbs.findIndex(breadcrumb => breadcrumb.key === currentStep?.key);
      nextStep = currentIndex > -1 ? breadcrumbs[currentIndex + 1] : null;
    }

    return nextStep || null;
  }
);

export const getNextStepUrl = createSelector(
  getNextStepObject,
  nextStep => (nextStep && nextStep.url) || ''
);

export const getCurrentStepSupportsFoil = createSelector(
  getCurrentStepObject,
  currentStep => !!currentStep?.id && isCustomizationStepWithFoil(currentStep.id)
);

export const getCardType = createSelector(
  getCurrentStepObject,
  currentStep => (currentStep && currentStep.cardType) || 'SAVE_THE_DATE'
);

export const getPageNumber = createSelector(
  getCurrentStepObject,
  currentStep => (currentStep && currentStep.pageNumber) || 0
);

type BreadcrumbGroup = {
  id: string;
  items: (Breadcrumb & { showInBreadcrumbs: true })[];
};
export const getStepGroup = createSelector(getStepGroups, getCardType, (stepGroups, cardType) =>
  stepGroups.find(group => group.cardType === cardType)
);

export const getBreadcrumbGroups = createSelector(getBreadcrumbs, breadcrumbs =>
  breadcrumbs.reduce((groups, step) => {
    const existingGroup = groups.find(g => g.id === step.breadcrumbGroup);

    if (existingGroup) {
      existingGroup.items.push(step);
    } else {
      groups.push({
        id: step.breadcrumbGroup,
        items: [step],
      });
    }

    return groups;
  }, [] as BreadcrumbGroup[])
);

export const getCustomizationUUID = createSelector(
  getCustomizationTypeUUIDs,
  getCardType,
  (customizationTypeToUUID, cardType) => customizationTypeToUUID[cardType]
);

export const getLeadCustomization = createSelector(
  getProjectType,
  getCustomizationTypeUUIDs,
  getCustomizations,
  (projectType, customizationTypeToUUIDs, customizations) => {
    const uuid = customizationTypeToUUIDs[projectType!];
    const leadCustomization = uuid ? customizations[uuid] : undefined;
    return leadCustomization;
  }
);

export const getSinglesAreProof = createSelector(getLeadCustomization, leadCustomization => {
  return leadCustomization?.card_setting?.qty_single_allowed;
});

export const getDigitalDetails = createSelector(
  getLeadCustomization,
  leadCustomization =>
    leadCustomization &&
    leadCustomization.digital_details &&
    leadCustomization.digital_details.details
);

export const getCustomizationByUUID = createSelector(
  getCustomizations,
  (_: RootState, customizationUUID: string) => customizationUUID,
  (customizations, customizationUUID) => customizations[customizationUUID]
);

export const getLeadVariationOptionValues = createSelector(getLeadCustomization, customization => {
  if (customization && customization.variation && customization.variation.option_values) {
    return customization.variation.option_values;
  }
  return null;
});

export const getLeadQuantity = createSelector(
  getLeadCustomization,
  customization => (customization && customization.quantity) || 0
);

export const getCustomization = createSelector(
  getCustomizations,
  getCustomizationUUID,
  (customizations, customizationUUID) => customizations[customizationUUID as string]
);

export const getCurrentCustomizationUnitMultiplier = createSelector(
  getCustomization,
  customization => {
    return customization?.card_setting?.qty_unit_multiplier || 1;
  }
);

export const getPage = createSelector(
  getCustomization,
  getPageEntities,
  getPageNumber,
  (customization, pageEntities, pageNumber) => {
    if (typeof pageNumber !== 'number' || !customization) return null;

    const pages = customization.pages.map(pageUUID => pageEntities[pageUUID]);
    const page = pages.find(p => {
      // Table number's both side have the same print, only show the first page
      if (customization.type === CARD_TYPE_MAP.tableNumber) {
        return p.page_number === 1;
      }

      return p.page_number === pageNumber + 1;
    });
    return page;
  }
);

export const getPageBackgroundColor = createSelector(getPage, page => page?.background_color);
export const getPageOrientation = createSelector(getPage, page => page?.orientation);
export const getPageUUID = createSelector(getPage, page => page?.uuid);
export const getPageLayoutUUID = createSelector(
  getPage,
  page => page?.card_template_layout_content_uuid
);

export const getPageElementUUIDs = createSelector(getPage, page => (page ? page.elements : []));

export const getElements = createSelector(
  getPageElementUUIDs,
  getElementEntities,
  (elementUUIDs, elementEntities) => elementUUIDs.map(elementUUID => elementEntities[elementUUID])
);

export const getElementsByUUID = createSelector(
  getPageElementUUIDs,
  getElementEntities,
  (elementUUIDs, elementEntities) => {
    const elementsByUUID: Record<string, WProjectCustomizationElementView> = {};
    elementUUIDs.forEach(elementUUID => {
      elementsByUUID[elementUUID] = elementEntities[elementUUID];
    });
    return elementsByUUID;
  }
);

export const getTextElements = createSelector(getElements, elements =>
  elements.filter(element => element.content_type === 'TEXT')
);

export const getFoiledTextElements = createSelector(getTextElements, textElements =>
  textElements.filter(
    element => element.printing_technique === 'CUSTOM_FOIL' && element.customizable
  )
);

export const getImageElements = createSelector(
  getElements,
  elements =>
    elements
      .filter(element => element.content_type === 'IMAGE')
      .sort((a, b) => (a.y - b.y === 0 ? a.x - b.x : a.y - b.y)) // Sort elements from top to bottom & left to right.
);

export const getBackgroundImageElement = createSelector(getImageElements, imageElements =>
  imageElements.find(element => element.tag === 'BACKGROUND')
);

export const getOverlayImageElements = createSelector(getImageElements, imageElements =>
  imageElements.filter(element => element.overlay)
);

export const getCustomizableImageElements = createSelector(getImageElements, imageElements =>
  imageElements.filter(element => element.customizable)
);

export const getCustomizationVariation = createSelector(
  getCustomization,
  customization => customization?.variation
);

export const getCustomizationVariationOptionValues = createSelector(
  getCustomizationVariation,
  variation => variation?.option_values
);

export const getCustomizationIsStampFoil = createSelector(
  getCustomizationVariationOptionValues,
  optionValues => !!optionValues?.foil && !NON_STAMP_FOIL_VALUES.includes(optionValues.foil)
);

export const getCustomizationNrfColor = createSelector(
  getCustomizationVariation,
  variation => variation?.nrf_color_code_label
);

export const getCustomizationSize = createSelector(
  getCustomizationVariation,
  variation => variation?.option_values?.size
);

export const getCustomizationSilhouette = createSelector(
  getCustomizationVariation,
  variation => variation?.option_values?.silhouette
);

export const getCustomizationFoilColor = createSelector(
  getCustomizationVariation,
  variation => variation?.option_values?.['foil-color'] || 'none'
);

export const getReversePrintingDisabled = createSelector(
  getCustomizationVariation,
  variation => variation?.option_values?.['reverse-printing'] === 'false' || false
);

export const getCustomizationEnvelopePrinting = createSelector(
  getCustomizationVariation,
  variation => variation?.option_values?.['envelope-printing'] || ENVELOPE_PRINTING.none
);

export const getCustomizationReturnAddressing = createSelector(
  getCustomization,
  customization => customization?.return_addressing || RETURN_ADDRESSING.none
);

export const getCustomizationRecipientAddressing = createSelector(
  getCustomization,
  customization => !!customization?.recipient_addressing
);

export const getCustomizationsWithValidOptionValues = createSelector(
  getCustomizations,
  customizationsByUUID => {
    const customizations = _keys(customizationsByUUID).map(uuid => customizationsByUUID[uuid]);
    return customizations.filter(customization => {
      if (customization && customization.variation) {
        const { medium, variation } = customization;
        const optionValueKeys = medium === 'PAPER' ? ['silhouette', 'paper-type'] : ['silhouette'];
        return _every(optionValueKeys, key => _has(variation.option_values, key));
      }
      return false;
    });
  }
);

const getRSVPCustomization = createSelector(
  getCustomizationTypeUUIDs,
  getCustomizations,
  (customizationTypeToUUID, customizations) => {
    const uuid = customizationTypeToUUID[CARD_TYPE_MAP.rsvp];
    return (uuid && customizations[uuid]) || null;
  }
);
export const getRSVPCustomizationDisabled = createSelector(
  getRSVPCustomization,
  rsvpCustomization => (rsvpCustomization && rsvpCustomization.inactive) || false
);

const getRecipientCustomization = createSelector(
  getSteps,
  getCustomizationTypeUUIDs,
  getCustomizations,
  (steps, customizationTypeToUUID, customizations) => {
    if (steps && steps.recipients && steps.recipients.cardType) {
      const uuid = customizationTypeToUUID[steps.recipients.cardType];
      return customizations[uuid as string];
    }
    return null;
  }
);
export const getIsRecipientAddressingEnabled = createSelector(
  getRecipientCustomization,
  recipientCustomization =>
    (recipientCustomization && recipientCustomization.recipient_addressing) || false
);

export const getIsCustomizationActive = createSelector(getCustomization, customization =>
  customization ? isCustomizationActive(customization) : null
);

const STEPS_WITH_CUSTOM_FOIL = [CUSTOMIZATION_STEP_MAP.detailFront, CUSTOMIZATION_STEP_MAP.rsvp];

const getDefinedOptionValue = (values: WCardVariationView['option_values'], key: string) =>
  values && values[key] && values[key] !== 'none' ? values[key] : null;

const findValidCustomization = (
  customizations: Record<string, CustomizationEntity>,
  id: string | undefined | null
): CustomizationEntity | null => {
  if (!id) return null;

  const customization = customizations[id];
  const isValid =
    customization &&
    isCustomizationActive(customization) &&
    !!customization.variation &&
    !!customization.variation.option_values;

  return isValid ? customization : null;
};

// ------------------------------
// CURRENT CARD DATA
// ------------------------------
// The current step's card catalog data (not customization data)
export const getCurrentCardData = createSelector(
  getCards,
  getCustomizationUUID,
  (cards, customizationUUID) => {
    return _find(cards, { customizationUUID }) || {};
  }
);

export const getCurrentCardOptionGroupsByKey = createSelector(
  getCurrentCardData,
  cardData => cardData.optionGroupsByKey || {}
);

export const getCurrentCardHasCustomFoilAvailable = createSelector(
  getCurrentCardOptionGroupsByKey,
  // @ts-expect-error getCards and cardCatalogSelector have not yet defined their types
  optionGroups => !!optionGroups.foil?.options.some(o => o.value === 'custom' && o.enabled)
);

type SubtotalItem = {
  customization: CustomizationEntity;
  price: number;
  description: string | null;
  label: string | null;
  customNotesMeta: WCardProjectThankYouMetaView;
  hasFoil: boolean | null;
  hasLiner: boolean;
  linerPrice: number;
  envelopePrice: number;
  envelopePrintingPrice: number;
  hasLetterpress: boolean;
  customFoilLabel: string | null | undefined;
  customFoilPrice: number;
  step: Breadcrumb;
  active: boolean;
};

export type SubtotalGroup = {
  cardType: CardType;
  hasInsidePrinting: boolean;
  items: SubtotalItem[];
  isActive: boolean;
  label: string;
  subtotal: number;
  quantity: number;
  defaultQuantity: number;
  isLeadCustomization: boolean;
};

export const getSubtotalSummary = createSelector(
  getCustomizations,
  getCustomizationTypeUUIDs,
  getStepGroups,
  getCurrentStepObject,
  getProjectType,
  getProjectMeta,
  getLeadCustomization,
  getProjectState,
  getCards,
  (
    customizations,
    customizationTypeToUUIDs,
    stepGroups,
    currentStep,
    projectType,
    projectMeta,
    leadCustomization,
    projectState,
    cardCatalogData
  ) => {
    const thankYouMeta = projectMeta && projectMeta.thank_you;
    const insidePrice =
      thankYouMeta && thankYouMeta.printed_extra_price_cents
        ? thankYouMeta.printed_extra_price_cents / 100
        : 0;
    const insideQuantity = thankYouMeta ? thankYouMeta.printed || 0 : 0;
    const isStampFoilCard = projectState.hasFoil;
    const cardCustomizations = projectState.customizationTypeToUUID.INVITATION_ENVELOPE;
    const cardVariations = _find(cardCatalogData, { customizationUUID: cardCustomizations });

    // Determine the price difference for colored (luxe) envelopes
    const envelopesWithColorOptionsOnly = cardVariations?.variations?.filter(
      ({ option_values }: { option_values: Record<string, string> }) =>
        option_values.color !== 'ffffff' &&
        option_values['envelope-lining'] === 'none' &&
        option_values['envelope-printing'] !== ENVELOPE_PRINTING.both
    );
    const envelopeColorPrice: number = getMaxOptionPrice(envelopesWithColorOptionsOnly) / 100;

    // Determine the price difference for envelope liners
    const envelopesWithLinerOptionsOnly = cardVariations?.variations.filter(
      ({ option_values }: { option_values: Record<string, string> }) =>
        option_values.color === 'ffffff' &&
        option_values['envelope-lining'] !== 'none' &&
        option_values['envelope-printing'] !== ENVELOPE_PRINTING.both
    );
    const envelopeLinerPrice: number = getMaxOptionPrice(envelopesWithLinerOptionsOnly) / 100;

    const envelopesWithBackPrintingOptionOnly = cardVariations?.variations.filter(
      ({ option_values }: { option_values: Record<string, string> }) =>
        option_values.color === 'ffffff' &&
        option_values['envelope-lining'] === 'none' &&
        option_values['envelope-printing'] === ENVELOPE_PRINTING.both
    );
    const envelopeBackPrintingPrice = getMaxOptionPrice(envelopesWithBackPrintingOptionOnly) / 100;

    const allGroups: SubtotalGroup[] = stepGroups.map(stepGroup => {
      const { cardType, steps } = stepGroup;
      const items: SubtotalItem[] = [];
      const isLeadCustomization = cardType === leadCustomization!.type;
      steps.forEach(step => {
        const customization = findValidCustomization(
          customizations,
          customizationTypeToUUIDs[step.cardType]
        );

        if (customization) {
          const {
            variation,
            uuid: customizationUUID,
            custom_notes_enabled: customNotesEnabled,
          } = customization;
          const options = variation?.option_values || {};
          const isReversePrintingEnabled = options['reverse-printing'] === 'true';
          const isPostcard = options.size === 'postcard';
          const isChangeTheDate = cardType === 'CHANGE_THE_DATE';
          const customFoilColor = (STEPS_WITH_CUSTOM_FOIL as string[]).includes(step.id)
            ? getDefinedOptionValue(options, 'foil-color')
            : null;

          // Postcards and CTDs include free reverse printing. Other cards charge for it when enabled.
          const shouldChargeReversePrinting =
            isReversePrintingEnabled && !isPostcard && !isChangeTheDate;
          const availableOptions = _find(cardCatalogData, { customizationUUID })?.optionGroupsByKey;
          const backPrice = getOptionPrice(availableOptions, 'reverse-printing', 'true') / 100;

          let price = variation?.price_cents ? variation.price_cents / 100 : 0;
          let description = STEP_DESCRIPTIONS[step.id] || '';
          let label = STEP_LABELS[step.id] || '';

          if (step.id === 'detailFront' && shouldChargeReversePrinting) {
            // When charging for reverse printing, we subtract its cost from the front's price.
            price -= backPrice;
          } else if (step.id === 'detailBack') {
            price = shouldChargeReversePrinting ? backPrice : 0;
            description =
              (!isReversePrintingEnabled && 'No print on the back') ||
              (isPostcard && 'Print on the back (required)') ||
              description;
          } else if (step.id === 'detailInside') {
            // * Blanks require no aditional cost
            // * Custom notes cost is displayed on the following step
            price = 0;
            description = customNotesEnabled
              ? 'Mix blank + printed messages inside'
              : 'All blank inside';
          } else if (step.id === 'detailWrite') {
            price = insidePrice;
            description = insideQuantity
              ? `<strong>${insideQuantity}</strong> printed message(s)`
              : description;
          } else if (step.id === 'recipients') {
            price = 0;

            // Holiday cards shouldn't mention "guests" like other cards
            if (projectType === 'HOLIDAY') {
              label = 'Recipients';
              description = 'Add addressing';
            }
          } else if (
            step.id === CUSTOMIZATION_STEP_MAP.detailAssign &&
            projectType === CARD_TYPE_MAP.place
          ) {
            price = 0;
          }

          items.push({
            customization,
            price,
            description,
            label,
            customNotesMeta: thankYouMeta || {},
            hasFoil: isStampFoilCard,
            hasLiner: !!getDefinedOptionValue(options, 'envelope-lining'),
            linerPrice: envelopeLinerPrice,
            envelopePrice: envelopeColorPrice,
            envelopePrintingPrice: envelopeBackPrintingPrice,
            hasLetterpress: options['printing-type'] === 'letterpress',
            customFoilLabel: (customFoilColor && getFoilColorLabel(customFoilColor)) || null,
            customFoilPrice: getOptionPrice(availableOptions, 'foil-color', customFoilColor) / 100,
            step,
            active: currentStep?.id === step.id,
          });
        }
      });

      const groupLeadItem = items[0] || {};
      const hasInsidePrinting = items.some(
        item => item.step.id === CUSTOMIZATION_STEP_MAP.detailInside
      );

      // All group-specific quantity logic
      const groupQuantity = groupLeadItem.customization?.quantity || 0;

      // All group-specific pricing logic
      const pricePerUnit = items.reduce((total, b) => total + b.price, 0);
      const groupSubtotal = hasInsidePrinting
        ? (pricePerUnit - insidePrice) * groupQuantity + insidePrice * insideQuantity
        : pricePerUnit * groupQuantity;

      const group: SubtotalGroup = {
        cardType,
        hasInsidePrinting,
        items,
        isActive: items.some(item => item.active),
        label: getLabelByCardType(cardType),
        subtotal: groupSubtotal,
        quantity: groupQuantity,
        defaultQuantity: getCustomizationDefaultQuantity(groupLeadItem.customization?.card_setting),
        isLeadCustomization,
      };

      return group;
    }, [] as SubtotalGroup[]);

    const groups = allGroups.filter(group => group.items.length > 0); // Remove all groups with no items
    const subtotal = groups.reduce((total, group) => total + group.subtotal, 0); // Add together all group subtotals

    return { groups, subtotal };
  }
);

export const getProjectQuantityData = createSelector(
  getSubtotalSummary,
  getProjectMeta,
  (summary, projectMeta) => {
    return summary.groups.reduce((acc, summaryGroup) => {
      const { cardType, items, quantity, hasInsidePrinting } = summaryGroup;
      const groupLeadItem = items[0];
      const customizationCardSettings = groupLeadItem.customization.card_setting;
      const quantityOptions = getCustomizationQuantityOptions(customizationCardSettings);
      const defaultQuantity = getCustomizationDefaultQuantity(customizationCardSettings);

      // When custom notes are present, the BE overrides projectMeta.minimum_quantity if the number of customized notes is over the "real" minimum.
      // This is done to avoid having to delete custom notes if the user selected a quantity lower than their # of notes.
      // Disable any customization quantity options below the current minumum.
      const currentMin = projectMeta?.minimum_quantity || 0;
      const nbPrintedNotes = projectMeta?.thank_you?.printed || 0;
      const disabledOptions =
        hasInsidePrinting && nbPrintedNotes > 0
          ? quantityOptions.filter(o => o.value < currentMin).map(o => o.value)
          : [];

      return {
        ...acc,
        [cardType]: {
          cardType,
          quantity,
          unitMultiplier: customizationCardSettings?.qty_unit_multiplier || 1,
          defaultQuantity,
          quantityOptions,
          disabledOptions,
        },
      };
    }, {} as Record<CardType, { cardType: CardType; quantity: number | undefined; defaultQuantity: number; quantityOptions: { label: string; value: unknown }[]; disabledOptions: number[] }>);
  }
);

export const getCurrentGroupQuantityData = createSelector(
  getSubtotalSummary,
  getProjectQuantityData,
  (summary, projectQuantityData) => {
    const currentGroup = summary.groups.find(group => group.isActive);
    return (currentGroup && projectQuantityData[currentGroup.cardType]) || null;
  }
);

export const getSubtotalSummaryItems = createSelector(getSubtotalSummary, summary => {
  return summary.groups.reduce(
    (acc, summaryGroup) => [...acc, ...summaryGroup.items],
    [] as SubtotalItem[]
  );
});

export const getCustomizationFoilColorOptionGroup = createSelector(
  getCurrentCardData,
  cardData => cardData?.optionGroupsByKey?.['foil-color'] || {}
);

export const getCustomizationNapkinColorOptionGroup = createSelector(
  getCurrentCardData,
  cardData => cardData?.optionGroupsByKey?.['napkin-color'] || {}
);

export const getCardColorsForCustomization = createSelector(
  getCurrentCardData,
  cardData => (cardData && cardData.optionGroupsByKey && cardData.optionGroupsByKey.color) || {}
);

export const getCardLinerColorsForCustomization = createSelector(
  getCurrentCardData,
  cardData =>
    (cardData && cardData.optionGroupsByKey && cardData.optionGroupsByKey['envelope-lining']) || {}
);

export const getEnvelopePriceForCustomization = createSelector(getCurrentCardData, cardData => {
  const colouredEnvelopeVariations = cardData?.variations?.filter(
    // @ts-expect-error - getCurrentCardData has not defined its type
    variation =>
      variation.option_values['envelope-lining'] === 'none' &&
      variation.price_cents !== 0 &&
      variation.option_values['envelope-printing'] === 'none'
  );
  return getMinOptionPrice(colouredEnvelopeVariations);
});

export const getEnvelopeLinerPriceForCustomization = createSelector(
  getCurrentCardData,
  cardData => {
    const noColouredEnvelopeVariations =
      cardData?.variations?.length &&
      cardData.variations.filter(
        // @ts-expect-error - getCurrentCardData has not defined its type
        variation =>
          variation.option_values['envelope-lining'] !== 'none' &&
          variation.price_cents !== 0 &&
          variation.option_values['envelope-printing'] === 'none'
      );
    return getMinOptionPrice(noColouredEnvelopeVariations);
  }
);

/**
 * Hacky way of checking if user has a proof order on cart
 * check for same name of product and if it's single item
 * @param {*} state
 * @param {*} props
 */
const proofCheckoutState = (
  state: RootState,
  props: {
    projectType: string;
    name: string;
    medium: string;
    hasFoil: boolean;
    hasCustomNotes: boolean;
  }
) => {
  const { cart } = state;
  const { projectType, name, medium, hasFoil, hasCustomNotes } = props;
  const checkoutState = {
    cardTypeEnabledForProof:
      medium === 'MAGNET' || hasFoil || hasCustomNotes
        ? false
        : isCardTypeWithProofOrders(projectType),
    hasMultipleOfSameCard: false,
    hasOneOfSameCard: false,
  };
  if (cart) {
    const { items, busy, size } = cart;
    if (items && size && size > 0 && name && !busy) {
      Object.assign(checkoutState, {
        hasOneOfSameCard: _some(cart.items, { name, quantity: 1 }),
        hasMultipleOfSameCard: _some(cart.items, { name }),
      });
    }
  }
  return checkoutState;
};

export const makeGetproofCheckoutState = () => {
  const getProofCheckoutState = createSelector(
    [proofCheckoutState],
    checkoutState => checkoutState
  );
  return getProofCheckoutState;
};

export const getImageSizeRequirements = createSelector(
  getProjectState,
  projectState => projectState.imageSizeRequirements
);
