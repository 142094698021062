export const getReferrer = prevRoute => {
  // If the specified previous route is falsy, fall back on the document referrer
  if (!prevRoute) {
    return document.referrer;
  }

  // If the previous route is already a URL, use it as-is
  if (typeof prevRoute === 'string') {
    return prevRoute;
  }

  // Otherwise, the previous route is likely a location object. Construct a URL from it.
  const protocol = window && window.location && window.location.protocol;
  const hostname = window && window.location && window.location.hostname;
  return `${protocol}//${hostname}${prevRoute.pathname || ''}${prevRoute.search || ''}`;
};
