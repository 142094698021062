import styled from '@emotion/styled';

import { SPACING } from '@zola/zola-ui/src/styles/emotion';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { DrawerFooter } from 'components/common/zolaUI/Drawer';

export const StyledDrawerFooter = styled(DrawerFooter)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const StyledCancelButton = styled(ButtonV3)`
  margin: 0 ${SPACING.S16} 0 auto;
`;
