import React from 'react';

import { PencilIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Pencil';

import { DrawerTitle } from 'components/common/zolaUI/Drawer';
import { ItemType, TitleFlex, EditLink } from '../BudgetItemView.styles';

type BudgetItemViewTitleProps = {
  type?: string;
  title?: string;
  itemUuid: string;
  handleStashChangesAndChangeRoute: (path: string) => void;
};

export const BudgetItemViewTitle = ({
  type,
  title,
  itemUuid,
  handleStashChangesAndChangeRoute,
}: BudgetItemViewTitleProps) => (
  <>
    {type && <ItemType>{type}</ItemType>}
    <DrawerTitle>
      <TitleFlex>
        {title}
        <EditLink
          role="button"
          sizes="small"
          onClick={() => handleStashChangesAndChangeRoute(`/item/${itemUuid}/edit`)}
        >
          <PencilIcon height={20} width={20} showTitle={false} />
        </EditLink>
      </TitleFlex>
    </DrawerTitle>
  </>
);
