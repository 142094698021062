import type { AnalyticsProduct, WClientUserAccountView } from '@zola/svc-web-api-ts-client';
import {
  trackEvent,
  trackIdentifyUser,
} from '@zola-helpers/client/dist/es/tracking/trackingHelper';

import {
  trackFlowCompleted,
  trackFlowStepCompleted,
  trackFlowStarted,
  FlowStepEventType,
} from '@zola-helpers/client/dist/es/tracking/flow/flowEvents';

import { getAllStorage, setCookie, getCookie, deleteCookie } from './storage';

export { trackIdentifyUser };

export const customEventNames = {
  REGISTRY_ITEM_ADDED: 'Registry Item Added',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_VIEWED: 'Product Viewed',
  PRODUCT_LIST_VIEWED: 'Product List Viewed',
  PRODUCT_LIST_FILTERED: 'Product List Filtered',
};

// Tracking  Utility Functions - These function use data from the Analytics cache.
// https://github.com/NewAmsterdamLabs/data-infrastructure/blob/development/segment2redis/README.md

export function hasOnboardedForInvitationsProduct(
  userContext: WClientUserAccountView,
  invitesProjectType: string
): boolean {
  const productsOnboarded =
    userContext && userContext.analytics && userContext.analytics.products_onboarded;
  if (!productsOnboarded || !invitesProjectType) {
    return false;
  }
  return (
    productsOnboarded.filter(
      (product: AnalyticsProduct) => product.component === invitesProjectType
    ).length > 0
  );
}

type Draft = { primary_customization: { type: string } };

export function hasPreviousDraftsForProjectType(
  drafts: Draft[],
  invitesProjectType: string
): boolean {
  if (drafts.length > 0) {
    return (
      drafts.filter(
        (draft: Draft) =>
          draft.primary_customization && draft.primary_customization.type === invitesProjectType
      ).length > 0
    );
  }
  return false;
}

export function userCreatedAfter(testBeganAtStr: string | number | Date): boolean {
  const testBeganAt = new Date(testBeganAtStr).getTime();
  const userCreatedAtValue = getAllStorage('zolaUserCreatedAt');
  const userCreatedAfterTestBegan = userCreatedAtValue
    ? new Date(userCreatedAtValue).getTime() > testBeganAt
    : false;
  return userCreatedAfterTestBegan;
}

export function hasOnboardedForProduct(
  userContext: WClientUserAccountView,
  businessUnitComponent: string
): boolean {
  const productsOnboarded =
    userContext && userContext.analytics && userContext.analytics.products_onboarded;
  if (!productsOnboarded) {
    return false;
  }
  return (
    productsOnboarded.filter(
      (product: AnalyticsProduct) => product.component === businessUnitComponent
    ).length > 0
  );
}
/*
  'Product Added': https://docs.google.com/spreadsheets/d/1He7KAgK_ryUca8TJgRAnowKXeFWxqq1csWZWZdyR3dc/edit#gid=164175519
*/
export function trackProductAdded(eventData: Record<string, unknown>): void {
  if (!eventData) return;
  // if the brand is not defined - clear it on the GTM data layer becuase we check for it to determine if the product added is a cash gift.
  if (!eventData.brand && window.dataLayer && window.dataLayer.push) {
    window.dataLayer.push({
      brand: undefined,
    });
  }
  trackEvent('Product Added', eventData);
}

export type TrackCTARecommendationData = {
  recommendation_key?: string;
  recommendation_name?: string;
  recommendation_copy?: string;
  location?: string;
  section?: string;
  variationKey?: string;
};

export type TrackCtaRecommendationActions = 'Clicked' | 'Viewed';

export function trackRecommendation(
  action: TrackCtaRecommendationActions,
  recommendationData: TrackCTARecommendationData
): void {
  trackEvent(`CTA Recommendation ${action}`, recommendationData);
}

export function trackGLOnboardSatrted(): void {
  trackEvent('Onboarding Started', {
    business_unit: 'WEDDINGS',
    component: 'WEDDING_GUEST_LIST',
  });
}

export function trackGLOnboardCompleted(): void {
  trackEvent('Onboarding Completed', {
    business_unit: 'WEDDINGS',
    component: 'WEDDING_GUEST_LIST',
  });
}

export function trackGLOnboardStepCompleted(eventData: Record<string, unknown>): void {
  trackEvent('Onboarding Step Completed', {
    business_unit: 'WEDDINGS',
    component: 'WEDDING_GUEST_LIST',
    ...eventData,
  });
}

export function trackUploadSpreadsheetStarted(flowRunId: string): void {
  if (flowRunId) {
    trackFlowStarted({
      flow_name: 'Upload Spreadsheet Flow',
      flow_version: 1,
      flow_run_id: flowRunId,
      flow_step_count: 5,
    });
  }
}

export function trackUploadSpreadsheetCompleted(
  flowRunId: string,
  updateFlowRunId?: () => void
): void {
  if (flowRunId) {
    trackFlowCompleted({
      flow_name: 'Upload Spreadsheet Flow',
      flow_version: 1,
      flow_run_id: flowRunId,
      flow_step_count: 5,
    });

    if (updateFlowRunId) {
      updateFlowRunId();
    }
  }
}

export function trackUploadSpreadsheetStepCompleted(
  eventData: Omit<FlowStepEventType, 'flow_name' | 'flow_version' | 'flow_run_id'>,
  flowRunId: string
): void {
  if (flowRunId) {
    trackFlowStepCompleted({
      flow_name: 'Upload Spreadsheet Flow',
      flow_version: 1,
      flow_run_id: flowRunId,
      ...eventData,
    });
  }
}

export const trackUpdateGuestListStep = (stepValueType: string, flowRunId: string): void => {
  if (flowRunId) {
    trackUploadSpreadsheetStepCompleted(
      {
        step_name: 'Update Guest List',
        step_number: 3,
        step_value_type: stepValueType,
      },
      flowRunId
    );
  }
};

export function trackLoadMorePLP(): void {
  trackEvent('CTA Clicked', {
    business_unit: 'INVITATIONS',
    cta_type: 'SHOW_MORE',
  });
}

const ZOOM_FLOW_VERSION = 1;

export function trackZoomSetupFlow(
  eventName: 'Flow Started' | 'Flow Completed',
  payload: {
    flow_step_count: number;
  }
): void;

export function trackZoomSetupFlow(
  eventName: 'Flow Step Completed',
  payload: {
    step_name: 'Set Up Link' | 'Zoom Authentication';
    step_number: number;
    step_value_type: 'Own Link' | 'Create Link' | 'success';
  }
): void;

export function trackZoomSetupFlow(
  eventName: 'Flow Started' | 'Flow Completed' | 'Flow Step Completed',
  payload:
    | {
        flow_step_count: number;
      }
    | {
        step_name: 'Set Up Link' | 'Zoom Authentication';
        step_number: number;
        step_value_type: 'Own Link' | 'Create Link' | 'success';
      }
): void {
  const flowRunId = getCookie('zoomSetupFlow');

  if (eventName === 'Flow Started') setCookie('zoomSetupFlow', `zoom_${Date.now().toString()}`, 1);
  if (eventName === 'Flow Completed') deleteCookie('zoomSetupFlow');
  if (flowRunId || eventName === 'Flow Started') {
    trackEvent(eventName, {
      ...payload,
      flow_version: ZOOM_FLOW_VERSION,
      flow_name: 'Zoom Setup Flow',
      ...(flowRunId && { flow_run_id: flowRunId }),
    });
  }
}

export const trackZoomCtaClick = (payload: Record<string, unknown>): void =>
  trackEvent('CTA Recommendation Clicked', {
    ...payload,
    recommendation_key: 'virtual_hub_cta',
  });

export const trackProductListFiltered = (payload: Record<string, unknown>): void => {
  const eventData = {
    category: 'Registry',
    filters: [],
    sorts: [],
  };
  trackEvent(customEventNames.PRODUCT_LIST_FILTERED, {
    ...eventData,
    ...payload,
  });
};
