import styled from '@emotion/styled';
import { CheckboxField } from '@zola/zola-ui/src/components/Form/CheckboxField';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import ModalV2 from '@zola/zola-ui/src/components/Modal/ModalV2';
import { ModalDialog } from '@zola/zola-ui/src/components/Modal/ModalV2.styles';
import SkeletonLoader from '@zola/zola-ui/src/components/SkeletonLoader';
import StarRatings from '@zola/zola-ui/src/components/StarRatings/StarRatings';

import { COLORS3, SPACING } from '@zola/zola-ui/src/styles/emotion';
import { Title2 } from '@zola/zola-ui/src/typography/Headings';
import { BodyBase } from '@zola/zola-ui/src/typography/Paragraphs';
import FormTextarea from '@zola/zola-ui/src/components/Form/TextareaFieldV2/TextareaFieldV2Adapter';
import { FormCheckbox } from 'components/common/form/FinalFormV2Elements';
import FinalFormDatePickerV2 from '@zola/zola-ui/src/components/Form/DatePickerInputV2/FinalFormDatePickerV2';
import { StyledDayPicker } from '@zola/zola-ui/src/components/Form/DatePickerInputV2/DatePickerInputV2/DatePickerInputV2.styles';
import {
  InputFieldContainer,
  OptionalText,
} from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3/InputFieldV3.styles';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

export const Content = styled.div<{ extraPaddingBottom: boolean }>`
  width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding: ${SPACING.LG} ${SPACING.S24}
    ${({ extraPaddingBottom }) => extraPaddingBottom && `calc(130px + ${SPACING.S16})`};

  ${MEDIA_QUERY_V2.DESKTOP} {
    max-height: 740px;
    width: 740px;
    padding: 56px ${SPACING.S48}
      ${({ extraPaddingBottom }) => extraPaddingBottom && `calc(130px + ${SPACING.S16})`};
  }
`;

export const Ctas = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: ${SPACING.S16};
  margin-top: ${SPACING.MD};
  padding: ${SPACING.S16} ${SPACING.S48};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${COLORS3.BLACK_030};
  background-color: ${COLORS3.WHITE_100};
  border-radius: 0 0 ${SPACING.S16} ${SPACING.S16};

  ${MEDIA_QUERY_V2.DESKTOP} {
    position: absolute;
  }
`;

export const StyledModalV2 = styled(ModalV2)`
  ${MEDIA_QUERY_V2.DESKTOP} {
    ${ModalDialog} {
      overflow-y: hidden;
    }
  }
`;

export const VendorsList = styled.div`
  padding-top: ${SPACING.S16};
  display: flex;
  flex-direction: column;
  gap: ${SPACING.S16};
`;

export const VendorCard = styled.div<{ allowInquiries: boolean }>`
  display: flex;
  gap: ${SPACING.S16};
  padding: ${SPACING.S16};
  border: 1px solid ${COLORS3.BLACK_010};
  border-radius: ${SPACING.S16};
  ${({ allowInquiries }) => allowInquiries && 'cursor: pointer;'}

  &:hover {
    border-color: ${COLORS3.BLACK_030};
  }

  ${MEDIA_QUERY_V2.DESKTOP} {
    align-items: center;
  }
`;

export const VendorCardImg = styled.img`
  width: 128px;
  height: 96px;
  object-fit: cover;
  border-radius: ${SPACING.S16};
`;

export const VendorCardDetails = styled.div``;

export const StarsAndLocation = styled.div`
  ${MEDIA_QUERY_V2.DESKTOP} {
    display: flex;
    gap: ${SPACING.XS};
  }
`;

export const Dot = styled.span`
  color: ${COLORS3.BLACK_030};
  padding: 0 ${SPACING.XXS};
  font-size: 9px; // makes the dot 3px/3px
`;

export const StyledSkeletonLoader = styled(SkeletonLoader)`
  height: 128px;

  & + & {
    margin-top: ${SPACING.S16};
  }
`;

export const StyledCheckboxField = styled(CheckboxField)`
  margin-left: auto;
`;

export const StyledStarRatings = styled(StarRatings)`
  .star-ratings__review-count {
    color: ${COLORS3.BLACK_050};
  }
`;

export const StyledBodyBase = styled(BodyBase)`
  color: ${COLORS3.BLACK_075};
  margin-bottom: ${SPACING.S24};
`;

export const BackBtn = styled(LinkV2)`
  margin-bottom: ${SPACING.S16};
`;

export const StyledFormTitle = styled(Title2)`
  margin-bottom: ${SPACING.S8};
`;

export const FormFields = styled.div<{ isSubmitting: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.S16};
  margin-bottom: ${SPACING.LG};

  ${({ isSubmitting }) => isSubmitting && 'pointer-events: none; filter: blur(3px);'}
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.S16};

  ${MEDIA_QUERY_V2.DESKTOP} {
    gap: ${SPACING.S24};
    align-items: center;
    flex-direction: row;
  }
`;

export const StyledFormTextarea = styled(FormTextarea)`
  min-height: 136px;
`;

export const StyledFormCheckbox = styled(FormCheckbox)`
  ${MEDIA_QUERY_V2.DESKTOP} {
    margin-top: 28px;
  }
`;

export const StyledFormDatePicker = styled(FinalFormDatePickerV2)`
  ${StyledDayPicker} {
    top: -355px;
  }
  ${InputFieldContainer}[data-state="error"] {
    ${StyledDayPicker} {
      top: -375px;
    }
  }
  ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
    ${OptionalText} {
      position: absolute;
      margin-left: ${SPACING.XXS};
    }
  }
  ${MEDIA_QUERY_V2.MOBILE} {
    ${StyledDayPicker} {
      bottom: 53px;
    }
    ${InputFieldContainer}[data-state="error"] {
      ${StyledDayPicker} {
        bottom: 74px;
      }
    }
  }
`;

export const NoResults = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${SPACING.LG};
  max-width: 550px;
  margin: 0 auto;
`;
