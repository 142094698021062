import React, { useMemo, useState } from 'react';

import type { RouteComponentProps } from 'react-router';
import type {
  AddBudgetItemRequest,
  UpdateBudgetItemRequest,
  WBudgetItemView,
} from '@zola/svc-web-api-ts-client';

import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import DropdownV3 from '@zola/zola-ui/src/components/Form/dropdownV3/DropdownV3';
import RadioFieldV2 from '@zola/zola-ui/src/components/Form/RadioFieldV2/RadioField/RadioFieldV2';
import { BodyBase } from '@zola/zola-ui/src/typography/Paragraphs';

import { notDesktopV2 } from '@zola-helpers/client/dist/es/util/responsive';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { useAppDispatch } from 'reducers/useAppDispatch';

import { DrawerTitle } from 'components/common/zolaUI/Drawer';
import {
  addBudgetItem,
  updateBudgetItem,
  deleteBudgetItem,
  getBudgetByAccountId,
} from '../util/api';
import { useBudgetDrawerLayer } from '../util/useBudgetDrawerLayer';
import {
  mapItemTypesToDropdownV3Values,
  mapTaxonomyNodesToDropdownV3Values,
  mapItemToUpdateItemRequest,
  getTaxonomyNodeUuidFromPrevPathname,
} from '../util/mappers';
import { BUDGET_BASE_ROUTE } from '../routes/constants';
import { useLeaveBudgetRoute } from '../routes/useLeaveBudgetRoute';
import { handleBudgetRouteChange } from '../routes/handleBudgetRouteChange';

import { getBudgetItemByUuid, useBudgetContext, AddOrUpdateBudgetItemRequest } from '../context';

import { BudgetConfirmDeleteMiniModal } from '../BudgetConfirmDeleteMiniModal/BudgetConfirmDeleteMiniModal';
import BudgetDeleteEntityButton from '../BudgetDeleteEntityButton/BudgetDeleteEntityButton';
import {
  FormElementsContainer,
  DisabledHelperText,
  StyledBodyBase,
  GroupContainer,
} from './BudgetItemAddOrEdit.styles';

type RouteParams = {
  itemUuid?: string;
};

export type BudgetItemAddOrEditProps = RouteComponentProps<RouteParams, RouteParams> & {
  className?: string;
};

export const BudgetItemAddOrEdit = ({
  className,
  params: { itemUuid },
  location: { pathname, state = {} },
  route,
  router: { setRouteLeaveHook },
}: BudgetItemAddOrEditProps): JSX.Element | null => {
  useLeaveBudgetRoute(route, setRouteLeaveHook);
  const dispatch = useAppDispatch();
  const { previousPathname } = state;
  const isEdit = !!itemUuid;

  // context values needed
  const {
    actions: { receiveBudgetItem, receiveDeletedBudgetItem, receiveBudget },
    state: {
      budget: { taxonomy_nodes = [] },
      item_types = [],
    },
    isBudgetRevamp,
  } = useBudgetContext();
  const isOnlyGroup = taxonomy_nodes.length === 1;

  // Form values data initializer
  const item = isEdit ? getBudgetItemByUuid(itemUuid, taxonomy_nodes) : undefined;
  const [localItem, setLocalItem] = useState<
    Pick<AddOrUpdateBudgetItemRequest, 'title' | 'taxonomy_node_uuid' | 'item_type'>
  >({
    title: item?.title || '',
    taxonomy_node_uuid:
      item?.taxonomy_node_uuid ||
      (isOnlyGroup ? taxonomy_nodes[0].uuid : undefined) ||
      getTaxonomyNodeUuidFromPrevPathname(previousPathname),
    item_type: (item?.item_type as unknown) as string,
  });
  const { title, item_type, taxonomy_node_uuid } = localItem;
  const itemTypeOptions = useMemo(() => mapItemTypesToDropdownV3Values(item_types), [item_types]);
  const groupOptions = useMemo(() => mapTaxonomyNodesToDropdownV3Values(taxonomy_nodes), [
    taxonomy_nodes,
  ]);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // form event handlers
  const handleTitleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    setHasUnsavedChanges(true);
    setLocalItem({ ...localItem, title: e.target.value });
  };

  const handleGroupChange = (value: string) => {
    setHasUnsavedChanges(true);
    setLocalItem({ ...localItem, taxonomy_node_uuid: value });
  };

  const handleTypeChange = (value: string) => {
    setHasUnsavedChanges(true);
    setLocalItem({ ...localItem, item_type: value });
  };

  // API handlers
  const handleDelete = () => {
    setIsSubmitting(true);
    setHasUnsavedChanges(false);
    deleteBudgetItem(itemUuid as string)
      .then(() => {
        receiveDeletedBudgetItem(itemUuid as string);
        dispatch(
          toastsActions.positive({
            headline: 'Deleted',
          })
        );
        setShowDeletePrompt(false);
      })
      // update the budget summary because deleted item may have had payments attached
      .then(getBudgetByAccountId)
      .then(res => {
        receiveBudget(res);
        handleBudgetRouteChange(previousPathname, { preventIfUnsaved: false });
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(
          toastsActions.negative({
            headline: 'There was an error deleting your budget item.',
          })
        );
      });
  };

  const handleCancel = () => {
    // Prevent going to previous layer if there are unsaved changes
    handleBudgetRouteChange(previousPathname || (isEdit ? `/item/${itemUuid}` : undefined), {
      preventIfUnsaved: hasUnsavedChanges,
      previousPathname: pathname,
    });
  };

  const handleSave = () => {
    setIsSubmitting(true);
    const itemTypeFinal = (item_type as unknown) as
      | UpdateBudgetItemRequest.ItemTypeEnum
      | AddBudgetItemRequest.ItemTypeEnum;
    const request = {
      title: title as string,
      item_type: itemTypeFinal,
      taxonomy_node_uuid,
    };

    (isEdit
      ? updateBudgetItem({
          ...mapItemToUpdateItemRequest(item as WBudgetItemView, isBudgetRevamp),
          ...request,
        })
      : addBudgetItem({
          [isBudgetRevamp ? 'actual_cost_cents' : 'cost_cents']: 0,
          ...(isBudgetRevamp && { estimated_cost_cents: 0 }),
          ...request,
        })
    )
      .then(res => {
        receiveBudgetItem(res);
        setLocalItem({
          title: res.title || '',
          item_type: (res.item_type as string | undefined) || '',
          taxonomy_node_uuid: res.taxonomy_node_uuid,
        });
        dispatch(
          toastsActions.positive({
            headline: isEdit ? 'Updated' : `${res.title} has been added to your budget`,
          })
        );
        setHasUnsavedChanges(false);
        handleBudgetRouteChange(previousPathname || `/item/${res.uuid}`, {
          preventIfUnsaved: false,
        });
      })
      // Update the budget summary
      .then(getBudgetByAccountId)
      .then(res => {
        receiveBudget(res);
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(
          toastsActions.negative({
            headline: `There was an error ${isEdit ? 'updating' : 'creating'} your budget item.`,
          })
        );
      });
  };

  useBudgetDrawerLayer(
    {
      key: pathname,
      useClose: previousPathname === BUDGET_BASE_ROUTE,
      cancelButtonDisabled: isSubmitting,
      saveButtonDisabled: !title || title.length > 50 || isSubmitting || !taxonomy_node_uuid,
      onSave: handleSave,
      onBack: handleCancel,
      onUnsavedChanges: hasUnsavedChanges
        ? () => {
            handleBudgetRouteChange(`/item/${isEdit ? `${itemUuid}/edit` : 'new'}`);
          }
        : undefined,
    },
    isSubmitting,
    [localItem]
  );

  if (isEdit && !item) {
    handleBudgetRouteChange();
    return null;
  }

  return (
    <div data-testid="BudgetItemAddOrEdit" className={className}>
      <DrawerTitle>{isEdit ? 'Edit' : 'New'} budget item</DrawerTitle>
      <FormElementsContainer>
        <InputFieldV3
          name="title"
          label="Name"
          maxChars={50}
          placeholder="Enter name"
          onChange={handleTitleChange}
          value={title}
          isRequired
        />
        <DropdownV3
          id="item_type"
          name="item_type"
          options={itemTypeOptions}
          placeholder="Select type"
          label="This item is our..."
          value={item_type}
          onSelect={handleTypeChange}
          disabled={!!item?.account_vendor}
          isNativeDropdown={notDesktopV2()}
        />
        {!!item?.account_vendor && (
          <DisabledHelperText>
            Remove the booked vendor from this item to change the type.
          </DisabledHelperText>
        )}
        {isBudgetRevamp ? (
          <GroupContainer isOnlyGroup={isOnlyGroup}>
            <BodyBase>Group</BodyBase>
            {taxonomy_nodes.map(node => (
              <RadioFieldV2
                key={`taxonomy-node-${node.uuid}`}
                id={node.uuid as string}
                label={<StyledBodyBase>{node.title}</StyledBodyBase>}
                checked={node.uuid === taxonomy_node_uuid || isOnlyGroup}
                onChange={() => handleGroupChange(node.uuid as string)}
              />
            ))}
          </GroupContainer>
        ) : (
          <DropdownV3
            id="taxonomy_node_id"
            name="taxonomy_node_id"
            options={groupOptions}
            label="Group"
            value={taxonomy_node_uuid}
            onSelect={handleGroupChange}
            isRequired
          />
        )}
        {isEdit && (
          <BudgetDeleteEntityButton type="item" onDelete={() => setShowDeletePrompt(true)} />
        )}
      </FormElementsContainer>
      {showDeletePrompt && (
        <BudgetConfirmDeleteMiniModal
          hed={`Are you sure you want to delete ${item?.title}?`}
          onConfirm={handleDelete}
          onCancel={() => setShowDeletePrompt(false)}
        />
      )}
    </div>
  );
};
