import _sortBy from 'lodash/sortBy';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import {
  withoutPhoto,
  withoutPhotos,
  removeCategoryFromPhotos,
  withoutCategory,
  moveCategory,
  movePhotos,
  reorderPhotosByCategory,
  movePhotosIntoCategory,
} from './reducerUtils';

const init = {
  savedPhotos: [],
  savedCategories: [],
  galleryPhotos: [],
  galleryCategories: [],
  photoSelection: {},
  uploadProgress: 100,
  isSideBarOpen: true,
  showOrganizeTips: false,
};

const reducer = (state = init, action) => {
  switch (action.type) {
    case 'FETCH_PHOTOS_SUCCESS': {
      const sortedPhotos = _sortBy(action.payload, photo => photo.position);
      // Deep clones for savedPhotos and galleryPhotos ensure changes to one won't affect the other.
      // To reduce rerenders, only update galleryPhotos if actually changed.
      return {
        ...state,
        savedPhotos: _cloneDeep(sortedPhotos),
        galleryPhotos: _isEqual(sortedPhotos, state.galleryPhotos)
          ? state.galleryPhotos
          : _cloneDeep(sortedPhotos),
      };
    }
    case 'SAVE_PHOTOS_SUCCESS': {
      // When a save request succeeds, record the state the gallery was in when the request started.
      const successfullySavedGalleryPhotos = action.payload;
      return {
        ...state,
        savedPhotos: _cloneDeep(successfullySavedGalleryPhotos),
      };
    }
    case 'RESET_PHOTOS_TO_PREVIOUS_SAVE': {
      // When a save request fails, reset the gallery photos to the last successful save so the user doesn't think it succeeded.
      return {
        ...state,
        galleryPhotos: _cloneDeep(state.savedPhotos),
      };
    }
    case 'FETCH_CATEGORIES_SUCCESS': {
      const sortedCategories = _sortBy(action.payload, cat => cat.position);
      // Deep clones for savedCategories and galleryCategories ensure changes to one won't affect the other.
      // To reduce rerenders, only update galleryCategories if actually changed.
      return {
        ...state,
        savedCategories: _cloneDeep(sortedCategories),
        galleryCategories: _isEqual(sortedCategories, state.galleryCategories)
          ? state.galleryCategories
          : _cloneDeep(sortedCategories),
      };
    }
    case 'SAVE_CATEGORIES_SUCCESS': {
      // When a save request succeeds, record the state the gallery was in when the request started.
      const successfullySavedCategories = action.payload;
      return {
        ...state,
        savedCategories: _cloneDeep(successfullySavedCategories),
      };
    }
    case 'RESET_CATEGORIES_TO_PREVIOUS_SAVE': {
      // When a save request fails, reset the gallery categories to the last successful save so the user doesn't think it succeeded.
      return {
        ...state,
        galleryCategories: _cloneDeep(state.savedCategories),
      };
    }
    case 'MOVE_CATEGORY': {
      const { moved, target, insertBeforeTarget } = action.payload;
      const reorderedCategories = moveCategory(
        moved,
        target,
        insertBeforeTarget,
        state.galleryCategories
      );
      return {
        ...state,
        galleryCategories: reorderedCategories,
        galleryPhotos: reorderPhotosByCategory(state.galleryPhotos, reorderedCategories),
      };
    }
    case 'MOVE_PHOTOS': {
      const { moved, target, insertBeforeTarget } = action.payload;
      return {
        ...state,
        galleryPhotos: movePhotos(moved, target, insertBeforeTarget, state.galleryPhotos),
      };
    }
    case 'DELETE_PHOTO': {
      const photoUUID = action.payload;
      const photoSelection = { ...state.photoSelection };
      delete photoSelection[photoUUID];
      return {
        ...state,
        savedPhotos: withoutPhoto(photoUUID, state.savedPhotos),
        galleryPhotos: withoutPhoto(photoUUID, state.galleryPhotos),
        photoSelection,
      };
    }
    case 'DELETE_PHOTOS': {
      const photoUUIDs = action.payload;
      const photoSelection = { ...state.photoSelection };
      photoUUIDs.forEach(photoUUID => delete photoSelection[photoUUID]);
      return {
        ...state,
        savedPhotos: withoutPhotos(photoUUIDs, state.savedPhotos),
        galleryPhotos: withoutPhotos(photoUUIDs, state.galleryPhotos),
        photoSelection,
      };
    }
    case 'DELETE_CATEGORY': {
      const categoryUUID = action.payload;
      const updatedCategories = withoutCategory(categoryUUID, state.galleryCategories);
      return {
        ...state,
        galleryCategories: updatedCategories,
        savedPhotos: reorderPhotosByCategory(
          removeCategoryFromPhotos(categoryUUID, state.savedPhotos),
          updatedCategories
        ),
        galleryPhotos: reorderPhotosByCategory(
          removeCategoryFromPhotos(categoryUUID, state.galleryPhotos),
          updatedCategories
        ),
      };
    }
    case 'MOVE_PHOTOS_INTO_CATEGORY': {
      const { categoryUUID, photos } = action.payload;
      const photosInCategory = state.galleryPhotos.filter(
        p => p.photobook_project_item_media_category_uuid === categoryUUID
      );

      // If the target category is empty, add it to the new photos and reorder everything by category position
      if (photosInCategory.length === 0) {
        const updatedPhotos = movePhotosIntoCategory(categoryUUID, photos, state.galleryPhotos);
        return {
          ...state,
          galleryPhotos: reorderPhotosByCategory(updatedPhotos, state.galleryCategories),
        };
      }

      // Otherwise, insert the new photos after the category's last photo
      const lastPhotoInCategory = photosInCategory[photosInCategory.length - 1];
      return {
        ...state,
        galleryPhotos: movePhotos(photos, lastPhotoInCategory, false, state.galleryPhotos),
      };
    }
    case 'UPDATE_PHOTO_SELECTION': {
      const { photoUUIDs, isSelected } = action.payload;
      const newSelection = photoUUIDs.reduce((acc, photoUUID) => {
        acc[photoUUID] = isSelected;
        return acc;
      }, {});

      return {
        ...state,
        photoSelection: {
          ...state.photoSelection,
          ...newSelection,
        },
      };
    }
    case 'CLEAR_PHOTO_SELECTION': {
      return {
        ...state,
        photoSelection: {},
      };
    }
    case 'TOGGLE_SELECTED_PHOTO': {
      const { photoUUID } = action.payload;
      return {
        ...state,
        photoSelection: {
          ...state.photoSelection,
          [photoUUID]: !state.photoSelection[photoUUID],
        },
      };
    }
    case 'TOGGLE_HIGHLIGHTED_PHOTO': {
      const { photoUUID } = action.payload;
      const indexToUpdate = state.galleryPhotos.findIndex(p => p.uuid === photoUUID);
      const photoToUpdate = state.galleryPhotos[indexToUpdate];
      return {
        ...state,
        galleryPhotos: [
          ...state.galleryPhotos.slice(0, indexToUpdate),
          { ...photoToUpdate, highlight: !photoToUpdate.highlight },
          ...state.galleryPhotos.slice(indexToUpdate + 1),
        ],
      };
    }
    case 'SET_UPLOAD_PROGRESS': {
      return {
        ...state,
        uploadProgress: action.payload,
      };
    }
    case 'RESET_GALLERY': {
      return {
        ...state,
        ...init,
      };
    }
    case 'SET_IS_SIDEBAR_OPEN': {
      return {
        ...state,
        isSideBarOpen: action.payload,
      };
    }
    case 'SET_SHOW_ORGANIZE_TIPS': {
      return {
        ...state,
        showOrganizeTips: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
