import { CARD_TYPE_MAP } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import { SupportedLeadCardType } from 'cards/types/supportedLeadCardTypes';

export const HAS_MOTIFS_BY_CARD_TYPE: Record<SupportedLeadCardType, boolean> = {
  [CARD_TYPE_MAP.matchingSuites]: true,
  [CARD_TYPE_MAP.invitation]: true,
  [CARD_TYPE_MAP.std]: true,
  [CARD_TYPE_MAP.dstd]: true,
  [CARD_TYPE_MAP.ctd]: false,
  [CARD_TYPE_MAP.thankyou]: true,
  [CARD_TYPE_MAP.menu]: true,
  [CARD_TYPE_MAP.program]: true,
  [CARD_TYPE_MAP.napkin]: true,
  [CARD_TYPE_MAP.signage]: true,
  [CARD_TYPE_MAP.sticker]: true,
  [CARD_TYPE_MAP.place]: true,
  [CARD_TYPE_MAP.details]: true,
  [CARD_TYPE_MAP.enclosure]: true,
  [CARD_TYPE_MAP.holiday]: true,
  [CARD_TYPE_MAP.weddingShower]: true,
  [CARD_TYPE_MAP.tableNumber]: true,
  [CARD_TYPE_MAP.paperAddOns]: false,
  [CARD_TYPE_MAP.bellyBand]: false,
  [CARD_TYPE_MAP.vellumJacket]: false,
  [CARD_TYPE_MAP.waxSeal]: false,
} as const;
