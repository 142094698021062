import ApiService from '../../../util/api';
import { fetchProjectMeta } from './meta';
import { receiveProject } from './receiveProject';
import { getCardByVariationUUID } from '../cardCatalogActions';
import ActionTypes from '../projectActionTypes';

function requestProject() {
  return {
    type: ActionTypes.REQUEST_PROJECT,
  };
}

/**
 *
 * @see http://34.192.78.81:9140/swagger-ui/index.html#/CardProjectV2/getProject
 * @param {*} projectUUID
 * @param {*} skip
 * @returns WProjectFullView
 */
export function fetchProject(projectUUID, skip = false) {
  return dispatch => {
    dispatch(requestProject());
    return ApiService.get(`/web-api/v2/card-project/${projectUUID}`).then(json => {
      const isTestProject = !!json.test_project;

      if (json.status !== 'error') {
        /**
         * The getProjectMeta and the findCardByVariationUuid calls are heavy on BE.
         * So in some case we don't need to call those two APIs, just fetch the project details and skip them.
         */
        if (!skip) {
          dispatch(fetchProjectMeta(projectUUID));
          json.customizations.forEach(customization => {
            if (customization.variation && customization.variation.uuid) {
              dispatch(
                getCardByVariationUUID(
                  customization.variation.uuid,
                  customization.uuid,
                  isTestProject
                )
              );
            }
          });
        }
        dispatch(receiveProject(json, isTestProject));
      }

      return json;
    });
  };
}
