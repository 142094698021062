import { useEffect, useState } from 'react';

const VALID_DOLLARS_CENTS = /^\d*(\.(\d{1,2})?)?$/;

type UseCurrencyInputReturn = {
  /** The value that should be used based on `isEditingCurrency` boolean */
  currencyValue: string;
  /** Non-formatted value, can be any number of digits with trailing cents */
  rawCurrencyValue: string;
  /** Formatted value, with comma separators (but no $) */
  formattedCurrencyValue: string;
  /** If input field currently has focus */
  isEditingCurrency: boolean;
  /** State setter for currency value, use in onChange */
  setCurrencyValue: (newValue: string) => void;
  /** isEditing toggle function, use in onFocus, onBlur */
  toggleIsEditingCurrency: () => void;
};

export const useCurrencyInput = (
  initialValue: string,
  inputRef: React.MutableRefObject<HTMLInputElement | null>,
  depsList?: React.DependencyList
): UseCurrencyInputReturn => {
  const parsedInitial = parseFloat(initialValue);

  if (Number.isNaN(parsedInitial)) {
    throw new Error(
      'Initial value provided to `useCurrencyInput` must be a number in string format.'
    );
  }
  const [isEditing, setIsEditing] = useState(false);
  const [rawValue, setRawValue] = useState(parsedInitial.toFixed(2));
  const [formattedValue, setFormattedValue] = useState(
    parsedInitial.toLocaleString('en-US', {
      minimumFractionDigits: 2,
    })
  );

  useEffect(() => {
    if (isEditing && inputRef && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.select();
      }, 0);
    }
  }, [inputRef, isEditing]);

  useEffect(() => {
    setRawValue(`${parsedInitial}`);
    setFormattedValue(
      parsedInitial.toLocaleString('en-US', {
        minimumFractionDigits: 2,
      })
    );
  }, depsList); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleIsEditingCurrency = () => {
    // if input is undefined onBlur, default to 0 to avoid NaN
    if (isEditing && !rawValue) {
      setRawValue('0');
      setFormattedValue(
        parseFloat('0').toLocaleString('en-US', {
          minimumFractionDigits: 2,
        })
      );
    }
    setIsEditing(!isEditing);
  };

  const setCurrencyValue = (newValue: string) => {
    const nonFormattedValue = newValue.replace(/[$,]+/g, '');
    if (VALID_DOLLARS_CENTS.test(nonFormattedValue)) {
      setRawValue(nonFormattedValue);
      setFormattedValue(
        parseFloat(nonFormattedValue).toLocaleString('en-US', {
          minimumFractionDigits: 2,
        })
      );
    }
  };

  return {
    currencyValue: isEditing ? rawValue : formattedValue,
    rawCurrencyValue: rawValue,
    formattedCurrencyValue: formattedValue,
    isEditingCurrency: isEditing,
    setCurrencyValue,
    toggleIsEditingCurrency,
  };
};
