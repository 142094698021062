import styled from '@emotion/styled';
import { StyledDrawerFooter } from 'components/manage/budget/BudgetDrawer/BudgetDrawer.styles';
import { COLORS3 as COLORS, FONT, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const Container = styled.div`
  ${StyledDrawerFooter} {
    justify-content: space-between;
  }
`;

export const Text = styled.p`
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)};
  color: ${COLORS.BLACK_100};
  margin-bottom: ${SPACING.S16};
`;

export const DetailContainer = styled.div`
  padding-bottom: ${SPACING.XS};
`;

export const Detail = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Notes = styled.div`
  padding-top: ${SPACING.S24};
  border-top: 1px solid ${COLORS.BLACK_015};
`;

export const NotesHeader = styled.h3`
  ${FONT.textSizeUltraCompact(FONT.SIZE.REGULAR)};
  margin-top: 0;
`;
