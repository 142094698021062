const init = {
  activeModal: null,
  props: {},
  options: {
    clippingRect: {},
    tooltip: null,
    className: null,
    windowProps: {},
    hideClose: false,
  },
};

const reducer = (state = init, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      if (state.activeModal) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        activeModal: action.payload.type,
        props: action.payload.props || {},
        options: { ...state.options, ...(action.payload.options || {}) },
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        activeModal: null,
        options: init.options,
      };
    case 'SET_MODAL_OPTIONS':
      return {
        ...state,
        options: { ...state.options, ...action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
