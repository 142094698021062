import { RootState } from 'src/reducers';

export const getWebsitePublished = (state: RootState) => state.wedding.account?.published || false;

export const getWebsitePasscode = (state: RootState) => state.wedding.account?.passcode;

export const getWeddingSlug = (state: RootState) => state.wedding.wedding.slug;

export const getWeddingThemeGroupTitle = (state: RootState) => state.wedding.themeGroupTitle;

export const getWeddingPreviewUrl = (state: RootState) => {
  const slug = getWeddingSlug(state);

  if (!slug) return '';

  return `/wedding/${slug}`;
};

export const getCurrentWebsitePageImage = (state: RootState) =>
  state.website.pages.activePageObject?.header_image_url;

export const getCmsBannerEnabled = (state: RootState) =>
  state.wedding.account.enable_cms_banner || false;

export const getWeddingAccount = (state: RootState) => state.wedding.account || {};

export const getCustomDomain = (state: RootState) => state.wedding.wedding?.custom_domain;

export default {
  getWebsitePasscode,
  getWebsitePublished,
  getWeddingSlug,
  getWeddingPreviewUrl,
  getWeddingThemeGroupTitle,
  getCurrentWebsitePageImage,
  getCmsBannerEnabled,
  getWeddingAccount,
  getCustomDomain,
};
