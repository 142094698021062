import styled from '@emotion/styled';

import { COLORS3, FONT, SPACING } from '@zola/zola-ui/src/styles/emotion';
import { BodyBase } from '@zola/zola-ui/src/typography/Paragraphs';

export const FormElementsContainer = styled.div`
  // same as & > *:not(:first-child), but first-child selectors throw an annoying console warning
  & > * + * {
    margin-top: ${SPACING.S16};
  }
`;

export const DisabledHelperText = styled.p`
  margin: ${SPACING.XXS} 0 0;
  color: ${COLORS3.BLACK_100};
  ${FONT.textSizeUltraCompact(FONT.SIZE.EXTRA_SMALL)}
`;

export const StyledBodyBase = styled(BodyBase)`
  letter-spacing: normal;
  text-transform: none;
`;

export const GroupContainer = styled.div<{ isOnlyGroup: boolean }>`
  ${({ isOnlyGroup }) => isOnlyGroup && `display: none;`}
`;
