import type { WThemeDetailView, WWeddingThemeV2View } from '@zola/svc-web-api-ts-client';
import type { WeddingThemeActionTypes } from 'actions/types/WeddingThemeActionTypes';
import * as ActionType from '../actions/types/WeddingThemeActionTypes';

export type WeddingThemeReducerStateType = {
  theme: WWeddingThemeV2View | undefined;
  themeDetail: WThemeDetailView | undefined;
};

const initialState = {
  theme: undefined,
  themeDetail: undefined,
};

const weddingThemeReducer = (
  state = initialState,
  action: WeddingThemeActionTypes
): WeddingThemeReducerStateType => {
  switch (action.type) {
    case ActionType.RECEIVE_WEDDING_THEME: {
      const { theme } = action.payload;
      return {
        ...state,
        theme: {
          ...theme,
        },
      };
    }
    case ActionType.RECEIVE_WEDDING_THEME_DETAIL: {
      const { themeDetail } = action.payload;
      return {
        ...state,
        themeDetail: {
          ...themeDetail,
        },
      };
    }
    default:
      return state;
  }
};

export default weddingThemeReducer;
