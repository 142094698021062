import * as AlbumDetailsTypes from './types';

const init: AlbumDetailsTypes.AlbumDetailsState = {
  initialized: false,
  busy: false,
  list: null,
};

const reducer = (
  state = init,
  action: AlbumDetailsTypes.AlbumDetailsActionType
): AlbumDetailsTypes.AlbumDetailsState => {
  switch (action.type) {
    case AlbumDetailsTypes.FETCH_ALBUM_DETAILS: {
      return {
        ...state,
        busy: true,
      };
    }
    case AlbumDetailsTypes.FETCH_ALBUM_DETAILS_FULFILLED: {
      return {
        ...state,
        initialized: true,
        busy: false,
        list: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
