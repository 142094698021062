import styled from '@emotion/styled';
import { COLORS3, FONT, MEDIA_QUERY, SPACING } from '@zola/zola-ui/src/styles/emotion';
import { COLORS_BUTTONS } from '@zola/zola-ui/src/styles/emotion/colors3';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { Input } from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3/InputFieldV3.styles';
import { CheckboxField } from '@zola/zola-ui/src/components/Form/CheckboxField';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';

import { VendorImg } from '../BudgetItemAddVendor/BudgetItemAddVendor.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleFlex = styled.span`
  display: flex;
  align-items: center;
`;

export const EditLink = styled(LinkV2)`
  flex: 0 0 auto;
  margin-left: ${SPACING.MD};
`;

export const ItemType = styled.small`
  color: ${COLORS3.BLACK_050};
  ${FONT.textSizeCompact(FONT.SIZE.EXTRA_SMALL)}
`;

export const CostContainer = styled.div`
  display: grid;
  grid-gap: ${SPACING.S24};
  grid-template-columns: 1fr 1fr;
`;

export const CostLabel = styled.label`
  ${FONT.textSizeCompact(FONT.SIZE.LARGE)};
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-family: ${FONT.FAMILY.SANS_SERIF};
  margin-bottom: ${SPACING.XS};
  text-transform: none;
  letter-spacing: normal;
  ${MEDIA_QUERY.MOBILE_XS} {
    ${FONT.textSizeCompact(FONT.SIZE.REGULAR)};
  }
`;

export const CostLabelV2 = styled.label`
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)};
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
  font-family: ${FONT.FAMILY.SANS_SERIF};
  margin-bottom: 0;
  text-transform: none;
  letter-spacing: normal;
`;

export const CostFields = styled.div`
  ${Input} {
    text-align: right;
  }
`;

export const SuggestedAllocation = styled.div`
  color: ${COLORS3.BLACK_050};
  ${FONT.textSizeCompact(FONT.SIZE.EXTRA_SMALL)}
`;

export const StyledCheckboxField = styled(CheckboxField)`
  margin-top: ${SPACING.S16};
  white-space: nowrap;

  .field-wrapper-checkbox {
    flex-direction: row-reverse;
  }
  .checkbox-icon-wrapper {
    margin-right: 0 !important;
    margin-left: ${SPACING.SM};
  }
  .selector-label-wrapper {
    text-align: right;
  }
`;

export const PaidRemaining = styled.div<{ isBudgetRevamp?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${SPACING.S16} ${SPACING.S24};
  margin-top: ${SPACING.S16};
  margin-bottom: ${SPACING.LG};
  align-items: center;
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}

  ${({ isBudgetRevamp }) =>
    isBudgetRevamp &&
    `margin-bottom: ${SPACING.S32}; padding-bottom: ${SPACING.S32}; border-bottom: 1px solid ${COLORS3.BLACK_010};`}
`;

export const PaidRemainingCurrency = styled.span`
  text-align: right;
`;

export const PaymentsHeader = styled.h2`
  margin: 0 0 ${SPACING.XS};
  font-weight: ${FONT.WEIGHT.REGULAR};
  ${FONT.textSizeCompact(FONT.SIZE.LARGE)};
  ${MEDIA_QUERY.MOBILE_XS} {
    ${FONT.textSizeCompact(FONT.SIZE.REGULAR)};
  }
`;

export const StyledP = styled.p`
  color: ${COLORS3.BLACK_100};
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
`;

export const AddPaymentButton = styled(ButtonV3)`
  margin-top: ${SPACING.S16};
  margin-bottom: ${SPACING.LG};
  align-self: flex-start;
`;

export const NotesLabel = styled.label`
  ${FONT.textSizeCompact(FONT.SIZE.LARGE)}
  font-weight: ${FONT.WEIGHT.REGULAR};
  font-family: ${FONT.FAMILY.SANS_SERIF};
  margin-bottom: ${SPACING.XS};
  text-transform: none;
  letter-spacing: normal;
  display: block;
`;

export const Optional = styled.span`
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
  color: ${COLORS3.BLACK_050};
`;

export const StyledAddVendorLink = styled(LinkV2)`
  .zola-ui.circle-icon {
    margin-right: ${SPACING.S16};
  }
`;

export const SelectedVendor = styled.div<{ isBudgetRevamp?: boolean }>`
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
  display: flex;
  align-items: center;

  ${({ isBudgetRevamp }) => isBudgetRevamp && `gap: ${SPACING.S16};`}

  .zola-ui.circle-icon, ${VendorImg} {
    margin-right: ${SPACING.S16};
    flex: 0 0 auto;
  }
`;

export const AccountVendor = styled.div<{ isBudgetRevamp?: boolean }>`
  margin-bottom: ${SPACING.LG};
  display: flex;

  ${({ isBudgetRevamp }) =>
    isBudgetRevamp &&
    `margin-bottom: ${SPACING.S32}; padding-bottom: ${SPACING.S32}; border-bottom: 1px solid ${COLORS3.BLACK_010};`}

  ${EditLink} {
    margin-left: auto;
  }
`;

export const VendorSrpLink = styled(LinkV2)`
  ${FONT.textSizeUltraCompact(FONT.SIZE.SMALL)}
  display: flex;
  align-items: center;
`;

export const VendorSrp = styled.div`
  display: flex;
  margin-left: auto;
  padding: ${SPACING.SM} ${SPACING.S16};
  background-color: ${COLORS3.BLACK_005};
  border-radius: ${SPACING.XS};
  margin-bottom: ${SPACING.SM};
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    right: 14px;
    bottom: -${SPACING.SM};
    border-top: ${SPACING.SM} solid ${COLORS3.BLACK_005};
    border-right: ${SPACING.SM} solid transparent;
    border-left: ${SPACING.SM} solid transparent;
    border-bottom: none;
  }
`;

export const DismissVendorSrp = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background: none;
  height: 20px;
  margin-left: ${SPACING.LG};
  color: ${COLORS3.BAY_100};
`;

export const UpcomingReminders = styled.div`
  margin-bottom: ${SPACING.LG};
`;

export const UpcomingReminder = styled.div<{ isOverdue: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.S16};
  border-radius: 8px;
  background-color: ${COLORS3.BLACK_005};
  color: ${({ isOverdue }) => (isOverdue ? COLORS3.WINE_100 : COLORS3.BAY_100)};

  &:not(:first-of-type) {
    margin-top: ${SPACING.S16};
  }
`;

export const ReminderCostTime = styled.div`
  ${FONT.textSizeUltraCompact(FONT.SIZE.SMALLER)}
`;

export const ViewDetailsLink = styled(LinkV2, {
  shouldForwardProp: prop => prop !== 'isOverdue',
})<{ isOverdue: boolean }>`
  color: ${({ isOverdue }) => (isOverdue ? COLORS3.WINE_100 : COLORS3.BAY_100)};
`;

export const AddVendorBtn = styled.button`
  padding: ${SPACING.S16};
  border-radius: ${SPACING.S16};
  border: 2px dashed ${COLORS3.BLACK_030};
  outline: none;
  background: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${SPACING.XS};

  &:only-child {
    flex: 1 1 auto;
  }

  &:hover {
    border-color: ${COLORS3.BLACK_100};
  }
`;

export const IconCircle = styled.div`
  background-color: ${COLORS3.BLACK_010};
  border-radius: 50%;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AddOrSeeVendors = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING.S16};
  width: 100%;
`;

export const SeeTopMatchesBtn = styled.button`
  padding: ${SPACING.S16};
  border-radius: ${SPACING.S16};
  background-color: ${COLORS_BUTTONS.BAY_100};
  color: ${COLORS3.WHITE_100};
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${SPACING.XS};
  height: 100%;
  flex: 1 0 auto;
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};

  &:hover {
    background-color: ${COLORS_BUTTONS.BAY_085};
  }
`;
