import React, { useState } from 'react';

import type { RouteComponentProps } from 'react-router';

import {
  mapAccountVendorsToSelectableAccountVendors,
  mapItemTypeToItemTypeDetails,
} from '../util/mappers';
import { BudgetAddExistingAccountVendor } from './components/BudgetAddExistingAccountVendor';
import { BudgetAddNewAccountVendor } from './components/BudgetAddNewAccountVendor';

import { useBudgetContext, getBudgetItemByUuid } from '../context';
import { useLeaveBudgetRoute } from '../routes/useLeaveBudgetRoute';
import { handleBudgetRouteChange } from '../routes/handleBudgetRouteChange';

type RouteParams = {
  itemUuid: string;
};

export type BudgetItemAddVendorProps = RouteComponentProps<RouteParams, RouteParams> & {
  className?: string;
};

export const BudgetItemAddVendor = ({
  className,
  location: { pathname, state = {} },
  params: { itemUuid },
  route,
  router: { setRouteLeaveHook },
}: BudgetItemAddVendorProps) => {
  useLeaveBudgetRoute(route, setRouteLeaveHook);
  const { previousPathname } = state;
  const {
    state: {
      budget: { taxonomy_nodes = [] },
      item_types = [],
      accountVendors = [],
    },
    isBudgetRevamp,
  } = useBudgetContext();

  const item = getBudgetItemByUuid(itemUuid, taxonomy_nodes);
  const { vendor_type } = item || {};
  const selectableVendors = mapAccountVendorsToSelectableAccountVendors(
    accountVendors,
    taxonomy_nodes,
    vendor_type
  );
  const { searchable_vendor_type: isBookableVendorType } = mapItemTypeToItemTypeDetails(
    item_types,
    vendor_type
  );

  const [view, setView] = useState<'existing' | 'new'>(
    selectableVendors.length ? 'existing' : 'new'
  );
  const setViewToNew = () => setView('new');

  // Close drawer if budget item doesn't exist for user
  if (!item?.uuid) {
    handleBudgetRouteChange();
    return null;
  }
  // Reroute to BudgetItemView if vendor type can't be associated to budget item
  if (!isBookableVendorType && !isBudgetRevamp) {
    handleBudgetRouteChange(`/item/${itemUuid}`);
    return null;
  }

  return (
    <div data-testid="BudgetItemAddVendor" className={className}>
      {view === 'existing' ? (
        <BudgetAddExistingAccountVendor
          item={item}
          itemUuid={itemUuid}
          pathname={pathname}
          selectableVendors={selectableVendors}
          setViewToNew={setViewToNew}
        />
      ) : (
        <BudgetAddNewAccountVendor
          previousPathname={previousPathname}
          pathname={pathname}
          item={item}
          itemUuid={itemUuid}
          isBookableVendorType={isBookableVendorType}
        />
      )}
    </div>
  );
};
