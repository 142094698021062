import React, { useMemo, useState } from 'react';
import type { RouteComponentProps } from 'react-router';
import _isEqual from 'lodash/isEqual';

import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

import type { WBudgetItemView, WBudgetTaxonomyNodeView } from '@zola/svc-web-api-ts-client';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';

import { DrawerTitle } from 'components/common/zolaUI/Drawer';

import { useAppDispatch } from 'reducers/useAppDispatch';
import { getTaxonomyNodeByUuid, useBudgetContext } from '../context';
import { BUDGET_BASE_ROUTE } from '../routes/constants';
import { useLeaveBudgetRoute } from '../routes/useLeaveBudgetRoute';
import { handleBudgetRouteChange } from '../routes/handleBudgetRouteChange';
import {
  addTaxonomyNode,
  deleteTaxonomyNode,
  reorderBudgetItems,
  updateTaxonomyNode,
} from '../util/api';
import { useBudgetDrawerLayer } from '../util/useBudgetDrawerLayer';

import BudgetDeleteEntityButton from '../BudgetDeleteEntityButton/BudgetDeleteEntityButton';
import { BudgetConfirmDeleteMiniModal } from '../BudgetConfirmDeleteMiniModal/BudgetConfirmDeleteMiniModal';
import { BudgetSortableList } from '../BudgetSortableList/BudgetSortableList';

import { AddContainer, AddHeader, AddButton } from '../BudgetSettings/BudgetSettings.styles';
import { DeleteSubtext, InputContainer } from './BudgetGroupAddOrEdit.styles';

type RouteParams = {
  taxonomyNodeUuid?: string;
};

export type BudgetGroupAddOrEditProps = RouteComponentProps<RouteParams, RouteParams>;

export const BudgetGroupAddOrEdit = ({
  location: { pathname, state = {} },
  params: { taxonomyNodeUuid },
  route,
  router: { setRouteLeaveHook },
}: BudgetGroupAddOrEditProps): JSX.Element | null => {
  const isEdit = !!taxonomyNodeUuid;
  const { previousPathname } = state;

  // Toast
  const dispatch = useAppDispatch();
  const showErrorToast = () =>
    dispatch(
      toastsActions.negative({
        headline: `There was an error. Please try again.`,
      })
    );
  const showSuccessToast = (headline?: string) =>
    dispatch(
      toastsActions.positive({
        headline: headline || isEdit ? 'Updated' : 'Added',
      })
    );

  // Context values
  const {
    actions: {
      receiveBudgetItems,
      receiveTaxonomyNode,
      receiveDeletedTaxonomyNode,
      receiveUnsavedTaxonomyNode,
      clearUnsavedTaxonomyNode,
    },
    state: {
      budget: { taxonomy_nodes = [], uuid: budget_uuid = '' },
      unsavedChanges: { unsavedTaxonomyNode },
    },
  } = useBudgetContext();

  // Drawer routing and unsaved changes setup
  useLeaveBudgetRoute(route, setRouteLeaveHook);

  // Form data initialization
  // If there are unsaved changes on coming back, use those; otherwise use context/API values
  const taxonomyNode = isEdit ? getTaxonomyNodeByUuid(taxonomyNodeUuid, taxonomy_nodes) : undefined;
  const [localTaxonomyNode, setLocalTaxonomyNode] = useState(
    unsavedTaxonomyNode || taxonomyNode || {}
  );
  const { items: budgetItems = [], title = '', uuid: taxonomy_node_uuid = '' } = localTaxonomyNode;
  const localBudgetItemUuids = useMemo(() => budgetItems.map(item => item.uuid || ''), [
    budgetItems,
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const hasTitleChange = useMemo(() => title !== taxonomyNode?.title, [taxonomyNode?.title, title]);
  const hasOrderChange = useMemo(
    () =>
      !_isEqual(
        localBudgetItemUuids,
        taxonomyNode?.items?.map(item => item.uuid || '')
      ),
    [localBudgetItemUuids, taxonomyNode?.items]
  );
  const deleteDisabled = budgetItems.length > 0;
  const saveDisabled = !title.length || title.length > 50 || isSubmitting;

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setHasUnsavedChanges(true);
    setLocalTaxonomyNode(prevValue => ({ ...prevValue, title: value }));
  };

  const handleReorderItems = (reorderedBudgetItems: WBudgetItemView[]) => {
    setHasUnsavedChanges(true);
    setLocalTaxonomyNode(prevValue => ({ ...prevValue, items: reorderedBudgetItems }));
  };

  const handleDelete = () => {
    setIsSubmitting(true);
    deleteTaxonomyNode(taxonomy_node_uuid)
      .then(() => {
        receiveDeletedTaxonomyNode(taxonomy_node_uuid);
        setHasUnsavedChanges(false);
        showSuccessToast('Deleted');
        setShowDeletePrompt(false);
        handleBudgetRouteChange('/settings', { preventIfUnsaved: false });
      })
      .catch(() => {
        setIsSubmitting(false);
        showErrorToast();
      });
  };

  const handleCancel = () => {
    // Prevent going to previous layer if there are unsaved changes
    handleBudgetRouteChange('/settings', { preventIfUnsaved: hasUnsavedChanges });
  };

  const handleSave = () => {
    setHasUnsavedChanges(false);
    setIsSubmitting(true);

    const requests = [];

    if (isEdit && hasTitleChange) {
      const updateRequest = { taxonomy_node_uuid, title };
      requests.push(updateTaxonomyNode(updateRequest));
    }
    if (isEdit && hasOrderChange) {
      const reorderRequest = { taxonomy_node_uuid, item_uuids: localBudgetItemUuids };
      requests.push(reorderBudgetItems(reorderRequest));
    }
    if (!isEdit) {
      const addRequest = { budget_uuid, title };
      requests.push(addTaxonomyNode(addRequest));
    }

    Promise.all(requests)
      .then(res => {
        if ((isEdit && hasTitleChange) || !isEdit) {
          receiveTaxonomyNode(res[0] as WBudgetTaxonomyNodeView);
        }
        if (isEdit && hasOrderChange) {
          receiveBudgetItems(res[hasTitleChange ? 1 : 0] as WBudgetItemView[]);
        }
        showSuccessToast();
        handleBudgetRouteChange(`/settings`, { preventIfUnsaved: false });
      })
      .catch(() => {
        setHasUnsavedChanges(true);
        setIsSubmitting(false);
        showErrorToast();
      });
  };

  const handleStashChangesAndChangeRoute = (path: string) => {
    if (hasUnsavedChanges) {
      // Stash changes for when user comes back
      receiveUnsavedTaxonomyNode(localTaxonomyNode);
    }
    handleBudgetRouteChange(path, { preventIfUnsaved: false, previousPathname: pathname });
  };

  // Handles coming back to BudgetGroupAddOrEdit if there were unsaved changes here
  useEffectOnce(() => {
    if (unsavedTaxonomyNode) {
      setHasUnsavedChanges(true);
      // Component was initialized with any unsaved changes, so clear them on mount
      clearUnsavedTaxonomyNode();
    }
  });

  // Handles save for drawer unsaved changes prompt
  // Wrapping inside useEffect ensures handleSave has most up-to-date values
  useBudgetDrawerLayer(
    {
      key: pathname,
      useClose: previousPathname === BUDGET_BASE_ROUTE,
      cancelButtonDisabled: isSubmitting,
      saveButtonDisabled: saveDisabled,
      onSave: handleSave,
      onBack: handleCancel,
      onUnsavedChanges: hasUnsavedChanges
        ? () => {
            handleBudgetRouteChange(`/group/${isEdit ? taxonomyNodeUuid : 'new'}`);
          }
        : undefined,
    },
    isSubmitting,
    [hasUnsavedChanges, localTaxonomyNode, saveDisabled]
  );

  // Close drawer if group doesn't exist for user
  if (isEdit && !taxonomyNode) {
    handleBudgetRouteChange();
    return null;
  }

  return (
    <div data-testid="BudgetGroupAddOrEdit">
      <DrawerTitle>{isEdit ? 'Edit' : 'New'} group</DrawerTitle>
      <InputContainer>
        <InputFieldV3
          id="title"
          name="title"
          label="Group name"
          maxChars={50}
          placeholder="Honeymoon"
          onChange={handleTitleChange}
          value={title}
          isRequired
        />
      </InputContainer>
      {isEdit ? (
        <>
          <AddContainer>
            <AddHeader>Items within this group</AddHeader>
            <AddButton
              disabled={isSubmitting}
              onClick={() => handleStashChangesAndChangeRoute('/item/new')}
              variant="secondary"
            >
              Add
            </AddButton>
          </AddContainer>
          <BudgetSortableList
            items={budgetItems}
            itemUuids={localBudgetItemUuids}
            onClickEdit={itemUuid => handleStashChangesAndChangeRoute(`/item/${itemUuid}/edit`)}
            onReorder={handleReorderItems}
          />
          <BudgetDeleteEntityButton
            type="group"
            disabled={Boolean(deleteDisabled || isSubmitting)}
            onDelete={() => setShowDeletePrompt(true)}
          />
          <DeleteSubtext>To delete this group, delete all the items in it first.</DeleteSubtext>
          {showDeletePrompt ? (
            <BudgetConfirmDeleteMiniModal
              onConfirm={handleDelete}
              onCancel={() => setShowDeletePrompt(false)}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};
