import React, { useCallback } from 'react';

import type { StorefrontCardView } from '@zola/svc-marketplace-ts-types';
import type { Storefront } from '@zola/tracking-contracts/src/objects';
import {
  getVendorTaxonomyKeyFromVendorType,
  VendorTypeEnum,
  SearchableVendorTaxonomyKey,
} from '@zola-helpers/client/dist/es/marketplace';

import getZolaImageFromUuid from '@zola-helpers/client/dist/es/util/getZolaImageFromUuid';
import { BodySmall } from '@zola/zola-ui/src/typography/Paragraphs';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { ExternalLinkIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ExternalLink';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';
import { trackProductClicked } from '@zola/tracking-contracts/src/tracking';
import { getBusinessCategory } from '@zola-helpers/client/dist/es/tracking/trackingHelper';
import { ProductClicked } from '@zola/tracking-contracts/src/events';

import { mapCostToFormattedString } from '../../util/mappers';

import {
  VendorCard,
  VendorCardImg,
  VendorCardDetails,
  StyledStarRatings,
  StarsAndLocation,
  Dot,
  StyledCheckboxField,
} from '../BudgetExploreMatchesModal.styles';

type BudgetMatchesSearchResultsProps = {
  vendors: StorefrontCardView[];
  selectedVendors: string[];
  allowInquiries: boolean;
  onCardClick: (storefrontUuid: string) => void;
};

const BudgetMatchesSearchResults = ({
  vendors,
  selectedVendors,
  allowInquiries,
  onCardClick,
}: BudgetMatchesSearchResultsProps): JSX.Element => {
  const handleLinkClick = useCallback(
    (
      e: React.MouseEvent,
      {
        storefrontUuid,
        name,
        vendorType,
      }: Pick<StorefrontCardView, 'storefrontUuid' | 'name' | 'vendorType'>,
      position: number
    ) => {
      e.stopPropagation();
      const storefrontData: Storefront = {
        storefront_uuid: storefrontUuid,
        name,
        category: vendorType
          ? getBusinessCategory(
              getVendorTaxonomyKeyFromVendorType(
                vendorType as VendorTypeEnum
              ) as SearchableVendorTaxonomyKey
            )
          : 'UNATTRIBUTED',
      };
      const data: ProductClicked = {
        ...storefrontData,
        business_category: vendorType
          ? getBusinessCategory(
              getVendorTaxonomyKeyFromVendorType(
                vendorType as VendorTypeEnum
              ) as SearchableVendorTaxonomyKey
            )
          : 'UNATTRIBUTED',
        business_unit: 'MARKETPLACE',
        position,
        listing_shelf_name: 'BUDGET_TOOL',
        entity_type: 'STOREFRONT',
      };
      trackProductClicked(data);
    },
    []
  );

  return (
    <>
      {vendors.map(
        (
          {
            name,
            storefrontUuid,
            city,
            stateProvince,
            imageIds,
            taxonomyNodeKey,
            slug,
            averageReviewsRate,
            startingPriceCents,
            reviewsCount,
            vendorType,
            // @ts-expect-error - not explicitly defined by svc-web-marketplace, but 'capacity' exists for venues
            details: { capacity },
          },
          i
        ) => {
          const checked = selectedVendors.some(
            selectedVendorStorefrontUuid => selectedVendorStorefrontUuid === storefrontUuid
          );
          return (
            <VendorCard
              key={`budget-match-vendor-${slug}`}
              onClick={() => onCardClick(storefrontUuid)}
              allowInquiries={allowInquiries}
            >
              <VendorCardImg src={`${getZolaImageFromUuid(imageIds[0])}?w=${128}&h=${96}`} />
              <VendorCardDetails>
                <LinkV2
                  href={`/wedding-vendors/${taxonomyNodeKey}/${slug}`}
                  target="_blank"
                  rel="nofollow noopener"
                  onClick={(e: React.MouseEvent) =>
                    handleLinkClick(e, { storefrontUuid, name, vendorType }, i + 1)
                  }
                  noUnderline
                >
                  {name}
                  <ExternalLinkIcon
                    height={20}
                    width={20}
                    showTitle={false}
                    style={{ verticalAlign: 'bottom' }}
                  />
                </LinkV2>
                <StarsAndLocation>
                  {reviewsCount > 0 && (
                    <StyledStarRatings
                      totalStars={5}
                      activeStars={Number(
                        averageReviewsRate.toLocaleString('en', {
                          maximumFractionDigits: 2,
                        })
                      )}
                      reviewCount={reviewsCount}
                      fillColor={COLORS3.YELLOW_100}
                      starSize="small"
                      textSize="small"
                    />
                  )}
                  <BodySmall>
                    {city}, {stateProvince}
                  </BodySmall>
                </StarsAndLocation>
                <BodySmall>
                  {capacity?.max && `Up to ${capacity.max} guests`}
                  {capacity?.max && startingPriceCents && <Dot>&#x2022;</Dot>}
                  {startingPriceCents &&
                    `Starts at ${mapCostToFormattedString(startingPriceCents, true)}`}
                </BodySmall>
              </VendorCardDetails>
              {allowInquiries && (
                <StyledCheckboxField
                  checked={checked}
                  id={`selected-storefront-${storefrontUuid}`}
                  readOnly
                  onClick={(e: React.MouseEvent) => e.stopPropagation()}
                />
              )}
            </VendorCard>
          );
        }
      )}
    </>
  );
};

export default BudgetMatchesSearchResults;
