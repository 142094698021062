import React, { useState } from 'react';

import { XIcon } from '@zola/zola-ui/src/components/SvgIconsV3/X';

import { checkUserFlag, setUserSetting } from '@zola-helpers/client/dist/es/util/userSettings';
import {
  getVendorTaxonomyKeyFromVendorType,
  VendorTypeEnum,
} from '@zola-helpers/client/dist/es/marketplace';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';
import {
  CtaEventDataType,
  CtaType,
  trackCtaClicked,
} from '@zola-helpers/client/dist/es/tracking/ctaEvents/ctaEvents';

import { useBudgetContext } from '../../context';

import { VendorSrp, DismissVendorSrp, VendorSrpLink } from '../BudgetItemView.styles';

type BudgetItemViewVendorSrpLinkProps = {
  showLink: boolean;
  rawCurrencyValue: string;
  formattedCurrencyValue: string;
  vendor_type?: string;
  vendorDisplayName?: string;
};

export const BudgetItemViewVendorSrpLink = ({
  showLink,
  rawCurrencyValue,
  formattedCurrencyValue,
  vendor_type,
  vendorDisplayName,
}: BudgetItemViewVendorSrpLinkProps) => {
  const [hasSeenCta, setHasSeenCta] = useState<boolean | undefined>(showLink);
  const vendorTaxonomyKey = getVendorTaxonomyKeyFromVendorType(vendor_type as VendorTypeEnum);
  const userSettingKey = `BUDGET_DISMISSED_CAT_${vendor_type}`;
  const LOCATION_FALLBACK = 'new-york-ny';

  const {
    state: {
      marketplaceLocation: { citySlug },
    },
  } = useBudgetContext();
  const location = citySlug !== null ? citySlug : LOCATION_FALLBACK;

  useEffectOnce(() => {
    checkUserFlag(userSettingKey)
      .then(res => {
        setHasSeenCta(res);
      })
      .catch(() => undefined);
  });

  if (!showLink || hasSeenCta) return null;

  const handleTracking = () => {
    // Uppercase and snake case to lowercase and hypen; e.g. 'CAKES_DESSERTS' => 'cakes-desserts'
    const formattedVendorType = vendor_type?.toLowerCase().replace('_', '-') || 'vendor';
    const trackingEventData: CtaEventDataType = {
      business_unit: 'CORE',
      cta: 'Find Vendor at Cost',
      cta_id: `budget-find-${formattedVendorType}-at-cost`,
      cta_type: CtaType.LINK,
      position: 0,
    };
    trackCtaClicked(trackingEventData);
  };

  const handleDismiss = () => {
    setHasSeenCta(true);
    setUserSetting(userSettingKey).catch(() => undefined);
  };

  const priceMaxQuery = Math.round(parseFloat(rawCurrencyValue));

  return (
    <VendorSrp>
      <VendorSrpLink
        arrow
        href={`/wedding-vendors/search/${location}--${vendorTaxonomyKey}?price-max=${priceMaxQuery}&price-min=0`}
        onClick={handleTracking}
        target="_blank"
        rel="noopener noreferrer"
        variant="secondary"
        inline
      >
        Find {vendorDisplayName?.toLowerCase()} up to ${formattedCurrencyValue.replace('.00', '')}
      </VendorSrpLink>
      <DismissVendorSrp onClick={handleDismiss} data-testid="budget-dismiss-vendor-srp">
        <XIcon height={20} width={20} showTitle={false} />
      </DismissVendorSrp>
    </VendorSrp>
  );
};
