import { useEffect } from 'react';

import { DrawerLayer, useDrawerControls } from 'components/common/zolaUI/Drawer';

export const useBudgetDrawerLayer = (
  layer: DrawerLayer,
  isSubmitting: boolean,
  depsList: React.DependencyList = []
) => {
  const { setDrawerLayer, setShowSavePrompt, currentLayer } = useDrawerControls();
  const { onUnsavedChanges, tertiaryElement, ...rest } = layer;

  const handleUnsavedChanges = onUnsavedChanges
    ? () => {
        onUnsavedChanges();
        setShowSavePrompt(true);
      }
    : undefined;

  useEffect(() => {
    if (!isSubmitting) {
      setDrawerLayer({
        ...currentLayer,
        ...rest,
        tertiaryElement,
        onUnsavedChanges: handleUnsavedChanges,
      });
    }
  }, [isSubmitting, ...depsList]);
};
