import fetch from 'isomorphic-fetch';
import _isEmpty from 'lodash/isEmpty';
import _size from 'lodash/size';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';

import * as ActionType from './types/SeatingChartActionTypes';
import ApiService from '../util/api';
import { fetchEventsByDate } from './EventActions';
import { hideModal } from './ModalActions';

function requestChart() {
  return {
    type: ActionType.REQUEST_CHART,
  };
}
function receiveChart(json) {
  return {
    type: ActionType.RECEIVE_CHART,
    payload: { data: json },
  };
}
function requestCharts() {
  return {
    type: ActionType.REQUEST_CHARTS,
  };
}
function receiveCharts(json) {
  return {
    type: ActionType.RECEIVE_CHARTS,
    payload: json,
  };
}
function deleteChart() {
  return {
    type: ActionType.DELETE_CHART,
  };
}
function createChart() {
  return {
    type: ActionType.CREATE_CHART,
  };
}
function updateChart() {
  return {
    type: ActionType.UPDATE_CHART,
  };
}
function redirectChart() {
  return {
    type: ActionType.REDIRECT_CHART,
  };
}
function hideSpinner() {
  return {
    type: ActionType.HIDE_SPINNER,
  };
}
function deleteTable() {
  return {
    type: ActionType.DELETE_TABLE,
  };
}

export function onTableReorder(sourceIndex, hoverIndex) {
  return {
    type: ActionType.REORDER_TABLES,
    payload: {
      sourceIndex,
      hoverIndex,
    },
  };
}

export function increaseStep() {
  return {
    type: ActionType.INC_ONBOARDING_STEP,
  };
}
export function decreaseStep() {
  return {
    type: ActionType.DEC_ONBOARDING_STEP,
  };
}

export function triggerOnboarding() {
  return {
    type: ActionType.TRIGGER_SEATING_ONBOARDING,
  };
}
export function finishOnboarding() {
  return {
    type: ActionType.FINISH_ONBOARDING,
  };
}
export function onboardingSelectionStep() {
  return {
    type: ActionType.ONBOARDING_SELECTION_STEP,
  };
}
export function onboardingTableStep() {
  return {
    type: ActionType.ONBOARDING_TABLE_STEP,
  };
}
export function setNewChartEvent(event) {
  return {
    type: ActionType.SET_NEW_CHART_EVENT,
    payload: {
      event,
    },
  };
}
export function selectGuest(guest, guestGroup, isCouple) {
  return {
    type: ActionType.SELECT_GUESTS,
    payload: {
      guest,
      guestGroup,
      isCouple,
    },
  };
}
export function clearSelectedGuests() {
  return {
    type: ActionType.CLEAR_SELECTED_GUESTS,
  };
}
export function onSeatReorder(sourceIndex, hoverIndex, tableUuid, forceReorder) {
  return {
    type: ActionType.REORDER_SEATS,
    payload: {
      sourceIndex,
      hoverIndex,
      tableUuid,
      forceReorder,
    },
  };
}
export function reorderSeatsInTable(seats) {
  return {
    type: ActionType.REORDER_SEATS_IN_TABLE,
    payload: { seats },
  };
}
export function pushSeatsToTable(tableUuid, guestsByGuestDistinction) {
  return {
    type: ActionType.PUSH_SEATS_TO_TABLE,
    payload: { tableUuid, guestsByGuestDistinction },
  };
}
export function addPeopleToTable(tableUuid, guestsByGuestDistinction) {
  return {
    type: ActionType.ADD_PEOPLE_TO_TABLE,
    payload: { tableUuid, guestsByGuestDistinction },
  };
}
export function addPersonToSeat(newSeat) {
  return {
    type: ActionType.ADD_PERSON_TO_SEAT,
    payload: { newSeat },
  };
}
export function removeSeatFromTable(seatUUID) {
  return {
    type: ActionType.CLEAR_SEAT,
    payload: { seatUUID },
  };
}
export function handleExpandGroup(guestGroup) {
  return {
    type: ActionType.EXPAND_GUEST_GROUP,
    payload: { guestGroup },
  };
}
export function searchGuestList(searchToken) {
  return {
    type: ActionType.SEARCH_GUEST_LIST,
    payload: { searchToken },
  };
}
export function toggleFilter(forceClose) {
  return {
    type: ActionType.TOGGLE_FILTER,
    payload: { forceClose },
  };
}
export function toggleFacetValue(bucketType) {
  return {
    type: ActionType.TOGGLE_FACET_VALUE,
    payload: { bucketType },
  };
}
export function resetFacetValues() {
  return {
    type: ActionType.RESET_FACET_VALUES,
  };
}
export function loadGuestList() {
  return {
    type: ActionType.LOAD_GUEST_LIST,
  };
}
export function loadFacetCounts() {
  return {
    type: ActionType.LOAD_FACET_COUNTS,
  };
}
export function unseatSelectedGuests() {
  return { type: ActionType.UNSEAT_SELECTED_GUESTS };
}
export function updateGuestList(updateSeatsRequest) {
  return {
    type: ActionType.UPDATE_GUEST_LIST,
    payload: {
      seats: updateSeatsRequest,
    },
  };
}
export function updateGuestListAction() {
  return (dispatch, getState) => {
    dispatch(updateGuestList(getState().seatingChart.updateSeatsRequest));
  };
}
export function startDrag(people) {
  return {
    type: ActionType.DRAG_STARTED,
    payload: { people },
  };
}
export function startTableDrag() {
  return {
    type: ActionType.DRAG_TABLE_STARTED,
  };
}
export function endDrag() {
  return {
    type: ActionType.DRAG_ENDED,
  };
}
export function setActiveSeatInput(seatUUID) {
  return {
    type: ActionType.SET_ACTIVE_SEAT_INPUT,
    payload: { seatUUID },
  };
}
export function showBanner(bannerText, bannerType, bannerID) {
  return {
    type: ActionType.SHOW_BANNER,
    payload: { bannerText, bannerType, bannerID },
  };
}
export function hideBanner(bannerID) {
  return {
    type: ActionType.HIDE_BANNER,
    payload: { bannerID },
  };
}
export function fetchChart(chartUuid) {
  return (dispatch, getState) => {
    dispatch(requestChart());

    const fetchChartRequest = {
      seating_chart_uuid: chartUuid == null ? getState().seatingChart.seatingChart.uuid : chartUuid,
    };

    return fetch('/web-api/v1/seatingchart/fetch', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fetchChartRequest),
    })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveChart(json));
        dispatch(loadGuestList());
        dispatch(toggleFilter(true));
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({
            headline: 'There was an error with your request. Please try again.',
          })
        );
        // Fetch or receive failed, do nothing for now except hide the spinner so it doesn't spin forever
        dispatch(hideSpinner());
      });
  };
}

const handleErrors = (error, dispatch, shouldRefetch) => {
  dispatch(
    toastsActions.negative({ headline: 'There was an error with your request. Please try again.' })
  );
  // We're going to attempt to reload the chart
  if (shouldRefetch) {
    dispatch(fetchChart());
  }

  throw new Error('error');
};

export function fetchChartList() {
  return dispatch => {
    dispatch(requestCharts());
    return fetch(`/web-api/v1/seatingchart/summaries`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(json => dispatch(receiveCharts(json)));
  };
}

export function deleteSeatingChart(chartUuid) {
  return dispatch =>
    fetch(`/web-api/v1/seatingchart/${chartUuid}`, {
      method: 'DELETE',
      credentials: 'same-origin',
    })
      .catch(error => handleErrors(error, dispatch, true))
      .then(() => dispatch(deleteChart()))
      .then(() => dispatch(toastsActions.positive({ headline: 'Chart Deleted' })))
      .then(() => {
        const href = '/wedding/manage/seating';
        window.location.href = href;
      });
}

export function deleteChartFromList(chartUUID) {
  return dispatch =>
    fetch(`/web-api/v1/seatingchart/${chartUUID}`, {
      method: 'DELETE',
      credentials: 'same-origin',
    })
      .catch(error => handleErrors(error, dispatch, true))
      .then(() => dispatch(fetchChartList()))
      .then(() => dispatch(hideModal()))
      .then(() => dispatch(toastsActions.positive({ headline: 'Chart Deleted' })));
}

export function redirectToChart(uuid) {
  return (dispatch, getState) => {
    dispatch(redirectChart());
    const chartUuid = uuid == null ? getState().seatingChart.seatingChart.uuid : uuid;
    window.location.href = `/wedding/manage/seating-chart/${chartUuid}`;
  };
}

export function editChart(chartRequest) {
  return dispatch => {
    dispatch(updateChart());
    return fetch('/web-api/v1/', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(chartRequest),
    })
      .then(response => response.json())
      .catch(error => handleErrors(error, dispatch, true));
  };
}

export function removeTable(tableUuid) {
  return (dispatch, getState) => {
    const seatingChartUuid = getState().seatingChart.seatingChart.uuid;
    const deleteTableRequest = {
      uuid: tableUuid,
      seating_chart_uuid: seatingChartUuid,
    };
    dispatch(requestChart());
    dispatch(deleteTable());
    return fetch('/web-api/v1/seatingchart/tables/delete', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(deleteTableRequest),
    })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveChart(json));
        dispatch(loadGuestList());
        dispatch(toggleFilter(true));
      })
      .catch(error => handleErrors(error, dispatch, true));
  };
}

export function addNewTables(tableRequest, shouldHandleErrors) {
  const newTablesRequest = { ...tableRequest };

  return (dispatch, getState) => {
    newTablesRequest.seating_chart_uuid = getState().seatingChart.seatingChart.uuid;
    const { tables } = getState().seatingChart;
    newTablesRequest.num_tables = _isEmpty(tables) ? 0 : _size(tables);
    dispatch(requestChart());
    return fetch('/web-api/v1/seatingchart/tables', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newTablesRequest),
    })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveChart(json));
        dispatch(loadGuestList());
        dispatch(toggleFilter(true));
      })
      .catch(error => {
        if (shouldHandleErrors) {
          handleErrors(error, dispatch, true);
        }
      });
  };
}

// shouldFetch is true when editing table seats/name. False when reordering (list view)
export function updateTables(tables, shouldFetch) {
  return (dispatch, getState) => {
    if (shouldFetch) {
      dispatch(requestChart());
    }
    const updateTablesRequest = {
      seating_chart_uuid: getState().seatingChart.seatingChart.uuid,
      tables,
    };

    return fetch('/web-api/v1/seatingchart/tables', {
      method: 'PUT',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateTablesRequest),
    })
      .then(response => response.json())
      .then(json => {
        if (shouldFetch) {
          dispatch(receiveChart(json));
          dispatch(loadGuestList());
          dispatch(toggleFilter(true));
        }
      })
      .catch(error => handleErrors(error, dispatch, true));
  };
}

export function updateSeats() {
  return (dispatch, getState) => {
    const updateSeatsRequest = {};
    updateSeatsRequest.seats = getState().seatingChart.updateSeatsRequest;

    if (updateSeatsRequest.seats.length === 0) {
      // No seats in request, don't update.
      return null;
    }
    updateSeatsRequest.seating_chart_uuid = getState().seatingChart.seatingChart.uuid;
    dispatch(clearSelectedGuests());

    return fetch('/web-api/v1/seatingchart/table/seats', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateSeatsRequest),
    })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveChart(json));
      })
      .then(() => {
        dispatch(loadGuestList());
      })
      .catch(error => handleErrors(error, dispatch, true));
  };
}

// Onboarding steps: Look into properly handling failed requests here. Right now leave as is.
export function addNewChart(chartRequest, newTables) {
  return dispatch => {
    const request = chartRequest;
    dispatch(createChart());
    return fetch('/web-api/v1/seatingchart', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    })
      .then(response => response.json())
      .then(json => {
        dispatch(receiveChart(json));
        dispatch(loadGuestList());
        dispatch(toggleFilter(true));
      })
      .catch(error => handleErrors(error, dispatch, false))
      .then(() => {
        if (newTables != null && newTables.num_tables > 0) {
          dispatch(addNewTables(newTables, false)) // If this errors out, the chart would have already been created. May need to handle something here
            .then(() => dispatch(redirectToChart()));
        } else {
          dispatch(redirectToChart());
        }
      });
  };
}

export function createAndFetchEvent(eventRequest) {
  return dispatch =>
    ApiService.post('/web-api/v2/cms/events', eventRequest)
      .then(response => {
        if (response.id != null) {
          const event = { event_id: response.id, name: response.name };
          dispatch(setNewChartEvent(event));
          dispatch(onboardingTableStep());
        }
      })
      .then(() => dispatch(fetchEventsByDate()))
      // TODO: add a toast notification
      .catch(error => handleErrors(error, dispatch, false));
}

export function createEventAndChart(eventReq, tables) {
  return dispatch =>
    ApiService.post('/web-api/v2/cms/events', eventReq)
      .then(response => {
        if (response.id !== null) {
          const event = { event_id: response.id, name: response.name };
          dispatch(setNewChartEvent(event));
          dispatch(addNewChart(event, tables));
        }
      })
      .then(() => dispatch(toastsActions.positive({ headline: 'Event Added' })))
      .then(() => dispatch(fetchEventsByDate()))
      .catch(error => handleErrors(error, dispatch, false));
}
