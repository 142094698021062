import { matchPath } from 'react-router-dom';

const routeChangeExceptions = [
  {
    id: 'checklist',
    path: '/wedding/manage/wedding-checklist',
    scrollLocationId: null, // don't scroll
    offset: 0,
  },
  {
    id: 'checklistTask',
    path: '/wedding/manage/wedding-checklist/:taskId',
    scrollLocationId: 'checklistMainContainer',
    offset: 120, // main nav height + checklist nav height
  },
  {
    id: 'paperPLP',
    path: '/wedding-planning/:cardType/shop',
    scrollLocationId: null,
    offset: 0,
  },
  {
    id: 'budget',
    path: '/wedding/manage/budget',
    scrollLocationId: null,
    offset: 0,
  },
];

const routeSniffer = (path = '', id, returnMatchOnly = true) => {
  const component = routeChangeExceptions.find(route => route.id === id);
  const match = matchPath(path, { path: component.path });

  if (returnMatchOnly) return match;
  return { ...component, ...match };
};

export default routeSniffer;
