import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BodyBase } from '@zola/zola-ui/src/typography/Paragraphs';
import { COLORS3, FONT, SPACING, ZINDEX } from '@zola/zola-ui/src/styles/emotion';
import { inputBaseStyles } from '@zola/zola-ui/src/components/Form/form.styles';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

const SUGGESTION_HEIGHT = '47px';

const concealScrollbars = css`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled.form`
  padding: ${SPACING.S24};
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERY_V2.DESKTOP} {
    width: 740px;
    padding: ${SPACING.S48};
  }

  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    padding-bottom: 0;
    max-width: 423px;
    margin: 0 auto;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.XS};

  ${MEDIA_QUERY_V2.DESKTOP} {
    align-items: center;
    text-align: center;
  }
`;

export const Dek = styled(BodyBase)`
  ${MEDIA_QUERY_V2.DESKTOP} {
    max-width: 430px;
  }
`;

export const Flex = styled.div`
  display: flex;
  gap: ${SPACING.S32};
  margin: ${SPACING.S24} 0 ${SPACING.LG};

  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    flex-direction: column;
  }
`;

export const FormFields = styled.div`
  ${MEDIA_QUERY_V2.DESKTOP} {
    flex: 0 0 237px;
  }
`;

export const GapControl = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING.S16};
  width: 100%;

  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    display: grid;
    grid-gap: ${SPACING.S16} ${SPACING.MD};
    grid-template-columns: 2fr 1fr;
  }
`;

export const StyledSkipBtn = styled(LinkV2)`
  display: flex;
  margin: ${SPACING.S16} auto 0;
`;

export const AssetContainer = styled.div`
  position: relative;
  background-color: ${COLORS3.BLACK_005};
  border-radius: ${SPACING.XS};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${SPACING.S24} 0;
  gap: ${SPACING.MD};

  ${MEDIA_QUERY_V2.DESKTOP} {
    flex: 0 0 375px;
  }
`;

export const GraphText = styled(BodyBase)`
  padding: 0 ${SPACING.S24};
  text-align: center;
`;

export const Animation = styled.div`
  position: relative;
  height: 156px;
`;

export const BellCurveGraph = styled.img<{ triggerAnimation: boolean }>`
  width: 375px;
  height: 135px;
  object-fit: cover;
  object-position: left;
  padding-right: ${({ triggerAnimation }) => (!triggerAnimation ? 0 : 375)}px;
  background-color: ${COLORS3.BLACK_005};
  ${({ triggerAnimation }) => !triggerAnimation && `transition: 0.5s;`}

  // smaller than iPhone Pro Max
  @media (max-width: 429px) {
    object-position: center;
    max-width: 375px;
    width: 100%;
  }
`;

export const GraphLineContainer = styled.div<{ triggerAnimation: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0 ${SPACING.S48};
  opacity: ${({ triggerAnimation }) => (!triggerAnimation ? 1 : 0)};
  transition: 0.5s;
`;

export const GraphLineText = styled.span`
  font-size: ${FONT.SIZE.EXTRA_SMALL};
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
  color: ${COLORS3.BAY_100};
`;

export const AutosuggestContainer = styled.div`
  position: relative;

  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    grid-area: 1 / 1 / 1 / 1;
  }

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__suggestions-list {
    ${concealScrollbars}
    list-style-type: none;
    padding-left: 0;
  }
  .react-autosuggest__suggestions-container {
    position: absolute;
    right: 0;
    left: 0;
  }

  .react-autosuggest__suggestions-container--open {
    overflow-y: scroll;
    z-index: ${ZINDEX.middle};
    max-height: calc(${SUGGESTION_HEIGHT} * 5);
    border: none;
    box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.2);
    width: 100%;
    background-color: ${COLORS3.WHITE_100};
    text-align: left;
    color: ${COLORS3.BLACK_100};
    ${concealScrollbars}

    .react-autosuggest__suggestion {
      padding-left: ${SPACING.XS};
      cursor: pointer;
      height: ${SUGGESTION_HEIGHT};
      line-height: ${SUGGESTION_HEIGHT};
      &--highlighted {
        background-color: ${COLORS3.BLACK_005};
      }
    }
  }
  .react-autosuggest__input {
    position: relative;
    height: 44px;
    margin-top: ${SPACING.XS};
    ${inputBaseStyles()};

    ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
      height: 52px;
      margin-top: ${SPACING.XXS};
    }

    &::placeholder {
      cursor: default;
      font-weight: ${FONT.WEIGHT.REGULAR};
    }
  }
  .autosuggest__suggestion--first {
    border-top: none !important;
  }
`;

export const FormLabel = styled.label`
  color: ${COLORS3.BLACK_100};
  font-size: ${FONT.SIZE.SMALL};
  text-transform: none;
  letter-spacing: normal;
  font-weight: ${FONT.WEIGHT.REGULAR};
  // defensive styling for _core.css in web-wedding
  .form-label {
    margin-bottom: 0;
  }
  margin-bottom: ${SPACING.XXS};
`;

export const NoWhiteSpace = styled.span`
  white-space: nowrap;
`;

export const GuestCount = styled.div`
  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    grid-area: 1 / 2 / 1 / 2;
  }
`;

export const Total = styled.div`
  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    grid-area: 2 / 1 / 2 / 3;
  }
`;

export const ButtonContainer = styled.div`
  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    display: flex;
    justify-content: flex-end;
    padding: ${SPACING.S16};
    position: sticky;
    bottom: 0;
    margin: 0 -${SPACING.S24};
    border-top: 1px solid ${COLORS3.BLACK_030};
    background-color: ${COLORS3.WHITE_100};
  }
`;
