import styled from '@emotion/styled';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { BodyBase, BodySmall } from '@zola/zola-ui/src/typography/Paragraphs';
import { COLORS3 as COLORS, FONT, SPACING } from '@zola/zola-ui/src/styles/emotion';
import { COLORS_SEMANTIC } from '@zola/zola-ui/src/styles/emotion/colors3';
import { sharedStyles } from '../BudgetContainer/BudgetContainer.styles';

export const Text = styled(BodyBase)<{ isOverdue?: boolean }>`
  color: ${({ isOverdue }) => (isOverdue ? COLORS_SEMANTIC.NEGATIVE_100 : COLORS.BLACK_100)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: ${SPACING.S16};
`;

export const BudgetUpcomingPaymentButton = styled(ButtonV3)`
  ${sharedStyles.rowButton};
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)};
  display: block;
  padding: ${SPACING.S16} 0 ${SPACING.MD};
  text-align: left;
  &:hover {
    ${Text} {
      text-decoration: underline;
    }
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Amount = styled(BodyBase)`
  color: ${COLORS.BLACK_075};
`;

export const Subtext = styled(BodySmall)`
  line-height: ${FONT.SIZE.SMALLER};
  color: ${COLORS.BLACK_050};
  margin-top: ${SPACING.XS};
  display: flex;
  align-items: center;
  gap: ${SPACING.XXS};
`;

export const VendorCatTitle = styled(BodySmall)`
  &:not(:last-of-type) {
    margin-bottom: ${SPACING.S16};
  }
`;
