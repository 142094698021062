import React, { useState } from 'react';

import type { WBudgetItemView } from '@zola/svc-web-api-ts-client';
import type { MappedWAccountVendorView } from 'api/vendorMarketplaceApi';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { CustomCircleIcon } from '@zola/zola-ui/src/components/CircleIconRebrand/CircleIconRebrand';

import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { useAppDispatch } from 'reducers/useAppDispatch';
import getZolaImageFromUuid from '@zola-helpers/client/dist/es/util/getZolaImageFromUuid';
import getVendorIcon from '@zola/zola-ui/src/util/getVendorIcon';
import {
  getVendorTaxonomyKeyFromVendorType,
  VendorTypeEnum,
} from '@zola-helpers/client/dist/es/marketplace';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';

import { useBudgetDrawerLayer } from '../../util/useBudgetDrawerLayer';
import { updateBudgetItem } from '../../util/api';
import { mapItemToUpdateItemRequest, mapItemTypeToItemTypeDetails } from '../../util/mappers';

import { useBudgetContext } from '../../context';

import {
  StyledDrawerTitle,
  DrawerSubTitle,
  NotFound,
  VendorOption,
  VendorImg,
  VendorName,
  StyledRadio,
  BUDGET_VENDOR_THUMBNAIL_SIZE,
} from '../BudgetItemAddVendor.styles';
import { handleBudgetRouteChange } from '../../routes/handleBudgetRouteChange';

type BudgetAddExistingAccountVendorProps = {
  item: WBudgetItemView;
  itemUuid: string;
  selectableVendors: MappedWAccountVendorView[];
  setViewToNew: () => void;
  pathname: string;
};

export const BudgetAddExistingAccountVendor = ({
  item,
  itemUuid,
  selectableVendors,
  pathname,
  setViewToNew,
}: BudgetAddExistingAccountVendorProps) => {
  const dispatch = useAppDispatch();

  const [localVendorUuid, setLocalVendorUuid] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    actions: { receiveBudgetItem },
    state: { item_types = [] },
    isBudgetRevamp,
  } = useBudgetContext();

  const handleVendorSelection = (uuid: string | undefined) => {
    setLocalVendorUuid(uuid);
  };

  const handleCancel = () => {
    handleBudgetRouteChange(`/item/${itemUuid}`);
  };

  const handleSave = () => {
    const request = {
      ...mapItemToUpdateItemRequest(item, isBudgetRevamp),
      account_vendor_uuid: localVendorUuid,
    };

    setIsSubmitting(true);
    updateBudgetItem(request)
      .then(res => {
        receiveBudgetItem(res);
        dispatch(
          toastsActions.positive({
            headline: 'Updated',
          })
        );
        handleBudgetRouteChange(`/item/${itemUuid}`, { preventIfUnsaved: false });
      })
      .catch(() => {
        setIsSubmitting(false);
        dispatch(
          toastsActions.negative({
            headline: 'There was an error updating your budget item.',
          })
        );
      });
  };

  useBudgetDrawerLayer(
    {
      key: pathname,
      useClose: false,
      cancelButtonDisabled: isSubmitting,
      saveButtonDisabled: isSubmitting || !localVendorUuid,
      onSave: handleSave,
      onBack: handleCancel,
      onUnsavedChanges: localVendorUuid
        ? () => {
            handleBudgetRouteChange(`/item/${itemUuid}/add-vendor`);
          }
        : undefined,
    },
    isSubmitting,
    [localVendorUuid]
  );

  const { vendor_type } = item;
  const { display_name } = mapItemTypeToItemTypeDetails(item_types, vendor_type);
  const vendorDisplayName = display_name?.toLowerCase();

  return (
    <>
      <StyledDrawerTitle>Select {vendorDisplayName}</StyledDrawerTitle>
      <DrawerSubTitle>Select one of your booked vendors, or add them now.</DrawerSubTitle>
      {selectableVendors.map(({ vendor_name, storefront_photo_uuid, account_vendor_uuid }) => (
        <VendorOption key={account_vendor_uuid} htmlFor={`${account_vendor_uuid}-radio`}>
          {storefront_photo_uuid ? (
            <VendorImg
              src={`${getZolaImageFromUuid(
                storefront_photo_uuid
              )}?w=${BUDGET_VENDOR_THUMBNAIL_SIZE}`}
              alt={vendor_name}
            />
          ) : (
            <CustomCircleIcon
              image={getVendorIcon(
                getVendorTaxonomyKeyFromVendorType(vendor_type as VendorTypeEnum)
              )}
              bgColor={COLORS3.BLACK_015}
              size={BUDGET_VENDOR_THUMBNAIL_SIZE}
            />
          )}
          <VendorName>{vendor_name}</VendorName>
          <StyledRadio
            id={`${account_vendor_uuid}-radio`}
            checked={account_vendor_uuid === localVendorUuid}
            onChange={() => handleVendorSelection(account_vendor_uuid)}
          />
        </VendorOption>
      ))}
      <NotFound>Don&apos;t see your {vendorDisplayName}?</NotFound>
      <ButtonV3 onClick={setViewToNew} variant="secondary">
        Add your {vendorDisplayName}
      </ButtonV3>
    </>
  );
};
