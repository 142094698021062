import React from 'react';
import { Route } from 'react-router';

export const getBusinessUnitFromWeddingsRoute = (path: string) => {
  let businessUnit = 'WEDDING_PLANNING';
  if (path.indexOf('wedding/manage/guests') > -1) businessUnit = 'WEDDING_GUEST_LIST';
  if (path.indexOf('wedding/manage/website') > -1) businessUnit = 'WEDDING_WEBSITE';
  if (path.indexOf('wedding/manage/wedding-checklist') > -1) businessUnit = 'WEDDING_CHECKLIST';
  return businessUnit;
};

export const renderNotFoundRoute = (path: string): JSX.Element => {
  return (
    <Route
      path={path}
      getComponent={location => {
        // Renders the not found page from web-preauth
        const encodedPath = location.location.pathname
          ? `?path=${encodeURIComponent(location.location.pathname)}`
          : '';
        window.location.assign(`/not-found${encodedPath}`);
      }}
    />
  );
};

export const redirectToNotFound = (): void => {
  return window.location.assign('/not-found');
};
