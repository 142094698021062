import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

// Middlewares
import segmentMiddleware from './eventsTrackingMiddleware';
import trackPageViewMiddleware from './trackPageViewMiddleware';

// Reducers
import finalReducers from '../reducers';

/* eslint no-underscore-dangle: ["error", { "allow": ["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */
const composeEnhancers =
  window.zola.env === 'development' || window.zola.env === 'staging'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

let middlewares = [thunk, trackPageViewMiddleware];

if (typeof window.analytics !== 'undefined') {
  middlewares = [...middlewares, segmentMiddleware];
}

const enhancers = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(finalReducers, enhancers);
const persistor = persistStore(store);

export default {
  store,
  persistor,
};
