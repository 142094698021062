import { CARD_TYPE_MAP } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import { URL_SLUG_BY_CARD_TYPE } from '@zola/zola-ui/src/paper/cards/constants/urlSlugByCardType';
import { SupportedLeadCardType } from 'cards/types/supportedLeadCardTypes';

export const getLeadCardBySlug = (slug: string): SupportedLeadCardType | undefined => {
  if (!slug) return undefined;

  // Paper add-ons all share the same slug, and should all return PAPER_ADD_ONS
  if (slug === URL_SLUG_BY_CARD_TYPE[CARD_TYPE_MAP.paperAddOns]) return CARD_TYPE_MAP.paperAddOns;

  // DETAILS & ENCLOSURES share the same slug, but always return DETAILS.
  if (slug === URL_SLUG_BY_CARD_TYPE[CARD_TYPE_MAP.details]) return CARD_TYPE_MAP.details;

  // Other lead card types have unique slugs
  return (Object.keys(URL_SLUG_BY_CARD_TYPE) as Array<SupportedLeadCardType>).find(
    key => URL_SLUG_BY_CARD_TYPE[key] === slug
  );
};
