import ApiService from '@zola-helpers/client/dist/es/http/api';
import type {
  WThemeDetailView,
  WThemeGroupDetailView,
  WWeddingThemeV2View,
} from '@zola/svc-web-api-ts-client';

import type { AppThunk } from 'reducers/useAppDispatch';
import * as ActionType from './types/WeddingThemeActionTypes';
import type { WeddingThemeActionTypes } from './types/WeddingThemeActionTypes';

export function receiveWeddingTheme(json: WWeddingThemeV2View): WeddingThemeActionTypes {
  return {
    type: ActionType.RECEIVE_WEDDING_THEME,
    payload: {
      theme: json,
    },
  };
}

export function receiveWeddingThemeDetail(json: WThemeDetailView): WeddingThemeActionTypes {
  return {
    type: ActionType.RECEIVE_WEDDING_THEME_DETAIL,
    payload: {
      themeDetail: json,
    },
  };
}

export function getWeddingWebsiteThemeDetail(themeKey: string): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    return ApiService.get<WThemeGroupDetailView>(`/web-api/v2/weddingTheme/getThemeGroup`)
      .then(response => {
        const themeDetail = response.themes.find(theme => theme.key === themeKey);
        if (themeDetail) {
          dispatch(receiveWeddingThemeDetail(themeDetail));
        }
      })
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Unable to get wedding theme detail');
      });
  };
}

export function getWeddingWebsiteThemeV2(): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    return ApiService.get<WWeddingThemeV2View>('/web-api/v2/weddingTheme/get')
      .then((response: WWeddingThemeV2View) => {
        dispatch(receiveWeddingTheme(response));
        getWeddingWebsiteThemeDetail(response.theme_key);
      })
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Unable to get wedding theme');
      });
  };
}
