import { combineReducers } from 'redux';
import { reducer as albumDetails } from './albumDetails';
import { reducer as albumItem } from './albumItem';
import { reducer as albumCustomizeNav } from './albumCustomizeNav';
import { reducer as draftsAndOrders } from './draftsAndOrders';
import { reducer as gallery } from './gallery';
import { reducer as galleryV2 } from './galleryV2';
import { reducer as modal } from './modal';
import { reducer as project } from './project';
import { reducer as contentLayout } from './contentLayout';
import { reducer as coverCustomization } from './coverCustomization';
import { reducer as uiSettings } from './uiSettings';
import { reducer as albumOptionsMenu } from './albumOptionsMenu';

const photobooks = combineReducers({
  albumDetails,
  albumItem,
  albumCustomizeNav,
  draftsAndOrders,
  gallery,
  galleryV2,
  modal,
  project,
  contentLayout,
  coverCustomization,
  uiSettings,
  albumOptionsMenu,
});

export default photobooks;
