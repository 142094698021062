import type { WBudgetItemPaymentView, WBudgetTaxonomyNodeView } from '@zola/svc-web-api-ts-client';

export const getTaxonomyNodeByUuid = (nodeUuid: string, nodes: WBudgetTaxonomyNodeView[]) =>
  nodes?.find(node => node.uuid === nodeUuid);

export const getBudgetItemByUuid = (itemUuid: string, nodes: WBudgetTaxonomyNodeView[]) => {
  const nodeWithItem = nodes?.find(node => node.items?.find(item => item.uuid === itemUuid));
  return nodeWithItem?.items?.find(item => item.uuid === itemUuid);
};

export const getBudgetPaymentByUuid = (paymentUuid: string, payments?: WBudgetItemPaymentView[]) =>
  payments?.find(payment => payment.uuid === paymentUuid);
