import { useEffect } from 'react';

const useSimpleScrollLock = (shouldLock: boolean) => {
  useEffect(() => {
    if (shouldLock) document.body.style.overflowY = 'hidden';
    else document.body.style.overflowY = '';
    return () => {
      document.body.style.overflowY = '';
    };
  }, [shouldLock]);
};

export default useSimpleScrollLock;
