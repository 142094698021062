import _sortBy from 'lodash/sortBy';
import { groupByCategory } from 'photobooks/utils/gallery';
import {
  getAlbumPhotos,
  getAlbumCategories,
  assignPhotosToAlbum,
  deleteAlbumPhoto,
  deleteAlbumPhotos,
} from 'photobooks/utils/api';
import { sortGalleryPhotosByCategoryPosition } from 'photobooks/utils/albumUtils';
import { getGalleryPhotos } from './selectors';

export const clearGallery = () => ({
  type: 'CLEAR_GALLERY',
});

export const setPhotos = payload => ({
  type: 'SET_PHOTOS',
  payload,
});

export const setCategories = payload => ({
  type: 'SET_CATEGORIES',
  payload,
});

export const resetGallery = () => ({ type: 'RESET_GALLERY_STATE' });

export const setGalleryDragState = payload => ({
  type: 'SET_GALLERY_DRAG_STATE',
  payload,
});

export const setPhotoMenuDragState = payload => ({
  type: 'SET_PHOTOMENU_DRAG_STATE',
  payload,
});

const setSortedPhotos = ({ dispatch, categories, photos }) => {
  const sortedPhotos = sortGalleryPhotosByCategoryPosition({
    categories,
    photoGroups: groupByCategory(photos),
  });

  return dispatch(setPhotos(sortedPhotos));
};

export const fetchPhotos = payload => {
  return dispatch => {
    return getAlbumPhotos(payload).then(data => {
      const photos = _sortBy(data, d => d.position);

      dispatch(setPhotos(photos));

      return photos;
    });
  };
};

export const fetchCategories = payload => {
  return dispatch => {
    return getAlbumCategories(payload).then(data => {
      const categories = _sortBy(data, d => d.position);

      dispatch(setCategories(categories));
      return categories;
    });
  };
};

export const simpleAssignPhotos = ({ albumUuid, uploadedImages }) => {
  return (dispatch, getState) => {
    const allPhotos = getGalleryPhotos(getState());

    // Images are already ordered by position.
    const offset = allPhotos.length === 0 ? 1 : allPhotos[allPhotos.length - 1].position + 1;

    return assignPhotosToAlbum({ albumUuid, images: uploadedImages, offset })
      .then(() =>
        Promise.all([
          dispatch(fetchCategories({ albumUuid })),
          dispatch(fetchPhotos({ albumUuid })),
        ])
      )
      .then(([categories, photos]) => setSortedPhotos({ dispatch, categories, photos }))
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };
};

export const assignPhotosToCategoryLite = ({ albumUuid, images, mediaCategoryUuid }) => {
  return (dispatch, getState) => {
    const allPhotos = getGalleryPhotos(getState());
    // Images are already ordered by position.
    const offset = allPhotos.length === 0 ? 1 : allPhotos[allPhotos.length - 1].position + 1;

    return assignPhotosToAlbum({
      albumUuid,
      images,
      offset,
      mediaCategoryUuid: mediaCategoryUuid || null,
    })
      .then(() =>
        Promise.all([
          dispatch(fetchCategories({ albumUuid })),
          dispatch(fetchPhotos({ albumUuid })),
        ])
      )
      .then(([categories, photos]) => setSortedPhotos({ dispatch, categories, photos }));
  };
};

export const deletePhoto = (albumUuid, imageUUID) => dispatch => {
  return deleteAlbumPhoto({ albumUuid, imageUUID })
    .then(() => getAlbumPhotos({ albumUuid }))
    .then(data => dispatch(setPhotos(data)));
};

export const deletePhotos = (albumUuid, imageUuids) => dispatch => {
  return deleteAlbumPhotos({ albumUuid, imageUuids })
    .then(() => getAlbumPhotos({ albumUuid }))
    .then(data => dispatch(setPhotos(data)));
};
