const init = {
  navNextEnabled: true,
  navNextDisabledText: null,
};

const reducer = (state = init, action) => {
  switch (action.type) {
    case 'SET_NAV_NEXT_ENABLED':
      return {
        ...state,
        navNextEnabled: action.payload,
      };
    case 'SET_NAV_NEXT_DISABLED_TEXT':
      return {
        ...state,
        navNextDisabledText: action.payload || null,
      };
    default:
      return state;
  }
};

export default reducer;
