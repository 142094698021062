import { getReferrer } from '../util/getReferrer';

// This middleware listens for location changes and sends a Segment "Page loaded" page event.
// It will also store the referrer on a global to be used by other Segment event types.

export const trackPageView = (getState, action) => {
  const state = getState();

  // Never update document.referrer directly as other anayltics platforms (e.g. GA) use it for attribution.
  // Instead, save it in the window.zola global so it can be referenced by other track events.
  const referrer = getReferrer(state && state.routing && state.routing.locationBeforeTransitions);
  if (window && window.zola) {
    window.zola.referrer = referrer;
  }

  const { payload } = action;
  const path = payload && payload.pathname;
  const search = payload && payload.search;
  const url = window && window.location && window.location.href;
  const nextRoutePageContext = {
    path: path || '',
    referrer,
    search: search || '',
    title: document && document.title,
    url: url || '',
  };

  // Send the page view event if analytics are available
  if (window.analytics) {
    window.analytics.page(nextRoutePageContext);
  }
};

const trackPageViewMiddleware = store => next => action => {
  const { type } = action;
  switch (type) {
    case '@@router/INIT_PATH':
    case '@@router/UPDATE_PATH':
    case '@@router/LOCATION_CHANGE':
      trackPageView(store.getState, action);
      return next(action);
    default:
      return next(action);
  }
};

export default trackPageViewMiddleware;
