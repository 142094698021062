import { AccountWeddingLocationView } from '@zola/svc-marketplace-ts-types';
import type {
  WBudgetItemView,
  WBudgetItemTypeView,
  WBudgetTaxonomyNodeView,
  WBudgetView,
  WBudgetItemPaymentView,
  WSuggestedBudgetByCategoryView,
} from '@zola/svc-web-api-ts-client';
import type { MappedWAccountVendorView } from 'api/vendorMarketplaceApi';
import type { BudgetSortBy } from './types';

export enum ActionTypes {
  RECEIVE_BUSY = 'RECEIVE_BUSY',
  RECEIVE_BUDGET = 'RECEIVE_BUDGET',
  RECEIVE_ITEM = 'RECEIVE_ITEM',
  RECEIVE_ITEMS = 'RECEIVE_ITEMS',
  RECEIVE_DELETED_ITEM = 'RECEIVE_DELETED_ITEM',
  RECEIVE_ITEM_TYPES = 'RECEIVE_ITEM_TYPES',
  RECEIVE_TAXONOMY_NODE = 'RECEIVE_TAXONOMY_NODE',
  RECEIVE_TAXONOMY_NODES = 'RECEIVE_TAXONOMY_NODES',
  RECEIVE_DELETED_TAXONOMY_NODE = 'RECEIVE_DELETED_TAXONOMY_NODE',
  RECEIVE_UNSAVED_MAX_SPEND = 'RECEIVE_UNSAVED_MAX_SPEND',
  RECEIVE_UNSAVED_ITEM = 'RECEIVE_UNSAVED_ITEM',
  RECEIVE_UNSAVED_TAXONOMY_NODE = 'RECEIVE_UNSAVED_TAXONOMY_NODE',
  RECEIVE_UNSAVED_ORDERED_TAXONOMY_NODES = 'RECEIVE_UNSAVED_ORDERED_TAXONOMY_NODES',
  CLEAR_MAX_SPEND_FROM_UNSAVED = 'CLEAR_MAX_SPEND_FROM_UNSAVED',
  CLEAR_ITEM_FROM_UNSAVED = 'CLEAR_ITEM_FROM_UNSAVED',
  CLEAR_TAXONOMY_NODE_FROM_UNSAVED = 'CLEAR_TAXONOMY_NODE_FROM_UNSAVED',
  CLEAR_ORDERED_TAXONOMY_NODES_FROM_UNSAVED = 'CLEAR_ORDERED_TAXONOMY_NODES_FROM_UNSAVED',
  RECEIVE_PAYMENT = 'RECEIVE_PAYMENT',
  RECEIVE_DELETED_PAYMENT = 'RECEIVE_DELETED_PAYMENT',
  RECEIVE_ACCOUNT_VENDORS = 'RECEIVE_ACCOUNT_VENDORS',
  RECEIVE_MARKETPLACE_LOCATION = 'RECEIVE_MARKETPLACE_LOCATION',
  RECEIVE_EMAIL_PREFERENCES = 'RECEIVE_EMAIL_PREFERENCES',
  RECEIVE_SORT_BY = 'RECEIVE_SORT_BY',
  RECEIVE_SUGGESTED_ALLOCATIONS = 'RECEIVE_SUGGESTED_ALLOCATIONS',
  CLEAR_SUGGESTED_ALLOCATIONS = 'CLEAR_SUGGESTED_ALLOCATIONS',
}

type RECEIVE_BUDGET_ACTION = {
  type: typeof ActionTypes.RECEIVE_BUDGET;
  payload: {
    budget: WBudgetView;
  };
};

type RECEIVE_BUSY_ACTION = {
  type: typeof ActionTypes.RECEIVE_BUSY;
  payload: {
    busy: boolean;
  };
};

type RECEIVE_ITEM_ACTION = {
  type: typeof ActionTypes.RECEIVE_ITEM;
  payload: {
    item: WBudgetItemView;
  };
};

type RECEIVE_ITEMS_ACTION = {
  type: typeof ActionTypes.RECEIVE_ITEMS;
  payload: {
    items: WBudgetItemView[];
  };
};

type RECEIVE_DELETED_ITEM_ACTION = {
  type: typeof ActionTypes.RECEIVE_DELETED_ITEM;
  payload: {
    itemUuid: string;
  };
};

type RECEIVE_ITEM_TYPES_ACTION = {
  type: typeof ActionTypes.RECEIVE_ITEM_TYPES;
  payload: {
    itemTypes: WBudgetItemTypeView[];
  };
};

type RECEIVE_TAXONOMY_NODE_ACTION = {
  type: typeof ActionTypes.RECEIVE_TAXONOMY_NODE;
  payload: {
    taxonomyNode: WBudgetTaxonomyNodeView;
  };
};

type RECEIVE_TAXONOMY_NODES_ACTION = {
  type: typeof ActionTypes.RECEIVE_TAXONOMY_NODES;
  payload: {
    taxonomyNodes: WBudgetTaxonomyNodeView[];
  };
};

type RECEIVE_DELETED_TAXONOMY_NODE_ACTION = {
  type: typeof ActionTypes.RECEIVE_DELETED_TAXONOMY_NODE;
  payload: {
    taxonomyNodeUuid: string;
  };
};

type RECEIVE_UNSAVED_MAX_SPEND_ACTION = {
  type: typeof ActionTypes.RECEIVE_UNSAVED_MAX_SPEND;
  payload: {
    maxSpend: number;
  };
};

type RECEIVE_UNSAVED_ITEM_ACTION = {
  type: typeof ActionTypes.RECEIVE_UNSAVED_ITEM;
  payload: {
    itemUuid: string;
    fields: WBudgetItemView;
  };
};

type RECEIVE_UNSAVED_TAXONOMY_NODE_ACTION = {
  type: typeof ActionTypes.RECEIVE_UNSAVED_TAXONOMY_NODE;
  payload: {
    node: WBudgetTaxonomyNodeView;
  };
};

type RECEIVE_UNSAVED_ORDERED_TAXONOMY_NODES_ACTION = {
  type: typeof ActionTypes.RECEIVE_UNSAVED_ORDERED_TAXONOMY_NODES;
  payload: {
    nodes: WBudgetTaxonomyNodeView[];
  };
};

type CLEAR_MAX_SPEND_FROM_UNSAVED_ACTION = {
  type: typeof ActionTypes.CLEAR_MAX_SPEND_FROM_UNSAVED;
};

type CLEAR_ITEM_FROM_UNSAVED_ACTION = {
  type: typeof ActionTypes.CLEAR_ITEM_FROM_UNSAVED;
  payload: {
    itemUuid: string;
  };
};

type CLEAR_TAXONOMY_NODE_FROM_UNSAVED_ACTION = {
  type: typeof ActionTypes.CLEAR_TAXONOMY_NODE_FROM_UNSAVED;
};

type CLEAR_ORDERED_TAXONOMY_NODES_FROM_UNSAVED_ACTION = {
  type: typeof ActionTypes.CLEAR_ORDERED_TAXONOMY_NODES_FROM_UNSAVED;
};

type RECEIVE_PAYMENT_ACTION = {
  type: typeof ActionTypes.RECEIVE_PAYMENT;
  payload: {
    payment: WBudgetItemPaymentView;
  };
};

type RECEIVE_DELETED_PAYMENT_ACTION = {
  type: typeof ActionTypes.RECEIVE_DELETED_PAYMENT;
  payload: {
    paymentUuid: string;
  };
};

type RECEIVE_ACCOUNT_VENDORS_ACTION = {
  type: typeof ActionTypes.RECEIVE_ACCOUNT_VENDORS;
  payload: {
    accountVendors: MappedWAccountVendorView[];
  };
};

type RECEIVE_MARKETPLACE_LOCATION_ACTION = {
  type: typeof ActionTypes.RECEIVE_MARKETPLACE_LOCATION;
  payload: {
    marketplaceLocation: AccountWeddingLocationView;
  };
};

type RECEIVE_EMAIL_PREFERENCES_ACTION = {
  type: typeof ActionTypes.RECEIVE_EMAIL_PREFERENCES;
  payload: {
    enabled: boolean;
  };
};

type RECEIVE_SORT_BY = {
  type: typeof ActionTypes.RECEIVE_SORT_BY;
  payload: {
    sortBy: BudgetSortBy;
  };
};

type RECEIVE_SUGGESTED_ALLOCATIONS = {
  type: typeof ActionTypes.RECEIVE_SUGGESTED_ALLOCATIONS;
  payload: {
    suggestedAllocations: WSuggestedBudgetByCategoryView;
  };
};

type CLEAR_SUGGESTED_ALLOCATIONS = {
  type: typeof ActionTypes.CLEAR_SUGGESTED_ALLOCATIONS;
};

export type BudgetActionTypes =
  | RECEIVE_BUDGET_ACTION
  | RECEIVE_BUSY_ACTION
  | RECEIVE_ITEM_ACTION
  | RECEIVE_ITEMS_ACTION
  | RECEIVE_DELETED_ITEM_ACTION
  | RECEIVE_ITEM_TYPES_ACTION
  | RECEIVE_TAXONOMY_NODE_ACTION
  | RECEIVE_TAXONOMY_NODES_ACTION
  | RECEIVE_DELETED_TAXONOMY_NODE_ACTION
  | RECEIVE_UNSAVED_MAX_SPEND_ACTION
  | RECEIVE_UNSAVED_ITEM_ACTION
  | RECEIVE_UNSAVED_TAXONOMY_NODE_ACTION
  | RECEIVE_UNSAVED_ORDERED_TAXONOMY_NODES_ACTION
  | CLEAR_ITEM_FROM_UNSAVED_ACTION
  | CLEAR_MAX_SPEND_FROM_UNSAVED_ACTION
  | CLEAR_TAXONOMY_NODE_FROM_UNSAVED_ACTION
  | CLEAR_ORDERED_TAXONOMY_NODES_FROM_UNSAVED_ACTION
  | RECEIVE_PAYMENT_ACTION
  | RECEIVE_DELETED_PAYMENT_ACTION
  | RECEIVE_ACCOUNT_VENDORS_ACTION
  | RECEIVE_MARKETPLACE_LOCATION_ACTION
  | RECEIVE_EMAIL_PREFERENCES_ACTION
  | RECEIVE_SORT_BY
  | RECEIVE_SUGGESTED_ALLOCATIONS
  | CLEAR_SUGGESTED_ALLOCATIONS;
