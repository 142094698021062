import ApiService from '../../../util/api';
import ActionTypes from '../projectActionTypes';
import { getProjectUUID, getAllProjectMeta } from '../../selectors/cardProjectSelector';

function requestProjectMeta() {
  return {
    type: ActionTypes.REQUEST_PROJECT_META,
  };
}

function receiveProjectMeta(projectMeta, projectUUID) {
  return {
    type: ActionTypes.RECEIVE_PROJECT_META,
    payload: {
      projectMeta,
      projectUUID,
    },
  };
}

export function fetchProjectMeta(projectUUID, forceFetch = false) {
  return (dispatch, getState) => {
    if (!forceFetch) {
      // If the project metadata is already available, don't fetch it again.
      const allMeta = getAllProjectMeta(getState());
      const existingProjectMeta = allMeta[projectUUID];
      if (existingProjectMeta) return Promise.resolve(existingProjectMeta);
    }

    dispatch(requestProjectMeta());
    return ApiService.get(`/web-api/v2/card-project/${projectUUID}/meta`).then(json => {
      dispatch(receiveProjectMeta(json, projectUUID));
      return json;
    });
  };
}

export function updateProjectMeta() {
  return (dispatch, getState) => {
    const state = getState();
    const projectUUID = getProjectUUID(state);
    return ApiService.put(`/web-api/v2/card-project/${projectUUID}/meta`)
      .then(json => {
        dispatch(receiveProjectMeta(json, projectUUID));
        return json;
      })
      .catch(() => undefined);
  };
}
