import ApiService from '@zola-helpers/client/dist/es/http/api';
import type {
  AddBudgetItemRequest,
  AddTaxonomyNodeRequest,
  ReorderBudgetItemsRequest,
  ReorderTaxonomyNodesRequest,
  UpdateBudgetItemRequest,
  UpdateBudgetRequest,
  UpdateTaxonomyNodeRequest,
  WBudgetItemPaymentView,
  WBudgetItemSummaryView,
  WBudgetItemTypeView,
  WBudgetItemView,
  WBudgetTaxonomyNodeView,
  WBudgetView,
  WSuggestBudgetByCategoryRequest,
  WSuggestedBudgetByCategoryView,
  WSuggestedTotalBudgetView,
  WSuggestTotalBudgetRequest,
} from '@zola/svc-web-api-ts-client';
import type {
  AddOrUpdateBudgetPaymentRequest,
  CreateBudgetRequestWithStringOrigin,
} from '../context';

export const createBudget = (body: CreateBudgetRequestWithStringOrigin): Promise<WBudgetView> => {
  return ApiService.post('/web-api/v1/budgets', body);
};

export const updateBudget = (body: UpdateBudgetRequest): Promise<WBudgetView> => {
  return ApiService.put('/web-api/v1/budgets/update', body);
};

export const getBudgetItem = (budgetItemUuid: string): Promise<WBudgetItemView> => {
  return ApiService.get(`/web-api/v1/budgets/items/${budgetItemUuid}`);
};

export const deleteBudgetItem = (budgetItemUuid: string): Promise<void> => {
  return ApiService.delete(`/web-api/v1/budgets/items/${budgetItemUuid}`);
};

export const getBudgetByAccountId = (): Promise<WBudgetView> => {
  return ApiService.get('/web-api/v1/budgets/by-account');
};

// Must provide either taxonomy_node_uuid or add_taxonomy_node_request
export const addBudgetItem = (body: AddBudgetItemRequest): Promise<WBudgetItemView> => {
  return ApiService.post('/web-api/v1/budgets/items', body);
};

// Must provide either taxonomy_node_uuid or add_taxonomy_node_request
export const updateBudgetItem = (body: UpdateBudgetItemRequest): Promise<WBudgetItemView> => {
  return ApiService.put('/web-api/v1/budgets/items/update', body);
};

export const getBudget = (budgetUuid: string): Promise<WBudgetView> => {
  return ApiService.get(`/web-api/v1/budgets/${budgetUuid}`);
};

export const getTaxonomyNode = (taxonomyNodeUuid: string): Promise<WBudgetTaxonomyNodeView> => {
  return ApiService.get(`/web-api/v1/budgets/taxonomy-nodes/${taxonomyNodeUuid}`);
};

export const deleteTaxonomyNode = (taxonomyNodeUuid: string): Promise<void> => {
  return ApiService.delete(`/web-api/v1/budgets/taxonomy-nodes/${taxonomyNodeUuid}`);
};

export const addTaxonomyNode = (body: AddTaxonomyNodeRequest): Promise<WBudgetTaxonomyNodeView> => {
  return ApiService.post('/web-api/v1/budgets/taxonomy-nodes', body);
};

export const updateTaxonomyNode = (
  body: UpdateTaxonomyNodeRequest
): Promise<WBudgetTaxonomyNodeView> => {
  return ApiService.put('/web-api/v1/budgets/taxonomy-nodes/update', body);
};

export const reorderTaxonomyNodes = (
  body: ReorderTaxonomyNodesRequest
): Promise<WBudgetTaxonomyNodeView[]> => {
  return ApiService.put('/web-api/v1/budgets/taxonomy-nodes/reorder', body);
};

export const reorderBudgetItems = (
  body: ReorderBudgetItemsRequest
): Promise<WBudgetTaxonomyNodeView[]> => {
  return ApiService.put('/web-api/v1/budgets/items/reorder', body);
};

export const getBudgetItemByAccountVendorUuid = (
  accountVendorUuid: string
): Promise<WBudgetItemSummaryView[]> => {
  const body = { accountVendorUuid };
  return ApiService.post('/web-api/v1/budgets/items/by-account-vendor', body);
};

export const getPayment = (paymentUuid: string): Promise<WBudgetItemPaymentView> => {
  return ApiService.get(`/web-api/v1/budgets/payments/${paymentUuid}`);
};

export const deletePayment = (paymentUuid: string): Promise<void> => {
  return ApiService.delete(`/web-api/v1/budgets/payments/${paymentUuid}`);
};

// Must provide paid_at if payment_type is 'PAID'
// Must provide due_at if payment_type is 'PENDING'
export const addPayment = (
  body: AddOrUpdateBudgetPaymentRequest
): Promise<WBudgetItemPaymentView> => {
  return ApiService.post('/web-api/v1/budgets/payments', body);
};

// Must provide paid_at if payment_type is 'PAID'
// Must provide due_at if payment_type is 'PENDING'
export const updatePayment = (
  body: AddOrUpdateBudgetPaymentRequest
): Promise<WBudgetItemPaymentView> => {
  return ApiService.put('/web-api/v1/budgets/payments/update', body);
};

export const getBudgetItemTypes = (): Promise<WBudgetItemTypeView[]> => {
  return ApiService.get('/web-api/v1/budgets/items/types');
};

export const getSuggestedBudgetTotal = (
  body: WSuggestTotalBudgetRequest
): Promise<WSuggestedTotalBudgetView> => {
  return ApiService.post('/web-api/v1/suggested-budget/total', body);
};

export const getSuggestedBudgetByCategory = (
  body: WSuggestBudgetByCategoryRequest
): Promise<WSuggestedBudgetByCategoryView> => {
  return ApiService.post('/web-api/v1/suggested-budget/by-category', body);
};
