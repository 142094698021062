import _difference from 'lodash/difference';
import {
  fetchAlbumItemByUuid as fetchAlbumItemByUuidAPI,
  removeSpread,
  saveViewedSpreads,
  updateSingleSlot,
  updateBulkSlot,
} from 'photobooks/utils/api';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { getProjectUuid } from 'photobooks/reducers/project/selectors';
import ApiService from '../../../util/api';
import {
  getAlbumItemUUID,
  getAllSlots,
  getImageOriginalRefs,
  getSyncedViewedSpreads,
  getViewedSpreads,
} from './selectors';

const albumItemIsFetching = () => ({
  type: 'FETCH_ALBUM_ITEM_BY_UUID',
});

const albumItemFulfilled = payload => ({
  type: 'FETCH_ALBUM_ITEM_BY_UUID_FULFILLED',
  payload,
});

const albumItemRejected = payload => ({
  type: 'FETCH_ALBUM_ITEM_BY_UUID_REJECTED',
  payload,
});

export function fetchAlbumItemByUuid(projectUuid, albumItemUuid, activeSpreadIndex = 0) {
  return dispatch => {
    dispatch(albumItemIsFetching());
    return fetchAlbumItemByUuidAPI({ projectUuid, albumItemUuid })
      .then(res => {
        dispatch(albumItemFulfilled({ albumItem: res, activeSpreadIndex }));
        return res;
      })
      .catch(err => {
        dispatch(albumItemRejected(err));
      });
  };
}

const requestSlotUpdate = () => ({
  type: 'REQUEST_SLOT_UPDATE',
});

const receiveSlotUpdate = payload => ({
  type: 'RECEIVE_SLOT_UPDATE',
  payload,
});

const rejectedSlotUpdate = payload => ({
  type: 'REJECTED_SLOT_UPDATE',
  payload,
});

export const clearSlotImage = payload => ({
  type: 'CLEAR_SLOT_IMAGE',
  payload,
});

export function removeDeletedOriginalPhoto(imageUuid) {
  return (dispatch, getState) => {
    const allSlots = getAllSlots(getState());
    const imageOriginalRefs = getImageOriginalRefs(getState());
    const slotsToClear = allSlots.filter(
      slot =>
        slot.image_uuid &&
        (slot.image_uuid === imageUuid || imageOriginalRefs[slot.image_uuid] === imageUuid)
    );
    const slotUuidsToClear = slotsToClear.map(slot => {
      return slot.image_uuid;
    });

    return dispatch(clearSlotImage({ slotUuidsToClear }));
  };
}

export function updateSlot(projectUuid, albumUuid, data) {
  return dispatch => {
    dispatch(requestSlotUpdate());
    return updateSingleSlot({ projectUuid, albumUuid, data })
      .then(res => {
        dispatch(receiveSlotUpdate(res));
      })
      .catch(err => {
        dispatch(rejectedSlotUpdate(err));
      });
  };
}

export function updateSlotBulk(projectUuid, albumUuid, data) {
  return dispatch => {
    dispatch(requestSlotUpdate());
    return updateBulkSlot({ projectUuid, albumUuid, data })
      .then(res => {
        dispatch(receiveSlotUpdate(res));
      })
      .catch(err => {
        dispatch(rejectedSlotUpdate(err));
      });
  };
}

export const updateActiveSpread = payload => ({
  type: 'UPDATE_ACTIVE_SPREAD',
  payload,
});

export const navigateActiveSpread = payload => ({
  type: 'NAVIGATE_ACTIVE_SPREAD',
  payload,
});

export const clearAlbum = payload => ({
  type: 'CLEAR_ALBUM',
  payload,
});

const requestAddSpread = () => ({
  type: 'REQUEST_ADD_SPREAD',
});

const receiveAddSpread = payload => ({
  type: 'RECEIVE_ADD_SPREAD',
  payload,
});

const rejectedAddSpread = payload => ({
  type: 'REJECTED_ADD_SPREAD',
  payload,
});

export function addSpreadToProject(
  projectUuid,
  albumItemUuid,
  spreadIndex,
  newActiveSpreadIndex = 0
) {
  return dispatch => {
    dispatch(requestAddSpread());
    return ApiService.post(
      `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumItemUuid}/spread/${spreadIndex}`
    )
      .then(data => {
        dispatch(
          receiveAddSpread({
            albumItem: data,
            newActiveSpreadIndex,
          })
        );
      })
      .catch(err => {
        dispatch(toastsActions.negative({ headline: 'Cannot add more spreads to this album' }));
        dispatch(rejectedAddSpread(err));
      });
  };
}

const requestRemoveSpread = () => ({
  type: 'REQUEST_REMOVE_SPREAD',
});

const receiveRemoveSpread = payload => ({
  type: 'RECEIVE_REMOVE_SPREAD',
  payload,
});

const rejectedRemoveSpread = payload => ({
  type: 'REJECTED_REMOVE_SPREAD',
  payload,
});

export function removeSpreadsFromProject(
  projectUuid,
  albumItemUuid,
  spreadIndexes,
  newActiveSpreadIndex = 0
) {
  return dispatch => {
    dispatch(requestRemoveSpread());
    return removeSpread({ projectUuid, albumItemUuid, spreadIndexes })
      .then(data => {
        dispatch(
          receiveRemoveSpread({
            albumItem: data,
            newActiveSpreadIndex,
          })
        );
      })
      .catch(() => {
        dispatch(rejectedRemoveSpread());
      });
  };
}

const requestMoveSpread = () => ({
  type: 'REQUEST_MOVE_SPREAD',
});

const receiveMoveSpread = payload => ({
  type: 'RECEIVE_MOVE_SPREAD',
  payload,
});

const rejectedMoveSpread = payload => ({
  type: 'REJECTED_MOVE_SPREAD',
  payload,
});

export function moveSpreadInProject(
  projectUuid,
  albumItemUuid,
  spreadIndex,
  newIndex,
  newActiveSpreadIndex = 0
) {
  return dispatch => {
    dispatch(requestMoveSpread());
    return ApiService.put(
      `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumItemUuid}/spread`,
      {
        spreadIndex,
        newIndex,
      }
    )
      .then(data => {
        dispatch(
          receiveMoveSpread({
            albumItem: data,
            newActiveSpreadIndex,
          })
        );
      })
      .catch(() => {
        dispatch(rejectedMoveSpread());
      });
  };
}

const requestUpdateLayout = () => ({
  type: 'REQUEST_UPDATE_LAYOUT',
});

const receiveUpdateLayout = payload => ({
  type: 'RECEIVE_UPDATE_LAYOUT',
  payload,
});

const rejectedUpdateLayout = payload => ({
  type: 'REJECTED_UPDATE_LAYOUT',
  payload,
});

export function updateCustomizationLayout(
  projectUuid,
  albumItemUuid,
  contentLayoutUuid,
  customizationSlots,
  spreadIndex,
  layoutIndex
) {
  return dispatch => {
    dispatch(requestUpdateLayout());
    return ApiService.put(
      `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumItemUuid}/layout`,
      {
        content_layout_uuid: contentLayoutUuid,
        customization_slots: customizationSlots,
        layout_index: layoutIndex,
        spread_index: spreadIndex,
      }
    )
      .then(data => {
        dispatch(
          receiveUpdateLayout({
            albumItem: data,
            newActiveSpreadIndex: spreadIndex,
          })
        );
      })
      .catch(() => {
        dispatch(rejectedUpdateLayout());
      });
  };
}

export const markSpreadAsViewed = spreadIndex => dispatch => {
  dispatch({
    type: 'MARK_SPREAD_AS_VIEWED',
    payload: { spreadIndex },
  });
};

export function syncViewedSpreads() {
  return (dispatch, getState) => {
    const state = getState();

    // If the project or album are unavailable, abort now.
    const projectUuid = getProjectUuid(state);
    const albumUuid = getAlbumItemUUID(state);
    if (!projectUuid || !albumUuid) {
      return Promise.resolve();
    }

    // Check if any viewed spreads need to be synced
    const syncedViewedSpreads = getSyncedViewedSpreads(state);
    const currentViewedSpreads = getViewedSpreads(state);
    const unsyncedViewedSpreads = _difference(currentViewedSpreads, syncedViewedSpreads);

    if (unsyncedViewedSpreads.length === 0) {
      return Promise.resolve();
    }

    return saveViewedSpreads({
      projectUuid,
      albumUuid,
      spreadIndexes: unsyncedViewedSpreads,
    })
      .then(res => {
        const { spreads } = res.data.customization_data;
        dispatch({
          type: 'RECEIVE_SAVE_VIEWED_SPREADS',
          payload: { spreads },
        });
      })
      .catch(error => {
        dispatch({
          type: 'REJECTED_SAVE_VIEWED_SPREADS',
          payload: error,
        });
      });
  };
}
