import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.less';

class MinimalLoader extends Component {
  constructor(props) {
    super(props);

    this.showLoader = this.showLoader.bind(this);

    this.state = {
      timeout: null,
      visible: !props.delay,
    };
  }

  componentDidMount() {
    if (this.props.delay) {
      this.setDelayTimeout();
    }
  }

  componentWillUnmount() {
    this.setState({ timeout: null });
    clearTimeout(this.state.timeout);
  }

  setDelayTimeout() {
    this.setState({
      timeout: setTimeout(this.showLoader, 500),
    });
  }

  showLoader() {
    this.setState({
      visible: true,
    });
  }

  render() {
    const { visible } = this.state;

    return (
      <div className={styles.zolaLoader} style={{ visibility: visible ? 'visible' : 'hidden' }}>
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
        <div className={styles.petal} />
      </div>
    );
  }
}

MinimalLoader.propTypes = {
  delay: PropTypes.bool.isRequired,
};

MinimalLoader.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  delay: false,
};

export default MinimalLoader;
