import React, { useEffect, useState } from 'react';
import { Form, Field, useForm, useFormState } from 'react-final-form';

import type { WBudgetItemView } from '@zola/svc-web-api-ts-client';

import FormInput from '@zola/zola-ui/src/components/Form/inputV3/FinalFormInput';
import FinalFormDropdown from '@zola/zola-ui/src/components/Form/dropdownV3/FinalFormDropdown';
import FinalFormDatePickerV2 from '@zola/zola-ui/src/components/Form/DatePickerInputV2/FinalFormDatePickerV2';
import {
  AutosuggestVenueFieldV2Props,
  VendorOrGoogleSearchResult,
} from 'components/common/form/AutosuggestVenueField/AutosuggestVenueFieldV2';

import { notDesktopV2 } from '@zola-helpers/client/dist/es/util/responsive';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { useAppDispatch } from 'reducers/useAppDispatch';
import {
  EMAIL,
  MAX_LENGTH_500,
  REQUIRED,
  DATE_AFTER_NOW,
} from '@zola/zola-ui/src/components/Form/util/validations';
import {
  getAccountVendors,
  BookVendorFormValues,
  VendorTaxonomyNodeId,
  createBookedVendorV2,
} from 'api/vendorMarketplaceApi';
import { finalFormUSStates } from 'components/common/form/CountryCodes';
import { getVendorTaxonomyId } from '@zola-helpers/client/dist/es/marketplace';
import { DrawerTitle } from 'components/common/zolaUI/Drawer';
import { updateBudgetItem } from '../../util/api';
import {
  mapAccountVendorsToReferenceVendorUuids,
  mapBookedVendorFormValues,
  mapItemToUpdateItemRequest,
} from '../../util/mappers';

import { useBudgetDrawerLayer } from '../../util/useBudgetDrawerLayer';

import { useBudgetContext } from '../../context';

import { StyledAutosuggestVenueFieldV2, Grid, RequiredAst } from '../BudgetItemAddVendor.styles';
import { handleBudgetRouteChange } from '../../routes/handleBudgetRouteChange';

type BudgetAddNewAccountVendorProps = {
  item: WBudgetItemView;
  itemUuid: string;
  pathname: string;
  previousPathname?: string;
  isBookableVendorType?: boolean;
};

const BudgetAddNewAccountVendorForm = ({
  item,
  itemUuid,
  pathname,
  isBookableVendorType,
  previousPathname,
}: BudgetAddNewAccountVendorProps): JSX.Element => {
  const { batch, change, submit } = useForm();
  const { values, dirty, submitting, invalid } = useFormState();

  const {
    state: { accountVendors = [] },
    isBudgetRevamp,
  } = useBudgetContext();

  const [selectedSuggestion, setSelectedSuggestion] = useState<VendorOrGoogleSearchResult>();
  const [disabledFields, setDisabledFields] = useState<{
    city?: boolean;
    stateProvince?: boolean;
    email?: boolean;
  }>({});
  const categoryId = getVendorTaxonomyId(item.vendor_type) as VendorTaxonomyNodeId;
  const suggestionsToFilter = mapAccountVendorsToReferenceVendorUuids(accountVendors);

  useEffect(() => {
    const disabled = values.referenceVendorId
      ? {
          city: !!values.city,
          email: !!values.email,
          stateProvince: !!values.stateProvince,
        }
      : {};
    setDisabledFields(disabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.referenceVendorId]);

  const handleVendorMarketplaceSelectionV2: AutosuggestVenueFieldV2Props['onSuggestSelect'] = suggestion => {
    const { type } = suggestion;
    if (type === 'google') {
      return;
    }

    setSelectedSuggestion(suggestion);
    const { name, uuid, address, email } = suggestion;
    batch(() => {
      change('referenceVendorId', uuid);
      change('vendorName', name);
      change('city', address.city);
      change('stateProvince', address.stateProvince);
      change('email', email);
    });
  };

  const resetSelectedVendor = () => {
    // if user previously selected a suggested vendor, then edits the name, onBlur, remove the reference and unlock the disabled fields
    if (values.referenceVendorId && values.vendorName !== selectedSuggestion?.name) {
      change('referenceVendorId', '');
      setSelectedSuggestion(undefined);
    }
  };

  const handleCancel = () => {
    handleBudgetRouteChange(previousPathname || `/item/${itemUuid}`, { preventIfUnsaved: false });
  };

  useBudgetDrawerLayer(
    {
      key: pathname,
      useClose: false,
      cancelButtonDisabled: submitting,
      saveButtonDisabled: submitting || invalid,
      onSave: submit, // eslint-disable-line @typescript-eslint/no-misused-promises
      onBack: handleCancel,
      onUnsavedChanges: dirty
        ? () => {
            handleBudgetRouteChange(`/item/${itemUuid}/add-vendor`);
          }
        : undefined,
    },
    submitting,
    [dirty, invalid]
  );

  return (
    <>
      <DrawerTitle>Add booked vendor</DrawerTitle>
      {isBookableVendorType && (
        <Field<string>
          name="vendorName"
          validate={MAX_LENGTH_500}
          render={({ input, meta }) => {
            return (
              <StyledAutosuggestVenueFieldV2
                allowGoogleSuggestions={false}
                categoryId={categoryId}
                id="budget-booked-vendor-name"
                input={{ ...input }}
                label={<>Name {!isBudgetRevamp && <RequiredAst>*</RequiredAst>}</>}
                maxChars={500}
                meta={{ ...meta }}
                onInputBlur={resetSelectedVendor}
                onSuggestSelect={handleVendorMarketplaceSelectionV2}
                placeholder="Their business is called..."
                showResetButton={false}
                allowShowMore={false}
                suggestionsToFilter={suggestionsToFilter}
              />
            );
          }}
        />
      )}
      <Grid>
        {isBookableVendorType && (
          <Grid columns="1fr 1fr">
            <FormInput
              name="city"
              label="City"
              type="text"
              id="address-city"
              placeholder="They're in..."
              disabled={disabledFields.city}
              validate={REQUIRED}
            />
            <FinalFormDropdown
              name="stateProvince"
              label="State"
              options={finalFormUSStates}
              id="address-stateProvince"
              disabled={disabledFields.stateProvince}
              placeholder="Select"
              validate={REQUIRED}
              isNativeDropdown={notDesktopV2()}
            />
          </Grid>
        )}
        {!isBookableVendorType && (
          <FormInput
            name="vendorName"
            label="Name"
            type="text"
            placeholder="Their business is called..."
            validate={REQUIRED}
          />
        )}
        <FormInput
          name="email"
          label="Email (optional)"
          type="text"
          id="email"
          placeholder={!isBookableVendorType ? "They're reachable at..." : ''}
          disabled={disabledFields.email}
          validate={EMAIL}
        />
        <Grid columns="1fr 1fr">
          <FinalFormDatePickerV2
            name="eventDate"
            label="Event date (optional)"
            id="event-date"
            validate={DATE_AFTER_NOW}
          />
          <></>
        </Grid>
      </Grid>
    </>
  );
};

export const BudgetAddNewAccountVendor = ({
  item,
  itemUuid,
  pathname,
  isBookableVendorType,
  previousPathname,
}: BudgetAddNewAccountVendorProps) => {
  const dispatch = useAppDispatch();
  const {
    actions: { receiveBudgetItem, receiveAccountVendors },
    isBudgetRevamp,
  } = useBudgetContext();

  const onFormSave = (values: BookVendorFormValues) => {
    const request = mapBookedVendorFormValues(values);
    createBookedVendorV2(request)
      .then(accountVendorResultsView => {
        if (!accountVendorResultsView.accountVendor?.uuid) {
          throw Error('Account vendor is null');
        }
        return updateBudgetItem({
          ...mapItemToUpdateItemRequest(item, isBudgetRevamp),
          account_vendor_uuid: accountVendorResultsView.accountVendor.uuid,
        });
      })
      .then(res => {
        receiveBudgetItem(res);
        dispatch(
          toastsActions.positive({
            headline: 'Added',
          })
        );
        handleBudgetRouteChange(`/item/${itemUuid}`, { preventIfUnsaved: false });
      })
      .then(getAccountVendors)
      .then(res => {
        receiveAccountVendors(res);
      })
      .catch((err: Partial<{ message?: string }>) => {
        dispatch(
          toastsActions.negative({
            headline: `There was an error updating your budget item. ${err.message}`,
          })
        );
      });
  };

  const PAPER_TYPES = ['SAVE_THE_DATES', 'PROGRAMS', 'PLACE_CARDS', 'THANK_YOU_CARDS'];
  const VENDOR_TYPE_IF_NULL = PAPER_TYPES.includes((item.item_type as string | undefined) || '')
    ? 'INVITATIONS'
    : 'GENERAL_SERVICES';

  return (
    <Form<BookVendorFormValues>
      onSubmit={onFormSave}
      initialValues={{
        vendorType: item.vendor_type || (isBudgetRevamp ? VENDOR_TYPE_IF_NULL : undefined),
      }}
    >
      {({ handleSubmit }) => (
        <form
          onSubmit={e => {
            handleSubmit(e)?.catch(() => undefined);
          }}
        >
          <BudgetAddNewAccountVendorForm
            pathname={pathname}
            item={item}
            itemUuid={itemUuid}
            isBookableVendorType={isBookableVendorType}
            previousPathname={previousPathname}
          />
        </form>
      )}
    </Form>
  );
};
