import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import {
  SortableItem,
  SortableItemLeft,
  ReorderButton,
  StyledReorderIcon,
  Text,
  EditButton,
} from './BudgetSortableItem.styles';

type BudgetSortableItemProps = {
  sortableItemUuid: string;
  onClickEdit: () => void;
  title: string;
};

export const BudgetSortableItem = ({
  sortableItemUuid,
  onClickEdit,
  title,
}: BudgetSortableItemProps): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: sortableItemUuid,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItem data-testid="BudgetSortableItem" ref={setNodeRef} style={style}>
      <SortableItemLeft>
        <ReorderButton variant="transparent" size="small" {...listeners} {...attributes}>
          <StyledReorderIcon height={24} width={24} />
        </ReorderButton>
        <Text>{title}</Text>
      </SortableItemLeft>
      <EditButton onClick={onClickEdit} role="button">
        Edit
      </EditButton>
    </SortableItem>
  );
};
