import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import ApiService from '@zola-helpers/client/dist/es/http/api';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import type {
  WCmsEventView,
  WMeetingResponseView,
  WZoomConnectionView,
  WZoomMeetingView,
  WZoomUserView,
} from '@zola/svc-web-api-ts-client';
import type { AppThunk } from 'reducers/useAppDispatch';
import * as ActionType from './types/ZoomActionTypes';

import { trackZoomSetupFlow } from '../util/trackingHelper';

import type { ZoomActionTypes, MeetingSignatureType } from './types/ZoomActionTypes';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);

const requestAccessToken = (code: string): ZoomActionTypes => ({
  type: ActionType.REQUEST_ACCESS_TOKEN,
  payload: { code },
});

const receiveAccessToken = (response: WZoomConnectionView): ZoomActionTypes => ({
  type: ActionType.RECEIVE_ACCESS_TOKEN,
  payload: response,
});

const removeAccessToken = (): ZoomActionTypes => ({
  type: ActionType.REMOVE_ACCESS_TOKEN,
});

const requestMeeting = (): ZoomActionTypes => ({
  type: ActionType.REQUEST_MEETING,
});

const receiveMeeting = (response: WZoomMeetingView): ZoomActionTypes => ({
  type: ActionType.RECEIVE_MEETING,
  payload: response,
});

const requestConnection = (): ZoomActionTypes => ({
  type: ActionType.REQUEST_CONNECTION,
});

const receiveConnection = (response: WZoomConnectionView): ZoomActionTypes => ({
  type: ActionType.RECEIVE_CONNECTION,
  payload: response,
});

const requestConnectionSettings = (): ZoomActionTypes => ({
  type: ActionType.REQUEST_CONNECTION_SETTINGS,
});

const receiveConnectionSettings = (response: MeetingSignatureType): ZoomActionTypes => ({
  type: ActionType.RECEIVE_CONNECTION_SETTINGS,
  payload: response,
});

const receiveZoomPlanType = (response: WZoomUserView): ZoomActionTypes => ({
  type: ActionType.RECEIVE_ZOOM_PLAN_TYPE,
  payload: response,
});

export function getAccessToken(code: string): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    dispatch(requestAccessToken(code));
    return ApiService.post<WZoomConnectionView>(`/web-api/v1/zoom/token/${code}`)
      .then((json: WZoomConnectionView) => {
        dispatch(receiveAccessToken(json));
        trackZoomSetupFlow('Flow Step Completed', {
          step_name: 'Zoom Authentication',
          step_number: 1,
          step_value_type: 'success',
        });
        dispatch(
          toastsActions.positive({ headline: 'You successfully connected your Zoom account!' })
        );
      })
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Sorry, we were unable to process your request');
      });
  };
}

export function revokeAccessToken(): AppThunk<Promise<ZoomActionTypes>> {
  return (dispatch): Promise<ZoomActionTypes> => {
    return ApiService.delete('/web-api/v1/zoom/token')
      .then(() => dispatch(removeAccessToken()))
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Sorry, we were unable to process your request');
      });
  };
}

export function getConnection(): AppThunk<Promise<WZoomConnectionView>> {
  return (dispatch): Promise<WZoomConnectionView> => {
    dispatch(requestConnection);
    return ApiService.get<WZoomConnectionView>('/web-api/v1/zoom/connection')
      .then((json: WZoomConnectionView) => {
        dispatch(receiveConnection(json));
        return json;
      })
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Sorry, we were unable to process your request');
      });
  };
}

export function getConnectionSettings(
  meetingId: string,
  role: string
): AppThunk<Promise<MeetingSignatureType>> {
  return (dispatch): Promise<MeetingSignatureType> => {
    dispatch(requestConnectionSettings());

    const reqBody = {
      meetingId,
      role,
    };

    return ApiService.post<MeetingSignatureType>(`/web-api/v1/zoom/signature/${meetingId}`, reqBody)
      .then((json: MeetingSignatureType) => {
        dispatch(receiveConnectionSettings(json));
        return json;
      })
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Sorry, we were unable to process your request');
      });
  };
}

export function fetchZoomPlanType(): AppThunk<Promise<void>> {
  return (dispatch): Promise<void> => {
    return ApiService.get<WZoomUserView>('/web-api/v1/zoom/me').then((response: WZoomUserView) => {
      dispatch(receiveZoomPlanType(response));
    });
  };
}

// When we get a passthrough Virtual Meeting from Zoom - times are in Zulu time and need to be converted.
function mapToLocalTimezones(
  zoomMeetingView: WMeetingResponseView
): WMeetingResponseView & { start_time: string } {
  const { start_time: startTime, timezone } = zoomMeetingView;
  const newStartTime = dayjs
    .utc(startTime)
    .tz(timezone || 'UTC')
    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
  return { ...zoomMeetingView, start_time: newStartTime };
}

export function getExternalMeeting(url: string): AppThunk<Promise<WZoomMeetingView>> {
  return (dispatch): Promise<WZoomMeetingView> => {
    dispatch(requestMeeting);
    return ApiService.post<WZoomMeetingView>('/web-api/v1/zoom/meeting/external', { url })
      .then(mapToLocalTimezones)
      .then((json: WZoomMeetingView) => {
        dispatch(receiveMeeting(json));
        dispatch(toastsActions.positive({ headline: 'Your Zoom meeting link is good to go.' }));
        return json;
      })
      .catch((err: Error) => {
        throw new Error((err && err.message) || 'Sorry, we were unable to process your request');
      });
  };
}

export function syncZoomMeeting(eventId: string): AppThunk<Promise<WCmsEventView | void>> {
  return (dispatch): Promise<WCmsEventView | void> => {
    return ApiService.put<WCmsEventView>('/web-api/v2/cms/event/virtual/sync', { eventId })
      .then((event: WCmsEventView) => {
        dispatch(toastsActions.positive({ headline: 'Zoom Meeting Synced' }));
        return event;
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({ headline: 'Something went wrong. Please try again later.' })
        );
      });
  };
}
