import { UNCATEGORIZED } from 'photobooks/constants/gallery';
import { groupByCategory } from 'photobooks/utils/gallery';

const withUpdatedPositions = items =>
  items.map((item, i) => (item.position === i + 1 ? item : { ...item, position: i + 1 })); // TODO: move to utils

export const withoutPhoto = (uuidToRemove, photos) => {
  const indexToRemove = photos.findIndex(p => p.uuid === uuidToRemove);

  // If the photo isn't found, return the unchanged photos
  if (indexToRemove === -1) return photos;

  return [
    ...photos.slice(0, indexToRemove), // photos preceding the removed one
    ...photos.slice(indexToRemove + 1).map(p => ({ ...p, position: p.position - 1 })), // following photos have their positions decremented by 1
  ];
};

export const withoutPhotos = (uuidsToRemove, photos) => {
  return uuidsToRemove.reduce((acc, uuid) => withoutPhoto(uuid, acc), photos);
};

export const removeCategoryFromPhotos = (categoryUuid, photos) => {
  return photos.map(photo => {
    if (photo.photobook_project_item_media_category_uuid === categoryUuid) {
      return { ...photo, photobook_project_item_media_category_uuid: null };
    }
    return photo;
  });
};

export const withoutCategory = (uuidToRemove, categories) => {
  const indexToRemove = categories.findIndex(c => c.uuid === uuidToRemove);

  // If the photo isn't found, return the unchanged categories
  if (indexToRemove === -1) return categories;

  return [
    ...categories.slice(0, indexToRemove), // categories preceding the removed one
    ...categories.slice(indexToRemove + 1).map(c => ({ ...c, position: c.position - 1 })), // following categories have their positions decremented by 1
  ];
};

export const moveCategory = (moved, target, insertBeforeTarget, categories) => {
  // Move existing categories into a new array (because Redux!)
  const result = [...categories];

  // Remove the "moved" category from its old place
  const movedIndex = result.findIndex(cat => cat.uuid === moved.uuid);
  result.splice(movedIndex, 1);

  // Insert the "moved" category before or after the target
  const targetIndex = result.findIndex(cat => cat.uuid === target.uuid);
  const insertionIndex = insertBeforeTarget ? targetIndex : targetIndex + 1;
  result.splice(insertionIndex, 0, moved);

  // Update all category positions
  return withUpdatedPositions(result);
};

export const movePhotos = (
  movedPhotos,
  targetPhoto,
  insertBeforeTarget,
  previouslyOrderedPhotos
) => {
  // Determine the target's position and category. This is done first because the target may actually be part of the moved photos.
  // If that occurs, the moved photos will simply be placed using the target's former position (NOT its index)
  const targetCategory = targetPhoto.photobook_project_item_media_category_uuid;
  const targetPosition = insertBeforeTarget ? targetPhoto.position : targetPhoto.position + 1;

  // Copy all previously ordered photos into a new array that we can manipulate freely
  const result = [...previouslyOrderedPhotos];

  // Add the target category to all moved photos
  const movedPhotosWithTargetCategory = movedPhotos.map(photo => ({
    ...photo,
    photobook_project_item_media_category_uuid: targetCategory,
  }));

  // Remove all moved photos from the new array
  movedPhotosWithTargetCategory.forEach(movedPhoto => {
    const movedIndex = result.findIndex(p => p.uuid === movedPhoto.uuid);
    result.splice(movedIndex, 1);
  });

  // Iterate over the remaining ordered photos until we find one that has a position equal or greater than the target position.
  let insertionIndex = result.findIndex(({ position }) => position >= targetPosition);
  if (insertionIndex === -1) {
    insertionIndex = result.length;
  }

  // Insert all moved photos at that index (i.e. right before the photo with the desired position)
  result.splice(insertionIndex, 0, ...movedPhotosWithTargetCategory);

  return withUpdatedPositions(result);
};

export const reorderPhotosByCategory = (photos, categories) => {
  const groupedByCategory = groupByCategory(photos);
  const orderedPhotos = categories
    .filter(category => !!groupedByCategory[category.uuid]) // Discard categories without photos
    .map(category => groupedByCategory[category.uuid]) // Map to an array of category photo arrays
    .reduce((a, b) => [...a, ...b], groupedByCategory[UNCATEGORIZED] || []); // Create a flat list of photos ordered by category

  return withUpdatedPositions(orderedPhotos);
};

export const movePhotosIntoCategory = (categoryUUID, photosToUpdate, allPhotos) => {
  const photoUUIDsToUpdate = photosToUpdate.map(p => p.uuid);
  return allPhotos.map(photo => {
    const needsToBeUpdated =
      photoUUIDsToUpdate.includes(photo.uuid) &&
      photo.photobook_project_item_media_category_uuid !== categoryUUID;

    return needsToBeUpdated
      ? { ...photo, photobook_project_item_media_category_uuid: categoryUUID }
      : photo;
  });
};
