import { useEffect, useState } from 'react';
import type { WBudgetItemView } from '@zola/svc-web-api-ts-client';
import type { BudgetSortBy } from '../context';
import { mapSortByToProp } from './mappers';

const useSortBy = (sortBy: BudgetSortBy, clickCount: number) => {
  const [sortProp, setSortProp] = useState<BudgetSortBy>(undefined);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | undefined>(undefined);

  useEffect(() => {
    if (clickCount > 0) {
      if (sortBy === sortProp) {
        if (sortDirection === 'desc') setSortDirection('asc');
        if (sortDirection === 'asc') setSortDirection(undefined);
        if (sortDirection === undefined) setSortDirection('desc');
      } else {
        setSortProp(sortBy);
        setSortDirection('desc');
      }
    }
  }, [clickCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortByFn = (itemA: WBudgetItemView, itemB: WBudgetItemView) => {
    const propA = mapSortByToProp(itemA, sortProp);
    const propB = mapSortByToProp(itemB, sortProp);
    if (typeof propA === 'string' && typeof propB === 'string') {
      if (sortDirection === 'desc') {
        // keeps empty strings at the bottom
        if (propA === '') return 1;
        if (propB === '') return -1;
        if (propA === propB) return 0;
        return propA.localeCompare(propB);
      }
      return propB.localeCompare(propA);
    }
    if (typeof propA === 'number' && typeof propB === 'number') {
      return sortDirection === 'asc' ? propA - propB : propB - propA;
    }
    return 0;
  };

  return { sortDirection, sortByFn };
};

export default useSortBy;
