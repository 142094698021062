import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { COLORS, FONT, SPACING } from '@zola/zola-ui/src/styles/emotion';
import ArrowIcon from '@zola/zola-ui/src/components/SvgIcons/Arrow';
import LinkV2 from '@zola/zola-ui/src/components/LinkV2/LinkV2';
import googleImg from '../../../../assets/images/powered_by_google_on_white.png';

export const autosuggestVenueFieldContainer = css`
  margin-bottom: ${SPACING.S24};

  .react-autosuggest__suggestions-container--open {
    li.react-autosuggest__suggestion {
      height: 50px;
      &:hover {
        cursor: pointer;
      }
    }
    .react-autosuggest__suggestion--first-google {
      border-top: 1px ${COLORS.ALTO} solid;
    }
  }
  &.with-google-suggestions ul.react-autosuggest__suggestions-list {
    background-image: url(${googleImg});
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: bottom;
    padding-bottom: ${SPACING.MD};
  }
`;

export const suggestionContainer = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: ${SPACING.XS} ${SPACING.MD};
  max-width: 100%;
  width: 100%;
`;

const preventTextWrapStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SuggestionTextMain = styled.div`
  ${FONT.textSizeRegular(FONT.FONT_SIZE_SMALLER)};
  font-weight: ${FONT.WEIGHT.BOLD};
  ${preventTextWrapStyles}
`;

export const SuggestionTextSecondary = styled.div`
  ${FONT.textSizeCompact(FONT.FONT_SIZE_EXTRA_SMALL)};
  color: ${COLORS.BOULDER};
  ${preventTextWrapStyles}
`;

const plainTextResultStyles = css`
  ${FONT.textSizeRegular(FONT.FONT_SIZE_SMALLER)};
  font-weight: ${FONT.WEIGHT.SEMI_BOLD};
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 ${SPACING.MD};
`;

export const ShowMoreContainer = styled.div`
  ${plainTextResultStyles};
  color: ${COLORS.LINK};
`;

export const NoResultsContainer = styled.div`
  ${plainTextResultStyles};
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  margin-left: ${SPACING.XXS};
`;

export const StyledLinkV2 = styled(LinkV2)`
  margin-bottom: 6px;
  margin-left: ${SPACING.XS};
`;
