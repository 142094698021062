import dayjs, { ConfigType as DayjsConfigType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import objectSupport from 'dayjs/plugin/objectSupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(objectSupport);
dayjs.extend(customParseFormat);

export const combineDateAndTime = (date: DayjsConfigType, time: DayjsConfigType) => {
  const dateString = dayjs(date).format('YYYY-MM-DD');
  const timeString = dayjs(time).format('HH:mm');
  return dayjs(`${dateString}T${timeString}Z`);
};

export const combineDateAndTimeUTC = (date: DayjsConfigType, time: DayjsConfigType) => {
  const dateString = dayjs(date).format('YYYY-MM-DD');
  const timeString = dayjs(time).format('HH:mm');
  return dayjs.utc(`${dateString}T${timeString}Z`);
};
