import _get from 'lodash/get';
import { UNCATEGORIZED } from 'photobooks/constants/gallery';

export const getTextCoverDesign = designTemplates =>
  designTemplates.find(template => template.album_cover_design_type === 'deboss-foil-free-text');

export const getBlankCoverDesign = designTemplates =>
  designTemplates.find(template => template.album_cover_design_type === 'none');

export const albumSizeToPts = sizeString => {
  const [width, height] = sizeString
    .trim()
    .split('x')
    .map(s => 72 * parseInt(s.trim(), 10));

  return { width, height };
};

export const getAlbumSize = albumItem => {
  const bookSize = _get(albumItem, ['variation', 'options', 'book-size']);

  return bookSize ? albumSizeToPts(bookSize) : { width: 1.0, height: 1.0 };
};

export const encodeCoverCustomizationRequest = ({ customizations, coverDesignTemplates }) => {
  const { albumSize } = customizations;

  const blankTemplate = getBlankCoverDesign(coverDesignTemplates);

  const textTemplate = getTextCoverDesign(coverDesignTemplates);

  const textTemplateMeta = textTemplate.metadata[0];

  const request = customizations.isBlank
    ? {
        album_cover_type_color_uuid: customizations.coverColor,
        front: { design_template_uuid: blankTemplate.uuid, slots: [] },
      }
    : {
        album_cover_type_color_uuid: customizations.coverColor,
        front: {
          design_template_uuid: textTemplate.uuid,
          slots: [
            {
              x: albumSize.width / 2 - textTemplateMeta.width / 2,
              y: albumSize.height / 2 - textTemplateMeta.height / 2,
              z: textTemplateMeta.z,
              width: textTemplateMeta.width,
              height: textTemplateMeta.height,
              content_type: 'TEXT',
              text_content: customizations.coverText,
              font: {
                album_cover_slot_font_uuid: customizations.font.uuid,
                album_cover_design_foil_color_uuid: customizations.foil.uuid,
                font_size: customizations.fontSize,
              },
            },
          ],
        },
      };

  return request;
};

export const decodeCoverCustomizationResponse = ({
  albumItem,
  coverDesignTemplates,
  coverFonts,
  coverFoilColors,
}) => {
  const coverColor = albumItem.details.find(detail => detail.key === 'album-cover-color').value;

  const coverData = albumItem.customization_data.cover;

  if (!coverData) {
    return { coverColor, albumSize: getAlbumSize(albumItem) };
  }

  const designTemplate = coverDesignTemplates.find(
    template => template.uuid === coverData.front.design_template_uuid
  );

  const isBlank = designTemplate.album_cover_design_type === 'none';

  const textSlot = isBlank ? '' : coverData.front.slots.find(slot => slot.content_type === 'TEXT');

  const coverText = textSlot.text_content;

  const font = coverFonts.find(
    coverFont => coverFont.uuid === (textSlot.font && textSlot.font.font_uuid)
  );

  const foil = coverFoilColors.find(
    coverFoil => coverFoil.uuid === (textSlot.font && textSlot.font.design_foil_color_uuid)
  );

  return {
    coverColor: coverData.album_cover_type_color_uuid,
    isBlank,
    fontSize: _get(textSlot, ['font', 'font_size']),
    font,
    foil,
    coverText,
    // TODO: resolve these fields when display boxes are supported
    hasDisplayBox: false,
    displayBoxText: '',
    albumSize: getAlbumSize(albumItem),
  };
};

export function createAlbumProject({
  createNewProject,
  projectUuid,
  selectedVariationUuid,
  coverColorUuid,
  selectedQuizLayout,
  setAlbumUuid,
  setProjectUuid,
  setBusy,
  failureToast,
  albumProjectCustomizationId,
}) {
  if (projectUuid) {
    if (setBusy) setBusy(false);
    return Promise.resolve();
  }

  return createNewProject({
    test_project: false,
    variation_uuid: selectedVariationUuid,
    cover_color_uuid: coverColorUuid,
    album_content_layout_style: selectedQuizLayout || 'BOLD',
    media_category_requests: [],
    album_project_customization_id: albumProjectCustomizationId,
  })
    .then(result => {
      const { uuid: newProjectUuid, project_items: projectItems, details: projectDetails } = result;

      const newAlbum = projectItems.find(each => each.type === 'ALBUM');
      const newAlbumUuid = newAlbum.project_item_uuid;

      // Refers to the saved project customization flow id generated upon project creation
      const currentProjectCustomizationId = projectDetails.find(
        detail => detail.key === 'album-project-customization-id'
      );

      if (setAlbumUuid) {
        setAlbumUuid(newAlbumUuid);
      }

      if (setProjectUuid) setProjectUuid(newProjectUuid);

      if (setBusy) setBusy(false);

      return {
        projectUuid: newProjectUuid,
        albumUuid: newAlbumUuid,
        projectItems,
        albumProjectCustomizationId: currentProjectCustomizationId.value,
      };
    })
    .catch(() => {
      failureToast({ headline: 'There was a problem creating your project...' });
      return Promise.resolve();
    });
}

export const sortGalleryPhotosByCategoryPosition = ({ categories = [], photoGroups = {} }) => {
  return categories
    .filter(category => !!photoGroups[category.uuid])
    .map(category => photoGroups[category.uuid])
    .reduce((a, b) => [...a, ...b], photoGroups[UNCATEGORIZED] || [])
    .map((photo, i) =>
      photo.position !== i + 1 ? { ...photo, position: i + 1, unsynced: true } : photo
    );
};

export const isSquareAlbum = booksize => {
  const parsed = booksize
    .split('x')
    .map(s => s.trim())
    .map(s => parseInt(s, 10));

  return parsed[0] === parsed[1];
};
