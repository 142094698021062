import type { CardType } from '@zola/zola-ui/src/paper/cards/constants/cardTypes';
import ApiService from 'util/api';

export const fetchAvailableSamplesCount = (cardType: CardType | undefined) => {
  if (!cardType) {
    return Promise.resolve(null);
  }

  return ApiService.get(`/web-api/v1/card-single-sample/availability/${cardType}`);
};
