import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import ApiService from '../../../util/api';

export const setActiveProjectsListing = activeView => ({
  type: 'SET_ACTIVE_PROJECTS_LISTING',
  payload: activeView,
});

export const projectsCountsIsFetching = () => ({
  type: 'FETCH_PROJECTS_COUNTS',
});

export const projectsCountsRejected = () => ({
  type: 'FETCH_PROJECTS_COUNTS_REJECTED',
});

const projectsCountsFulfilled = payload => ({
  type: 'FETCH_PROJECTS_COUNTS_FULLFILLED',
  payload,
});

export const emptyProjectsListing = () => ({
  type: 'EMPTY_PROJECTS_LISTING',
});

const draftIsFetching = () => ({
  type: 'FETCH_DRAFT_LIST',
});

const draftListFulfilled = payload => ({
  type: 'FETCH_DRAFT_LIST_FULLFILLED',
  payload,
});

const draftListRejected = () => ({
  type: 'FETCH_DRAFT_LIST_REJECTED',
});

const orderIsFetching = () => ({
  type: 'FETCH_ORDER_LIST',
});

const orderListFulfilled = payload => ({
  type: 'FETCH_ORDER_LIST_FULLFILLED',
  payload,
});

const orderListRejected = () => ({
  type: 'FETCH_ORDER_LIST_REJECTED',
});

const projectDeleteRejected = () => ({
  type: 'DELETE_PROJECT_REJECTED',
});

const projectDeleteSucceeded = payload => ({
  type: 'DELETE_PROJECT_SUCCEEDED',
  payload,
});

const projectCancelRejected = () => ({
  type: 'CANCEL_PROJECT_REJECTED',
});

const projectCancelSucceeded = payload => ({
  type: 'CANCEL_PROJECT_SUCCEEDED',
  payload,
});

export const fetchOrderList = () => dispatch => {
  dispatch(orderIsFetching());
  return ApiService.get(`/web-api/v1/photobook/project/list/orders`)
    .then(res => {
      dispatch(orderListFulfilled(res));
    })
    .catch(err => dispatch(orderListRejected(err)));
};

export const fetchDraftList = () => dispatch => {
  dispatch(draftIsFetching());
  return ApiService.get(`/web-api/v1/photobook/project/list/drafts`)
    .then(res => {
      dispatch(draftListFulfilled(res));
    })
    .catch(err => dispatch(draftListRejected(err)));
};

export const fetchProjectsCounts = () => dispatch => {
  dispatch(projectsCountsIsFetching());
  return ApiService.get(`/web-api/v1/photobook/project/count`)
    .then(res => {
      dispatch(projectsCountsFulfilled(res));
    })
    .catch(err => dispatch(projectsCountsRejected(err)));
};

export const cancelProject = (projectUuid, splitOrderId) => dispatch => {
  return ApiService.put(`/web-api/v1/photobook/project/${projectUuid}/cancel`, { splitOrderId })
    .then(res => {
      dispatch(projectCancelSucceeded({ projectUuid, status: res.orderStatus }));
      dispatch(toastsActions.positive({ headline: `Order Canceled!` }));
    })
    .catch(err => {
      dispatch(projectCancelRejected(err));
      dispatch(toastsActions.negative({ headline: "Order can't be canceled for now" }));
    });
};

export const deleteProject = projectUuid => dispatch => {
  return ApiService.delete(`/web-api/v1/photobook/project/${projectUuid}`)
    .then(() => {
      dispatch(projectDeleteSucceeded(projectUuid));
      dispatch(toastsActions.positive({ headline: 'Draft Deleted!' }));
    })
    .catch(err => {
      dispatch(projectDeleteRejected(err));
      dispatch(toastsActions.negative({ headline: "Order can't be deleted for now" }));
    });
};
