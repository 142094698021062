import React from 'react';

import getVendorIconV3 from '@zola/zola-ui/src/util/getVendorIconV3';
import { CircleDollarIcon } from '@zola/zola-ui/src/components/SvgIconsV3/CircleDollar';
import { ZolaHeartmarkIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ZolaHeartmark';
import { BellIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Bell';
import {
  getVendorTaxonomyKeyFromVendorType,
  VendorTypeEnum,
} from '@zola-helpers/client/dist/es/marketplace';
import getZolaImageFromUuid from '@zola-helpers/client/dist/es/util/getZolaImageFromUuid';
import COLORS3, { COLORS_BRAND } from '@zola/zola-ui/src/styles/emotion/colors3';

import type { MappedWAccountVendorView } from 'api/vendorMarketplaceApi';

import { Container, VendorImg } from './BudgetVendorImgOrIcon.styles';

type BudgetVendorImgOrIconProps = {
  bookedVendor?: MappedWAccountVendorView;
  vendorType?: string;
  size?: 60 | 40;
  fallBackIcon?: React.FC;
};

const BudgetVendorImgOrIcon = ({
  bookedVendor,
  vendorType,
  size = 40,
  fallBackIcon = BellIcon,
}: BudgetVendorImgOrIconProps) => {
  const { storefront_photo_uuid: storefrontPhotoUuid, vendor_name: vendorName } =
    bookedVendor || {};

  let Icon;
  if (vendorName?.toLowerCase() === 'zola') Icon = ZolaHeartmarkIcon;
  else if (bookedVendor && vendorType)
    Icon = getVendorIconV3(
      getVendorTaxonomyKeyFromVendorType(vendorType as VendorTypeEnum) || 'all'
    );
  else if (vendorName) Icon = CircleDollarIcon;
  else Icon = fallBackIcon;

  const getStyles = () => {
    if (vendorName?.toLowerCase() === 'zola')
      return { color: COLORS_BRAND.SECONDARY_SKY_100, bgColor: COLORS_BRAND.BOLD_MARINE_100 };
    if ((bookedVendor && vendorType) || vendorName)
      return { color: COLORS3.MINT_100, bgColor: COLORS3.FOREST_100 };
    if (fallBackIcon !== BellIcon)
      return {
        color: COLORS3.BLACK_100,
        bgColor: COLORS3.WHITE_100,
        border: `1px dashed ${COLORS3.BLACK_100}`,
      };
    return { color: COLORS3.BLACK_100, bgColor: COLORS3.BLACK_005 };
  };

  return (
    <Container {...getStyles()} size={size}>
      {storefrontPhotoUuid ? (
        <VendorImg
          src={`${getZolaImageFromUuid(storefrontPhotoUuid)}?w=${size}&h=${size}`}
          alt={vendorName}
        />
      ) : (
        <Icon width={20} height={20} showTitle={false} />
      )}
    </Container>
  );
};

export default BudgetVendorImgOrIcon;
