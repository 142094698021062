import styled from '@emotion/styled';
import { COLORS3 as COLORS, SPACING } from '@zola/zola-ui/src/styles/emotion';

export const InputContainer = styled.div`
  margin-bottom: ${SPACING.S24};
`;

export const DeleteSubtext = styled.p`
  color: ${COLORS.BLACK_100};
  margin-top: ${SPACING.S16};
`;
