'use strict';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();
  window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');

// String.prototype.startsWith() polyfill
require('core-js/stable/string/starts-with');

// String.prototype.endsWith() polyfill
require('core-js/stable/string/ends-with');

// Support IntersectionObserver which help image lazy loading
require('intersection-observer');

// window.scroll({ behavior: 'smooth' }) polyfill
// It will use native implementation if present.
require('smoothscroll-polyfill').polyfill();

// polyfills for react 16.* on >IE11
require('core-js/stable/map');
require('core-js/stable/set');
require('raf/polyfill');
require('core-js/stable/object');
require('core-js/stable/array/find');
require('core-js/stable/array/find-index');
require('core-js/stable/array/includes');
require('core-js/features/number/is-nan');

// moved here from index.jsx
require('core-js/stable/symbol');
require('core-js/stable/object/get-prototype-of');
require('core-js/stable/promise/finally');

// Array Iterator
require('core-js/stable/array/iterator');

// Array Iterator
require('core-js/stable/url');
require('core-js/stable/url-search-params');

// Polyfill CustomEvent for IE
(function () {
  if (typeof window.CustomEvent === 'function') return false; //If not IE

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();
