import type { WAlbumView, AlbumVariationView } from '@zola/svc-web-api-ts-client';

export interface AlbumOptionsMenuOption {
  label: string;
  value: string;
}

export interface AlbumOptionsMenuSelection {
  albumType: AlbumOptionsMenuOption;
  albumSize: AlbumOptionsMenuOption;
  albumPaperType: AlbumOptionsMenuOption;
  albumCoverMaterial: AlbumOptionsMenuOption;
}

export interface AlbumOptionsMenuSelectionUpdate {
  variation: AlbumVariationView;
  selection: AlbumOptionsMenuSelection;
  albumDetail: WAlbumView;
}

export interface AlbumOptionsMenuState {
  selection: AlbumOptionsMenuSelection | null;
  showLoadingOverlay: boolean;
}

export const SET_ALBUM_OPTIONS_MENU_SELECTION = 'SET_ALBUM_OPTIONS_MENU_SELECTION';
export interface SetAlbumOptionsMenuSelectionType {
  type: typeof SET_ALBUM_OPTIONS_MENU_SELECTION;
  payload: AlbumOptionsMenuSelection;
}

export const SET_SHOW_ALBUM_OPTIONS_MENU_LOADING_OVERLAY =
  'SET_SHOW_ALBUM_OPTIONS_MENU_LOADING_OVERLAY';
export interface SetShowAlbumOptionsMenuLoadingType {
  type: typeof SET_SHOW_ALBUM_OPTIONS_MENU_LOADING_OVERLAY;
  payload: boolean;
}

export type AlbumOptionsMenuActionType =
  | SetAlbumOptionsMenuSelectionType
  | SetShowAlbumOptionsMenuLoadingType;
