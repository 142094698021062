import ApiService from '../../util/api';

export const getAlbumPhotos = ({ albumUuid }) => {
  return ApiService.get(
    `/web-api/v1/photobook/project/item/${albumUuid}/media/category/image/list`
  );
};

export const assignPhotosToAlbum = ({
  albumUuid,
  images,
  offset = 0,
  mediaCategoryUuid = null,
}) => {
  const body = images.map((image, i) => ({
    image_uuid: image.uuid,
    position: offset + i,
    photobook_project_item_media_category_uuid: mediaCategoryUuid,
  }));

  return ApiService.post(
    `/web-api/v1/photobook/project/item/${albumUuid}/media/category/image`,
    body
  );
};

export const deleteAlbumPhoto = ({ albumUuid, imageUUID }) => {
  return ApiService.delete(
    `/web-api/v1/photobook/project/item/${albumUuid}/media/category/image/${imageUUID}`
  );
};

export const deleteAlbumPhotos = ({ albumUuid, imageUuids }) => {
  return ApiService.post(
    `/web-api/v1/photobook/project/item/${albumUuid}/media/category/image/bulk-delete`,
    imageUuids
  );
};

export const getAlbumCategories = ({ albumUuid }) => {
  return ApiService.get(`/web-api/v1/photobook/project/item/${albumUuid}/media/category/list`);
};

export const deleteAlbumCategory = ({ albumUuid, categoryUUID }) => {
  return ApiService.delete(
    `/web-api/v1/photobook/project/item/${albumUuid}/media/category/${categoryUUID}`
  );
};

export const addAlbumCategory = ({ albumUuid, name }) => {
  return ApiService.put(`/web-api/v1/photobook/project/item/${albumUuid}/media/category/custom`, {
    name,
  }).then(response => {
    if (response.data) return response.data;

    throw new Error(response.message || 'Something went wrong.');
  });
};

export const bulkUpdatePhotos = ({ albumUuid, updates }) => {
  return ApiService.put(
    `/web-api/v1/photobook/project/item/${albumUuid}/media/category/image/category`,
    updates
  );
};

export const bulkUpdateCategories = ({ albumUuid, updates }) => {
  return ApiService.put(
    `/web-api/v1/photobook/project/item/${albumUuid}/media/category/position`,
    updates
  );
};

export const fetchModuleGroup = ({ tag }) => {
  return ApiService.get(`/web-api/v1/module-group/tag/${tag}`, {
    credentials: 'same-origin',
  });
};

export const getAlbumList = () => {
  return ApiService.get('/web-api/v1/photobook/catalog/album/list');
};

export const getDetailedAlbumList = () => {
  return ApiService.get('/web-api/v1/photobook/catalog/album/list/detailed');
};

export const generateAlbum = ({ projectUuid, albumUuid, categoryRequests }) => {
  return ApiService.post(
    `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumUuid}/media`,
    categoryRequests
  );
};

export const fetchPhotobookProject = ({ projectUuid }) => {
  return ApiService.get(`/web-api/v1/photobook/project/${projectUuid}`);
};

export const fetchAlbumItemByUuid = ({ projectUuid, albumItemUuid }) => {
  return ApiService.get(`/web-api/v1/photobook/project/${projectUuid}/album-item/${albumItemUuid}`);
};

export const renderProjectPreview = ({ projectUuid }) => {
  return ApiService.put(`/web-api/v1/photobook/project/${projectUuid}/render`);
};

export const getProjectPreviewRenders = ({ projectUuid }) => {
  return ApiService.get(`/web-api/v1/photobook/project/${projectUuid}/render/list`);
};

export const validateAlbumItem = ({ projectUuid, albumItemUuid }) => {
  return ApiService.get(
    `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumItemUuid}/validation`
  );
};

export const removeSpread = ({ projectUuid, albumItemUuid, spreadIndexes }) => {
  return ApiService.delete(
    `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumItemUuid}/spread`,
    spreadIndexes
  );
};

export const getDesignFoilColors = () => {
  return ApiService.get(`/web-api/v1/photobook/catalog/options/album-cover/font/color/list`);
};

export const getCoverFonts = () => {
  return ApiService.get('/web-api/v1/photobook/catalog/options/album-cover/font/list');
};

export const getCoverDesignTemplates = () => {
  return ApiService.get(`/web-api/v1/photobook/catalog/options/album-cover/design-template/list`);
};

export const updateCoverCustomization = ({ projectUuid, albumUuid, coverCustomizationRequest }) => {
  return ApiService.put(
    `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumUuid}/cover`,
    coverCustomizationRequest
  );
};

export const saveViewedSpreads = ({ projectUuid, albumUuid, spreadIndexes }) => {
  return ApiService.put(
    `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumUuid}/spread/view`,
    {
      spreadIndexes,
    }
  );
};

export const updateSingleSlot = ({ projectUuid, albumUuid, data }) => {
  return ApiService.put(
    `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumUuid}/slot`,
    data
  );
};

export const updateBulkSlot = ({ projectUuid, albumUuid, data }) => {
  return ApiService.put(
    `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumUuid}/slots`,
    data
  );
};

export const updateAlbumVariation = ({ projectUuid, albumUuid, variationUuid }) => {
  return ApiService.put(
    `/web-api/v1/photobook/project/${projectUuid}/album-item/${albumUuid}/variation`,
    { variationUuid }
  );
};

export const clonePhotobookProject = projectUuid => {
  return ApiService.put(`/web-api/v1/photobook/project/${projectUuid}/clone`);
};
