/* eslint-disable import/order */
import React from 'react';
import fetch from 'isomorphic-fetch';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import { Route, IndexRedirect, browserHistory, Redirect } from 'react-router';
import onboardTrackingConstants from '@zola-helpers/client/dist/es/tracking/onboard/onboardTrackingConstants';
import { trackOnboardingCompleted } from '@zola-helpers/client/dist/es/tracking/onboard/onboardEvents';
import { notMobile, isMobile } from '@zola-helpers/client/dist/es/util/responsive';
import { getLocalStorage } from '@zola-helpers/client/dist/es/util/storage';

import MinimalLoader from 'components/common/ui/MinimalLoader';

// Routes
import getWeddingManageRoutes from './routes/weddingManageRoutes';

// Components
import App from './App';

// Actions
import { submitOnboarding } from 'actions/OnboardActions';
import { getWeddingBySlug, storeMobileToken } from 'actions/PublicWebsiteActions';
import { getUserContext } from 'actions/UserActions';
import { fetchWeddingAccount } from 'actions/WeddingActions';

import { getWeddingWebsiteThemeV2 } from 'actions/WeddingThemeActions';

// card actions
import { updateCatalogAdminView } from './cards/actions/cardCatalogActions';
import { checkFreeSampleAvailability } from './cards/actions/project/checkFreeSampleAvailability';
import { fetchProject } from './cards/actions/project/fetchProject';
import { setCurrentPathname } from './cards/actions/project/setCurrentPathname';
import { customizationCleanUp } from './cards/actions/cardCustomizationActions';
import { setActiveSuite } from './cards/reducers/pdp/actions';

// Albums actions
import { setActiveProjectsListing } from 'photobooks/reducers/draftsAndOrders/actions';
import { fetchPhotobookProject, clearPhotobookProject } from 'photobooks/reducers/project/actions';
import { clearAlbum } from 'photobooks/reducers/albumItem/actions';
import { clearGallery } from 'photobooks/reducers/gallery/actions';

// Selectors
import { getOrderedSteps } from './cards/selectors/cardProjectSelector';
import {
  getUserHasCompletedOnboarding,
  getUserHasWeddingAccount,
} from 'selectors/user/userSelectors';

// utils & helpers
import featureFlags from 'util/featureFlags';
import { asyncComponent } from './util/asyncComponent';
import { getProjectUrlParams } from 'photobooks/utils/url';
import { redirectToNotFound, renderNotFoundRoute } from './routesHelpers';
import { PAPER_PDP_IS_ADMIN_VIEW } from './cards/constants/Admin';

import { getCustomizationStepUrl } from '@zola/zola-ui/src/paper/cards/util/getCustomizationStepUrl';
import { getStepFromPathname } from './cards/util/getStepFromPathname';

const PreAuthApp = asyncComponent(() =>
  import(/* webpackChunkName: "PreAuthApp" */ './pages/preauth/PreAuthApp')
);
const SettingsURL = asyncComponent(() =>
  import(/* webpackChunkName: "SettingsURL" */ './pages/settings/SettingsURL/SettingsURL')
);
const WeddingDetailsContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: "WeddingDetailsContainer" */ './pages/settings/wedding/WeddingDetailsContainer'
  )
);
const OtherRegistriesContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: "OtherRegistriesContainer" */ './pages/settings/otherRegistries/OtherRegistriesContainer'
  )
);
const NotificationSettings = asyncComponent(() =>
  import(
    /* webpackChunkName: "NotificationSettings" */ './pages/settings/NotificationSettings/NotificationSettings'
  )
);
const PrivacySettings = asyncComponent(() =>
  import(/* webpackChunkName: "PrivacySettings" */ './pages/settings/privacy/PrivacySettings')
);
const PasswordSecurity = asyncComponent(() =>
  import(
    /* webpackChunkName: "PasswordSecurity" */ './pages/settings/PasswordSecurity/PasswordSecurity'
  )
);
const InvitesFromVendors = asyncComponent(() =>
  import(/* webpackChunkName: "InvitesFromVendors" */ './pages/settings/InvitesFromVendors')
);

const Credits = asyncComponent(() => import('./components/Credits'));
const PaperFavorites = asyncComponent(() =>
  import(/* webpackChunkName: "PaperFavorites" */ './favorites/pages/paper')
);

const CommunityQuickOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "WeddingWebsiteOnboardFlow" */ './components/onboard/onboard-v2/flows/CommunityQuickOnboardFlow/CommunityQuickOnboardFlow'
  )
);

const Photobooks = asyncComponent(() =>
  import(/* webpackChunkName: "Photobooks" */ './photobooks/pages/Photobooks')
);

const AlbumDetailsPage = asyncComponent(() =>
  import(/* webpackChunkName: "AlbumDetailsPage" */ './photobooks/pages/pdp/AlbumDetailsPage')
);

const LayoutStep = asyncComponent(() =>
  import(/* webpackChunkName: "LayoutStep" */ './photobooks/pages/customization/steps/LayoutStep')
);

const UploadStep = asyncComponent(() =>
  import(/* webpackChunkName: "UploadStep" */ './photobooks/pages/customization/steps/UploadStep')
);

const OrganizeStep = asyncComponent(() =>
  import(
    /* webpackChunkName: "OrganizeStep" */ './photobooks/pages/customization/steps/OrganizeStep'
  )
);

const HighlightStep = asyncComponent(() =>
  import(
    /* webpackChunkName: "HighlightStep" */ './photobooks/pages/customization/steps/HighlightStepRedesign'
  )
);

const CustomizeStep = asyncComponent(() =>
  import(
    /* webpackChunkName: "CustomizeStep" */ './photobooks/pages/customization/steps/CustomizeStep'
  )
);

const CoverStep = asyncComponent(() =>
  import(/* webpackChunkName: "CoverStep" */ './photobooks/pages/customization/steps/CoverStep')
);

const AlbumDrafts = asyncComponent(() =>
  import(
    /* webpackChunkName: "AlbumDrafts" */ './photobooks/pages/draftsAndOrders/DraftsAndOrdersPage'
  )
);

const AlbumOrders = asyncComponent(() =>
  import(
    /* webpackChunkName: "AlbumOrders" */ './photobooks/pages/draftsAndOrders/DraftsAndOrdersPage'
  )
);

const OnboardSandbox = asyncComponent(() =>
  import(/* webpackChunkName: "OnboardSandbox" */ './pages/404/DSTDUnavailableErrorPage')
);

const Cards = asyncComponent(() => import(/* webpackChunkName: "Cards" */ './cards/pages/Cards'));

const DSTDUnavailableErrorPage = asyncComponent(() =>
  import(/* webpackChunkName: "DSTDUnavailableErrorPage" */ './pages/404/DSTDUnavailableErrorPage')
);

const CardProjects = asyncComponent(() =>
  import(/* webpackChunkName: "CardProjects" */ './cards/pages/CardProjects')
);

const DraftsAndOrdersPage = asyncComponent(() =>
  import(/* webpackChunkName: "DraftsAndOrdersPage" */ './cards/pages/DraftsAndOrdersPage')
);

const CardCustomizationFlow = asyncComponent(() =>
  import(/* webpackChunkName: "CardCustomizationFlow" */ './cards/pages/CardCustomizationFlow')
);

const Guests = asyncComponent(() =>
  import(
    /* webpackChunkName: "Guests" */ './cards/components/Customization/steps/Addressing/AddressingV2'
  )
);

const EventDetailFrontV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "EventDetailFrontV2" */ './cards/components/Customization/steps/EventDetailFront'
  )
);

const EventDetailInsideV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "EventDetailInsideV2" */ './cards/components/Customization/steps/EventDetailInside'
  )
);

const EventDetailWrite = asyncComponent(() =>
  import(
    /* webpackChunkName: "EventDetailWrite" */ './cards/components/Customization/steps/EventDetailWrite'
  )
);

const EventDetailAssign = asyncComponent(() =>
  import(
    /* webpackChunkName: "EventDetailAssign" */ './cards/components/Customization/steps/EventDetailAssign'
  )
);

const EventDetailBackV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "EventDetailBackV2" */ './cards/components/Customization/steps/EventDetailBack'
  )
);

const RSVPV2 = asyncComponent(() =>
  import(/* webpackChunkName: "RSVPV2" */ './cards/components/Customization/steps/RSVP')
);

const RsvpEnvelopeContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: "RsvpEnvelopeContainer" */ './cards/components/Customization/steps/RsvpEnvelopeContainer'
  )
);

const EnvelopeContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: "EnvelopeContainer" */ './cards/components/Customization/steps/EnvelopeContainer'
  )
);

const Recipients = asyncComponent(() =>
  import(
    /* webpackChunkName: "Recipients" */ './cards/components/Customization/steps/Recipients/Recipients'
  )
);

const Addressing = asyncComponent(() =>
  import(
    /* webpackChunkName: "Addressing" */ './cards/components/Customization/steps/Addressing/Addressing'
  )
);

const AddMore = asyncComponent(() =>
  import(/* webpackChunkName: "AddMore" */ './cards/components/Customization/steps/AddMore')
);

const Review = asyncComponent(() =>
  import(/* webpackChunkName: "Review" */ './cards/components/Customization/steps/Review/Review')
);

const AddToCartUpsell = asyncComponent(() =>
  import(/* webpackChunkName: "confirmation" */ './cards/components/Cart/Upsell/AddToCartUpsell')
);

const CardDigitalDetailPage = asyncComponent(() =>
  import(/* webpackChunkName: "CardDigitalDetailPage" */ './cards/pages/CardDigitalDetailPage')
);

const DigitalMobileFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "DigitalMobileFlow" */ './cards/components/Customization/DigitalSteps/DigitalMobileFlow'
  )
);

const DigitalProjectManagement = asyncComponent(() =>
  import(
    /* webpackChunkName: "DigitalProjectManagement" */ './cards/components/DigitalProjectManagement'
  )
);

const Details = asyncComponent(() =>
  import(/* webpackChunkName: "Details" */ './cards/components/Customization/DigitalSteps/Details')
);

const Links = asyncComponent(() =>
  import(/* webpackChunkName: "Links" */ './cards/components/Customization/DigitalSteps/Links')
);

const FreeSamples = asyncComponent(() =>
  import(
    /* webpackChunkName: "FreeSamples" */ './cards/components/Customization/DigitalSteps/FreeSamples/FreeSamples'
  )
);

const Share = asyncComponent(() =>
  import(
    /* webpackChunkName: "Share" */ './cards/components/Customization/DigitalSteps/Share/Share'
  )
);

const PostOnboardMobile = asyncComponent(() =>
  import(/* webpackChunkName: "PostOnboardMobile" */ './pages/postOnboard/PostOnboardContainer')
);

const GuestHelp = asyncComponent(() =>
  import(/* webpackChunkName: "GuestHelp" */ './pages/GuestHelp')
);

const NewWeddingPlanningOnboardFlowV2 = asyncComponent(() =>
  import(
    /* webpackChunkName: "NewWeddingPlanningOnboardFlowV2" */ './components/onboard/onboard-v2/flows/WeddingPlanningOnboardFlowV2'
  )
);

const SinglePageOnboardingInvites = asyncComponent(() =>
  import(
    /* webpackChunkName: "SinglePageOnboardingInvites" */ './components/onboard/onboard-v2/flows/CardsQuickOnboardFavoriteFlow/CardsQuickOnboardFavoriteFlow'
  )
);

const WeddingInvitationOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "WeddingInvitationOnboardFlow" */ './components/onboard/onboard-v2/flows/CardsOnboardFlow'
  )
);

const WebsiteQuickOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "WebsiteQuickOnboardFlow" */ './components/onboard/onboard-v2/flows/WebsiteQuickOnboardFlow/WebsiteQuickOnboardFlow'
  )
);

const VendorFavoriteOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "VendorFavoriteOnboardFlow" */ './components/onboard/onboard-v2/flows/VendorFavoriteQuickOnboardFlow/VendorFavoriteQuickOnboardFlow'
  )
);

const WeddingWebsiteOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "WeddingWebsiteOnboardFlow" */ './components/onboard/onboard-v2/flows/WeddingWebsiteOnboardFlow'
  )
);

const MobileWeddingWebsiteThemes = asyncComponent(() =>
  import(
    /* webpackChunkName: "MobileWeddingWebsiteThemes" */ './pages/postOnboard/WebsiteThemesWrapper'
  )
);

const WeddingChecklistOnboardedUser = asyncComponent(() =>
  import(
    /* webpackChunkName: "WeddingChecklistOnboardedUser" */ './components/onboard/flowControllers/WeddingChecklistFlow/WeddingChecklistOnboardedUser'
  )
);

const WeddingChecklistOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "WeddingChecklistOnboardFlow" */ './components/onboard/flowControllers/WeddingChecklistFlow'
  )
);

const WeddingGuestListOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "WeddingGuestListOnboardFlow" */ './components/onboard/onboard-v2/flows/GuestListOnboardFlow'
  )
);

const RegistryOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "RegistryOnboardFlow" */ './components/onboard/onboard-v2/flows/RegistryOnboardFlow'
  )
);

const BudgetOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "BudgetOnboardFlow" */ './components/onboard/onboard-v2/flows/BudgetOnboardFlow'
  )
);

const RealWeddingsNonFavoriteOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "RealWeddingsNonFavoriteOnboardFlow" */ './components/onboard/onboard-v2/flows/RealWeddingsNonFavoriteOnboardFlow'
  )
);

const RealWeddingsFavoriteOnboardFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "RealWeddingsFavoriteOnboardFlow" */ './components/onboard/onboard-v2/flows/RealWeddingsFavoriteOnboardFlow'
  )
);

const VendorSavedSearchFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "VendorSavedSearchFlow" */ './components/onboard/onboard-v2/flows/VendorSavedSearchFlow'
  )
);

const BookedVendorFlow = asyncComponent(() =>
  import(
    /* webpackChunkName: "BookedVendorFlow" */ './components/onboard/onboard-v2/flows/BookedVendorFlow'
  )
);

const VirtualEmbed = asyncComponent(() =>
  import(/* webpackChunkName: "VirtualEmbed" */ './components/PublicWebsite/pages/VirtualEmbed')
);

const ZoomMeetingOver = asyncComponent(() =>
  import(
    /* webpackChunkName: "ZoomMeetingOver" */ './components/PublicWebsite/pages/Virtual/ZoomMeetingOver'
  )
);

const PrintChecklistContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: "PrintChecklistContainer" */ './components/manage/ChecklistV2/PrintChecklist/PrintChecklistContainer'
  )
);

const AccountSettings = asyncComponent(() =>
  import(
    /* webpackChunkName: "AccountSettings" */ './pages/settings/AccountSettings/AccountSettings'
  )
);

const AlbumCustomizationFlowPage = asyncComponent(() =>
  import(
    /* webpackChunkName: "AlbumCustomizationFlowPage" */ './photobooks/pages/customization/AlbumCustomizationFlowPage'
  )
);

// call updateBoxShadow (which is set in web-nav) becuz if you visit a weddings page with no sub-nav (ie, web-nav has a dropshadow),
// and then navigate to a weddings page with a sub-nav, it should update web-nav to NOT have a dropshadow (and vice versa);
browserHistory.listen(location => {
  if (location.pathname) {
    if (window.zolaNav && window.zolaNav.updateBoxShadow) {
      window.zolaNav.updateBoxShadow(location.pathname);
    }
  }
});

const { BUSINESS_UNIT, COMPONENT } = onboardTrackingConstants;

const getRoutes = store => {
  const checkGetUser = (nextState, replaceState, callback) => {
    const { dispatch } = store;
    dispatch(getUserContext())
      .then(() => callback())
      .catch(() => callback());
  };

  const fetchThemeWithCustomizations = cb => {
    const { dispatch } = store;
    dispatch(getWeddingWebsiteThemeV2()).then(() => {
      cb();
    });
    cb();
  };

  const checkWeddingWithToken = (nextState, replaceState, callback) => {
    const { dispatch } = store;
    const { slug } = nextState.params;
    const token = nextState.location.query.preview_token;
    if (token) {
      // User
      dispatch(storeMobileToken(token));
      fetch('/website-nav/web-api/v1/auth/mobile-website-preview', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      })
        .then(() => {
          dispatch(getWeddingBySlug(slug))
            .then(() => {
              fetchThemeWithCustomizations(callback);
            })
            .catch(() => {
              redirectToNotFound();
            });
        })
        .catch(() => {
          redirectToNotFound();
        });
    } else {
      // Guests
      dispatch(getWeddingBySlug(slug))
        .then(() => {
          fetchThemeWithCustomizations(callback);
        })
        .catch(e => {
          if (e.status === 403) {
            replaceState(`/wedding/${slug}/passcode`);
            callback();
          } else {
            redirectToNotFound();
          }
        });
    }
  };

  const checkLoginAndRedirectToReferralPage = (nextState, replaceState, callback) => {
    const { dispatch } = store;
    dispatch(getUserContext())
      .then(() => {
        const state = store.getState();
        // if user is guest or user is "idle" (been logged in for more than 30 minutes), then redirect them to login page
        if (
          !state.user.userContext ||
          state.user.userContext.is_guest ||
          state.user.userContext.status === 'IDLE'
        ) {
          window.location.href = `/account/login?returnTo=${window.location.pathname}`;
        } else if (
          state.user.userContext.has_wedding_account ||
          state.user.userContext.has_registry
        ) {
          callback();
        }
        callback();
      })
      .catch(() => {
        window.location.href = '/account/login';
        callback();
      });
  };

  // checks for a specific requirement and if it doesn't exist, redirects them to another page
  const checkForRequirement = requirement => (nextState, replaceState, callback) => {
    const state = store.getState();
    const { slug } = state.user.userContext.user_role_account_weddings[0];
    const registry = state.user.userContext.has_registry;
    const wedding = state.user.userContext.has_wedding_account;
    const onboarding = state.user.userContext.has_completed_onboarding;

    if (requirement === 'slug' && !slug) {
      window.location = '/account/settings/info';
    } else if (requirement === 'registryOrWedding' && !registry && !wedding) {
      window.location = '/account/settings/info';
    } else if (requirement === 'onboarding' && !onboarding) {
      window.location = '/wedding/onboard/wedding-planning';
    } else {
      // if doesn't meet the requirements, then call `callback` (aka send them back to the original page);
      callback();
    }
  };

  // Auto onboard products that only requires owner name, partner name and wedding date
  const checkOnboardStatus = (nextState, replaceState, callback) => {
    const { dispatch, getState } = store;
    const state = getState();
    let { product } = nextState.location.query;
    const { pathname } = nextState.location;
    const NEW_PLANNING_PATH = '/wedding/onboard/wedding-planning';
    if (pathname === NEW_PLANNING_PATH) {
      product = 'WEDDING_PLANNING';
    }
    const NEW_WEDDING_CHECKLIST_PATH = '/wedding/onboard/wedding-checklist';
    const ONBOARDED_USER_CHECKLIST_PATH = '/wedding/onboard/wedding-checklist/create';
    if (pathname === NEW_WEDDING_CHECKLIST_PATH || pathname === ONBOARDED_USER_CHECKLIST_PATH) {
      product = 'WEDDING_CHECKLIST';
    }
    const NEW_WEDDING_WEBSITE_PATH = '/wedding/onboard/wedding-website';
    if (pathname === NEW_WEDDING_WEBSITE_PATH) {
      product = 'WEDDING_WEBSITE';
    }
    const NEW_WEDDING_GUEST_LIST_PATH = '/wedding/onboard/wedding-guest-list';
    if (pathname === NEW_WEDDING_GUEST_LIST_PATH) {
      product = 'WEDDING_GUEST_LIST';
    }

    // This is the base path for the paper flow. The paper flow paths are different depends on where an starts
    // the flow.
    const NEW_PAPER_BASE_PATH = '/wedding/onboard/wedding-paper';
    if (pathname.indexOf(NEW_PAPER_BASE_PATH) >= 0) {
      product = 'INVITATIONS';
    }

    // Weddings products with basic onboarding flows
    const basicFlowWeddingProducts = {
      [COMPONENT.WEDDING_PLANNING]: '/wedding/manage',
      [COMPONENT.WEDDING_GUEST_LIST]: '/wedding/manage/guests/all',
      [COMPONENT.WEDDING_WEBSITE]: '/wedding/manage/website/designs',
    };
    // only auto onboard existing users with wedding details
    if (!getUserHasCompletedOnboarding(state)) {
      // Check if the user should be placed in the new flow experiment
      // Only new/basic wedding planning flow users should be bucketed in the experiment
      if (
        (product === COMPONENT.WEDDING_PLANNING && pathname !== NEW_PLANNING_PATH) ||
        (product === 'SELECT_PRODUCT' && pathname !== NEW_PLANNING_PATH)
      ) {
        replaceState(NEW_PLANNING_PATH);
      }

      if (product === 'WEDDING_CHECKLIST' && pathname !== NEW_WEDDING_CHECKLIST_PATH) {
        replaceState(NEW_WEDDING_CHECKLIST_PATH);
      }

      if (product === 'WEDDING_WEBSITE' && pathname !== NEW_WEDDING_WEBSITE_PATH) {
        replaceState(NEW_WEDDING_WEBSITE_PATH);
      }
      if (product === 'WEDDING_GUEST_LIST' && pathname !== NEW_WEDDING_GUEST_LIST_PATH) {
        replaceState(NEW_WEDDING_GUEST_LIST_PATH);
      }
      // since there are multiple routes pointing to the paper onboarding flow. We will use use the
      if (
        (product === 'INVITATIONS' ||
          product === 'SAVE_THE_DATE' ||
          product === 'CHANGE_THE_DATE' ||
          product === 'DAY_OF_PAPER' ||
          product === 'HOLIDAY' ||
          product === 'THANK_YOU_CARDS') &&
        pathname.indexOf(NEW_PAPER_BASE_PATH) < 0
      ) {
        switch (product) {
          case 'SAVE_THE_DATE':
          case 'CHANGE_THE_DATE':
          case 'DAY_OF_PAPER':
          case 'HOLIDAY':
          case 'THANK_YOU_CARDS':
            replaceState(`${NEW_PAPER_BASE_PATH}/${product}`);
            break;
          default:
            replaceState(NEW_PAPER_BASE_PATH);
        }
      }
      callback();
      return;
    }

    // onboard users automatically for wedding planning
    if (Object.keys(basicFlowWeddingProducts).includes(product)) {
      if (getUserHasWeddingAccount(state)) {
        replaceState(basicFlowWeddingProducts[product]);
        callback();
      } else {
        // create a wedding account
        const onboardingData = {
          existing_account: true,
          business_unit: product,
          answers: [],
        };
        dispatch(submitOnboarding(onboardingData)).then(() => {
          trackOnboardingCompleted(BUSINESS_UNIT.WEDDINGS, product, { isExistingUser: true });
          trackOnboardingCompleted(BUSINESS_UNIT.WEDDINGS, COMPONENT.WEDDINGS, {
            isExistingUser: true,
          });
          replaceState(basicFlowWeddingProducts[product]);
          callback();
        });
      }
      return;
    }

    // User has onboarded but is trying to access the SELECT_PRODUCT onboard for some reason
    if (product === 'SELECT_PRODUCT') {
      replaceState('/wedding/onboard/wedding-planning');
    }

    // Onboarded but is trying to access the new WEDDING_CHECKLIST flow
    if (product === 'WEDDING_CHECKLIST' && pathname !== ONBOARDED_USER_CHECKLIST_PATH) {
      replaceState('/wedding/onboard/wedding-checklist/create');
      callback();
      return;
    }

    callback();
  };

  // TODO: DSTD
  const onEnterDigitalManagement = (nextState, replaceState, callback) => {
    const { projectUUID } = nextState.params;
    const { dispatch } = store;
    const state = store.getState();
    const {
      userContext: {
        has_wedding_account: hasWeddingAccount,
        has_invitation_account: hasInvitationAccount,
        invitation_account: { cards_onboarding_completed: isCardOnboardingCompleted } = {},
      } = {},
    } = state.user;
    // Some cleanup to restore reducer state for errors and customization toggles
    dispatch(customizationCleanUp());
    if (state.user.hasIdentified && hasInvitationAccount && isCardOnboardingCompleted) {
      if (hasWeddingAccount) {
        dispatch(fetchWeddingAccount());
      } else {
        // create wedding account in the background
        dispatch(
          submitOnboarding({
            answers: [],
            business_unit: 'WEDDING_WEBSITE',
            existing_account: true,
          })
        );
      }

      dispatch(checkFreeSampleAvailability('INVITATION')).then(() => {
        dispatch(fetchProject(projectUUID)).then(project => {
          if (project.status === 'error') {
            // Redirect onboarded users to drafts if error or they don't own project
            replaceState('/wedding-planning/projects/drafts');
          }
          callback();
        });
      });
    } else {
      window.location.href = '/wedding-planning/paper';
      callback();
    }
  };

  // TODO: DSTD
  const onEnterDigitalCustomizationFlow = (nextState, replaceState, callback) => {
    const { projectUUID } = nextState.params;
    const { dispatch } = store;
    const state = store.getState();
    const {
      userContext: {
        has_wedding_account: hasWeddingAccount,
        has_invitation_account: hasInvitationAccount,
        invitation_account: { cards_onboarding_completed: isCardOnboardingCompleted } = {},
      } = {},
    } = state.user;
    // Some cleanup to restore reducer state for errors and customization toggles
    dispatch(customizationCleanUp());
    if (state.user.hasIdentified && hasInvitationAccount && isCardOnboardingCompleted) {
      if (hasWeddingAccount) {
        dispatch(fetchWeddingAccount());
      } else {
        // create wedding account in the background
        dispatch(
          submitOnboarding({
            answers: [],
            business_unit: 'WEDDING_WEBSITE',
            existing_account: true,
          })
        );
      }

      if (isMobile()) {
        replaceState('/wedding-planning/digital-mobile');
        callback();
      }

      dispatch(checkFreeSampleAvailability('INVITATION')).then(() => {
        dispatch(fetchProject(projectUUID)).then(project => {
          if (project.status === 'error') {
            // Redirect onboarded users to drafts if error or they don't own project
            replaceState('/wedding-planning/projects/drafts');
          } else {
            const { digital_suite: digitalSuite } = project;
            if (!digitalSuite) {
              window.location.href = '/wedding-planning/paper';
            }
          }
          callback();
        });
      });
    } else {
      window.location.href = '/wedding-planning/paper';
      callback();
    }
  };

  // onEnter hook used for customization flow
  const onEnterCustomizationFlow = (nextState, replaceState, callback) => {
    const { projectUUID } = nextState.params;
    const { dispatch } = store;
    const state = store.getState();
    const {
      userContext: {
        has_wedding_account: hasWeddingAccount,
        has_invitation_account: hasInvitationAccount,
        invitation_account: { cards_onboarding_completed: isCardOnboardingCompleted } = {},
        is_guest: isGuest,
      } = {},
    } = state.user;
    const { isAdminFromState } = state.cards.cardCatalog;

    // With the paper PDP moved over to Next.js, redux isn't playing nicely switching between Next and react-router
    // hack to ensure we are correctly identifying isAdminView and allowing admin to preview unlisted PDPs
    const isAdminPDP = getLocalStorage(PAPER_PDP_IS_ADMIN_VIEW);
    const isAdminView = isAdminFromState || isAdminPDP;

    const redirectToDrafts = () => replaceState('/wedding-planning/projects/drafts');

    // Some cleanup to restore reducer state for errors and customization toggles
    dispatch(customizationCleanUp());

    if (
      (state.user.hasIdentified && hasInvitationAccount && isCardOnboardingCompleted) ||
      (featureFlags.get('pushSignupIntoCustomization') && notMobile())
    ) {
      if (hasWeddingAccount) {
        dispatch(fetchWeddingAccount());
      }

      dispatch(fetchProject(projectUUID)).then(project => {
        if (project.status === 'error') {
          if (isGuest) {
            // Redirect guests to paper LP if error or they don't own project
            window.location.href = '/wedding-planning/paper';
          } else {
            // Redirect onboarded users to drafts if error or they don't own project
            redirectToDrafts();
            callback();
          }
          return;
        }

        const {
          completed_at: completed,
          editable: isEditable,
          digital_suite: digitalSuite,
        } = project;

        if (digitalSuite) {
          window.location.href = '/wedding-planning/paper';
        }
        if (!isEditable && !isAdminView) {
          redirectToDrafts();
        }
        if (completed) {
          replaceState('/wedding-planning/projects/finished');
        }
        callback();
      });
    } else {
      window.location.href = '/wedding-planning/paper';
    }
  };

  const onEnterAlbumsCustomizationStep = (nextState, replaceState, callback) => {
    const state = store.getState();
    const {
      userContext: {
        has_invitation_account: hasInvitationAccount,
        invitation_account: {
          photobooks_onboarding_completed: isPhotobookOnboardingCompleted,
        } = {},
      } = {},
    } = state.user;
    const { dispatch } = store;

    // Login check
    if (state.user.hasIdentified && hasInvitationAccount && isPhotobookOnboardingCompleted) {
      // Project ownership check
      const { projectUuid } = getProjectUrlParams();
      dispatch(fetchPhotobookProject(projectUuid))
        .then(data => {
          if (!data) {
            replaceState('/wedding-albums/shop');
            callback();
          }
          callback();
        })
        .catch(() => {
          replaceState('/wedding-albums/shop');
          callback();
        });
    } else {
      replaceState('/wedding-albums/shop');
      callback();
    }
  };

  const onLeaveAlbumsCustomizationStep = () => {
    const { dispatch } = store;
    dispatch(clearPhotobookProject());
    dispatch(clearAlbum());
    dispatch(clearGallery());
  };

  // onEnter hook used for customization steps
  const onEnterCustomizationStep = (nextState, replaceState, callback) => {
    const { projectUUID } = nextState.params;
    const state = store.getState();
    const orderedSteps = getOrderedSteps(state);
    const currentStep = getStepFromPathname(nextState.location.pathname, orderedSteps);

    // Make the new pathname available in redux to avoid wrapping all customization flow component in withRouter
    store.dispatch(setCurrentPathname(nextState.location.pathname));

    if (currentStep) {
      callback();
    } else {
      // If specified step is unsupported, send to the first step
      replaceState(getCustomizationStepUrl(projectUUID));
      callback();
    }
  };

  const gotoOrder = ({ redirectTo }) => (nextState, replaceState, callback) => {
    const { dispatch } = store;
    dispatch(getUserContext())
      .then(() => {
        const state = store.getState();
        if (
          !state.user.userContext ||
          state.user.userContext.is_guest ||
          !state.user.userContext.has_invitation_account
        ) {
          window.location.href = redirectTo;
        }
        callback();
      })
      .catch(() => {
        window.location.assign('/500');
      });
  };
  // TODO: Some of this methods are duplicates, will need to review later to remove and unify
  const checkDigitalCardType = (nextState, replaceState, callback) => {
    const { cardType } = nextState.params;

    if (cardType !== 'save-the-date') {
      window.location.href = '/wedding-planning/paper';
    }
    callback();
  };

  const onEnterPhotobookProjects = activeView => (nextState, replaceState, callback) => {
    const { dispatch } = store;
    dispatch(setActiveProjectsListing(activeView));
    const state = store.getState();
    const { userContext } = state.user;
    if (!userContext || userContext.is_guest || !userContext.has_invitation_account) {
      replaceState('/wedding-albums/shop');
      callback();
    }
    callback();
  };

  const enterDigitalPDP = isAdminView => (nextState, replaceState, callback) => {
    const { dispatch } = store;
    const { uuid, cardType } = nextState.params;
    dispatch(updateCatalogAdminView(isAdminView));
    dispatch(setActiveSuite(uuid));

    try {
      callback();
    } catch (e) {
      window.location.href = `/wedding-planning/digital/${cardType}/shop`;
    }
  };
  const onEnterDigitalPDP = enterDigitalPDP(false);
  const onEnterAdminDigitalPDP = enterDigitalPDP(true);

  return (
    <Route component={App} onEnter={checkGetUser}>
      {/* Favorites */}
      <Route path="/favorites/paper" component={PaperFavorites} />
      <Route exact path="community/sign-up" component={CommunityQuickOnboardFlow} />
      {/* Wedding Albums */}
      <Route path="/wedding-albums" component={Photobooks}>
        <IndexRedirect to="/wedding-albums/shop" />
        <Route
          path="shop"
          getComponent={() => {
            // Shop Albums LP has been moved to web-preauth
            window.location.assign('/wedding-albums/shop');
          }}
        />
        <Route path="select" component={AlbumDetailsPage} />
        <Route
          path="create"
          getComponent={(location, callback) => {
            callback(null, props => (
              <AlbumCustomizationFlowPage key={location.pathname} {...props} />
            ));
          }}
        >
          <IndexRedirect to="/wedding-albums/shop" />
          <Route path="layout" component={LayoutStep} />
          <Route path="upload" component={UploadStep} />
          <Route
            path=":projectUuid/:albumUuid"
            onEnter={onEnterAlbumsCustomizationStep}
            onLeave={onLeaveAlbumsCustomizationStep}
          >
            <>
              <Route path="organize" component={OrganizeStep} />
              <Route path="highlight" component={HighlightStep} />
            </>
            <Route path="customize" component={CustomizeStep} />
            <Route path="cover" component={CoverStep} />
          </Route>
        </Route>
        <Route path="drafts" component={AlbumDrafts} onEnter={onEnterPhotobookProjects('drafts')} />
        <Route path="orders" component={AlbumOrders} onEnter={onEnterPhotobookProjects('orders')} />
      </Route>
      {/* WEDDING  */}
      <Route path="/wedding-planning" onEnter={checkGetUser} component={PreAuthApp}>
        {_includes(['development', 'staging'], _get(window, 'zola.env')) && ( // Generic route for sandbox work.
          <Route path="sandbox" component={OnboardSandbox} />
        )}
        <Route
          path="website/design/:themeKey"
          component={location => {
            const { themeKey } = location.params;
            // Keep route to handle any internal redirects.
            window.location.assign(
              `${window.location.protocol}//${window.location.host}/wedding-planning/website/design/${themeKey}`
            );
          }}
        />
        <Route
          path="website/designs"
          onEnter={() => {
            // Keep route to handle any internal redirects.
            window.location.assign(
              `${window.location.protocol}//${window.location.host}/wedding-planning/website/designs`
            );
          }}
          component={MinimalLoader}
        />
      </Route>
      {/* END --- WEDDING */}
      <Route path="/wedding-planning" component={Cards}>
        <Route path="digital-save-the-date-unavailable" component={DSTDUnavailableErrorPage} />
        <Route path="projects" component={CardProjects}>
          <Redirect path="drafts-and-orders" to="drafts" />
          <Route
            path=":segment"
            component={DraftsAndOrdersPage}
            onEnter={gotoOrder({ redirectTo: '/wedding-planning/save-the-date/shop' })}
          />
          <IndexRedirect to="drafts" />
          <Route
            path="digital"
            component={DraftsAndOrdersPage}
            onEnter={gotoOrder({ redirectTo: '/wedding-planning/digital/save-the-date/shop' })}
          />
        </Route>
        {/* Customization flow routes */}
        <Route
          path="custom/:projectUUID"
          component={CardCustomizationFlow}
          onEnter={onEnterCustomizationFlow}
        >
          <Route path="guests" component={Guests} onEnter={onEnterCustomizationStep} />
          <Route
            path="detailFront"
            component={EventDetailFrontV2}
            onEnter={onEnterCustomizationStep}
          />
          <Route
            path="detailInside"
            component={EventDetailInsideV2}
            onEnter={onEnterCustomizationStep}
          />
          <Route
            path="detailWrite"
            component={EventDetailWrite}
            onEnter={onEnterCustomizationStep}
          />
          <Route
            path="detailAssign"
            component={EventDetailAssign}
            onEnter={onEnterCustomizationStep}
          />
          <Route
            path="detailBack"
            component={EventDetailBackV2}
            onEnter={onEnterCustomizationStep}
          />
          <Route path="rsvp" component={RSVPV2} onEnter={onEnterCustomizationStep} />
          <Route
            path="rsvpEnvelope"
            component={RsvpEnvelopeContainer}
            onEnter={onEnterCustomizationStep}
          />
          <Route path="envelope" component={EnvelopeContainer} onEnter={onEnterCustomizationStep} />
          <Route path="recipients" component={Recipients} onEnter={onEnterCustomizationStep} />
          <Route path="addressing" component={Addressing} onEnter={onEnterCustomizationStep} />
          <Route
            path="addressingPreview"
            component={Addressing}
            onEnter={onEnterCustomizationStep}
          />
          <Route path="addMorePaper" component={AddMore} onEnter={onEnterCustomizationStep} />
          <Route path="reviewPaper" component={Review} onEnter={onEnterCustomizationStep} />
          <Route path=":cardTypeSlug">
            <Route
              path="detailFront"
              getComponent={({ location }, callback) => {
                callback(null, props => (
                  // The `key` prop makes the component remount on URL change
                  <EventDetailFrontV2 key={`detail-front-${location.pathname}`} {...props} />
                ));
              }}
              onEnter={onEnterCustomizationStep}
            />
            <Route
              path="detailInside"
              getComponent={({ location }, callback) => {
                // The `key` prop makes the component remount on URL change
                callback(null, props => <EventDetailInsideV2 key={location.pathname} {...props} />);
              }}
              onEnter={onEnterCustomizationStep}
            />
            <Route
              path="detailWrite"
              getComponent={(location, callback) => {
                // The `key` prop makes the component remount on URL change
                callback(null, props => <EventDetailWrite key={location.pathname} {...props} />);
              }}
              onEnter={onEnterCustomizationStep}
            />
            <Route
              path="detailAssign"
              getComponent={(location, callback) => {
                callback(null, props => <EventDetailAssign key={location.pathname} {...props} />);
              }}
              onEnter={onEnterCustomizationStep}
            />
            <Route
              path="detailBack"
              getComponent={({ location }, callback) => {
                // The `key` prop makes the component remount on URL change
                callback(null, props => <EventDetailBackV2 key={location.pathname} {...props} />);
              }}
              onEnter={onEnterCustomizationStep}
            />
          </Route>
        </Route>

        <Route path="cart/:projectUuid/confirmation" component={AddToCartUpsell} />

        <Route path="digital/:cardType" onEnter={checkDigitalCardType}>
          <IndexRedirect to="./" />
          <Route path=":uuid" component={CardDigitalDetailPage} onEnter={onEnterDigitalPDP} />
        </Route>

        <Route path="admin">
          <Route path="digital/:cardType" onEnter={checkDigitalCardType}>
            <IndexRedirect to="./" />
            <Route
              path=":uuid"
              component={CardDigitalDetailPage}
              onEnter={onEnterAdminDigitalPDP}
            />
          </Route>
        </Route>

        <Route
          path="digital-mobile"
          component={DigitalMobileFlow}
          onEnter={(nextState, replaceState, callback) => {
            if (notMobile()) {
              // TODO DSTD update route
              replaceState('/wedding-planning/projects/drafts');
            }
            callback();
          }}
        />
        <Route
          path="digital-project-management/:projectUUID"
          component={DigitalProjectManagement}
          onEnter={onEnterDigitalManagement}
        />

        {/*
          TODO: DSTD
          Digital Customization flow routes
          It needs to be adjusted when BE API is ready
        */}
        <Route
          path="custom-digital/:projectUUID"
          component={CardCustomizationFlow}
          onEnter={onEnterDigitalCustomizationFlow}
        >
          <Route path="front" component={EventDetailFrontV2} onEnter={onEnterCustomizationStep} />
          <Route path="details" component={Details} onEnter={onEnterCustomizationStep} />
          <Route path="links" component={Links} onEnter={onEnterCustomizationStep} />
          <Route path="freeSamples" component={FreeSamples} onEnter={onEnterCustomizationStep} />
          <Route path="share" component={Share} onEnter={onEnterCustomizationStep} />
        </Route>
      </Route>
      {/* Preauth onboarding pages */}
      <Route path="/onboard">
        <Redirect path="select-product" to="/wedding/onboard/wedding-planning" />
        <Route
          path="explore"
          onEnter={(nextState, replaceState, callback) => {
            if (notMobile()) {
              replaceState('/wedding/manage');
            }
            callback();
          }}
          component={PostOnboardMobile}
        />
        <IndexRedirect to="/wedding/onboard/wedding-planning" />
        {renderNotFoundRoute('*')}
      </Route>
      <Route path="/wedding" onEnter={checkGetUser}>
        <IndexRedirect to="/wedding-planning" />
        <Route
          path="mobile-landing"
          component={() => {
            window.location.href = '/onboard/new';
            return null;
          }}
        />
        <Route path="guest-help" component={GuestHelp} />
        <Route path="onboard" onEnter={checkOnboardStatus}>
          <Redirect path="select-product" to="wedding-planning" />
          <Route path="wedding-planning" component={NewWeddingPlanningOnboardFlowV2} />
          <Route
            path="wedding-paper(/:paperProduct)/form"
            component={SinglePageOnboardingInvites}
          />
          <Route path="wedding-paper(/:paperProduct)" component={WeddingInvitationOnboardFlow} />
          <Route exact path="wedding-website/form" component={WebsiteQuickOnboardFlow} />
          <Route exact path="wedding-vendor-favorite" component={VendorFavoriteOnboardFlow} />

          <Route exact path="wedding-website" component={WeddingWebsiteOnboardFlow} />
          <Route path="wedding-website/explore" component={MobileWeddingWebsiteThemes} />
          <Route path="wedding-checklist/create" component={WeddingChecklistOnboardedUser} />
          <Route path="wedding-checklist" component={WeddingChecklistOnboardFlow} />
          <Route path="wedding-guest-list" component={WeddingGuestListOnboardFlow} />
          <Route path="registry" component={RegistryOnboardFlow} />
          <Route path="budget" component={BudgetOnboardFlow} />
          <Route path="real-weddings-nonfavorite" component={RealWeddingsNonFavoriteOnboardFlow} />
          <Route path="real-weddings-favorite" component={RealWeddingsFavoriteOnboardFlow} />
          <Route path="vendor-saved-search" component={VendorSavedSearchFlow} />
          <Route path="booked-vendor" component={BookedVendorFlow} />
        </Route>
        {/* getWeddingManageRoutes All nested routes of /wedding/manage */}
        {getWeddingManageRoutes(store)}
        <Route path="credits" component={Credits} />
        <Route
          path=":slug/virtual-embed/:eventHash"
          onEnter={checkWeddingWithToken}
          component={VirtualEmbed}
        />
        <Route path=":slug/virtual-embed/:eventHash/meeting-over" component={ZoomMeetingOver} />
        <Route path="print">
          <Route path="checklist" component={PrintChecklistContainer} />
        </Route>
        {renderNotFoundRoute('/notfound')}
        {renderNotFoundRoute('*')}
      </Route>
      {/* START --- new account pages */}
      <Route path="/account" onEnter={checkLoginAndRedirectToReferralPage}>
        <Route path="settings" component={AccountSettings} onEnter={checkGetUser}>
          <Route path="urls" component={SettingsURL} onEnter={checkForRequirement('slug')} />
          <Route
            path="wedding"
            component={WeddingDetailsContainer}
            onEnter={checkForRequirement('onboarding')}
          />
          <Route
            path="other-registries"
            component={OtherRegistriesContainer}
            onEnter={checkForRequirement('registry')}
          />
          <Route path="notifications" component={NotificationSettings} />
          <Route
            path="privacy"
            component={PrivacySettings}
            onEnter={checkForRequirement('registryOrWedding')}
          />
          <Route path="password-security" component={PasswordSecurity} exact />
          <Route path="hellos-from-vendors" component={InvitesFromVendors} exact />
        </Route>
      </Route>
      {/* EXTERNAL REDIRECTS - there might be a better way to do this */}
      <Route
        path="/registry/dashboard"
        component={() => {
          window.location = '/registry/dashboard';
        }}
      />
      {renderNotFoundRoute('*')}
    </Route>
  );
};

export default getRoutes;
