import ApiService from '@zola-helpers/client/dist/es/http/api';
import ServerApiService from '@zola-helpers/server/dist/es/utils/apiService';
import { getServerURL } from '@zola-helpers/server/dist/es/utils/url';
import type { SearchableVendorTaxonomyKey } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import type {
  WAccountVendorView,
  WBoardStorefrontView,
  WVendorCrossSellView,
  WVendorCrossSellRequest,
} from '@zola/svc-web-api-ts-client';
import type {
  AccountPreferencesView,
  AccountQuizView,
  AccountVendorView,
  AccountWeddingLocationView,
  BaseReferenceVendorRequest,
  BoardWeddingPhotoView,
  CreateAccountVendorRequest,
  CreateAddressRequest,
  StorefrontCardView,
  USCityView,
  FavoriteStorefrontSearchView,
  AccountVendorResultView,
  V4StorefrontSearchRequest,
  StorefrontSearchView,
  InquiryView,
  EntityFacetView,
  BulkCreateInquiryRequestItem,
  SuggestedTotalVendorBudgetView,
  SuggestedVendorBudgetByCategoryView,
} from '@zola/svc-marketplace-ts-types';

/** @see https://github.com/NewAmsterdamLabs/web-marketplace/blob/development/src/types/responseTypes.ts */
export interface AddressView {
  uuid?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateProvince?: string | null;
  postalCode?: string | null;
  countryCode?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  googleMapsPlaceId?: string | null;
  possibleCitySlug?: string | null;
  usCountyId?: number | null;
}

/** @see https://github.com/NewAmsterdamLabs/web-marketplace/blob/development/src/types/mappedResponseTypes.ts */
export interface MappedAddressView extends AddressView {
  location: string;
  singleLineAddress: string;
  googleMapsImageUrl: string;
  googleMapsUrl: string;
}

export interface MappedVendorSearchResult
  extends Pick<
    ReferenceVendorView,
    'id' | 'uuid' | 'taxonomyNodeId' | 'name' | 'email' | 'websiteUrl' | 'phone'
  > {
  address: MappedAddressView;

  // This is mapped and its always undefined, but its hard to prove it wasn't used somewhere
  slug: undefined;
}

export type MappedWAccountVendorView = Omit<WAccountVendorView, 'vendor_type'> & {
  vendor_type: AccountVendorView.VendorTypeEnum;
};

export type MappedWVendorCrossSellRequest = Omit<
  WVendorCrossSellRequest,
  'account_id' | 'priority_list'
> & {
  priority_list: AccountVendorView.VendorTypeEnum[];
};

export interface ReferenceVendorView {
  id: number;
  uuid: string;
  taxonomyNodeId: number;
  searchScore: string | null;
  name: string;
  email: string | null;
  websiteUrl: string | null;
  phone: string | null;
  weddingIds: Array<number> | null;
  address: AddressView | null;
}

export type TaxonomyNodeToVendorMarketSearchView = {
  key: string;
  id: number;
  searchableMarkets: string[];
};

export type VendorMarketView = {
  key: string;
  id: number;
  label: string;
  searchScore: number | null;
  latitude: number | null;
  longitude: number | null;
  radiusMiles: number | null;
  onboardable: boolean;
  searchable?: boolean;
  parentId: number | null;
  rootId: number | null;
  marketLevel: 'Country' | 'State' | 'Metro' | 'Borough' | 'City' | 'Neighborhood';
};

export interface BookVendorFormValues {
  vendorName: string;
  vendorType: string;
  city: string;
  stateProvince: string;
  email?: string;
  price?: string;
  eventDate?: string;
  referenceVendorId?: number;
  uuid?: string;
}

type PartialCreateAddressRequest = Pick<CreateAddressRequest, 'city' | 'stateProvince'>;

export type CreateBookedVendorRequest = Partial<
  Omit<CreateAccountVendorRequest, 'referenceVendorRequest' | 'vendorType' | 'eventDate'>
> & {
  vendorType: string;
  eventDate?: Date | string;
  referenceVendorRequest: Partial<Omit<BaseReferenceVendorRequest, 'address'>> & {
    address: PartialCreateAddressRequest | null;
  };
};

export type VendorSupplied = { venueMarket?: string; photographerMarket?: string };

export type VendorTaxonomyNodeId = number; // really 1 to 26

export function checkMarketSuppliedForVendors({
  city,
  state,
}: {
  city: string;
  state: string;
}): Promise<VendorSupplied> {
  return ApiService.post<VendorMarketView[]>(
    `/web-marketplace-api/v1/vendor-onboarding/verify-market-presence`,
    {
      city,
      state,
    }
  )
    .then((response: VendorMarketView[]): string | false => {
      // might have got an empty response.  The city isn't mapped to a searchable market
      const marketForCity = response[0]?.key;
      return marketForCity || false;
    })
    .then(
      (marketForCity: string | false): Promise<VendorSupplied> => {
        if (marketForCity) {
          return ApiService.get<TaxonomyNodeToVendorMarketSearchView[]>(
            '/web-marketplace-api/v2/vendor-market/for-taxonomy-nodes'
          ).then(
            (suppliedVendors: TaxonomyNodeToVendorMarketSearchView[]): VendorSupplied => {
              const venueMarkets = suppliedVendors.filter(node => node.key === 'wedding-venues')[0];
              const photographerMarkets = suppliedVendors.filter(
                node => node.key === 'wedding-photographers'
              )[0];
              const venueMarket =
                venueMarkets.searchableMarkets.indexOf(marketForCity) > -1
                  ? marketForCity
                  : undefined;
              const photographerMarket =
                photographerMarkets.searchableMarkets.indexOf(marketForCity) > -1
                  ? marketForCity
                  : undefined;
              return {
                venueMarket,
                photographerMarket,
              };
            }
          );
        }
        return Promise.resolve({});
      }
    );
}

export const favoriteStorefront = (
  req: Omit<WBoardStorefrontView, 'account_id' | 'user_object_id'>
): Promise<WBoardStorefrontView> =>
  ApiService.post<WBoardStorefrontView>('/web-api/v1/board-resource/storefront/favorite', req);

export const getAccountPreferences = (): Promise<AccountPreferencesView> =>
  ApiService.get<AccountPreferencesView>('/web-marketplace-api/v1/account-preferences');

export const getAccountVendors = (): Promise<MappedWAccountVendorView[]> =>
  ApiService.get<MappedWAccountVendorView[]>('/web-api/v1/marketplace-account/vendors');

export const getStorefrontCardView = (uuid: string): Promise<StorefrontCardView> =>
  ApiService.get<StorefrontCardView>(`/web-marketplace-api/v3/storefront/cardview/${uuid}`);

export const getMarketplaceCrossSell = (
  req: MappedWVendorCrossSellRequest
): Promise<WVendorCrossSellView> =>
  ApiService.post<WVendorCrossSellView>('/web-api/v1/marketplace-search/vendor-cross-sell', req);

export const loadQuizzesAnswered = () => {
  return ApiService.get<AccountQuizView[]>('/web-marketplace-api/v2/couple-preference/quizzes');
};

export const createBookedVendorV2 = (
  req: CreateBookedVendorRequest
): Promise<AccountVendorResultView> => {
  return ApiService.post('/web-marketplace-api/v2/account/vendor?priority=0', req);
};

export const vendorMarketplaceSearch = (inputVal: string, categoryId: VendorTaxonomyNodeId) =>
  ApiService.post<MappedVendorSearchResult[]>(
    '/web-marketplace-api/v1/vendor-search/list-by-category',
    {
      prefix: inputVal,
      category_id: categoryId,
    }
  );

export const getMarketplaceLocation = (): Promise<AccountWeddingLocationView | null> =>
  ApiService.get('/web-marketplace-api/v1/account-preferences/location');

export const updateWeddingLocation = (request: {
  location: USCityView;
  source?: string;
  origin?: string;
}): Promise<void> => {
  const { location, source, origin } = request;
  const address = {
    city: location.name,
    stateProvince: location.stateCode,
  };
  return ApiService.put(`/web-marketplace-api/v1/account-preferences/wedding-location`, {
    address,
    source,
    origin,
  });
};

export const recordQuizletAnswer = (request: {
  key: string;
  liked: boolean;
  source: string;
  origin: string;
}): Promise<void> => {
  return ApiService.post('/web-marketplace-api/v1/quizlet/answer', request);
};

export const createFavoritePhoto = (request: BoardWeddingPhotoView): Promise<unknown> => {
  return ApiService.get(`/web-marketplace-api/v1/favorites`)
    .then((response: any) => {
      // response type MappedBoardView
      if (response && 'uuid' in response) {
        ApiService.post(`/web-marketplace-api/v1/favorites/wedding-photo`, {
          ...request,
          boardUuid: response.uuid,
        }).catch(() => undefined);
      }
    })
    .catch(() => undefined);
};

export type SaveSearchRequest = {
  label: string;
  v4StorefrontSearchRequest: Omit<V4StorefrontSearchRequest, 'accountId'>;
};

export const saveMarketplaceVendorSearch = (
  params: SaveSearchRequest
): Promise<FavoriteStorefrontSearchView> => {
  return ApiService.post<FavoriteStorefrontSearchView>(
    '/web-marketplace-api/v1/storefront-search/favorite-search',
    params
  );
};

export type PageCardView = {
  tag: string;
  heroImageset: {
    url: string;
    descriptor: string;
  }[];
  href: string;
  title: string;
  excerpt: string;
};

export const getExpertAdviceArticles = (slugs: string[]) => {
  return ServerApiService.post<PageCardView[]>(
    getServerURL('MARKETPLACE', '/web-marketplace-api/v1/content-api/pages/by-slug'),
    {
      slugs,
    }
  );
};

export type VendorSearchRequestType = Omit<V4StorefrontSearchRequest, 'accountId' | 'userObjectId'>;

export const fetchStorefrontSearchResults = (
  search: VendorSearchRequestType
): Promise<StorefrontSearchView> => {
  return ApiService.post<StorefrontSearchView, VendorSearchRequestType>(
    '/web-marketplace-api/v4/storefront/search',
    search
  );
};
export interface MappedInquiryView
  extends Omit<
    InquiryView,
    | 'addressView'
    | 'entityFacetViews'
    | 'vendorMarketView'
    | 'weddingEntityFacetViews'
    | 'weddingDate'
    | 'weddingYearMonthDate'
  > {
  weddingDate: Date | null;
  weddingYearMonthDate: Date | null;
  weddingStartPrice: number | null;
  weddingEndPrice: number | null;
  serviceStartPrice: number | null;
  serviceEndPrice: number | null;
  inquiryDetailViewFacets: EntityFacetView[];
  location: Partial<AddressView>;
  taxonomyKey: SearchableVendorTaxonomyKey;

  // Mapped from vendorMarketView.id
  vendorMarketId?: number | null;
}
export interface BulkCreateInquiryRequest {
  items: Array<BulkCreateInquiryRequestItem>;
}

export const bulkCreateInquiry = (
  bulkCreateInquiryRequest: BulkCreateInquiryRequest
): Promise<MappedInquiryView[]> =>
  ApiService.post('/web-marketplace-api/v1/inquiry/bulk', bulkCreateInquiryRequest);

export type SuggestedBudgetRequest = {
  location?: string;
  guest_count?: number;
  total_budget_cents?: number;
};

/** returns a total suggested budget based on location/guestCount; no request object will return US average with ~100 guests as baseline */
export const fetchSuggestedBudgetTotal = (
  suggestedBudgetRequest?: SuggestedBudgetRequest
): Promise<SuggestedTotalVendorBudgetView> =>
  ApiService.post('/web-marketplace-api/v2/suggested-vendor-budget/total', suggestedBudgetRequest);

export const fetchSuggestBudgetByCategory = (
  suggestedBudgetRequest?: SuggestedBudgetRequest
): Promise<SuggestedVendorBudgetByCategoryView> =>
  ApiService.post(
    '/web-marketplace-api/v2/suggested-vendor-budget/by-category',
    suggestedBudgetRequest
  );
