import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import type { RouteComponentProps } from 'react-router';
import _debounce from 'lodash/debounce';
import _isFinite from 'lodash/isFinite';
import type { WBudgetItemView } from '@zola/svc-web-api-ts-client';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import pluralize from '@zola-helpers/client/dist/es/transformers/pluralize';
import insertInArrayIf from '@zola-helpers/client/dist/es/util/insertInArrayIf';

import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';
import { BodyBase } from '@zola/zola-ui/src/typography/Paragraphs';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { Strong } from '@zola/zola-ui/src/typography/Text';
import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import { InputFieldAddOnType } from '@zola/zola-ui/src/components/Form/InputFieldV2/InputFieldAddOn';
import { TrashIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Trash';

import { useAppDispatch } from 'reducers/useAppDispatch';
import { useAppSelector } from 'reducers/useAppSelector';
import {
  getUserWeddingCity,
  getUserWeddingGuestCount,
  getUserWeddingStateProvince,
} from 'selectors/user/userSelectors';
import { getBudgetItemByUuid, useBudgetContext } from '../context';
import { handleBudgetRouteChange } from '../routes/handleBudgetRouteChange';
import { useLeaveBudgetRoute } from '../routes/useLeaveBudgetRoute';
import {
  getBudgetByAccountId,
  getSuggestedBudgetByCategory,
  updateBudget,
  updateBudgetItem,
} from '../util/api';
import { useCurrencyInput } from '../util/useCurrencyInput';
import { mapCostToFormattedString, mapItemToUpdateItemRequest } from '../util/mappers';
import { useBudgetDrawerLayer } from '../util/useBudgetDrawerLayer';

import BudgetSliderInput from '../BudgetSliderInput/BudgetSliderInput';
import BudgetDetailsModal from '../BudgetDetailsModal/BudgetDetailsModal';
import BudgetConfirmDeleteModal from '../BudgetConfirmDeleteModal/BudgetConfirmDeleteModal';

import {
  StyledTitle3,
  EstimateTitle,
  StyledDrawerTitle,
  Top,
  TotalBudget,
  Label,
  InputContainer,
  Allocation,
  StyledInfoCircleIcon,
  StyledTooltipV2,
  HoverDeleteButton,
  Row,
} from './BudgetEstimates.styles';

export const BudgetEstimates = ({
  route,
  router: { setRouteLeaveHook },
}: RouteComponentProps<object, object>): JSX.Element => {
  useLeaveBudgetRoute(route, setRouteLeaveHook);
  const dispatch = useAppDispatch();
  const guestCount = useAppSelector(getUserWeddingGuestCount);
  const userCity = useAppSelector(getUserWeddingCity);
  const userStateProvince = useAppSelector(getUserWeddingStateProvince);

  const [showTooltip, setShowTooltip] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showBudgetDetails, setShowBudgetDetails] = useState(false);
  const [deletePromptUuid, setDeletePromptUuid] = useState('');
  const triggerRef = useRef<HTMLAnchorElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    state: {
      budget: { uuid: budgetUuid, budgeted_cents = 0, taxonomy_nodes = [] },
      marketplaceLocation: { citySlug },
      suggestedAllocations: { suggested_budgets_by_category } = {},
    },
    actions: { receiveBudget, receiveSuggestedAllocations, clearSuggestedAllocations },
  } = useBudgetContext();

  const [localEstimates, setLocalEstimates] = useState<
    Pick<WBudgetItemView, 'estimated_cost_cents' | 'uuid'>[]
  >([]);

  const {
    currencyValue,
    rawCurrencyValue,
    setCurrencyValue,
    toggleIsEditingCurrency,
  } = useCurrencyInput((budgeted_cents / 100).toFixed(2), inputRef, []);
  const localTotalBudget = Number((parseFloat(rawCurrencyValue) * 100).toFixed(0));

  const handleUpdateEstimate = _debounce((uuid: string | undefined, estimateCents: number) => {
    setHasUnsavedChanges(true);
    setLocalEstimates(prevEst => [
      ...prevEst.filter(est => est.uuid !== uuid),
      { uuid, estimated_cost_cents: estimateCents },
    ]);
  }, 300);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFetchSuggestedByCategory = useCallback(
    _debounce(
      req =>
        getSuggestedBudgetByCategory(req).then(suggested => {
          receiveSuggestedAllocations(suggested);
        }),
      500
    ),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasUnsavedChanges(true);
    setCurrencyValue(e.target.value);

    if (Math.round(Number(e.target.value)) !== Math.round(budgeted_cents)) {
      handleFetchSuggestedByCategory({
        location: userCity && userStateProvince ? citySlug : undefined,
        guest_count: guestCount || undefined,
        total_budget_cents: Number(e.target.value) * 100,
      })?.catch(() => null);
    }
  };

  const handleSave = () => {
    setIsSubmitting(true);
    const itemsToUpdatePromises = localEstimates.map(({ uuid, estimated_cost_cents }) => {
      const request = {
        ...mapItemToUpdateItemRequest(
          getBudgetItemByUuid(uuid as string, taxonomy_nodes) as WBudgetItemView,
          true
        ),
        estimated_cost_cents,
      };
      return () => updateBudgetItem(request);
    });
    const didUpdateTotal = localTotalBudget !== budgeted_cents;
    Promise.all([
      ...itemsToUpdatePromises.map(u => u()),
      ...insertInArrayIf(
        didUpdateTotal,
        updateBudget({ budget_uuid: budgetUuid as string, budgeted_cents: localTotalBudget })
      ),
    ])
      .then(() => {
        handleBudgetRouteChange('', { preventIfUnsaved: false });
        dispatch(
          toastsActions.positive({
            headline: 'Updated estimates',
          })
        );
      })
      // fetch the entire budget again since we want everything new and update the budget summary
      .then(getBudgetByAccountId)
      .then(res => {
        receiveBudget(res);
      })
      .catch(() => {
        dispatch(
          toastsActions.negative({
            headline: 'There was an error updating your estimates.',
          })
        );
      })
      .finally(() => setIsSubmitting(false));
  };

  const handleCancel = () => {
    clearSuggestedAllocations();
    handleBudgetRouteChange();
  };

  useEffectOnce(() => {
    return () => clearSuggestedAllocations();
  });

  const handleTrashClick = (uuid: string) => {
    setDeletePromptUuid(uuid);
  };

  useBudgetDrawerLayer(
    {
      key: 'estimates',
      saveButtonDisabled: localTotalBudget < 1000,
      onSave: handleSave,
      onBack: handleCancel,
      onUnsavedChanges: hasUnsavedChanges
        ? () => {
            handleBudgetRouteChange(`/estimates`);
          }
        : undefined,
    },
    isSubmitting,
    [localEstimates, localTotalBudget]
  );

  const totalUserEstimatesOrSuggestedEstimate = useMemo(
    () =>
      taxonomy_nodes.reduce<number>(
        (nodeSum, { items = [] }) =>
          items.reduce<number>((prevSum, { uuid, estimated_cost_cents }) => {
            const localEstimate = localEstimates.find(est => est.uuid === uuid);
            const estimate = localEstimate?.estimated_cost_cents || estimated_cost_cents || 0;
            return prevSum + estimate;
          }, nodeSum),
        0
      ),
    [localEstimates, taxonomy_nodes]
  );

  const subText = useMemo(() => {
    let dek = '';
    if (guestCount || (userCity && userStateProvince)) {
      dek += `Expert recommended estimates are based on a wedding`;
    }
    if (guestCount) {
      dek += ` with ${pluralize('guest', 'guests', guestCount)}`;
    }
    if (userCity && userStateProvince) {
      dek += ` in ${userCity}, ${userStateProvince}`;
    } else if (dek) {
      dek += ' in the US';
    }
    if (dek) dek += '.';
    return dek;
  }, [guestCount, userCity, userStateProvince]);

  const totalPercentage = (totalUserEstimatesOrSuggestedEstimate / localTotalBudget) * 100;
  const isEstimateMore = totalUserEstimatesOrSuggestedEstimate > localTotalBudget;
  const difference = Math.abs(localTotalBudget - totalUserEstimatesOrSuggestedEstimate);

  return (
    <>
      <Top>
        <div>
          <StyledDrawerTitle>Create your budget</StyledDrawerTitle>
          <BodyBase>{subText}</BodyBase>
          <LinkV2
            role="button"
            onClick={() => setShowBudgetDetails(true)}
            inline
            innerRef={triggerRef}
          >
            Edit details
          </LinkV2>
        </div>
        <TotalBudget>
          <Label>
            <StyledTooltipV2
              text="The absolute max amount you'd want to spend."
              popIn={showTooltip}
            />
            Total budget{' '}
            <StyledInfoCircleIcon
              height={20}
              width={20}
              showTitle={false}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            />
          </Label>{' '}
          <InputContainer>
            <InputFieldV3
              name="budgeted_cents"
              onChange={handleInputChange}
              onFocus={toggleIsEditingCurrency}
              onBlur={toggleIsEditingCurrency}
              value={currencyValue}
              isControlled
              ref={inputRef}
              addOn={InputFieldAddOnType.CASH}
              errorMessage={Number(rawCurrencyValue) < 1000 ? 'Minimum must be $1,000' : undefined}
            />
          </InputContainer>
        </TotalBudget>
        <Allocation isEstimateMore={isEstimateMore}>
          <span>
            <Strong>
              {Math.round(
                _isFinite(totalPercentage)
                  ? totalPercentage
                  : totalUserEstimatesOrSuggestedEstimate / 100
              )}
              % of total budget allocated{' '}
            </Strong>
            {Boolean(difference) &&
              `(${mapCostToFormattedString(difference, true)} ${
                isEstimateMore ? 'over' : 'under'
              })`}
          </span>
          {Boolean(difference) && (
            <LinkV2
              role="button"
              onClick={() => {
                setHasUnsavedChanges(true);
                setCurrencyValue((totalUserEstimatesOrSuggestedEstimate / 100).toString());
              }}
              inline
              sizes="smaller"
            >
              {isEstimateMore ? 'Increase' : 'Decrease'} total budget
            </LinkV2>
          )}
        </Allocation>
      </Top>

      {taxonomy_nodes.map(({ title: catTitle, items }, i) => {
        return items?.length ? (
          <Fragment key={`budget-estimates-cat-${i}`}>
            <StyledTitle3 presentation="h4">
              {catTitle}
              {i === 0 && <EstimateTitle>Estimate</EstimateTitle>}
            </StyledTitle3>
            {items?.map(
              ({
                uuid,
                title,
                estimated_cost_cents,
                suggested_allocation: { allocated_cost_cents } = {},
                suggested_budget_category_key,
              }) => {
                const localEstimate =
                  localEstimates.find(est => est.uuid === uuid)?.estimated_cost_cents ||
                  estimated_cost_cents ||
                  0;
                const { budget_estimate_mid_point_cents } =
                  (suggested_budget_category_key &&
                    suggested_budgets_by_category?.[suggested_budget_category_key]) ||
                  {};
                const expertsRecommend = budget_estimate_mid_point_cents || allocated_cost_cents;
                return (
                  <Row key={`budget-slider-${title}`}>
                    <BudgetSliderInput
                      name={title as string}
                      value={localEstimate}
                      label={title}
                      dek={
                        expertsRecommend
                          ? `Experts recommend spending ${mapCostToFormattedString(
                              expertsRecommend,
                              true
                            )}.`
                          : ''
                      }
                      onChange={estimateCents => handleUpdateEstimate(uuid, estimateCents)}
                    />
                    <HoverDeleteButton
                      role="button"
                      onClick={() => handleTrashClick(uuid as string)}
                      color="red"
                    >
                      <TrashIcon width={20} height={20} showTitle={false} />
                    </HoverDeleteButton>
                  </Row>
                );
              }
            )}
          </Fragment>
        ) : null;
      })}
      {showBudgetDetails && (
        <BudgetDetailsModal
          triggerRef={triggerRef}
          onClose={() => {
            setShowBudgetDetails(false);
          }}
        />
      )}
      {deletePromptUuid && (
        <BudgetConfirmDeleteModal
          type="item"
          hed={`Are you sure you want to delete ${
            getBudgetItemByUuid(deletePromptUuid, taxonomy_nodes)?.title
          }?`}
          uuid={deletePromptUuid}
          onDelete={() => setDeletePromptUuid('')}
          onCancel={() => setDeletePromptUuid('')}
          triggerRef={null}
        />
      )}
    </>
  );
};

export default BudgetEstimates;
