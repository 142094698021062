import { createSelector } from 'reselect';

export const getSubtotal = state => state.cards.customization.review.total;
export const getPendingChanges = state => state.cards.customization.hasPendingChanges;

// formHistory selectors
export const getFormHistory = state => state.cards.formHistory;

// customizationBar selectors
export const getCustomizationErrorsByElementUUID = state =>
  state.cards.cardCustomizationBar.errorsByElementUUID;

export const getCustomizationHasErrors = createSelector(
  getCustomizationErrorsByElementUUID,
  errorsByElementUUID => !!errorsByElementUUID && errorsByElementUUID.length > 0
);

// recipients selectors
export const getGuestGroups = state => state?.guestList?.guests?.byId;
export const getRecipientsIsBusy = state => state.cards.recipients.busy;
export const getSelectedGuestIds = state => state.cards.recipients.selectedGuestIds;
export const getFontSizeOverrides = state => state.cards.recipients.fontSizeOverrides;
export const getIsFirstFontResizeComplete = state =>
  state.cards.recipients.isFirstFontResizeComplete;
export const getInvitedGuestsCount = state => state.guestList.guests.orderedIds.length || 0;

export const getGuestGroupsMissingEmailIds = createSelector(
  getGuestGroups,
  getSelectedGuestIds,
  (guestGroups, selectedGuestIds) =>
    selectedGuestIds.filter(
      id =>
        guestGroups[id] &&
        (guestGroups[id].email_address === null || guestGroups[id].email_address === '')
    )
);

export const getIsGuestListMissingEmail = createSelector(
  getGuestGroupsMissingEmailIds,
  missingEmailIds => missingEmailIds.length > 0
);

/**
 * Returns sorted guest group ids by priority:
 * 1. Missing address
 * 2. Incomplete address
 * 3. Invited
 * 4. Maybe invited
 */
export const getSortedGuestGroupIds = createSelector(getGuestGroups, guestGroups => {
  const guestGroupsData = Object.keys(guestGroups).map(key => {
    const item = guestGroups[key];
    return {
      id: key,
      missingAddress: item.isMissingAddress ? 1 : 0,
      incompleteAddress: !item.isCompleteAddress ? 1 : 0,
      invited: item.invited ? 1 : 0,
    };
  });
  const sortedGuestGroupsData = guestGroupsData.sort((a, b) => {
    if (a.invited !== b.invited) {
      return b.invited - a.invited;
    }
    if (a.incompleteAddress !== b.incompleteAddress) {
      return b.incompleteAddress - a.incompleteAddress;
    }
    return b.missingAddress - a.missingAddress;
  });
  return sortedGuestGroupsData.map(item => Number(item.id));
});

/**
 * Returns selected guest group ids in sorted order
 */
export const getSelectedSortedGuestGroupIds = createSelector(
  getSelectedGuestIds,
  getSortedGuestGroupIds,
  (selectedGuestGroupIds, sortedGuestGroupIds) => {
    const selectedSet = new Set(selectedGuestGroupIds);
    return sortedGuestGroupIds.filter(id => selectedSet.has(id));
  }
);
