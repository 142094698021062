import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Global } from '@emotion/react';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { XIcon } from '@zola/zola-ui/src/components/SvgIconsV3/X';
import { ArrowNarrowLeftIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ArrowNarrowLeft';

import useClickOutside from '@zola/zola-ui/src/hooks/useClickOutside';
import useSaveChangesPrompt from '@zola/zola-ui/src/hooks/useSaveChangesPrompt';
import usePrevious from '@zola/zola-ui/src/hooks/usePrevious';
import useSimpleScrollLock from 'lib/hooks/useSimpleScrollLock';

import { DrawerProvider, useDrawerControls, DrawerContextProps } from './context';

import {
  Overlay,
  Container,
  HeaderBar,
  CloseButton,
  BackButton,
  ScreenReaderText,
  ScrollContainer,
  UnsavedChangesPrompt,
  PromptOverlay,
  PromptTitle,
  ButtonContainer,
  Discard,
  lockBgscrolling,
  DrawerFooter,
  StyledCancelButton,
} from './Drawer.styles';

export type DrawerProps = {
  className?: string;
  /** Locks background scrolling in place. Default true */
  lockBgScrolling?: boolean;
  /** Should the drawer close when the 'escape' key is press.  Default true */
  closeOnEscape?: boolean;
};

const Drawer: React.FC<DrawerProps> = ({
  children,
  lockBgScrolling = true,
  closeOnEscape = true,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const {
    isOpen = false,
    showSavePrompt = false,
    drawerLayers = [],
    currentLayer,
    setIsOpen,
    setShowSavePrompt,
    setOnUnsavedChanges,
    onClose = () => undefined,
    removeDrawerLayer,
  } = useDrawerControls();
  const {
    key = '',
    useClose = true,
    onBack,
    onSave,
    onUnsavedChanges,
    tertiaryElement,
    cancelButtonText = 'Cancel',
    cancelButtonDisabled,
    saveButtonText = 'Save',
    saveButtonDisabled,
  } = currentLayer || {};
  /** Occasionally, when save prompt is shown, we need to know if it was triggered from a close action or back action */
  const [backOrCloseAction, setBackOrCloseAction] = useState<'close' | 'back' | undefined>();
  const prevKey = usePrevious(key);

  /** This will catch a route change if it can't be caught internally, i.e. a full-page refresh route change vs shallow change of react-router; */
  useSaveChangesPrompt(!!onUnsavedChanges);

  /** locks background from scrolling when open */
  useSimpleScrollLock(lockBgScrolling && isOpen);

  useEffect(() => {
    if (
      showSavePrompt &&
      (!isOpen || (key !== prevKey && process.env.JEST_WORKER_ID === undefined))
    ) {
      setShowSavePrompt(false);
    }
  }, [key, prevKey, showSavePrompt, isOpen, setShowSavePrompt]);

  /** When attempting to close with multiple layers, the default behavior is to recursively iterate through layers;
   * However, if there are unsaved changes, we want the loop to stop
   * shouldCloseAfterLoop is set to true if all layers had no unsavedChanges
   */
  const loopThroughLayersToFindUnsavedChange = useCallback(() => {
    let shouldCloseAfterLoop = true;
    // Remove current and then any layers without any unsaved changes, but stop if layer has unsaved changes
    for (const layer of drawerLayers) {
      if (layer.key === key || !layer.onUnsavedChanges) {
        removeDrawerLayer(layer.key);
      } else {
        layer.onUnsavedChanges();
        shouldCloseAfterLoop = false;
        break;
      }
    }
    return shouldCloseAfterLoop;
  }, [key, drawerLayers, removeDrawerLayer]);

  /** Final onClose call that is not conditional on unsaved changes */
  const handleForceClose = useCallback(() => {
    onClose();
    setIsOpen(false);
    removeDrawerLayer(key);
  }, [key, onClose, setIsOpen, removeDrawerLayer]);

  // close function for X icon or clicking the opacity overlay
  const handleCloseDrawer = useCallback(() => {
    setBackOrCloseAction('close');
    // if this layer has unsaved change, trigger
    if (onUnsavedChanges) onUnsavedChanges();
    // else, check if any other layers have unsavedChanges
    else if (drawerLayers.some(l => l.onUnsavedChanges)) loopThroughLayersToFindUnsavedChange();
    // else close the drawer
    else handleForceClose();
  }, [loopThroughLayersToFindUnsavedChange, onUnsavedChanges, handleForceClose, drawerLayers]);

  useClickOutside({
    ref,
    onClickOutside: handleCloseDrawer,
    exclusion: document.getElementById('budget-modal-root'),
    isOpen,
  });

  /** used when clicking back arrow or cancel, but will trigger global onClose if no onBack defined  */
  const handleBackOrClose = () => {
    // fire onBack if defined, takes priority over onClose
    if (onBack) onBack();
    else onClose();
  };

  const handleBack = () => {
    setBackOrCloseAction('back');
    // prevent back click if unsaved changes
    if (onUnsavedChanges) onUnsavedChanges();
    // otherwise proceed as usual
    else {
      removeDrawerLayer(currentLayer?.key as string);
      handleBackOrClose();
    }
  };

  // Close drawer when hitting "Esc".
  useEffect(() => {
    const close = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && closeOnEscape) {
        event.preventDefault();
        event.stopPropagation();
        handleCloseDrawer();
      }
    };
    window.addEventListener('keydown', close, true);

    return function removeEventListenerAndReturnFocus() {
      window.removeEventListener('keydown', close, true);
    };
  }, [closeOnEscape, handleCloseDrawer]);

  // Clicking 'Discard' in Save Change prompt
  const handleCloseFromPrompt = () => {
    setShowSavePrompt(false);
    // remove the callback for unsaved changes
    setOnUnsavedChanges();
    // when Drawer is used with react-router's setOnLeaveHook, we must remove these calls from the synchronous stack, lest we end up with a race condition
    // since we're simply taking it out of the stack, the setTimeout is imperceptible to the human eye; no harm, no foul
    setTimeout(() => {
      // if prompt was triggered from back arrow, proceed with traversing back to next layer
      if (backOrCloseAction === 'back') {
        removeDrawerLayer(currentLayer?.key as string);
        handleBackOrClose();
      }
      // else if triggered from close action, loop through
      else {
        const shouldCloseAfterLoop = loopThroughLayersToFindUnsavedChange();
        if (shouldCloseAfterLoop) handleForceClose();
      }
    }, 0);
  };

  // Clicking 'Keep editing'
  const handleContinueEditing = () => {
    setShowSavePrompt(false);
  };

  // Clicking 'Save'
  const handleSave = () => {
    removeDrawerLayer(currentLayer?.key as string);
    if (onSave) onSave();
    else handleBackOrClose();
  };

  return (
    <Overlay data-testid="zui-Drawer" isOpen={isOpen}>
      <Container isOpen={isOpen} ref={ref}>
        <ScrollContainer id="drawer-scroll-container">
          <HeaderBar>
            {useClose ? (
              <CloseButton onClick={handleCloseDrawer}>
                <XIcon height={20} width={20} showTitle={false} />
                <ScreenReaderText>Close</ScreenReaderText>
              </CloseButton>
            ) : (
              <BackButton onClick={handleBack}>
                <ArrowNarrowLeftIcon height={20} width={20} showTitle={false} />
                <ScreenReaderText>Back</ScreenReaderText>
              </BackButton>
            )}
          </HeaderBar>
          {children}
          <DrawerFooter>
            {tertiaryElement}
            {onBack && (
              <StyledCancelButton
                variant="secondary"
                onClick={handleBack}
                disabled={cancelButtonDisabled}
              >
                {cancelButtonText}
              </StyledCancelButton>
            )}
            {onSave && (
              <ButtonV3 onClick={handleSave} disabled={saveButtonDisabled}>
                {saveButtonText}
              </ButtonV3>
            )}
          </DrawerFooter>
          {showSavePrompt && (
            <PromptOverlay>
              <UnsavedChangesPrompt data-testid="zui-Drawer__prompt">
                <PromptTitle>Are you sure? You have unsaved changes.</PromptTitle>
                <ButtonContainer>
                  <ButtonV3 onClick={handleContinueEditing} variant="secondary" size="small">
                    Keep editing
                  </ButtonV3>
                  <ButtonV3 onClick={handleSave} size="small">
                    Save
                  </ButtonV3>
                </ButtonContainer>
                <Discard role="button" onClick={handleCloseFromPrompt}>
                  Discard
                </Discard>
              </UnsavedChangesPrompt>
            </PromptOverlay>
          )}
        </ScrollContainer>
      </Container>
    </Overlay>
  );
};

const DrawerWithProvider: React.FC<DrawerProps & DrawerContextProps> = ({
  children,
  className,
  lockBgScrolling,
  closeOnEscape,
  ...contextProps
}) => (
  <DrawerProvider {...contextProps}>
    <Global styles={lockBgscrolling} />
    <Drawer className={className} lockBgScrolling={lockBgScrolling} closeOnEscape={closeOnEscape}>
      {children}
    </Drawer>
  </DrawerProvider>
);

export function withDrawer<P>(Component: React.ComponentType<P>, opts?: DrawerProps) {
  const ComponentWrappedInProvider: React.FC<P> = props => {
    return (
      <DrawerWithProvider {...opts}>
        <Component {...props} />
      </DrawerWithProvider>
    );
  };

  return ComponentWrappedInProvider;
}

export default DrawerWithProvider;
