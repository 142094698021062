import React, { useRef, useState } from 'react';

import type { RouteComponentProps } from 'react-router';
import type { UpdateBudgetItemRequest } from '@zola/svc-web-api-ts-client';

import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';
import InputFieldV3 from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3';
import { InputFieldAddOnType } from '@zola/zola-ui/src/components/Form/InputFieldV2/InputFieldAddOn';
import { TextareaFieldV2 } from '@zola/zola-ui/src/components/Form/TextareaFieldV2';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import { useAppDispatch } from 'reducers/useAppDispatch';
import { getBudgetByAccountId, updateBudgetItem } from '../util/api';
import { useCurrencyInput } from '../util/useCurrencyInput';
import { useBudgetDrawerLayer } from '../util/useBudgetDrawerLayer';
import {
  mapCostToFormattedString,
  mapItemToBookedVendor,
  mapItemToUpdateItemRequest,
  mapItemTypeToItemTypeDetails,
} from '../util/mappers';

import { BudgetItemViewTitle } from './components/BudgetItemViewTitle';
import { BudgetItemViewVendorCtas } from './components/BudgetItemViewVendorCtas';
import { BudgetItemViewVendorCtasV2 } from './components/BudgetItemViewVendorCtasV2';
import { BudgetItemViewPayments } from './components/BudgetItemViewPayments';
import { BudgetItemViewVendorSrpLink } from './components/BudgetItemViewVendorSrpLink';
import { BudgetConfirmDeleteMiniModal } from '../BudgetConfirmDeleteMiniModal/BudgetConfirmDeleteMiniModal';
import { useBudgetContext, getBudgetItemByUuid } from '../context';
import { handleBudgetRouteChange } from '../routes/handleBudgetRouteChange';
import { useLeaveBudgetRoute } from '../routes/useLeaveBudgetRoute';

import {
  Container,
  CostContainer,
  CostLabel,
  CostFields,
  SuggestedAllocation,
  StyledCheckboxField,
  NotesLabel,
  Optional,
  PaidRemaining,
  PaidRemainingCurrency,
  CostLabelV2,
} from './BudgetItemView.styles';
import BudgetConfirmDeleteModal from '../BudgetConfirmDeleteModal/BudgetConfirmDeleteModal';
import BudgetDeleteEntityButton from '../BudgetDeleteEntityButton/BudgetDeleteEntityButton';

type RouteParams = {
  itemUuid: string;
};

export type BudgetItemViewProps = RouteComponentProps<RouteParams, RouteParams> & {
  className?: string;
};

export const BudgetItemView = ({
  className,
  location: { pathname },
  params: { itemUuid },
  route,
  router: { setRouteLeaveHook },
}: BudgetItemViewProps): JSX.Element | null => {
  useLeaveBudgetRoute(route, setRouteLeaveHook);
  const dispatch = useAppDispatch();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const {
    actions: { receiveBudgetItem, receiveBudget, receiveUnsavedItem, clearUnsavedItem },
    state: {
      budget: { taxonomy_nodes = [] },
      item_types = [],
      accountVendors = [],
      unsavedChanges: { unsavedItems },
    },
    isBudgetRevamp,
  } = useBudgetContext();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const deleteTriggerRef = useRef<HTMLButtonElement | null>(null);

  // Form values data initializer
  // If unsavedChanges exist for item, use those values first, otherwise get from API
  const itemFromContext = getBudgetItemByUuid(itemUuid, taxonomy_nodes);
  const item = unsavedItems[itemUuid] || itemFromContext || {};
  const {
    account_vendor,
    cost_cents,
    actual_cost_cents,
    estimate,
    note,
    paid_cents,
    payments,
    suggested_allocation,
    title,
    vendor_type,
    estimated_cost_cents,
  } = item;

  const costProp = isBudgetRevamp ? actual_cost_cents : cost_cents;
  const costPropString = isBudgetRevamp ? 'actual_cost_cents' : 'cost_cents';

  const {
    currencyValue,
    rawCurrencyValue,
    formattedCurrencyValue,
    setCurrencyValue,
    toggleIsEditingCurrency,
  } = useCurrencyInput(((costProp || 0) / 100).toString(), inputRef, [
    isBudgetRevamp ? itemFromContext?.actual_cost_cents : itemFromContext?.cost_cents,
  ]);

  const [localItem, setLocalItem] = useState<
    Pick<UpdateBudgetItemRequest, 'note' | 'estimate' | 'account_vendor_uuid'>
  >({
    account_vendor_uuid: account_vendor?.uuid,
    estimate: estimate || false,
    note: note || '',
  });
  const {
    account_vendor_uuid: localAccountVendorUuid,
    estimate: localEstimate,
    note: localNote,
  } = localItem;
  const localCostCents = Number((parseFloat(rawCurrencyValue) * 100).toFixed(0));

  const {
    display_name,
    searchable_vendor_type: isBookableVendorType,
  } = mapItemTypeToItemTypeDetails(item_types, vendor_type);
  const bookedVendor = mapItemToBookedVendor(accountVendors, account_vendor?.uuid);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showVendorPrompt, setShowVendorPrompt] = useState(false);
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [currencyInputHasBlurred, setCurrencyInputHasBlurred] = useState(
    parseFloat(currencyValue) > 0 // this state is only relevant to items with no cost added on init; otherwise set true
  );
  // shouldShowVendorSrp does not include the call to user settings, that is done in child component if all of these criteria are true
  const shouldShowVendorSrp =
    currencyInputHasBlurred &&
    !!isBookableVendorType &&
    !bookedVendor &&
    !payments?.length &&
    parseFloat(rawCurrencyValue) > 0;
  const saveDisabled = (localNote && localNote.length > 200) || isSubmitting;

  const handleCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasUnsavedChanges(true);
    setCurrencyValue(e.target.value);
  };

  const handleEstimateChange = () => {
    setHasUnsavedChanges(true);
    setLocalItem(prevValue => ({ ...prevValue, estimate: !prevValue.estimate }));
  };

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setHasUnsavedChanges(true);
    setLocalItem(prevValue => ({ ...prevValue, note: value }));
  };

  const handleStashChanges = () => {
    if (hasUnsavedChanges) {
      // stash changes for when user comes back
      receiveUnsavedItem(itemUuid, {
        ...localItem,
        [costPropString]: localCostCents,
      });
    }
  };

  const handleStashChangesAndChangeRoute = (path: string) => {
    handleStashChanges();
    handleBudgetRouteChange(path, { preventIfUnsaved: false, previousPathname: pathname });
  };

  const handleCancel = () => {
    handleBudgetRouteChange();
  };

  const handleSave = (removeVendor = false) => {
    const request = {
      ...mapItemToUpdateItemRequest(item, isBudgetRevamp),
      ...(removeVendor && { account_vendor_uuid: undefined }),
      [costPropString]: localCostCents,
      note: localNote,
      ...(!isBudgetRevamp && { estimate: localEstimate }),
    };

    setHasUnsavedChanges(false);
    setIsSubmitting(true);
    updateBudgetItem(request)
      .then(res => {
        receiveBudgetItem(res);
        setLocalItem(prevValue => ({
          ...prevValue,
          [costPropString]: (res.actual_cost_cents || 0) / 100,
          estimate: res.estimate,
          note: res.note,
        }));
        dispatch(
          toastsActions.positive({
            headline: 'Updated',
          })
        );
        if (removeVendor) {
          setIsSubmitting(false);
          setShowVendorPrompt(false);
        } else handleBudgetRouteChange('', { preventIfUnsaved: false });
      })
      // update the budget summary
      .then(getBudgetByAccountId)
      .then(res => {
        receiveBudget(res);
      })
      .catch(() => {
        setHasUnsavedChanges(true);
        setIsSubmitting(false);
        dispatch(
          toastsActions.negative({
            headline: 'There was an error updating your budget item.',
          })
        );
      });
  };

  const onDelete = () => {
    setShowDeletePrompt(false);
    handleBudgetRouteChange();
  };

  const handleDisconnectLinkedVendor = () => handleSave(true);

  const onCurrencyBlur = () => {
    if (!currencyInputHasBlurred) setCurrencyInputHasBlurred(true);
    toggleIsEditingCurrency();
  };

  // Handles coming back to BudgetItemView if there were unsaved changes here
  useEffectOnce(() => {
    if (unsavedItems[itemUuid]) {
      setHasUnsavedChanges(true);
      // component was initialized with any unsaved changes, so clear them on mount
      clearUnsavedItem(itemUuid);
    }
  });

  useBudgetDrawerLayer(
    {
      key: pathname,
      cancelButtonDisabled: isSubmitting,
      saveButtonDisabled: saveDisabled,
      onSave: hasUnsavedChanges ? () => handleSave() : handleCancel,
      onBack: handleCancel,
      onUnsavedChanges: hasUnsavedChanges
        ? () => {
            handleBudgetRouteChange(`/item/${itemUuid}`);
          }
        : undefined,
    },
    isSubmitting,
    [localItem, rawCurrencyValue]
  );

  // Close drawer if budget item doesn't exist for user
  if (!itemFromContext?.uuid) {
    handleBudgetRouteChange();
    return null;
  }

  return (
    <Container data-testid="BudgetItemView" className={className}>
      <BudgetItemViewTitle
        type={isBookableVendorType ? display_name : undefined}
        title={title}
        itemUuid={itemUuid}
        handleStashChangesAndChangeRoute={handleStashChangesAndChangeRoute}
      />
      {isBookableVendorType && !isBudgetRevamp && (
        <BudgetItemViewVendorCtas
          bookedVendor={localAccountVendorUuid ? bookedVendor : undefined}
          typeDisplayName={isBookableVendorType ? display_name : undefined}
          vendorType={vendor_type}
          itemUuid={itemUuid}
          handleDisconnectAccountVendor={() => setShowVendorPrompt(true)}
          handleStashChangesAndChangeRoute={handleStashChangesAndChangeRoute}
        />
      )}
      {isBudgetRevamp && (
        <BudgetItemViewVendorCtasV2
          esimtatedCostCents={estimated_cost_cents}
          bookedVendor={localAccountVendorUuid ? bookedVendor : undefined}
          isBookableVendorType={!!isBookableVendorType}
          vendorType={vendor_type}
          itemType={item.item_type as string | undefined}
          itemUuid={itemUuid}
          handleDisconnectAccountVendor={() => setShowVendorPrompt(true)}
          handleStashChangesAndChangeRoute={handleStashChangesAndChangeRoute}
        />
      )}
      {isBudgetRevamp ? (
        <PaidRemaining isBudgetRevamp>
          <span>Estimate</span>
          <PaidRemainingCurrency data-testid="BudgetItemView-Estimate">
            {Boolean(estimated_cost_cents) && (
              <LinkV2
                role="button"
                onClick={() => handleStashChangesAndChangeRoute('/estimates')}
                subtle
              >
                {mapCostToFormattedString(estimated_cost_cents)}
              </LinkV2>
            )}
          </PaidRemainingCurrency>
          <CostLabelV2 htmlFor="actual_cost_cents">Actual cost</CostLabelV2>
          <CostFields>
            <InputFieldV3
              name="actual_cost_cents"
              id="actual_cost_cents"
              onChange={handleCostChange}
              onFocus={toggleIsEditingCurrency}
              onBlur={onCurrencyBlur}
              addOn={InputFieldAddOnType.CASH}
              value={currencyValue}
              isControlled
              maxLength={17}
              ref={inputRef}
            />
          </CostFields>
          <span>Paid</span>
          <PaidRemainingCurrency data-testid="BudgetItemView-Paid">
            {mapCostToFormattedString(paid_cents)}
          </PaidRemainingCurrency>
          <span>Remaining due</span>
          <PaidRemainingCurrency data-testid="BudgetItemView-Due">
            {mapCostToFormattedString(localCostCents - (paid_cents || 0))}
          </PaidRemainingCurrency>
        </PaidRemaining>
      ) : (
        <>
          <BudgetItemViewVendorSrpLink
            showLink={shouldShowVendorSrp}
            vendor_type={vendor_type}
            vendorDisplayName={display_name}
            formattedCurrencyValue={formattedCurrencyValue}
            rawCurrencyValue={rawCurrencyValue}
          />
          <CostContainer>
            <div>
              <CostLabel htmlFor="cost_cents">Cost</CostLabel>
              {suggested_allocation && (
                <SuggestedAllocation>
                  Experts recommend spending {suggested_allocation.display_percentage} of your
                  budget
                  {suggested_allocation.allocated_cost_cents &&
                    `, or ${mapCostToFormattedString(suggested_allocation.allocated_cost_cents)}`}
                  .
                </SuggestedAllocation>
              )}
            </div>
            <CostFields>
              <InputFieldV3
                name="cost_cents"
                id="cost_cents"
                onChange={handleCostChange}
                onFocus={toggleIsEditingCurrency}
                onBlur={onCurrencyBlur}
                addOn={InputFieldAddOnType.CASH}
                value={currencyValue}
                isControlled
                maxLength={17}
                ref={inputRef}
              />
              <StyledCheckboxField
                name="estimate"
                label="This is an estimate"
                checked={localEstimate}
                onChange={handleEstimateChange}
              />
            </CostFields>
          </CostContainer>
        </>
      )}
      <BudgetItemViewPayments
        paidCents={paid_cents}
        costCents={localCostCents}
        payments={payments}
        itemUuid={itemUuid}
        handleStashChanges={handleStashChanges}
      />
      <NotesLabel htmlFor="note">
        Notes <Optional>(optional)</Optional>
      </NotesLabel>
      <TextareaFieldV2
        name="note"
        id="note"
        onChange={handleNoteChange}
        value={localNote}
        placeholder="Use this space to note deposits, related contact info, cancellation deadlines, etc."
        maxChars={200}
      />
      {isBudgetRevamp && (
        <BudgetDeleteEntityButton
          ref={deleteTriggerRef}
          onDelete={() => setShowDeletePrompt(true)}
          type="item"
        />
      )}
      {showVendorPrompt && (
        <BudgetConfirmDeleteMiniModal
          hed="Remove from your budget?"
          dek="They will still appear as one of your booked vendors."
          confirmText="Yes, remove"
          onConfirm={handleDisconnectLinkedVendor}
          onCancel={() => setShowVendorPrompt(false)}
        />
      )}
      {showDeletePrompt && (
        <BudgetConfirmDeleteModal
          uuid={itemUuid}
          type="item"
          hed={`Are you sure you want to delete ${item?.title}?`}
          triggerRef={deleteTriggerRef}
          onDelete={onDelete}
          onCancel={() => setShowDeletePrompt(false)}
        />
      )}
    </Container>
  );
};
