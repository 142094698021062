import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  COLORS3 as COLORS,
  FONT,
  MEDIA_QUERY,
  SPACING,
  ZINDEX,
} from '@zola/zola-ui/src/styles/emotion';
import { StyledDayPicker } from '@zola/zola-ui/src/components/Form/DatePickerInputV2/DatePickerInputV2/DatePickerInputV2.styles';

export const Container = styled.div`
  background-color: ${COLORS.WHITE_100};

  ${StyledDayPicker} {
    z-index: ${ZINDEX.top + 1} !important;
    padding-bottom: ${SPACING.LG};
  }

  ${MEDIA_QUERY.DESKTOP} {
    padding-left: 74px;
  }
`;

export const InnerContainer = styled.div`
  min-height: 100vh;
  margin: auto;
`;

export const FlexContainer = styled.div`
  display: flex;

  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    flex-direction: column;
  }
`;

const Left = styled.div`
  display: flex;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`;

const RightColumn = styled.div<{ hideOnMobileTablet?: boolean }>`
  width: 88px;
  margin-left: ${SPACING.MD};
  ${MEDIA_QUERY.EXCLUDE_DESKTOP} {
    display: ${p => (p.hideOnMobileTablet ? 'none' : '')};
  }
`;

const rowButton = css`
  width: 100%;
  height: fit-content;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid ${COLORS.BLACK_010};
  font-weight: ${FONT.WEIGHT.REGULAR};
`;

export const sharedStyles = { Left, Right, RightColumn, rowButton };
