import styled from '@emotion/styled';

import { COLORS3, FONT } from '@zola/zola-ui/src/styles/emotion';

import { UnsavedChangesPrompt } from 'components/common/zolaUI/Drawer/Drawer.styles';

export const Container = styled(UnsavedChangesPrompt)`
  max-width: 312px;
`;

export const PromptDek = styled.p`
  &:empty {
    display: none;
  }
  color: ${COLORS3.BLACK_100};
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
`;
