import React from 'react';

import { TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';

import type { WBudgetItemPaymentView } from '@zola/svc-web-api-ts-client';

import { getBudgetPaymentStatus, BudgetPaymentStatus } from '../util/mappers';

import { StyledTagV2 } from './BudgetPaymentTag.styles';

export type BudgetPaymentTagProps = {
  payment: WBudgetItemPaymentView;
};

export const BudgetPaymentTag = ({ payment }: BudgetPaymentTagProps): JSX.Element | null => {
  const type = getBudgetPaymentStatus(payment);

  const tagTypes = {
    [BudgetPaymentStatus.OVERDUE]: {
      variant: TagV2Variant.RED,
      text: BudgetPaymentStatus.OVERDUE,
    },
    [BudgetPaymentStatus.PAID]: {
      variant: TagV2Variant.GREEN,
      text: BudgetPaymentStatus.PAID,
    },
    [BudgetPaymentStatus.UPCOMING]: {
      variant: TagV2Variant.GRAY,
      text: BudgetPaymentStatus.UPCOMING,
    },
  };

  return <StyledTagV2 variant={tagTypes[type].variant}>{tagTypes[type].text}</StyledTagV2>;
};
