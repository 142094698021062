import _get from 'lodash/get';
import _uniq from 'lodash/uniq';
import _isEqual from 'lodash/isEqual';
import { getNewSpreadIndex } from 'photobooks/utils/getNewSpreadIndex';

const init = {
  busy: true,
  error: null,
  albumDetails: null,
  albumProperties: null,
  albumVariation: null,
  activeSpread: null,
  slotUpdating: false,
  updatingSpreadIndexes: false,
  syncedViewedSpreads: [],
  viewedSpreads: [],
};

const getViewedSpreadData = (oldViewedSpreads, newSpreads) => {
  const syncedViewedSpreads = (newSpreads || [])
    .filter(spread => !!spread.viewed)
    .map(spread => spread.spread_index)
    .sort((a, b) => a - b);
  const viewedSpreads = _isEqual(oldViewedSpreads, syncedViewedSpreads)
    ? oldViewedSpreads
    : syncedViewedSpreads;

  return { syncedViewedSpreads, viewedSpreads };
};

const addViewedSpread = (currentViewedSpreads, newSpreadIndex) => {
  const proposed = _uniq([...currentViewedSpreads, newSpreadIndex]).sort((a, b) => a - b);
  return _isEqual(currentViewedSpreads, proposed) ? currentViewedSpreads : proposed;
};

const reducer = (state = init, action) => {
  switch (action.type) {
    case 'FETCH_ALBUM_ITEM_BY_UUID':
      return {
        ...state,
        error: null,
      };
    case 'FETCH_ALBUM_ITEM_BY_UUID_FULFILLED': {
      const albumItem = action.payload.albumItem || {};
      const activeSpreadIndex = action.payload.activeSpreadIndex || 0;
      return {
        ...state,
        albumItemUuid: albumItem.uuid,
        albumDetails: albumItem.customization_data,
        albumProperties: albumItem.details,
        albumVariation: albumItem.variation,
        activeSpread: albumItem.customization_data.spreads[activeSpreadIndex],
        busy: false,
        ...getViewedSpreadData(state.viewedSpreads, albumItem.customization_data.spreads),
      };
    }
    case 'FETCH_ALBUM_ITEM_BY_UUID_REJECTED':
      return {
        ...state,
        busy: false,
        error: action.payload,
      };
    case 'REQUEST_SLOT_UPDATE': {
      return {
        ...state,
        slotUpdating: true,
        error: null,
      };
    }
    case 'RECEIVE_SLOT_UPDATE': {
      const { activeSpread } = state;
      return {
        ...state,
        albumDetails: action.payload.customization_data,
        activeSpread: action.payload.customization_data.spreads[activeSpread.spread_index],
        slotUpdating: false,
      };
    }
    case 'REJECTED_SLOT_UPDATE': {
      return {
        ...state,
        slotUpdating: false,
        error: action.payload,
      };
    }
    case 'NAVIGATE_ACTIVE_SPREAD': {
      const navDirection = action.payload;
      const { activeSpread } = state;
      const totalSpreadLastIndex = state.albumDetails.spreads.length - 1;
      const newSpreadIndex = getNewSpreadIndex(
        activeSpread.spread_index,
        navDirection,
        totalSpreadLastIndex
      );
      const newActiveSpread = state.albumDetails.spreads[newSpreadIndex];
      return {
        ...state,
        activeSpread: newActiveSpread,
        viewedSpreads: addViewedSpread(state.viewedSpreads, newActiveSpread.spread_index),
      };
    }
    case 'UPDATE_ACTIVE_SPREAD': {
      const newSpreadIndex = action.payload;
      const newActiveSpread = state.albumDetails.spreads.find(spread => {
        return spread.spread_index === newSpreadIndex;
      });

      return {
        ...state,
        activeSpread: newActiveSpread,
        viewedSpreads: addViewedSpread(state.viewedSpreads, newSpreadIndex),
      };
    }
    case 'CLEAR_ALBUM': {
      return {
        ...state,
        busy: true,
        albumItemUuid: null,
        albumDetails: null,
        albumProperties: null,
        albumVariation: null,
        activeSpread: null,
        syncedViewedSpreads: [],
        viewedSpreads: [],
      };
    }
    case 'REQUEST_ADD_SPREAD': {
      return {
        ...state,
        updatingSpreadIndexes: true,
      };
    }
    case 'RECEIVE_ADD_SPREAD': {
      const { newActiveSpreadIndex, albumItem } = action.payload;
      const allSpreads = _get(albumItem, ['customization_data', 'spreads'], []);
      return {
        ...state,
        albumDetails: _get(albumItem, ['customization_data']) || {
          ...state.albumDetails,
        },
        activeSpread: allSpreads[newActiveSpreadIndex],
        updatingSpreadIndexes: false,
        ...getViewedSpreadData(state.viewedSpreads, allSpreads),
      };
    }
    case 'REJECTED_ADD_SPREAD': {
      return {
        ...state,
        updatingSpreadIndexes: false,
        error: action.payload,
      };
    }
    case 'REQUEST_REMOVE_SPREAD': {
      return {
        ...state,
        updatingSpreadIndexes: true,
      };
    }
    case 'RECEIVE_REMOVE_SPREAD': {
      const { newActiveSpreadIndex, albumItem } = action.payload;
      const allSpreads = _get(albumItem, ['customization_data', 'spreads'], []);
      return {
        ...state,
        albumDetails: _get(albumItem, ['customization_data']) || {
          ...state.albumDetails,
        },
        activeSpread: allSpreads[newActiveSpreadIndex],
        updatingSpreadIndexes: false,
        ...getViewedSpreadData(state.viewedSpreads, allSpreads),
      };
    }
    case 'REJECTED_REMOVE_SPREAD': {
      return {
        ...state,
        updatingSpreadIndexes: false,
        error: action.payload,
      };
    }
    case 'REQUEST_UPDATE_LAYOUT':
    case 'REQUEST_MOVE_SPREAD': {
      return {
        ...state,
        updatingSpreadIndexes: true,
      };
    }
    case 'RECEIVE_UPDATE_LAYOUT':
    case 'RECEIVE_MOVE_SPREAD': {
      const { newActiveSpreadIndex, albumItem } = action.payload;
      const allSpreads = _get(albumItem, ['customization_data', 'spreads'], []);
      return {
        ...state,
        albumDetails: _get(albumItem, ['customization_data']) || {
          ...state.albumDetails,
        },
        activeSpread: allSpreads[newActiveSpreadIndex],
        updatingSpreadIndexes: false,
        ...getViewedSpreadData(state.viewedSpreads, allSpreads),
      };
    }
    case 'REJECTED_UPDATE_LAYOUT':
    case 'REJECTED_MOVE_SPREAD': {
      return {
        ...state,
        updatingSpreadIndexes: false,
        error: action.payload,
      };
    }
    case 'MARK_SPREAD_AS_VIEWED': {
      return {
        ...state,
        viewedSpreads: addViewedSpread(state.viewedSpreads, action.payload.spreadIndex),
      };
    }
    case 'RECEIVE_SAVE_VIEWED_SPREADS': {
      return {
        ...state,
        ...getViewedSpreadData(state.viewedSpreads, action.payload.spreads),
      };
    }
    case 'REJECTED_SAVE_VIEWED_SPREADS': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'CLEAR_SLOT_IMAGE': {
      const { slotUuidsToClear } = action.payload;

      return {
        ...state,
        albumDetails: {
          ...state.albumDetails,
          spreads: state.albumDetails.spreads.map(spread => ({
            ...spread,
            layouts: spread.layouts.map(layout => ({
              ...layout,
              slots: layout.slots.map(slot => ({
                ...slot,
                image_uuid: slotUuidsToClear.includes(slot.image_uuid) ? null : slot.image_uuid,
              })),
            })),
          })),
        },
        activeSpread: {
          ...state.activeSpread,
          layouts: state.activeSpread.layouts.map(layout => ({
            ...layout,
            slots: layout.slots.map(slot => ({
              ...slot,
              image_uuid: slotUuidsToClear.includes(slot.image_uuid) ? null : slot.image_uuid,
            })),
          })),
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
