import styled from '@emotion/styled';

import { COLORS3, FONT, SPACING, ZINDEX } from '@zola/zola-ui/src/styles/emotion';

import { Input } from '@zola/zola-ui/src/components/Form/inputV3/InputFieldV3/InputFieldV3.styles';
import { InfoCircleIcon } from '@zola/zola-ui/src/components/SvgIconsV3/InfoCircle';
import TooltipV2 from '@zola/zola-ui/src/components/TooltipV2';
import { Title3 } from '@zola/zola-ui/src/typography/Headings';

import { DrawerTitle } from 'components/common/zolaUI/Drawer';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';

export const EstimateContainer = styled.div`
  margin-top: ${SPACING.LG};
`;

export const StyledTitle3 = styled(Title3)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${SPACING.LG};
`;

export const EstimateTitle = styled.span`
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)};
`;

export const StyledDrawerTitle = styled(DrawerTitle)`
  margin-bottom: ${SPACING.XS};
`;

export const Top = styled.div`
  gap: ${SPACING.S24};
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACING.S24};
`;

export const TotalBudget = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 50%;
  ${FONT.textSizeUltraCompact(FONT.SIZE.REGULAR)}
  gap: ${SPACING.XS};
  position: relative;
`;

export const Allocation = styled.div<{ isEstimateMore: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${SPACING.XXS};
  border-radius: ${SPACING.XS};
  padding: ${SPACING.S16};
  text-align: center;
  background-color: ${({ isEstimateMore }) =>
    isEstimateMore ? COLORS3.BLUSH_100 : COLORS3.MINT_100};
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}

  ${MEDIA_QUERY_V2.DESKTOP} {
    height: 80px;
    padding: 0;
  }
`;

export const StyledInfoCircleIcon = styled(InfoCircleIcon)`
  color: ${COLORS3.BLACK_050};
`;

export const StyledTooltipV2 = styled(TooltipV2)`
  position: absolute;
  z-index: ${ZINDEX.middle};
  bottom: ${SPACING.S32};
  right: 6px;
  width: 172px;
`;

export const InputContainer = styled.div`
  max-width: 160px;

  ${Input} {
    text-align: right;
  }
`;

export const HoverDeleteButton = styled(LinkV2)`
  position: absolute;
  right: -30px;
  top: ${SPACING.S24};
  opacity: 0;
  transition: opacity 0.5s;

  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    display: none;
  }
`;

export const Row = styled.div`
  position: relative;

  &:hover {
    ${HoverDeleteButton} {
      opacity: 1;
    }
  }
`;
