import React, { ReactElement } from 'react';
import { Link } from '@zola/zola-ui/src/components/Link';
import dayjs, { ConfigType as DayjsConfigType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import objectSupport from 'dayjs/plugin/objectSupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  REQUIRED,
  EMAIL,
  ZIP,
  PHONE,
  PASSWORD,
  SLUG,
  TIME,
  URL,
  DATE_WITHIN_VALID_RANGE,
  DATE_AFTER_NOW,
  GREATER_THAN_ZERO,
  GREATER_THAN_OR_EQUAL_TO_ZERO,
  NUMBER,
  INSTAGRAM_HANDLE,
  maxLength,
  MAX_LENGTH_5,
  MAX_LENGTH_15,
  MAX_LENGTH_20,
  MAX_LENGTH_25,
  MAX_LENGTH_30,
  MAX_LENGTH_35,
  MAX_LENGTH_50,
  MAX_LENGTH_40,
  MAX_LENGTH_60,
  MAX_LENGTH_80,
  MAX_LENGTH_100,
  MAX_LENGTH_150,
  MAX_LENGTH_200,
  MAX_LENGTH_250,
  MAX_LENGTH_400,
  MAX_LENGTH_500,
  MAX_LENGTH_800,
  MAX_LENGTH_1000,
  MAX_LENGTH_1500,
  MAX_LENGTH_2000,
  MAX_LENGTH_5000,
  URL_REGEX,
  DATE_REGEX,
} from '@zola/zola-ui/src/components/Form/util/validations';
import { combineDateAndTime } from 'util/combineDateAndTime';

const MAX_LENGTH_4000 = maxLength(4000);

dayjs.extend(utc);
dayjs.extend(objectSupport);
dayjs.extend(customParseFormat);

export const INITIAL_REGEX = /^[a-zA-Z]{1}$/;
export const COLOR_REGEX = /^#([A-Fa-f0-9]{3}$)|([A-Fa-f0-9]{6}$)/;

export enum validationMsg {
  DATE = 'Invalid date',
  NOT_ZOLA = 'Your Zola registry not showing up? Call us at 408-657-9652 to add it.',
  URL_MSG = 'Invalid URL',
}

export const COLOR = (value: string | number): boolean => {
  const normalizedValue = value?.toString();
  return COLOR_REGEX.test(normalizedValue);
};

export const DATE = (value: string): validationMsg.DATE | undefined => {
  if (typeof value === 'object') {
    return undefined;
  }
  return value && !DATE_REGEX.test(value) ? validationMsg.DATE : undefined;
};

Number.isInteger =
  Number.isInteger ||
  // eslint-disable-next-line func-names
  function (value: number): boolean {
    // eslint-disable-next-line no-restricted-globals
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  };

const ZOLA_REGEX = /zola/i;
const ZOLA_DOT_COM_REGEX = /zola\.com/i;

export const NOT_ZOLA = (value: string | undefined): validationMsg.NOT_ZOLA | undefined => {
  if (!value) {
    return undefined;
  }
  if (ZOLA_REGEX.test(value) && ZOLA_DOT_COM_REGEX.test(value)) {
    return validationMsg.NOT_ZOLA;
  }
  return undefined;
};

export const VALIDATE_URL = (urlValue: string | undefined): validationMsg.URL_MSG | undefined => {
  if (urlValue && URL_REGEX.test(urlValue)) {
    return validationMsg.URL_MSG;
  }
  return undefined;
};

export const CHECK_CURRENT_WEDDING_DATE = (
  value: DayjsConfigType,
  input: { currentlySelectedDate?: Date; registryIsPublic?: boolean }
): ReactElement | undefined => {
  const currentlySelectedDate = dayjs(input.currentlySelectedDate);
  const { registryIsPublic } = input;
  const now = dayjs();
  const weddingDateHasPassed = currentlySelectedDate.isBefore(now, 'day');
  if (registryIsPublic && weddingDateHasPassed) {
    return (
      <>
        Your wedding date has passed. Need help updating it to a future date? Reach out to us{' '}
        <Link inline target="_blank" rel="noopener noreferrer" href="/contact-us">
          here
        </Link>
        .
      </>
    );
  }
  return undefined;
};

export const AFTER_START_DATE_TIME = (
  startDate: DayjsConfigType,
  startTime: DayjsConfigType,
  endDate: DayjsConfigType,
  endTime: DayjsConfigType
) => {
  // If no end date is provided, assume it's the same day as the start.
  const validEndDate = endDate || startDate;

  // No need to validate if there's no end time defined
  if (validEndDate && endTime) {
    // Can't validate without start info
    if (!startDate || !startTime) return 'Start time required';

    const start = combineDateAndTime(startDate, startTime);
    const end = combineDateAndTime(validEndDate, endTime);
    if (end.isBefore(start)) {
      return 'End time must be after start time';
    }
  }

  return undefined;
};

export const MIN_NUMBER_1000 = (val: string | number | undefined): string | undefined => {
  if (val && Number(val) < 1000) {
    return 'Minimum value is $1000';
  }
  return undefined;
};

export {
  DATE_AFTER_NOW,
  DATE_REGEX,
  DATE_WITHIN_VALID_RANGE,
  EMAIL,
  GREATER_THAN_OR_EQUAL_TO_ZERO,
  GREATER_THAN_ZERO,
  INSTAGRAM_HANDLE,
  maxLength,
  NUMBER,
  PASSWORD,
  PHONE,
  REQUIRED,
  SLUG,
  TIME,
  URL_REGEX,
  URL,
  ZIP,
  MAX_LENGTH_5,
  MAX_LENGTH_15,
  MAX_LENGTH_20,
  MAX_LENGTH_25,
  MAX_LENGTH_30,
  MAX_LENGTH_35,
  MAX_LENGTH_50,
  MAX_LENGTH_40,
  MAX_LENGTH_60,
  MAX_LENGTH_80,
  MAX_LENGTH_100,
  MAX_LENGTH_150,
  MAX_LENGTH_200,
  MAX_LENGTH_250,
  MAX_LENGTH_400,
  MAX_LENGTH_500,
  MAX_LENGTH_800,
  MAX_LENGTH_1000,
  MAX_LENGTH_1500,
  MAX_LENGTH_2000,
  MAX_LENGTH_4000,
  MAX_LENGTH_5000,
};
