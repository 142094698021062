import type { WAlbumView } from '@zola/svc-web-api-ts-client';

export interface AlbumDetailsState {
  initialized: boolean;
  busy: boolean;
  list: WAlbumView[] | null;
}

export const FETCH_ALBUM_DETAILS = 'FETCH_ALBUM_DETAILS';
export interface FetchAlbumDetailsType {
  type: typeof FETCH_ALBUM_DETAILS;
}

export const FETCH_ALBUM_DETAILS_FULFILLED = 'FETCH_ALBUM_DETAILS_FULFILLED';
export interface FetchAlbumDetailsFulfilledType {
  type: typeof FETCH_ALBUM_DETAILS_FULFILLED;
  payload: WAlbumView[];
}

export type AlbumDetailsActionType = FetchAlbumDetailsType | FetchAlbumDetailsFulfilledType;
