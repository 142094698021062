import type { CustomizationStep } from '@zola/zola-ui/src/paper/cards/constants/customizationSteps';
import { TITLE_SINGULAR_BY_CARD_TYPE } from '@zola/zola-ui/src/paper/cards/constants/titleSingularByCardType';

export const STEP_LABELS: Record<CustomizationStep, string> = {
  addMorePaper: 'Add More',
  addressing: 'Addressing',
  addressingPreview: 'Preview',
  detailAssign: 'Assign',
  detailBack: 'Back',
  detailFront: 'Front',
  detailInside: 'Inside',
  details: 'Details',
  detailWrite: 'Write Notes',
  envelope: 'Envelope',
  freeSamples: 'Free Samples',
  front: 'Front',
  links: 'Links',
  recipients: 'Guests',
  reviewPaper: 'Review',
  rsvp: 'RSVP Card',
  rsvpEnvelope: 'RSVP Envelope',
  share: 'Share',
} as const;

// Used only in the subtotal summary dropdown
export const STEP_DESCRIPTIONS: Record<CustomizationStep, string> = {
  addMorePaper: 'Add matching programs, menus, and place cards',
  addressing: 'Add guest addressing',
  addressingPreview: 'Add guest addressing',
  detailAssign: 'Assign Guests',
  detailBack: 'Print on the back',
  detailFront: 'Print on the front (required)',
  detailInside: 'Print on the inside',
  details: 'Digital save the date',
  detailWrite: 'Print on the inside',
  envelope: 'Print mailing address',
  freeSamples: 'Digital save the date',
  front: 'Digital save the date',
  links: 'Digital save the date',
  recipients: 'Add guest addressing',
  reviewPaper: 'reviewPaper',
  rsvp: 'Add RSVP cards & envelopes',
  rsvpEnvelope: 'Print your return address',
  share: 'Digital save the date',
} as const;

export const BREADCRUMB_GROUP_LABELS = {
  ...STEP_LABELS,
  ...TITLE_SINGULAR_BY_CARD_TYPE,
} as const;
